import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ValidatorService } from '../validator.service';
import { armadoJson, ListaJSON } from '../datosgral/datosgral.component';
import {
  datosfisico,
  DialogData,
  estrucM,
  OtrosArchivos
} from '../confarmado/confarmado.component';
import { Router } from '@angular/router';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { resultOT } from '../models/resultOT';
import { OTModel } from '../models/OTModel';
import { appSettings } from '../appSettings';
import { resultFile } from '../models/resultFile';
import { EventEmitterService } from '../event-emitter.service';

// tslint:disable-next-line: class-name
interface jsonFisico {
  idOrden: string;
  armados: any;
  fisico: any;
}
interface listadoA {
  tipo: string;
  ramo: string;
  numConPol: string;
  listaPolizas: any[];
  uniquePDF: Boolean;
  nombreuniquePDF: string;
  armadoEstructura: any[];
}
let listado: listadoA = {
  tipo: '',
  ramo: '',
  numConPol: '',
  listaPolizas: [],
  uniquePDF: null,
  nombreuniquePDF: '',
  armadoEstructura: []
};
let listadoM: listadoA[] = [];
interface digital {
  contacto: any;
  listaArmados: any[];
}
interface jsonDigital {
  idOrden: string;
  armados: any;
  digital: any;
}
let jsonF: jsonFisico = {
  idOrden: '',
  armados: {},
  fisico: {}
};
let jsonD: jsonDigital = {
  idOrden: '',
  armados: {},
  digital: {}
};
interface DatosNoti {
  nombre: string;
  email: string[];
  celular: string[];
}
let datosNoti: DatosNoti = {
  nombre: '',
  email: null,
  celular: null
};

// Variables que viajan para expandir o contraer los paneles
export let expandPanelCAfromDN: boolean;

@Component({
  selector: 'app-notificacion',
  templateUrl: './notificacion.component.html',
  styleUrls: ['./notificacion.component.scss']
})
export class NotificacionComponent implements OnInit {
  // Variables locales para el panel
  expandPanelDatosNotifLoc: boolean;

  // Descarga Json file
  private setting = {
    element: {
      dynamicDownload: null as HTMLElement
    }
  };

  TxtStep3 = 'Datos Notificación';
  Txtlbl3 = 'Nombre de destinatario';
  Txtlbl4 = 'Correo electrónico';
  Txtlbl5 = 'Celular';
  Txtlbl6 = 'Correo opcional';
  TxtbtnCnl = 'Cancelar';
  Txtbtn3 = 'Guardar';
  TxtbtnEnv = 'Enviar';
  TxtNot2 = 'Se ha guardado la solicitud con folio ';
  TxtNot3 = 'Tu solicitud ha sido creada con éxito con el folio ';
  TxtNot4 = ' guardada';
  TxtNot5 = ' enviada';
  placeholder = 'Seleccione un criterio';
  TxtNtf1 = 'Campo obligatorio';
  TxtNot1 = 'Solictitud';
  TxtNotTil = '';
  TxtNotMsg = '';
  mensajeError = '';
  profileForm: FormGroup;
  notiDatosNoti: boolean;
  ramo: string;
  correoActual: boolean;
  correoActualOp: boolean;

  nombreDestinatario = new FormControl('', [
    Validators.required,
    Validators.maxLength(20)
  ]);
  correo = new FormControl('', [
    Validators.required,
    Validators.email,
    Validators.maxLength(30)
  ]);
  celular = new FormControl('', [
    Validators.pattern('^[0-9]{0,10}$'),
    Validators.maxLength(10)
  ]);
  correoOpcional = new FormControl('', [Validators.email]);
  // variables para habilitar los botones de 'Guardar' y 'Enviar'
  SaveDes: Boolean = true;
  SendDes: Boolean = true;

  // variable y funcion para mostrar la notitifacion
  notificacion: Boolean;

  // variable para indicar si Guardar/Enviar
  auxMsg: string;

  constructor(
    private eventEmitterService: EventEmitterService,
    private formBuilder: FormBuilder,
    private serviceValidator: ValidatorService,
    private router: Router,
    public dialog: MatDialog,
    protected http: HttpClient //   private http: HttpClient
  ) {}

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogCancelar, {
      width: '250px',
      data: {}
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit(): void {
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeSecondComponentFunction.subscribe(
          (name: string) => {
            this.onSubmit(name);
          }
        );
    }

    // Formularios
    this.profileForm = this.formBuilder.group({
      nombreDestinatario: ['', Validators.required],
      correo: ['', Validators.required, Validators.email],
      celular: ['', Validators.pattern('^[0-9]{0,10}$')],
      correoOpcional: ['', Validators.email]
    });
    // this.router.navigate(['./home']);
  }

  iniDatosNotificacion() {
    /*expandPanelCAfromDN = expandPanelDatosNotif;
    this.expandPanelDatosNotifLoc = expandPanelDatosNotif;*/
  }

  actualizaValor() {
    if (this.serviceValidator.esEmailValido(this.profileForm.value.correo)) {
      this.correoActual = true;
      const correoAct = this.profileForm.value.correo;
      this.profileForm.controls['correo'].setErrors(null);
    } else {
      this.correoActual = false;
    }
  }

  actualizaValorOp() {
    if (
      this.serviceValidator.esEmailValido(this.profileForm.value.correoOpcional)
    ) {
      this.correoActualOp = true;
      const correoActOp = this.profileForm.value.correoOpcional;
      this.profileForm.controls['correoOpcional'].setErrors(null);
    } else {
      this.correoActualOp = false;
    }
  }

  numOrden: number;
  onSubmit(buttonType: any) {
    console.log(buttonType);

    if (
      this.profileForm.value.nombreDestinatario !== '' &&
      this.profileForm.value.correo !== ''
    ) {
      this.notiDatosNoti = false;
      let validaCorreoOp = true;
      if (
        this.profileForm.value.correoOpcional !== '' &&
        this.profileForm.value.correoOpcional !== null
      ) {
        validaCorreoOp = this.correoActualOp;
      }
      let validaCel = true;
      const celStr =
        this.profileForm.value.celular !== null
          ? this.profileForm.value.celular.toString()
          : this.profileForm.value.celular;
      if (
        this.profileForm.value.celular !== '' &&
        this.profileForm.value.celular !== null &&
        celStr.length !== 10
      ) {
        validaCel = false;
      }

      if (
        this.serviceValidator.esEmailValido(this.profileForm.value.correo) &&
        validaCorreoOp &&
        validaCel
      ) {
        this.notiDatosNoti = false;
        datosNoti = {
          nombre: this.profileForm.value.nombreDestinatario,
          email: [
            this.profileForm.value.correo,
            this.profileForm.value.correoOpcional
          ],
          celular: [this.profileForm.value.celular]
        };
        if (buttonType === 'Enviar') {
          var auxOT: resultOT;
          var otNumber = Math.floor(Math.random() * (4957 - 1000)) + 1000;
          var jsonSave: OTModel;
          var archivoSave: OTModel;

          if (armadoJson.digital === true) {
            let numArm = 0;
            this.numOrden = otNumber;
            let num = 0;
            for (let conPool of ListaJSON) {
              for (let armCarp of estrucM) {
                if (armCarp.id === conPool.id) {
                  /* listado = {
                      tipo: conPool.tipo,
                      ramo: conPool.ramo,
                      numConPol: conPool.numConPol,
                      listaPolizas: conPool.listaPolizas,
                      uniquePDF: seccionPdfUni.uniquePDF,
                      nombreuniquePDF: seccionPdfUni.nombreuniquePDF,
                      armadoEstructura: armCarp.estructuraCar,
                    }*/
                }
              }
              /* listado = {
                  tipo: conPool.tipo,
                  ramo: conPool.ramo,
                  numConPol: conPool.numConPol,
                  listaPolizas: conPool.listaPolizas,
                  uniquePDF: seccionPdfUni.uniquePDF,
                  nombreuniquePDF: seccionPdfUni.nombreuniquePDF,
                  //armadoEstructura: estrucM[numArm],
                } */
              numArm = numArm + 1;
              num = listadoM.length;
              listadoM[num] = listado;
            }
            /* listado = {
              tipo: listaJson.tipo,
              ramo: listaJson.ramo,
              numConPol: listaJson.numConPol,
              listaPolizas: listaJson.listaPolizas,
              uniquePDF: seccionPdfUni.uniquePDF,
              nombreuniquePDF: seccionPdfUni.nombreuniquePDF,
              armadoEstructura: estrucM,
            }; */
            jsonD = {
              idOrden: this.numOrden.toString(),
              armados: armadoJson,
              digital: {
                contacto: datosNoti,
                listaArmados: listadoM
              }
            };

            var auxb64 = window.btoa(
              unescape(encodeURIComponent(JSON.stringify(jsonD)))
            );
            console.log('Base64: ' + auxb64);

            jsonSave = {
              tipoArchivo: 'json',
              tipoArmado: '',
              guardado: '',
              nombreArchivo: this.numOrden + '.json',
              carpetaArchivo: this.numOrden.toString(),
              otArchivo: this.numOrden.toString(),
              ambiente: 'PROD',
              bytesArchivo: auxb64
            };

            console.log('jsonToSave: ' + JSON.stringify(jsonSave));

            this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
              next: (data) => {
                console.log('jsonToSave: ' + JSON.stringify(data));
              },
              error: (error) => {
                console.error('There was an error!', error);
              }
            });
            for (let archivo of OtrosArchivos) {
              archivoSave = {
                tipoArchivo: 'otro',
                tipoArmado: '',
                guardado: '',
                nombreArchivo: archivo.nombreA,
                carpetaArchivo: this.numOrden.toString(),
                otArchivo: this.numOrden.toString(),
                ambiente: 'PROD',
                bytesArchivo: archivo.base64
              };
              this.http
                .post<resultFile>(appSettings.URL_OT, archivoSave)
                .subscribe({
                  next: (data) => {
                    console.log('ArchivoToSave: ' + archivo);
                  },
                  error: (error) => {
                    console.error('There was an error!', error);
                  }
                });
            }
            this.dyanmicDownloadByHtmlTag({
              fileName: this.numOrden + '.json',
              text: JSON.stringify(jsonD)
            });

            this.mostrarNotificacion(buttonType);
          } else if (armadoJson.fisico === true) {
            this.numOrden = otNumber;

            jsonF = {
              idOrden: this.numOrden.toString(),
              armados: armadoJson,
              fisico: datosfisico
            };

            var auxb64 = window.btoa(
              unescape(encodeURIComponent(JSON.stringify(jsonF)))
            );
            console.log('Base64: ' + auxb64);

            jsonSave = {
              tipoArchivo: 'json',
              tipoArmado: '',
              guardado: '',
              nombreArchivo: this.numOrden.toString() + '.json',
              carpetaArchivo: this.numOrden.toString(),
              otArchivo: this.numOrden.toString(),
              ambiente: 'PROD',
              bytesArchivo: auxb64
            };

            console.log('jsonToSave: ' + JSON.stringify(jsonSave));

            this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
              next: (data) => {
                console.log('jsonToSave: ' + JSON.stringify(data));
              },
              error: (error) => {
                console.error('There was an error!', error);
              }
            });

            this.dyanmicDownloadByHtmlTag({
              fileName: this.numOrden + '.json',
              text: JSON.stringify(jsonF)
            });

            this.mostrarNotificacion(buttonType);
          }

          /*  if (armadoJson.digital === true){
          this.http.post<resultOT>(appSettings.URL_OT, {guardado: 'json', tipoArmado: 'ARMDI'}).subscribe({
            next: data => {
              auxOT= data;
              console.log('auxOT'+auxOT.otNext);
              otNumber=auxOT.otNext.substring(2);
              console.log('OTNumber'+ otNumber);

              this.numOrden = otNumber;

              listado = {
                tipo: listaJson.tipo,
                ramo: listaJson.ramo,
                numConPol: listaJson.numConPol,
                listaPolizas: listaJson.listaPolizas,
                uniquePDF: seccionPdfUni.uniquePDF,
                nombreuniquePDF: seccionPdfUni.nombreuniquePDF,
                armadoEstructura: estructuraCar,
              };
              jsonD = {
                idOrden: auxOT.otNext,
                armados: armadoJson,
                digital: {
                  contacto: datosNoti,
                  listaArmados: listado,
                }
              };

              var auxb64 = window.btoa(unescape(encodeURIComponent( JSON.stringify(jsonD) )));
              console.log('Base64: '+auxb64);

              jsonSave={
                tipoArchivo:'json',
                tipoArmado:'',
                guardado:'',
                nombreArchivo:auxOT.otNext+'.json',
                carpetaArchivo:otNumber,
                otArchivo:otNumber,
                ambiente:'QA',
                bytesArchivo:auxb64
              };

              console.log('jsonToSave: '+JSON.stringify(jsonSave));

              this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
                next: data => {
                  console.log('jsonToSave: '+JSON.stringify(data));
                },
                error: error =>{
                  console.error('There was an error!', error)
                }
              });

              this.dyanmicDownloadByHtmlTag({
                fileName: this.numOrden+'.json',
                text: JSON.stringify(jsonD)
              });

              this.mostrarNotificacion(buttonType);

            },
            error: error =>{
              console.error('There was an error!', error)
            }
          });
        }else if (armadoJson.fisico === true){

          this.http.post<resultOT>(appSettings.URL_OT, {guardado: 'json', tipoArmado: 'ARMFI'}).subscribe({
            next: data => {

              auxOT= data;
              console.log('auxOT'+auxOT.otNext);
              otNumber=auxOT.otNext.substring(2);
              console.log('OTNumber'+ otNumber);

              this.numOrden = otNumber;

              jsonF = {
                idOrden: auxOT.otNext,
                armados: armadoJson,
                fisico: datosfisico
              };

              var auxb64 = window.btoa(unescape(encodeURIComponent( JSON.stringify(jsonF) )));
              console.log('Base64: '+auxb64);

              jsonSave={
                tipoArchivo:'json',
                tipoArmado:'',
                guardado:'',
                nombreArchivo:auxOT.otNext+'.json',
                carpetaArchivo:otNumber,
                otArchivo:otNumber,
                ambiente:'QA',
                bytesArchivo:auxb64
              };

              console.log('jsonToSave: '+JSON.stringify(jsonSave));

              this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
                next: data => {
                  console.log('jsonToSave: '+JSON.stringify(data));
                },
                error: error =>{
                  console.error('There was an error!', error)
                }
              });

              this.dyanmicDownloadByHtmlTag({
                fileName: this.numOrden+'.json',
                text: JSON.stringify(jsonF)
              });

              this.mostrarNotificacion(buttonType);

            },
            error: error =>{
              console.error('There was an error!', error)
            }
          });

        }*/
        }
      } else {
        this.mensajeError =
          validaCel === false
            ? 'Formato de celular no válido'
            : 'Formato de correo no válido';
        //this.mensajeError = 'Formato de correo no válido';
        this.notiDatosNoti = true;
      }
    } else {
      this.profileForm.reset();
      this.mensajeError = 'No se llenaron los campos obligatorios';
      this.notiDatosNoti = true;
    }
  }

  mostrarNotificacion(nomBtn?: any, numOT?: string) {
    if (this.notificacion === undefined || this.notificacion === false) {
      this.notificacion = true;
    }
    this.TxtNotTil = '';
    this.TxtNotMsg = '';
    if (nomBtn === 'Enviar') {
      this.TxtNotTil = this.TxtNot5;
      //this.TxtNotMsg = this.TxtNot3 + this.numOrden;
      this.TxtNotMsg = this.TxtNot3 + numOT;
      this.SendDes = false;
      this.SaveDes = false;
    } else if (nomBtn === 'guardar') {
      this.TxtNotTil = this.TxtNot4;
      //this.TxtNotMsg = this.TxtNot2 + this.numOrden;
      this.TxtNotMsg = this.TxtNot2 + numOT;
      this.SaveDes = false;
    } else {
      this.notificacion = false;
      this.SaveDes = true;
      this.SendDes = true;
    }
    /*setTimeout(() => {
      this.notificacion = false;
      window.location.assign('/');
    }, 10000);*/
  }

  public dyanmicDownloadByHtmlTag(arg: { fileName: string; text: string }) {
    if (!this.setting.element.dynamicDownload) {
      this.setting.element.dynamicDownload = document.createElement('a');
    }
    const element = this.setting.element.dynamicDownload;
    const fileType =
      arg.fileName.indexOf('.json') > -1 ? 'text/json' : 'text/plain';
    element.setAttribute(
      'href',
      `data:${fileType};charset=utf-8,${encodeURIComponent(arg.text)}`
    );
    element.setAttribute('download', arg.fileName);
    const event = new MouseEvent('click');
    element.dispatchEvent(event);
  }

  onlyNumbers(event) {
    console.log(event.key);
    const pattern = /^[0-9]*$/;
    // return !!/^[0-9]*$/.test(event.key);

    if (pattern.test(event.key)) {
    }
  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'dialog-cancelar',
  templateUrl: 'dialog-cancelar.html'
})
// tslint:disable-next-line: component-class-suffix
export class DialogCancelar {
  constructor(
    public dialogRef: MatDialogRef<DialogCancelar>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(): void {
    this.dialogRef.close();
    window.location.assign('/');
  }
}
