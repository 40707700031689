<mat-card class="empate-armado">
  <mat-divider></mat-divider>
  <p class="empate-armado__title">Sección para configuración de empate</p>
  <form [formGroup]="form">
    <div class="row">
      <mat-form-field class="col-4" appearance="outline">
        <mat-label>Elige una opción</mat-label>
        <mat-select formControlName="tipoArmado" required>
          <mat-option value="agrupaciones">Agrupaciones</mat-option>
          <mat-option value="re-nombrado">Re-nombrado</mat-option>
          <mat-option value="empates">Empates</mat-option>
          <mat-option value="Separacion">Separación</mat-option>
        </mat-select>
        <mat-error>Campo requerido</mat-error>
      </mat-form-field>

      <div class="col-4">
        <mat-form-field class="col-11">
          <mat-label>Adjuntar documento</mat-label>
          <ngx-mat-file-input
            formControlName="file"
            placeholder="Adjuntar documento (Excel)"
            accept=".xlsx, .xls, .csv, .pdf, .zip"
            required
            multiple
            (change)="onFileSelected()"
          ></ngx-mat-file-input>
          <mat-icon matPrefix>file_upload</mat-icon>

          <mat-error *ngIf="form.controls['file'].errors?.required">
            Campo requerido
          </mat-error>
          <mat-error *ngIf="form.controls['file'].errors?.maxContentSize">
            {{ form.controls['file'].errors.maxContentSize | json }}
          </mat-error>
        </mat-form-field>
        <button
          class="col-1"
          mat-icon-button
          (click)="deleteAllFiles()"
          type="button"
        >
          <mat-icon matPrefix>delete_outline</mat-icon>
        </button>
      </div>

      <mat-form-field class="col-4" appearance="outline">
        <mat-label>Elegir carpeta(s)</mat-label>
        <mat-select formControlName="carpetas" multiple>
          <mat-option *ngFor="let carpeta of carpetas" [value]="carpeta.name">
            {{ carpeta.name }}
          </mat-option>
        </mat-select>
        <mat-error>Campo requerido</mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-list>
        <mat-list-item *ngFor="let file of files">
          <mat-icon>{{ renderFileIcon(file.type) }}</mat-icon>
          <span>{{ file.name }}</span>
          <button
            mat-icon-button
            type="button"
            (click)="deleteFileSelected(file)"
          >
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </div>
  </form>
</mat-card>
