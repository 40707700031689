import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import { formatDate } from '@angular/common';
import { ValidatorService } from '../validator.service';
import { CdEnvioService, DatosEnvio } from '../cd-envio.service';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef
} from '@angular/material/dialog';
import {
  armadoJson,
  DatosgralComponent,
  listaJson,
  ListaJSON,
  opcRamoSelec,
  opcVars,
  Paneles
} from '../datosgral/datosgral.component';
import { expandPanelCAfromDN } from '../notificacion/notificacion.component';
import { HttpClient, HttpParams } from '@angular/common/http';
import { appSettings } from '../appSettings';
import { ServicioAzulService } from '../src_app_servicio-azul.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmpateUploadComponent } from '../components/empate-upload/empate-upload.component';

export interface FoodNode {
  name: string;
  estatus?: string;
  tipo?: string;
  tipo2?: string;
  children?: FoodNode[];
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}
interface listDoc {
  tipoArchivo: string;
  renombre: string;
  ruta?: string;
}
interface listDocA {
  tipoArchivo: string;
  renombre: string;
  ruta: string;
}
let listdoc: listDoc = {
  tipoArchivo: '',
  renombre: '',
  ruta: null
};
let listdocA: listDocA = {
  tipoArchivo: '',
  renombre: '',
  ruta: ''
};
let ListaDocumentos: listDoc[] = [];
let ListaDocumentosAnexos: listDocA[] = [];

interface estructura {
  tipoCarpeta: string;
  nodoPadre: string;
  nodo: number;
  nombreCarpeta: string;
  listaDocumentos?: listDoc[];
  listaDocumentosAnexos?: listDocA[];
}
let estruc: estructura = {
  tipoCarpeta: '',
  nodoPadre: '',
  nodo: 0,
  nombreCarpeta: '',
  listaDocumentos: [],
  listaDocumentosAnexos: []
};

// Variables que viajan para expandir o contraer los paneles
export let expandPanelDGfromCA: boolean;
export let disablePanelDGfromCA: boolean;
export let expandPanelDatosNotif: boolean;

export let estructuraCar: estructura[] = [];
export let estrucM: any[] = [];
// Dialog
export interface DialogData {
  ramos: string;
  carpeta: string;
}
export interface DialogData2 {
  nombre: string;
  archivos: string;
  nomenclatura: any[];
  nivelCarpeta?: string;
  nivelPadre?: string;
}

// tree armado digital carpetas
export interface carpeta {
  nombre?: string;
  tipo?: string;
  nivel?: string;
  filelist?: listDoc[];
  subcarpeta?: carpeta[];
  id?: number;
}
let aux_carpetas: carpeta[];
let aux_carpeta: carpeta = {
  nombre: null,
  filelist: [],
  subcarpeta: [{}]
};

export interface armadoFisico {
  listaPolizasPool: listPolizas[];
  fechaEntrega: string;
  tipoEmpaquetado: string;
  cantidadPolizas: number;
  totalDependientes: number;
  totalTitulares: number;
  cantidadUnidades: number;
  caracteristicasArmado?: string;
  empateImpresion: Boolean;
  listaDocumentosAnexos: listaDocAne[];
  datosEntrega: datosEntrega;
}
export interface listPolizas {
  ramo: string;
  lista: any[];
}
let lista: listPolizas = {
  ramo: '',
  lista: []
};
export interface listaDocAne {
  tipoArchivo: string;
  renombre: string;
  ruta: string;
}

export interface direccion {
  calle: string;
  numero: string;
  colonia: string;
  delegacion: string;
  ciudad: string;
  codigoPostal: string;
}
let direDatos: direccion = {
  numero: '',
  calle: '',
  codigoPostal: '',
  colonia: '',
  delegacion: '',
  ciudad: ''
};
export interface datosEntrega {
  lugarenvio: string;
  envioespecial: Boolean;
  nombreDestinatario: string;
  telefono: string;
  direccion?: direccion;
  referenciaEntrega: string;
}

let datosLug: datosEntrega = {
  lugarenvio: '',
  envioespecial: null,
  nombreDestinatario: '',
  telefono: '',
  direccion: null,
  referenciaEntrega: ''
};

export let datosfisico: armadoFisico = {
  listaPolizasPool: [],
  fechaEntrega: '',
  tipoEmpaquetado: '',
  cantidadPolizas: 0,
  totalDependientes: 0,
  totalTitulares: 0,
  cantidadUnidades: 0,
  caracteristicasArmado: '',
  empateImpresion: null,
  listaDocumentosAnexos: [],
  datosEntrega: null
};

interface archivoOtros {
  nombreA: string;
  base64: string;
}
export let OtrosArchivos: archivoOtros[] = [];
let TREE_DATA_2: FoodNode[] = [];
let AUTOSINFO: FoodNode[];
let GMMINFO: FoodNode[];
let VIDAINFO: FoodNode[];
let GMMAZUL: FoodNode[];
let carpetasAzul: any;
let carpetasInfo: any;
let AUTOSINFOCarp: FoodNode[];
let GMMINFOCarp: FoodNode[];
let VIDAINFOCarp: FoodNode[];
let GMMAZULCarp: FoodNode[];
/*
const AUTOSINFO: FoodNode[] = [// lista de comentos para autos-info
    // {name: 'Contrato',children: [{name: 'CONTRATO',estatus:'NA',tipo:'CON'}]},
    // tslint:disable-next-line: max-line-length
    { name: 'Contrato', children: [{ name: 'CARÁTULA PADRE', estatus: 'NA', tipo: 'PP',tipo2:'CCP' }, { name: 'CARÁTULA HIJA', estatus: 'NA', tipo: 'PH',tipo2:'CPH' }] },
    { name: 'Solicitudes', children: [{ name: 'SOLICITUDES DE COBRO', estatus: 'NA', tipo: 'PP',tipo2:'SCP' }, { name: 'SOLICITUDES DE COBRO H', estatus: 'NA', tipo: 'PH',tipo2:'SCH' }] },
    // tslint:disable-next-line: max-line-length
    { name: 'Avisos', children: [{ name: 'AVISOS DE PAGO GLOBALES', estatus: 'NA', tipo: 'PP',tipo2:'AVGP' }, { name: 'AVISOS DE COBRO H', estatus: 'NA', tipo: 'PH',tipo2:'ACH' }] },
    // tslint:disable-next-line: max-line-length
    { name: 'Anexos', children: [{ name: 'RC_P', estatus: 'NA', tipo: '',tipo2:'RCP' }, { name: 'RC', estatus: 'NA', tipo: '',tipo2:'RC' }, { name: 'OTROS', estatus: 'NA', tipo: '',tipo2:'OTROS' }] },
    { name: 'Notas', children: [{ name: 'NOTAS DE COMISIÓN H', estatus: 'NA', tipo: 'PH',tipo2:'NCH' }] },
    { name: 'Folletos de línea', children: [{ name: 'FOLLETOS AUTOS', estatus: 'NA', tipo: 'PP',tipo2:'FAP' }] }];
let GMMINFO: FoodNode[] = [// lista de comentos para GMM-info
      { name: 'Carátulas', children: [{ name: 'CARÁTULA P', estatus: 'NA', tipo: 'PP',tipo2:'CCP' }] },
      { name: 'Condiciones', children: [{ name: 'CONDICIONES PARTICULARES PADRE', estatus: 'NA', tipo: 'PP',tipo2:'CGP' },{ name: 'CONDICIONES PARTICULARES HIJA', estatus: 'NA', tipo: 'PH',tipo2:'CPH' }] },
      { name: 'Avisos', children: [{ name: 'AVISO DE COBRO', estatus: 'NA', tipo: 'PP',tipo2:'ACP' }] },
      // tslint:disable-next-line: max-line-length
      { name: 'Registros', children: [{ name: 'REGISTRO DE ASEGURADOS', estatus: 'NA', tipo: 'PP',tipo2:'RA' },{ name: 'REGISTRO DE ALTA', estatus: 'NA', tipo: 'PP',tipo2:'RAP' }, { name: 'REGISTRO DE BAJA', estatus: 'NA', tipo: 'PP',tipo2:'REBP' }] },
      { name: 'Folletos', children: [{ name: 'FOLLETOS GMM', estatus: 'NA', tipo: 'PP',tipo2:'FGMM' }] },
      { name: 'Desgloses', children: [{ name: 'DESGLOSES DE PRIMAS', estatus: 'NA', tipo: 'PP',tipo2:'DP' }] },
      { name: 'Anexos', children: [{ name: 'MOVIMIENTO ANUAL', estatus: 'NA', tipo: '' ,tipo2:'MA'},{ name: 'REPORTE MENSUAL DE INTERVIGENCIA', estatus: 'NA', tipo: '',tipo2:'RMI' },{ name: 'OTROS', estatus: 'NA', tipo: '',tipo2:'OTROS' }] },
      { name: 'Tarjetas', children: [{ name: 'TARJETAS', estatus: 'NA', tipo: 'PH',tipo2:'TD' }] },
      { name: 'Certificados', children: [{ name: 'CERTIFICADOS', estatus: 'NA', tipo: 'PH',tipo2:'CERT' }] },
    ];
let VIDAINFO: FoodNode[] = [// lista de comentos para VIDA-info
      { name: 'Contrato', children: [{ name: 'CARÁTULA CON CONDICIONES GENERALES P', estatus: 'NA', tipo: 'PP',tipo2:'CCGP' }] },
      { name: 'Registros', children: [{ name: 'REGISTRO DE ASEGURADOS', estatus: 'NA', tipo: 'PP',tipo2:'RA' }] },
      { name: 'Detalles de coberturas', children: [{ name: 'DETALLES DE COBERTURA', estatus: 'NA', tipo: 'PP',tipo2:'DC' }] },
      { name: 'Solicitudes', children: [{ name: 'SOLICITUD DE COBRO', estatus: 'NA', tipo: 'PP',tipo2:'SCP' }] },
      { name: 'Avisos', children: [{ name: 'AVISO DE PAGO GLOBAL', estatus: 'NA', tipo: 'PP',tipo2:'APP' }] },
      { name: 'Cartas', children: [{ name: 'CARTA DE REQUISITOS', estatus: 'NA', tipo: 'PP',tipo2:'CR' }] },
      { name: 'Requisitos', children: [{ name: 'REQUISITOS MÉDICOS', estatus: 'NA', tipo: 'PP',tipo2:'RM' }] },
      { name: 'Certificados', children: [{ name: 'CERTIFICADOS', estatus: 'NA', tipo: 'PH',tipo2:'CERTP' }] } // 17062020
      /*{ name: 'Anexos', children: [{ name: 'HOJA DE EXCEDENTES', estatus: 'NA', tipo: '',tipo2:'HE' }, { name: 'RESUMEN EJECUTIVO', estatus: 'NA', tipo: '',tipo2:'REE' }, { name: 'FÓRMULA DE DIVIDENDOS', estatus: 'NA', tipo: '',tipo2:'FD' },      { name: 'ENDOSOS', estatus: 'NA', tipo: '',tipo2:'EN' },{ name: 'EXPERIENCIA', estatus: 'NA', tipo: '',tipo2:'EXP' },{ name: 'CAPTURA DETALLADA', estatus: 'NA', tipo: '',tipo2:'CD' },
      // tslint:disable-next-line: max-line-length
      { name: 'CAPTURA RESUMIDA', estatus: 'NA', tipo: '',tipo2:'CR' },{ name: 'CAPTURA ESTRUCTURADA', estatus: 'NA', tipo: '',tipo2:'CERT' },
      // tslint:disable-next-line: whitespace
      // tslint:disable-next-line: max-line-length
      { name: 'EMPATE PARA OTROS RAMOS', estatus: 'NA', tipo: '',tipo2:'EOR' },{ name: 'CERTIFICADOS', estatus: 'NA', tipo: 'PH',tipo2:'CERTP' }] },*/ // 17062020
//];
/*let  GMMAZUL: FoodNode[] = [// lista de comentos para autos-info
        { name: 'Contrato', children: [{ name: 'CARÁTULA', estatus: 'NA', tipo: 'POL',tipo2:'CWR' }] },
        { name: 'Registros', children: [{ name: 'REGISTRO DE ASEGURADOS', estatus: 'NA', tipo: 'POL',tipo2:'RA' }] },
        { name: 'Endosos', children: [{ name: 'ENDOSOS', estatus: 'NA', tipo: 'POL',tipo2:'EN' }] },
        { name: 'Solicitudes', children: [{ name: 'SOLICITUD DE COBRO', estatus: 'NA', tipo: 'POL',tipo2:'SC' }] },
        // tslint:disable-next-line: max-line-length
        { name: 'Avisos', children: [{ name: 'AVISO DE COBRO', estatus: 'NA', tipo: 'POL',tipo2:'AC' },{ name: 'AVISO DE PAGO GLOBAL', estatus: 'NA', tipo: 'POL',tipo2:'AP' }] },
        { name: 'Preimpresos', children: [{ name: 'CONDICIONES GENERALES', estatus: 'NA', tipo: 'POL',tipo2:'CG' }] },
        { name: 'Folletos', children: [{ name: 'FOLLETOS', estatus: 'NA', tipo: 'POL',tipo2:'FO' }] },
        { name: 'Certificados', children: [{ name: 'CERTIFICADOS', estatus: 'NA', tipo: 'CERT',tipo2:'CERTA' }] },
        { name: 'Tarjetas', children: [{ name: 'TARJETAS', estatus: 'NA', tipo: 'CERT',tipo2:'TD' }] }
      ];*/
interface nombreCarp {
  name: string;
  nivel: string;
}
interface nombreCarp2 {
  name: string;
  nivel?: string;
  nivelP?: string;
}
interface comboArm {
  carpList: carpeta;
  listDoc: FoodNode;
}
interface ListaVarNivel {
  ////
  lista: any[];
  nivel: string;
  nivelP?: string;
}
let nivel_c = '';
interface RamoTcarpeta {
  ramo: string;
  tipoCarpeta: string;
}
let RamoCarpeta_aux: RamoTcarpeta = {
  ramo: '',
  tipoCarpeta: ''
};

let nomenV: nombreCarp2[] = [];
let varsAutos: nombreCarp[];
let varsGMMInfo: nombreCarp[];
let varsVida: nombreCarp[];
let varsGMMAzul: nombreCarp[];
/*
let varsAutos:nombreCarp[]=[
  {name:'CONTRATO',nivel:'C'},
  {name:'POLIZAPADRE',nivel:'PP'},
  {name:'NUMERODEENDOSO',nivel:'PP'},
  {name:'RAZONSOCIAL',nivel:'PP'},
  {name:'SERIEDELVEHICULO',nivel:'PH'},
  {name:'POLIZAHIJA',nivel:'PH'},
  {name:'NOMBREDELTITULAR',nivel:'PH'}, //17062020
  {name:'NOMBREDELCONTRATANTE',nivel:'PH'}, //17062020
  {name:'REFERENCIA1',nivel:'PH'},
  {name:'REFERENCIA2',nivel:'PH'},
];
let varsGMMInfo:nombreCarp[]=[
  {name:'CONTRATO',nivel:'C'},{name:'NOMBREDELCONTRATANTE',nivel:'PP'},{name:'POLIZAPADRE',nivel:'PP'},{name:'POLIZAHIJA',nivel:'PH'},{name:'CLAVEFAMILIAR',nivel:'PH'}, //17062020
];
let varsVida:nombreCarp[]=[
  {name:'CONTRATO',nivel:'C'},{name:'NOMBREDELCONTRATANTE',nivel:'PP'},{name:'POLIZAPADRE',nivel:'PP'},{name:'POLIZAHIJA',nivel:'PH'}, //17062020
];
let varsGMMAzul:nombreCarp[]=[
  {name:'NOMBREPOOL',nivel:'PL'},{name:'NOMBREDELCERTIFICADO',nivel:'PO'},{name:'NOPOLIZA',nivel:'PO'},{name:'NUMERODECERTIFICADO',nivel:'PO'},
]*/

let nameCarp: nombreCarp2;
let carpetas: carpeta[];

@Component({
  selector: 'app-confarmado',
  templateUrl: './confarmado.component.html',
  styleUrls: ['./confarmado.component.scss'],
  providers: [MatSnackBar]
})
export class ConfarmadoComponent implements OnInit {
  public datosG = DatosgralComponent;
  public dialogoCont = DialogContenidoCarpeta; // variable para pasar valores al pop de contenido de carpeta
  TxtStep2 = 'Configuración Armado';
  Txtlbl1 = 'Ramo';
  Txtlbl2 = 'Arrastre aquí para';
  Txtlbl3 = 'adjuntar documentos';
  Txtlbl4 = 'Condiciones Generales';
  Txtlbl5 = 'Eliminar';
  Txtlbl7 = 'Fecha estimada de entrega';
  Txtlbl8 = 'Bloques';
  Txtlbl10 = 'Cantidad de pólizas';
  Txtlbl11 = 'Total de dependientes';
  Txtlbl12 = 'Total de titulares';
  Txtlbl13 = 'Folder';
  Txtlbl14 = 'Cantidad de unidades';
  Txtlbl15 = 'Características Armado Físico';
  Txtlbl17 = 'Lugar de envío';
  Txtlbl18 = 'Calle';
  Txtlbl19 = 'Número';
  Txtlbl20 = 'Colonia';
  Txtlbl21 = 'Delegación';
  Txtlbl22 = 'Ciudad';
  Txtlbl23 = 'Código Postal';
  Txtlbl24 = 'Teléfono';
  Txtlbl25 = 'Envío especial';
  Txtlbl26 = 'Referencias de entrega';
  Txtlbl27 = 'Documentos';
  Txtlbl30 = 'Empate de impresión';
  Txtlbl32 = '';
  Txtlbl33 = 'Tipo de Carpeta';
  Txtlbl34 = 'Nombre de destinatario';
  TxtStep4 = 'Configuración Armado Especial Física';
  TxtStep5 = 'Datos de entrega física';
  TxtNtf1 = 'Campo obligatorio';
  TxtNtf2 = 'Campo numérico';
  TxtNtf4 = 'No se llenaron todos los datos de Armado físico especial';
  TxtNtf5 = '';
  TxtNtf6 = 'La fecha no puede ser anterior al día de hoy';
  Txtbtn2 = 'Cancelar';
  Txtbtn3 = 'Guardar';
  Txtbtn4 = 'Enviar';
  TxtNot1 = 'No se seleccionó un tipo de impresión';
  TxtNtf8 = '';
  TxtNtf9 = 'Código no valido';
  TxtNtf10 = 'Los campos con * son obligatorios';
  TxtNtf11 = 'Código no ingresado';
  TxtNtf12 = '';
  TxtNtf13 = 'No puede tener subcarpetas';
  TxtNtf14 = 'Uno de campo no fue llenado';
  TxtNtf15 = 'Ya existe una carpeta de Póliza Padre';
  TxtNtf16 =
    'No se puede crear una carpeta del mismo tipo que la carpeta superior';
  TxtNtf17 = 'Ya existe una carpeta Póliza Hija';
  TxtNtf18 = 'No puede crear una carpeta padre dentro de una carpeta hija';
  TxtNtf19 = 'Sólo se pueden crear una carpeta de tipo Anexos';
  TxtNtf20 = '';
  //TxtNtf21 = 'Archivo agregado correctamente: ';
  TxtNtf21 = 'El archivo "';
  TxtNtf22 = 'No se puede cargar Archivo ';
  //TxtNtf23 = 'El archivo ya esta asignado a una carpeta';
  TxtNtf23 = 'El archivo "';
  TxtNtf24 = 'Los archivos de tipo OTRO no pueden asignarse a esta carpeta';
  TxtNtf25 = 'Sólo está permitido agregar archivos de tipo OTRO';
  TxtNtf26 =
    'Sólo está permitido agregar archivos de tipo PÓLIZA HIJA/CERTIFICADO';
  TxlBtCnt = 'Continuar';
  TxlBtCnl = 'Cancelar';
  TimeNoti = 9000;
  TxtNtf27 = '';
  TxtNtf28 = 'No se realizo una búsqueda';
  TxtNtf29 = 'No seleccionó una póliza';
  TxtNtf30 = '" ya está asignado a una carpeta';
  TxtNtf31 = '" se ha agregado correctamente';
  TxtNtf32 = '';
  TxtNtf33 = 'Sólo archivos PDF';
  TxtNtf34 = 'No se seleccionó una carpeta';
  Pool = 'Pool';
  NombrePool = 'NOMBREPOOL';
  ramos: any[] = [
    { name: 'Autos' },
    { name: 'GMM' },
    { name: 'Vida' },
    { name: 'Daños' },
    { name: 'Todos' }
  ];

  // variables para mostrar el panel de armado digital o Fisico
  panelAD: boolean;
  panelAF: boolean;
  // variable para mostrar el mensaje
  notiArmado: boolean;
  notiDatosArmado: boolean;
  auxMsg: string;
  notiDatosNoti: boolean;
  estilo: string;
  opcRamoSelec: string;
  // Variables locales para el panel
  expandPanelConfArmLoc: boolean;
  disablePanelConfArmLoc: boolean;

  isPdfUnif: Boolean = false;
  nomenclatura: string;
  edoConfArm: Boolean = false; // variable para saber si se dio clic en panel
  // Archivos de la estrucatura de armado dialog
  carpetasEstructura: any[];
  tiposCarpetas;
  tiposCarpetasInfo: any[] = [
    { name: 'Póliza Padre' },
    { name: 'Póliza Hija' },
    { name: 'Adjuntos' }
  ];
  tiposCarpetasAzul: any[] = [
    { name: 'Póliza' },
    { name: 'Certificado' },
    { name: 'Anexos' }
  ];

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @ViewChild(EmpateUploadComponent) empateUpload: EmpateUploadComponent;

  carpetaSelec: string;

  placeholder = 'Seleccione un criterio';

  armadoFisicoForm: FormGroup;
  entregaFisicaForm: FormGroup;
  nuevaCarpeta: FormGroup;

  today = new Date();
  fechaSolEntrega = new FormControl('', [Validators.required]);
  ramo = new FormControl('', [Validators.required]);
  contidadPolizas = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]+$')
  ]);
  totalDependientes = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]+$')
  ]);
  totalTitulares = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]+$')
  ]);
  cantidadUnidades = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]+$')
  ]);
  caracteristicasAF = new FormControl('', [Validators.required]);

  nombreCarpeta = new FormControl('', [Validators.required]);
  tipoCarpeta = new FormControl('', [Validators.required]);
  renombreCarpeta = new FormControl('', [Validators.required]);
  referencias = new FormControl('', []);

  // Funcion que muestra el cuadrodo para agregar archivos en Armado fisico
  mostrar: boolean;
  mostrarEmpate: boolean;

  // Tree Documentos
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level
    };
  };

  // tree
  // tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  // tslint:disable-next-line: member-ordering
  dataSource2 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  // tree polizas

  // tslint:disable-next-line: member-ordering
  dataSource3 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  listaCodigo: any;
  constructor(
    private formBuilder: FormBuilder,
    private serviceValidator: ValidatorService,
    private serviceCodSend: CdEnvioService,
    protected servicioAzul: ServicioAzulService,
    // Dialog
    public dialog: MatDialog,
    protected http: HttpClient,
    private _snackBar: MatSnackBar
  ) {
    //se obtiene los documentos
    /* this.servicioAzul.getDocRamo('Autos', 'INFO').subscribe( // doc autos
      response => {
        if (response.status === 200) {
          AUTOSINFOCarp = response.body;
          for (let archivo of AUTOSINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = AUTOSINFOCarp.indexOf(archivo)
              AUTOSINFOCarp[pos].name = AUTOSINFOCarp[pos].tipo2;
            }
          }
        }
      },
      error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    ); */
    this.documentosAutos();
    this.documentosVida();
    this.documentosGmm();
    this.documentosGmmAzul();
    /* this.servicioAzul.getDocRamo('Vida', 'INFO').subscribe( // doc vida
      response => {
        if (response.status === 200) {
          VIDAINFOCarp = response.body;
          for (let archivo of VIDAINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = VIDAINFOCarp.indexOf(archivo)
              VIDAINFOCarp[pos].name = VIDAINFOCarp[pos].tipo2;
            }
          }
        }
      },
      error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    );
    this.servicioAzul.getDocRamo('GMM', 'INFO').subscribe( // doc GMM
      response => {
        if (response.status === 200) {
          GMMINFOCarp = response.body;
          for (let archivo of GMMINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = GMMINFOCarp.indexOf(archivo)
              GMMINFOCarp[pos].name = GMMINFOCarp[pos].tipo2;
            }
          }
        }
      },
      error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    );
    this.servicioAzul.getDocRamo('GMM', 'Azul').subscribe( // doc Gmm azul
      response => {
        if (response.status === 200) {
          GMMAZULCarp = response.body;
          for (let archivo of GMMAZULCarp) {
            if (archivo.name === 'N/A') {
              let pos = GMMAZULCarp.indexOf(archivo)
              GMMAZULCarp[pos].name = GMMAZULCarp[pos].tipo2;
            }
          }
        }
      },
      error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    ); */
    //se obtiene las carpetas por ramo
    this.carpetasInfo();
    this.carpetasAzul();
    /*     let parametosINFO=new HttpParams();
    parametosINFO=parametosINFO.append('plataforma','INFO');
    this.http.get(appSettings.URL_OT19,{params:parametosINFO})//carpetas azul
    .subscribe({
      next: data => {
        console.log()
        carpetasInfo = data;
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    });
    let parametosAzul=new HttpParams();
    parametosAzul=parametosAzul.append('ramo','GMM');
    parametosAzul=parametosAzul.append('plataforma','Azul');
    this.http.get(appSettings.URL_OT19,{params:parametosAzul})//carpetas azul
    .subscribe({
      next: data => {
        console.log()
        carpetasAzul = data;
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    });
    let paramsVarsAutos= new HttpParams();
    paramsVarsAutos=paramsVarsAutos.append('ramo','Autos')
    paramsVarsAutos=paramsVarsAutos.append('plataforma','INFO');
    this.http.get<nombreCarp[]>(appSettings.URL_OT20,{params:paramsVarsAutos})//VARSAUTOS info
    .subscribe({
      next: data => {
        varsAutos = data;
        for(let vars of varsAutos){
          let pos=varsAutos.indexOf(vars)
          switch(vars.nivel){
            case 'Contrato':
              varsAutos[pos].nivel='C'
              break;
            case 'Poliza Padre':
              varsAutos[pos].nivel='PP'
              break;
            case 'Poliza Hija':
              varsAutos[pos].nivel='PH'
              break;
          }
        }
        console.log("vars autos", varsAutos)
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    }); */
    this.VariablesAutos();
    this.VariablesVida();
    this.VariablesGMM();
    this.VariablesGMMAzul();
    /* let paramsVarsGMM= new HttpParams();
    paramsVarsGMM=paramsVarsGMM.append('ramo','GMM')
    paramsVarsGMM=paramsVarsGMM.append('plataforma','INFO');
    this.http.get<nombreCarp[]>(appSettings.URL_OT20,{params:paramsVarsGMM})//VARSGMM info
    .subscribe({
      next: data => {
        varsGMMInfo = data;
        for(let vars of varsGMMInfo){
          let pos=varsGMMInfo.indexOf(vars)
          switch(vars.nivel){
            case 'Contrato':
              varsGMMInfo[pos].nivel='C'
              break;
            case 'Poliza Padre':
              varsGMMInfo[pos].nivel='PP'
              break;
            case 'Poliza Hija':
              varsGMMInfo[pos].nivel='PH'
              break;
          }
          if(vars.name==='CLAVEFAMILIAR'){
            varsGMMInfo[pos].nivel='PH'
          }
        }
        console.log("vars info", varsGMMInfo)
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    });
    let paramsVarsVida= new HttpParams();
    paramsVarsVida=paramsVarsVida.append('ramo','Vida')
    paramsVarsVida=paramsVarsVida.append('plataforma','INFO');
    this.http.get<nombreCarp[]>(appSettings.URL_OT20,{params:paramsVarsVida})//VARSVIDA info
    .subscribe({
      next: data => {
        varsVida = data;
        for(let vars of varsVida){
          let pos=varsVida.indexOf(vars)
          switch(vars.nivel){
            case 'Contrato':
              varsVida[pos].nivel='C'
              break;
            case 'Poliza Padre':
              varsVida[pos].nivel='PP'
              break;
            case 'Poliza Hija':
              varsVida[pos].nivel='PH'
              break;
          }
        }
        console.log("vars vida", varsVida)
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    });
    let paramsVarsGMMAzul= new HttpParams();
    paramsVarsGMMAzul= paramsVarsGMMAzul.append('ramo','GMM')
    paramsVarsGMMAzul= paramsVarsGMMAzul.append('plataforma','Azul')
    this.http.get<nombreCarp[]>(appSettings.URL_OT20,{params:paramsVarsGMMAzul})//VARSGMM AZUL
    .subscribe({
      next: data => {
        varsGMMAzul = data;
        for(let vars of varsGMMAzul){
          let pos=varsGMMAzul.indexOf(vars)
          switch(vars.nivel){
            case 'Pool':
              varsGMMAzul[pos].nivel='PL'
              break;
            case 'Poliza':
              varsGMMAzul[pos].nivel='PO'
              break;
            case 'Certificado':
              varsGMMAzul[pos].nivel='CERT'
              break;
          }
        }
        console.log("vars azul",varsGMMAzul)
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)
        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    });
 */
    this.codigosEnvio();
    /*  this.http.get(appSettings.URL_OT15)//lista de codigos
    .subscribe({
      next: data => {
        this.listaCodigo = data;
      },
      error: error => {
        if (error.status === 500) {
          console.error('error de servidor', error)


        }else if(error.status === 400){
          console.error('request no valido', error)
        }else if(error.status === 0){
          console.error('error de conexion', error)
        }
      }
    }); */
  }

  ngOnInit(): void {
    this.armadoFisicoForm = this.formBuilder.group({
      fechaSolEntrega: [
        formatDate(new Date(), 'dd/MM/yyyy', 'en'),
        Validators.required
      ],
      ramo: ['', Validators.required],
      bloquesGmmVida: [false],
      carteras: [false, Validators.required],
      contidadPolizas: [
        '',
        [Validators.required, Validators.pattern('^[0-9]+$')]
      ],
      totalDependientes: [
        '',
        [Validators.required, Validators.pattern('^[0-9]+$')]
      ],
      totalTitulares: [
        '',
        [Validators.required, Validators.pattern('^[0-9]+$')]
      ],
      bloquesAutos: [false],
      folder: [false],
      cantidadUnidades: [
        '',
        [Validators.required, Validators.pattern('^[0-9]+$')]
      ],
      caracteristicasAF: [],
      caracteristicasED: [],
      checkEmpate: [false]
    });

    this.entregaFisicaForm = this.formBuilder.group({
      lugarEnvio: [''],
      calle: { value: '', disabled: true },
      numero: { value: '', disabled: true },
      colonia: { value: '', disabled: true },
      delegacion: { value: '', disabled: true },
      ciudad: { value: '', disabled: true },
      codigoPostal: { value: '', disabled: true },
      contacto: { value: '', disabled: true },
      telefono_d: { value: '', disabled: true },
      referencias: ['']
    });

    this.nuevaCarpeta = this.formBuilder.group({
      tipoCarpeta: ['', Validators.required],
      nombreCarpeta: ['', Validators.required]
    });
  }
  documentosAutos() {
    this.servicioAzul.getDocRamo('Autos', 'INFO').subscribe(
      // doc autos
      (response) => {
        if (response.status === 200) {
          AUTOSINFOCarp = response.body;
          for (let archivo of AUTOSINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = AUTOSINFOCarp.indexOf(archivo);
              AUTOSINFOCarp[pos].name = AUTOSINFOCarp[pos].tipo2;
            }
          }
        }
      },
      (error) => {
        if (error.status === 500) {
          this.notiCodigo500();
          console.error('error de servidor', error);
        } else if (error.status === 400) {
          this.notiCodigo400();
          this.documentosAutos();
          console.error('request no valido', error);
        } else if (error.status === 0) {
          this.notiCodigo0();
          console.error('error de conexion', error);
        }
      }
    );
  }
  documentosVida() {
    this.servicioAzul.getDocRamo('Vida', 'INFO').subscribe(
      // doc vida
      (response) => {
        if (response.status === 200) {
          VIDAINFOCarp = response.body;
          for (let archivo of VIDAINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = VIDAINFOCarp.indexOf(archivo);
              VIDAINFOCarp[pos].name = VIDAINFOCarp[pos].tipo2;
            }
          }
        }
      },
      (error) => {
        if (error.status === 500) {
          this.notiCodigo500();
          console.error('error de servidor', error);
        } else if (error.status === 400) {
          this.notiCodigo400();
          this.documentosVida();
          console.error('request no valido', error);
        } else if (error.status === 0) {
          this.notiCodigo0();
          console.error('error de conexion', error);
        }
      }
    );
  }
  documentosGmm() {
    this.servicioAzul.getDocRamo('GMM', 'INFO').subscribe(
      // doc GMM
      (response) => {
        if (response.status === 200) {
          GMMINFOCarp = response.body;
          for (let archivo of GMMINFOCarp) {
            if (archivo.name === 'N/A') {
              let pos = GMMINFOCarp.indexOf(archivo);
              GMMINFOCarp[pos].name = GMMINFOCarp[pos].tipo2;
            }
          }
        }
      },
      (error) => {
        if (error.status === 500) {
          this.notiCodigo500();
          console.error('error de servidor', error);
        } else if (error.status === 400) {
          this.notiCodigo400();
          this.documentosGmm();
          console.error('request no valido', error);
        } else if (error.status === 0) {
          this.notiCodigo0();
          console.error('error de conexion', error);
        }
      }
    );
  }
  documentosGmmAzul() {
    this.servicioAzul.getDocRamo('GMM', 'Azul').subscribe(
      // doc Gmm azul
      (response) => {
        if (response.status === 200) {
          GMMAZULCarp = response.body;
          for (let archivo of GMMAZULCarp) {
            if (archivo.name === 'N/A') {
              let pos = GMMAZULCarp.indexOf(archivo);
              GMMAZULCarp[pos].name = GMMAZULCarp[pos].tipo2;
            }
          }
        }
      },
      (error) => {
        if (error.status === 500) {
          this.notiCodigo500();
          console.error('error de servidor', error);
        } else if (error.status === 400) {
          this.notiCodigo400();
          this.documentosGmmAzul();
          console.error('request no valido', error);
        } else if (error.status === 0) {
          this.notiCodigo0();
          console.error('error de conexion', error);
        }
      }
    );
  }
  carpetasInfo() {
    let parametosINFO = new HttpParams();
    parametosINFO = parametosINFO.append('plataforma', 'INFO');
    this.http
      .get(appSettings.URL_OT19, { params: parametosINFO }) //carpetas azul
      .subscribe({
        next: (data) => {
          console.log();
          carpetasInfo = data;
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.carpetasInfo();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  carpetasAzul() {
    let parametosAzul = new HttpParams();
    parametosAzul = parametosAzul.append('ramo', 'GMM');
    parametosAzul = parametosAzul.append('plataforma', 'Azul');
    this.http
      .get(appSettings.URL_OT19, { params: parametosAzul }) //carpetas azul
      .subscribe({
        next: (data) => {
          console.log();
          carpetasAzul = data;
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.carpetasAzul();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  VariablesAutos() {
    let paramsVarsAutos = new HttpParams();
    paramsVarsAutos = paramsVarsAutos.append('ramo', 'Autos');
    paramsVarsAutos = paramsVarsAutos.append('plataforma', 'INFO');
    this.http
      .get<nombreCarp[]>(appSettings.URL_OT20, {
        params: paramsVarsAutos
      }) //VARSAUTOS info
      .subscribe({
        next: (data) => {
          varsAutos = data;
          for (let vars of varsAutos) {
            let pos = varsAutos.indexOf(vars);
            switch (vars.nivel) {
              case 'Contrato':
                varsAutos[pos].nivel = 'C';
                break;
              case 'Poliza Padre':
                varsAutos[pos].nivel = 'PP';
                break;
              case 'Poliza Hija':
                varsAutos[pos].nivel = 'PH';
                break;
            }
          }
          //console.log("vars autos", varsAutos)
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.VariablesAutos();
            this.notiCodigo400();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  VariablesGMM() {
    let paramsVarsGMM = new HttpParams();
    paramsVarsGMM = paramsVarsGMM.append('ramo', 'GMM');
    paramsVarsGMM = paramsVarsGMM.append('plataforma', 'INFO');
    this.http
      .get<nombreCarp[]>(appSettings.URL_OT20, { params: paramsVarsGMM }) //VARSGMM info
      .subscribe({
        next: (data) => {
          varsGMMInfo = data;
          for (let vars of varsGMMInfo) {
            let pos = varsGMMInfo.indexOf(vars);
            switch (vars.nivel) {
              case 'Contrato':
                varsGMMInfo[pos].nivel = 'C';
                break;
              case 'Poliza Padre':
                varsGMMInfo[pos].nivel = 'PP';
                break;
              case 'Poliza Hija':
                varsGMMInfo[pos].nivel = 'PH';
                break;
            }
            if (vars.name === 'CLAVEFAMILIAR') {
              varsGMMInfo[pos].nivel = 'PH';
            }
          }
          //console.log("vars info", varsGMMInfo)
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.VariablesGMM();
            this.notiCodigo400();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  VariablesVida() {
    let paramsVarsVida = new HttpParams();
    paramsVarsVida = paramsVarsVida.append('ramo', 'Vida');
    paramsVarsVida = paramsVarsVida.append('plataforma', 'INFO');
    this.http
      .get<nombreCarp[]>(appSettings.URL_OT20, { params: paramsVarsVida }) //VARSVIDA info
      .subscribe({
        next: (data) => {
          varsVida = data;
          for (let vars of varsVida) {
            let pos = varsVida.indexOf(vars);
            switch (vars.nivel) {
              case 'Contrato':
                varsVida[pos].nivel = 'C';
                break;
              case 'Poliza Padre':
                varsVida[pos].nivel = 'PP';
                break;
              case 'Poliza Hija':
                varsVida[pos].nivel = 'PH';
                break;
            }
          }
          //console.log("vars vida", varsVida)
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.VariablesVida();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  VariablesGMMAzul() {
    let paramsVarsGMMAzul = new HttpParams();
    paramsVarsGMMAzul = paramsVarsGMMAzul.append('ramo', 'GMM');
    paramsVarsGMMAzul = paramsVarsGMMAzul.append('plataforma', 'Azul');
    this.http
      .get<nombreCarp[]>(appSettings.URL_OT20, {
        params: paramsVarsGMMAzul
      }) //VARSGMM AZUL
      .subscribe({
        next: (data) => {
          varsGMMAzul = data;
          for (let vars of varsGMMAzul) {
            let pos = varsGMMAzul.indexOf(vars);
            switch (vars.nivel) {
              case 'Pool':
                varsGMMAzul[pos].nivel = 'PL';
                break;
              case 'Poliza':
                varsGMMAzul[pos].nivel = 'PO';
                break;
              case 'Certificado':
                varsGMMAzul[pos].nivel = 'CERT';
                break;
            }
          }
          //console.log("vars azul",varsGMMAzul)
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.VariablesGMMAzul();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  codigosEnvio() {
    this.http
      .get(appSettings.URL_OT15) //lista de codigos
      .subscribe({
        next: (data) => {
          this.listaCodigo = data;
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.codigosEnvio();
            console.error('request no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }
  carpetas_ini: carpeta;
  comboDigMulti: any;
  crearEstructura(idList: number) {
    //this.resetArchivosPDF();
    let numArmados = 0;
    for (let contr of ListaJSON) {
      if (contr.id === idList) {
        if (contr.tipo === 'Pool') {
          carpetas = [
            {
              nombre: 'Pool',
              tipo: 'Pool',
              nivel: '1',
              filelist: [],
              subcarpeta: [],
              id: idList
            }
          ];
          this.listaDocumentos(contr.ramo, contr.tipo);
          //this.listaVariables(contr.ramo,contr.tipo);
          this.carpetas2 = carpetas;
          //this.tiposCarpetas = [{name: 'Póliza'}, {name: 'Certificado'}, {name: 'Anexos'}];
          this.tiposCarpetas = carpetasAzul;
          RamoCarpeta_aux = {
            ramo: contr.ramo,
            tipoCarpeta: contr.tipo
          };
        } else if (contr.tipo === 'Contrato' || contr.tipo === 'Póliza') {
          carpetas = [
            {
              nombre: 'Contrato',
              tipo: 'Contrato',
              nivel: '1',
              filelist: [],
              subcarpeta: [],
              id: idList
            }
          ];
          this.listaDocumentos(contr.ramo, contr.tipo);
          //this.listaVariables(contr.ramo,contr.tipo);
          this.carpetas2 = carpetas;
          //this.tiposCarpetas = [{name: 'Póliza Padre'}, {name: 'Póliza Hija'}, {name: 'Adjuntos'}];
          this.tiposCarpetas = carpetasInfo;
          RamoCarpeta_aux = {
            ramo: contr.ramo,
            tipoCarpeta: contr.tipo
          };
        }
        //numArmados=carpetasM.length;
        //carpetasM[numArmados]=this.carpetas2;
      }
    }
  }
  ifFiles: number = 0;
  validaArmado() {
    let cont = 0;
    for (let carp of carpetas) {
      if (carp.filelist.length !== 0) {
        cont = cont + 1;
        break;
      }
      for (let carp1 of carp.subcarpeta) {
        if (carp1.filelist.length !== 0) {
          cont = cont + 1;
          break;
        }
        for (let carp2 of carp1.subcarpeta) {
          if (carp2.filelist.length !== 0) {
            cont = cont + 1;
            break;
          }
          for (let carp3 of carp2.subcarpeta) {
            if (carp3.filelist.length !== 0) {
              cont = cont + 1;
              break;
            }
          }
        }
      }
    }
    this.ifFiles = cont;
  }
  cambioSel: string = 'uno';
  notiTipoArmado(idList?: number) {
    console.log('notiarmado');
    let numArmados = 0;
    this.crearEstructura(idList);
    this.armadoFisicoForm.controls.ramo.setValue(this.opcRamoSelec);
    this.armadoFisicoForm.controls.ramo.disable();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  // variables para el DnD
  files: any[] = [];
  filesFisico: any[] = [];
  mostrarMsg: Boolean;
  mostrarMsgFisico: Boolean;

  // Funcion detecta el ingreso de un arhivo
  onFileDropped($event) {
    this.prepareFilesList($event);
  }
  onFileDroppedFisico($event) {
    this.prepareFilesListFisico($event);
  }
  listaDocumentos(ramo: string, criterio: string) {
    switch (criterio) {
      case 'Contrato':
        this.opcRamo(ramo);
        break;
      case 'Póliza':
        this.opcRamo(ramo);
        break;
      default:
        TREE_DATA_2 = GMMAZUL;
        break;
    }
    this.dataSource2.data = TREE_DATA_2;
  }
  opcRamo(ramo: string) {
    switch (ramo) {
      case 'Vida':
        TREE_DATA_2 = VIDAINFO;
        break;
      case 'Autos':
        TREE_DATA_2 = AUTOSINFO;
        break;
      case 'GMM':
        TREE_DATA_2 = GMMINFO;
        break;
    }
    // console.log(TREE_DATA_2)
  }

  listaVariables(ramo: string, criterio: string, nivel?: string) {
    console.log(ramo, criterio, nivel);
    switch (criterio) {
      case 'Contrato':
        this.opcRamoVar(ramo, nivel);
        break;
      case 'Póliza':
        this.opcRamoVar(ramo, nivel);
        break;
      default:
        //nomenV=varsGMMAzul;
        this.varsPorNivel(varsGMMAzul, nivel);
        break;
    }
  }
  opcRamoVar(ramo: string, nivel?: string) {
    switch (ramo) {
      case 'Vida':
        //nomenV=varsVida;
        this.varsPorNivel(varsVida, nivel);
        break;
      case 'Autos':
        this.varsPorNivel(varsAutos, nivel);
        break;
      case 'GMM':
        this.varsPorNivel(varsGMMInfo, nivel);
        break;
    }
  }
  // funcion para cargas las carpetas al dialog
  cargarListaCarp() {
    this.carpetasEstructura = [];
    for (let c1 of carpetas) {
      nameCarp = { name: c1.nombre, nivel: c1.nivel, nivelP: 'N/A' };
      this.carpetasEstructura.push(nameCarp);
      if (c1.subcarpeta.length !== 0) {
        for (let c2 of c1.subcarpeta) {
          nameCarp = {
            name: c2.nombre,
            nivel: c2.nivel,
            nivelP: c1.nivel
          };
          this.carpetasEstructura.push(nameCarp);
          if (c2.subcarpeta.length !== 0) {
            for (let c3 of c2.subcarpeta) {
              nameCarp = {
                name: c3.nombre,
                nivel: c3.nivel,
                nivelP: c2.nivel
              };
              this.carpetasEstructura.push(nameCarp);
              if (c3.subcarpeta.length !== 0) {
                for (let c4 of c3.subcarpeta) {
                  nameCarp = {
                    name: c4.nombre,
                    nivel: c4.nivel,
                    nivelP: c3.nivel
                  };
                  this.carpetasEstructura.push(nameCarp);
                }
              }
            }
          }
        }
      }
    }
  }
  // Agrega el archivo a la lista de archivos
  b64file = '';
  b64filesArray: Array<any> = [];
  prepareFilesList(files: Array<any>) {
    let numFiles = 0;
    let numFiles2 = 0;
    let agregaFile = false;
    let auxb64 = '';
    let validacionArchivo = '';
    for (const item of files) {
      item.progress = 0;
      const pathSplitted = item.name.split('.');
      const extension = pathSplitted.pop();
      let nombreAuxArch = '';
      validacionArchivo = this.validaArchivoV2(item.name, 'OTROS', 'dnd');
      console.log(validacionArchivo, item.name);
      //if (extension === 'pdf' && validacionArchivo==='NA') {//validacion para archivos adjuntos
      if (extension === 'pdf') {
        item.arrayBuffer().then((data) => {
          let binary = '';
          let bytes = new Uint8Array(data);
          let len = bytes.byteLength;
          for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
          }
          auxb64 = window.btoa(binary);
          this.b64filesArray.push(auxb64);
          nombreAuxArch = item.name.replace('.pdf', '');
          numFiles = OtrosArchivos.length;
          OtrosArchivos[numFiles] = {
            nombreA: nombreAuxArch,
            base64: auxb64
          };
        });
        this.files.push(item);
        agregaFile = true;
      } else if (extension !== 'pdf') {
        console.log(' No se permiten extensiones distintas a PDF');
        this.TxtNtf32 = this.TxtNtf33;
        this.dropFilesD = true;
        setTimeout(() => {
          this.dropFilesD = false;
        }, this.TimeNoti);
        console.log(' No se permiten extensiones distintas a PDF');
      } /* else if(validacionArchivo==='A'){//validacion para archivos adjuntos
        this.mensajeArchivoErr(this.TxtNtf23+item.name+this.TxtNtf30);
      } */
    }
    if (files.length !== 0 && agregaFile === true) {
      this.mostrarMsg = true;
      numFiles2 = this.files.length;
      this.cargarListaCarp();
      console.log(this.carpetasEstructura);
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        width: '350px',
        data: {
          ramos: this.carpetasEstructura,
          carpeta: this.carpetaSelec
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed');
        if (result === undefined || result === '') {
          this.mensajeArchivoErr(this.TxtNtf34);
        } else {
          this.carpetaSelec = result[0];
          console.log(this.files[numFiles2 - 1].name);
          this.agregarArch(
            this.files[numFiles2 - 1].name,
            this.carpetaSelec,
            'dnd',
            result[1],
            result[2]
          );
        }
        /*for(let archivo of this.files){//para agregar mas de un archivo adjunto a la vez
          console.log(archivo.name)
          this.agregarArch(archivo.name, this.carpetaSelec, 'dnd')
        }*/
        /* console.log(this.files[numFiles2 - 1].name);
        this.agregarArch(this.files[numFiles2 - 1].name, this.carpetaSelec, 'dnd') */
        //this.files=[]; //reset de la lista de archivos, validacion de archivos adjuntos
        //this.mostrarMsg = false;
      });
      this.mostrarMsg = true;
    }
    console.log(this.b64filesArray);
    //this.files=[];
  }
  dropFiles: Boolean;
  dropFilesD: Boolean;
  prepareFilesListFisico(filesFisico: Array<any>) {
    if (filesFisico.length !== 0) {
      this.mostrarMsgFisico = true;
    }
    let numFiles = 0;
    let agregaFile = false;
    for (const item of filesFisico) {
      item.progress = 0;
      const pathSplitted = item.name.split('.');
      const extension = pathSplitted.pop();
      if (extension === 'pdf') {
        this.filesFisico.push(item);
        agregaFile = true;
      } else {
        this.dropFiles = true;
        setTimeout(() => {
          this.dropFiles = false;
        }, this.TimeNoti);
        console.log(' No se permiten extensiones distintas a PDF');
      }
    }
  }

  // Funcion para eliminar un archivo de la lista
  deleteFile(index: number) {
    this.files.splice(index, 1);
    if (this.files.length === 0) {
      this.mostrarMsg = false;
    }
  }

  deleteFileFisico(index: number) {
    this.filesFisico.splice(index, 1);
    if (this.filesFisico.length === 0) {
      this.mostrarMsgFisico = false;
    }
  }

  // Funcion que obtiene el tamanio del archivo en bytes
  formatBytes(bytes, decimals?) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  agregarArchivos(): void {
    switch (this.mostrar) {
      case true: {
        this.mostrar = false;
        this.filesFisico = [];
        break;
      }
      case false: {
        this.mostrar = true;
        break;
      }
      default: {
        this.mostrar = true;
        break;
      }
    }
  }

  cargaDatosEntrega(envioS: Boolean) {
    // this.obtenerDatosEntrega();// se obtinene los datos de envio
    datosLug = {
      lugarenvio: this.entregaFisicaForm.value.lugarEnvio,
      envioespecial: envioS,
      nombreDestinatario: this.entregaFisicaForm.value.contacto,
      telefono: this.entregaFisicaForm.value.telefono_d,
      direccion: direDatos,
      referenciaEntrega: this.entregaFisicaForm.value.referencias
    };
    // console.log("Datos Entrega",datosLug)
  }
  cargaDatosEntrega2(envioS: Boolean) {
    // this.obtenerDatosEntrega();// se obtinene los datos de envio
    datosLug = {
      lugarenvio: this.entregaFisicaForm.value.lugarEnvio,
      envioespecial: envioS,
      nombreDestinatario: this.datosEnvio.contacto,
      telefono: this.datosEnvio.telefono_d,
      direccion: direDatos,
      referenciaEntrega: this.entregaFisicaForm.value.referencias
    };
    // console.log("Datos Entrega",datosLug)
  }
  cargarDireccion() {
    direDatos = {
      calle: this.entregaFisicaForm.value.calle,
      codigoPostal: this.entregaFisicaForm.value.codigoPostal,
      colonia: this.entregaFisicaForm.value.colonia,
      delegacion: this.entregaFisicaForm.value.delegacion,
      ciudad: this.entregaFisicaForm.value.ciudad,
      numero: this.entregaFisicaForm.value.numero
    };
  }
  cargarDireccion2() {
    direDatos = {
      calle: this.datosEnvio.calle,
      codigoPostal: this.datosEnvio.codigo_postal,
      colonia: this.datosEnvio.colonia,
      delegacion: this.datosEnvio.delegacion,
      ciudad: this.datosEnvio.ciudad,
      numero: this.datosEnvio.numero
    };
  }
  cargaDatosFisico() {
    let archivo: listaDocAne;
    let listArchivo: listaDocAne[] = [];
    let cont = 0;
    let tipoEmpaque = '';
    if (
      this.armadoFisicoForm.value.bloquesAutos === true &&
      this.armadoFisicoForm.value.bloquesGmmVida === false &&
      this.armadoFisicoForm.value.carteras === false &&
      this.armadoFisicoForm.value.folder === false
    ) {
      tipoEmpaque = 'Bloque';
    } else if (
      this.armadoFisicoForm.value.bloquesAutos === false &&
      this.armadoFisicoForm.value.bloquesGmmVida === true &&
      this.armadoFisicoForm.value.carteras === false &&
      this.armadoFisicoForm.value.folder === false
    ) {
      tipoEmpaque = 'Bloques';
    } else if (
      this.armadoFisicoForm.value.bloquesAutos === false &&
      this.armadoFisicoForm.value.bloquesGmmVida === false &&
      this.armadoFisicoForm.value.carteras === true &&
      this.armadoFisicoForm.value.folder === false
    ) {
      tipoEmpaque = 'Condicicones Generales';
    } else if (
      this.armadoFisicoForm.value.bloquesAutos === false &&
      this.armadoFisicoForm.value.bloquesGmmVida === false &&
      this.armadoFisicoForm.value.carteras === false &&
      this.armadoFisicoForm.value.folder === true
    ) {
      tipoEmpaque = 'Folder';
    }
    let empate = null;
    if (
      this.armadoFisicoForm.value.checkEmpate === undefined ||
      this.armadoFisicoForm.value.checkEmpate === false
    ) {
      empate = false;
      listArchivo = [];
    } else if (this.armadoFisicoForm.value.checkEmpate === true) {
      empate = true;
      if (this.filesFisico.length !== 0) {
        for (let file of this.filesFisico) {
          archivo = {
            tipoArchivo: 'OTROS',
            renombre: file.name,
            ruta: '/Presolicitud/QA/' + file.name
          };
          listArchivo[cont] = archivo;
          cont++;
        }
      }
    }
    lista = { ramo: listaJson.ramo, lista: listaJson.listaPolizas };
    let arregloLista: listPolizas[] = [];
    arregloLista[0] = lista;
    datosfisico = {
      listaPolizasPool: arregloLista,
      // tslint:disable-next-line: max-line-length
      fechaEntrega:
        this.armadoFisicoForm.value.fechaSolEntrega.getDate() +
        '/' +
        (this.armadoFisicoForm.value.fechaSolEntrega.getMonth() + 1) +
        '/' +
        this.armadoFisicoForm.value.fechaSolEntrega.getFullYear(),
      tipoEmpaquetado: tipoEmpaque,
      cantidadPolizas: this.armadoFisicoForm.value.contidadPolizas,
      totalDependientes: this.armadoFisicoForm.value.totalDependientes,
      totalTitulares: this.armadoFisicoForm.value.totalTitulares,
      cantidadUnidades: this.armadoFisicoForm.value.cantidadUnidades,
      caracteristicasArmado: this.armadoFisicoForm.value.caracteristicasAF,
      empateImpresion: empate,
      listaDocumentosAnexos: listArchivo,
      datosEntrega: datosLug
    };
    // console.log("datos fisica",datosfisico);
  }
  tipoCarpetaEstru(valor: string): string {
    let newTipo = '';
    switch (valor) {
      case 'Póliza Padre':
        newTipo = 'PP';
        break;
      case 'Póliza Hija':
        newTipo = 'PH';
        break;
      case 'Contracto':
        newTipo = 'CON';
        break;
      case 'Adjuntos':
        newTipo = 'OTRO';
        break;
      case 'Pool':
        newTipo = 'POOL';
        break;
      case 'Certificado':
        newTipo = 'CERT';
      case 'Póliza':
        newTipo = 'POL';
        break;
      case 'Anexos':
        newTipo = 'ANE';
        break;
    }
    return newTipo;
  }
  ArregloCarpetas = [];
  cargaCarpetas() {
    estructuraCar = [];
    let tipo = '';
    let numfiles = 0;
    let numfiles2 = 0;
    let numfiles3 = 0;
    let numfiles4 = 0;
    let numfilesA = 0;
    let numfiles2A = 0;
    let numfiles3A = 0;
    let numfiles4A = 0;
    let cont1 = 0;
    let numCar = 0;
    console.log(carpetas);
    console.log(this.ArregloCarpetas);
    for (let armados of this.ArregloCarpetas) {
      //for(let sub of carpetas){//nivel 1
      estructuraCar = [];
      numfiles = 0;
      console.log(armados);
      for (let sub of armados) {
        //nivel 1
        tipo = this.tipoCarpetaEstru(sub.tipo);
        if (sub.filelist.length !== 0) {
          ListaDocumentos = [];
          for (let file of sub.filelist) {
            listdoc = {
              tipoArchivo: file.tipoArchivo,
              renombre: file.renombre
            };
            ListaDocumentos[numfiles] = listdoc;
            numfiles++;
          }
        } else {
          ListaDocumentos = [];
        }
        let nombreCarpetaAux: string;
        if (sub.tipo === this.Pool) {
          if (sub.nombre === this.Pool) {
            nombreCarpetaAux = this.NombrePool;
          } else {
            nombreCarpetaAux = sub.nombre;
          }
        } else {
          nombreCarpetaAux = sub.nombre;
        }
        estruc = {
          tipoCarpeta: sub.tipo,
          nodoPadre: 'N/A',
          nodo: parseInt(sub.nivel),
          nombreCarpeta: nombreCarpetaAux,
          listaDocumentos: ListaDocumentos,
          listaDocumentosAnexos: []
        };

        estructuraCar[cont1] = estruc;

        for (let sub1 of sub.subcarpeta) {
          //nivel 2
          tipo = this.tipoCarpetaEstru(sub1.tipo);
          if (sub1.nivel === '4') {
            if (sub1.filelist.length !== 0) {
              numfiles2 = 0;
              numfiles2A = 0;
              ListaDocumentosAnexos = [];
              ListaDocumentos = [];
              for (let file of sub1.filelist) {
                if (file.tipoArchivo !== 'OTROS') {
                  listdoc = {
                    tipoArchivo: file.tipoArchivo,
                    renombre: file.renombre
                  };
                  ListaDocumentos[numfiles2] = listdoc;
                  numfiles2++;
                } else {
                  listdocA = {
                    tipoArchivo: file.tipoArchivo,
                    renombre: file.renombre,
                    ruta: file.ruta
                  };
                  ListaDocumentosAnexos[numfiles2A] = listdocA;
                  numfiles2A++;
                }
                /*  listdocA={tipoArchivo:file.tipoArchivo,renombre:file.renombre,ruta:file.ruta}
              ListaDocumentosAnexos[numfiles2]=listdocA;
              numfiles2++; */
              }
              //ListaDocumentos=[];
            } else {
              ListaDocumentosAnexos = [];
              ListaDocumentos = [];
            }
          } else {
            if (sub1.filelist.length !== 0) {
              numfiles2 = 0;
              ListaDocumentos = [];
              for (let file of sub1.filelist) {
                listdoc = {
                  tipoArchivo: file.tipoArchivo,
                  renombre: file.renombre
                };
                ListaDocumentos[numfiles2] = listdoc;
                numfiles2++;
              }
              ListaDocumentosAnexos = [];
            } else {
              ListaDocumentos = [];
              ListaDocumentosAnexos = [];
            }
          }
          estruc = {
            tipoCarpeta: sub1.tipo,
            nodoPadre: sub.nivel,
            nodo: parseInt(sub1.nivel),
            nombreCarpeta: sub1.nombre,
            listaDocumentos: ListaDocumentos,
            listaDocumentosAnexos: ListaDocumentosAnexos
          };
          numCar = estructuraCar.length;
          estructuraCar[numCar] = estruc;

          for (let sub2 of sub1.subcarpeta) {
            //nivel 3
            tipo = this.tipoCarpetaEstru(sub2.tipo);
            if (sub2.nivel === '4') {
              if (sub2.filelist.length !== 0) {
                numfiles3 = 0;
                numfiles3A = 0;
                ListaDocumentosAnexos = [];
                ListaDocumentos = [];
                for (let file of sub2.filelist) {
                  if (file.tipoArchivo !== 'OTROS') {
                    listdoc = {
                      tipoArchivo: file.tipoArchivo,
                      renombre: file.renombre
                    };
                    ListaDocumentos[numfiles3] = listdoc;
                    numfiles3++;
                  } else {
                    listdocA = {
                      tipoArchivo: file.tipoArchivo,
                      renombre: file.renombre,
                      ruta: file.ruta
                    };
                    ListaDocumentosAnexos[numfiles3A] = listdocA;
                    numfiles3A++;
                  }
                }
              } else {
                ListaDocumentosAnexos = [];
                ListaDocumentos = [];
              }
            } else {
              if (sub2.filelist.length !== 0) {
                numfiles3 = 0;
                ListaDocumentos = [];
                for (let file of sub2.filelist) {
                  listdoc = {
                    tipoArchivo: file.tipoArchivo,
                    renombre: file.renombre
                  };
                  ListaDocumentos[numfiles3] = listdoc;
                  numfiles3++;
                }
                ListaDocumentosAnexos = [];
              } else {
                ListaDocumentos = [];
                ListaDocumentosAnexos = [];
              }
            }
            estruc = {
              tipoCarpeta: sub2.tipo,
              nodoPadre: sub1.nivel,
              nodo: parseInt(sub2.nivel),
              nombreCarpeta: sub2.nombre,
              listaDocumentos: ListaDocumentos,
              listaDocumentosAnexos: ListaDocumentosAnexos
            };
            numCar = estructuraCar.length;
            estructuraCar[numCar] = estruc;

            for (let sub3 of sub2.subcarpeta) {
              //nivel 4
              tipo = this.tipoCarpetaEstru(sub3.tipo);
              if (sub3.nivel === '4') {
                if (sub3.filelist.length !== 0) {
                  numfiles4 = 0;
                  numfiles4A = 0;
                  ListaDocumentosAnexos = [];
                  ListaDocumentos = [];
                  for (let file of sub3.filelist) {
                    if (file.tipoArchivo !== 'OTROS') {
                      listdoc = {
                        tipoArchivo: file.tipoArchivo,
                        renombre: file.renombre
                      };
                      ListaDocumentos[numfiles4] = listdoc;
                      numfiles4++;
                    } else {
                      listdocA = {
                        tipoArchivo: file.tipoArchivo,
                        renombre: file.renombre,
                        ruta: file.ruta
                      };
                      ListaDocumentosAnexos[numfiles4A] = listdocA;
                      numfiles4A++;
                    }
                  }
                  //ListaDocumentos=[];
                } else {
                  ListaDocumentosAnexos = [];
                  ListaDocumentos = [];
                }
              } else {
                if (sub3.filelist.length !== 0) {
                  numfiles4 = 0;
                  ListaDocumentos = [];
                  for (let file of sub3.filelist) {
                    listdoc = {
                      tipoArchivo: file.tipoArchivo,
                      renombre: file.renombre
                    };
                    ListaDocumentos[numfiles4] = listdoc;
                    numfiles4++;
                  }
                  ListaDocumentosAnexos = [];
                } else {
                  ListaDocumentos = [];
                  ListaDocumentosAnexos = [];
                }
              }
              estruc = {
                tipoCarpeta: sub3.tipo,
                nodoPadre: sub2.nivel,
                nodo: parseInt(sub3.nivel),
                nombreCarpeta: sub3.nombre,
                listaDocumentos: ListaDocumentos,
                listaDocumentosAnexos: ListaDocumentosAnexos
              };
              numCar = estructuraCar.length;
              estructuraCar[numCar] = estruc;
            }
          }
        }
      }
      let num = 0;
      let ifExist = false;
      num = estrucM.length;
      if (num === 0) {
        estrucM[num] = { estructuraCar, id: armados[0].id };
      } else {
        for (let armado of estrucM) {
          if (armado.id === armados[0].id) {
            //let pos=estrucM.indexOf(armado)
            ifExist = true;
            break;
            //estrucM[pos]={estructuraCar,id:armado.id}
          }
        }
        if (ifExist === true) {
          for (let armado of estrucM) {
            if (armado.id === armados[0].id) {
              let pos = estrucM.indexOf(armado);
              estrucM[pos] = { estructuraCar, id: armado.id };
            }
          }
        } else {
          estrucM[num] = { estructuraCar, id: armados[0].id };
        }
      }
    }
    console.log(estrucM);
    console.log(estructuraCar);
  }

  passDatosArm: Boolean; //variable para saber que los campos de armado espcial fueron llenados
  passDatosEnv: Boolean; ////variable que corresponde ha que se ingreso un codigo de envio valido
  notiError(msn: string) {
    this.TxtNtf5 = msn;
    this.notiDatosArmado = true;
    setTimeout(() => {
      this.notiDatosArmado = false;
    }, this.TimeNoti);
  }

  validateEmpate() {
    return this.empateUpload.validateErrors();
  }

  payloadEmpate() {
    return this.empateUpload.onSubmit();
  }

  onSubmit() {
    //console.log('continuar')
    this.TxtNtf8 = '';
    let valFecha = '';
    // console.log(this.armadoFisicoForm.value);
    this.obtenerDatosEntrega();
    if (
      this.entregaFisicaForm.value.lugarEnvio === '' ||
      this.entregaFisicaForm.value.lugarEnvio === null
    ) {
      // no se ingreso un codigo
      // console.log('Código no ingresado');
      this.TxtNtf8 = this.TxtNtf11;
      this.notiCodEnvio = true;
      setTimeout(() => {
        this.notiCodEnvio = false;
      }, this.TimeNoti);
    } else {
      // se ingresa un codigo
      if (this.envioEspecialEnable === true) {
        // Envio especial Activo
        if (
          this.entregaFisicaForm.value.lugarEnvio !== '' &&
          this.entregaFisicaForm.value.calle !== '' &&
          this.entregaFisicaForm.value.numero !== '' &&
          this.entregaFisicaForm.value.colonia !== '' &&
          this.entregaFisicaForm.value.delegacion !== '' &&
          this.entregaFisicaForm.value.ciudad !== '' &&
          this.entregaFisicaForm.value.codigoPostal !== '' &&
          this.entregaFisicaForm.value.contacto !== ''
        ) {
          // campos llenados
          // console.log('Los datos son correctos')
          this.obtenerDatosEntrega();
          this.cargarDireccion();
          this.cargaDatosEntrega(true);
          // this.entregaFisicaForm.reset();
          this.passDatosEnv = true;
          this.notiCodEnvio = false;
        } else {
          // campos no llenados
          // console.log('Todos los campos son obligatorios')
          this.TxtNtf8 = this.TxtNtf10;
          this.notiCodEnvio = true;
          setTimeout(() => {
            this.notiCodEnvio = false;
          }, this.TimeNoti);
          this.passDatosEnv = false;
        }
      } else {
        // envio especial no acntivo
        if (this.entregaFisicaForm.value.lugarEnvio !== '') {
          // se ingreso un codigo
          if (this.datosEnvio.lugar_envio === '') {
            // codigo no valido
            //console.log('Código no invalido')
            this.TxtNtf8 = this.TxtNtf9;
            this.notiCodEnvio = true;
            this.passDatosEnv = false;
            setTimeout(() => {
              this.notiCodEnvio = false;
            }, this.TimeNoti);
          } else {
            // codigo valido
            //this.obtenerDatosEntrega();
            this.cargarDireccion2();
            this.cargaDatosEntrega2(false);
            this.passDatosEnv = true;
            // console.log('Datos cargados y enviados')
            // this.entregaFisicaForm.reset();
          }
        }
      }
    }
    // Validacion de los datos de armado Fisico
    if (
      this.armadoFisicoForm.value.fechaSolEntrega !== null &&
      this.armadoFisicoForm.value.ramo !== '' &&
      this.armadoFisicoForm.value.contidadPolizas !== '' &&
      this.armadoFisicoForm.value.totalDependientes !== '' &&
      this.armadoFisicoForm.value.totalTitulares !== '' &&
      /*  (this.armadoFisicoForm.value.bloquesGmmVida === "" || this.armadoFisicoForm.value.bloquesGmmVida === undefined) &&
      (this.armadoFisicoForm.value.carteras === "" || this.armadoFisicoForm.value.carteras === undefined) &&
      (this.armadoFisicoForm.value.bloquesAutos === "" || this.armadoFisicoForm.value.bloquesAutos === undefined) &&
      (this.armadoFisicoForm.value.folder === "" || this.armadoFisicoForm.value.folder === undefined) &&*/
      this.armadoFisicoForm.value.cantidadUnidades !== '' &&
      this.armadoFisicoForm.value.cantidadUnidades !== ''
    ) {
      /*  valFecha=this.comparaFechas();
      if(valFecha!=='NV'){ */
      this.cargaDatosFisico();
      // console.log("Datos AF", this.armadoFisicoForm.value);
      if (
        this.serviceValidator.esNumeroValido(
          this.armadoFisicoForm.value.contidadPolizas
        ) &&
        this.serviceValidator.esNumeroValido(
          this.armadoFisicoForm.value.totalDependientes
        ) &&
        this.serviceValidator.esNumeroValido(
          this.armadoFisicoForm.value.totalTitulares
        ) &&
        this.serviceValidator.esNumeroValido(
          this.armadoFisicoForm.value.cantidadUnidades
        )
      ) {
        this.cargaDatosFisico();
        console.log('Datos AF correctos');
        this.notiDatosArmado = false;
        this.passDatosArm = true;
      } else {
        console.log('Datos incorrectos');
        this.notiError(this.TxtNtf4);
        this.passDatosArm = false;
      }
      // }
    } else {
      console.log('No hay datos de armado físico');
      this.passDatosArm = false;
      this.notiError(this.TxtNtf4);
    }
  }

  // funcion para buscar los codigo de envio
  public datosEnvio: DatosEnvio = {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  };
  public datosEnvio_aux: DatosEnvio = {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  };

  CodigosEnvio: DatosEnvio[];
  notiCodEnvio: Boolean = false;

  cambio() {
    this.notiCodEnvio = false;
    if (
      this.entregaFisicaForm.value.lugarEnvio === null ||
      this.entregaFisicaForm.value.lugarEnvio === ''
    ) {
      this.TxtNtf8 = this.TxtNtf11;
      this.notiCodEnvio = true;
      this.entregaFisicaForm.reset();
    } else {
      let ifcodigo = false;
      for (let cod of this.listaCodigo) {
        if (cod.codigo === this.entregaFisicaForm.value.lugarEnvio) {
          ifcodigo = true;
          break;
        } else {
          ifcodigo = false;
        }
      }
      if (ifcodigo === true) {
        this.http
          .get<DatosEnvio[]>(appSettings.URL_OT14) //codigo de envio
          .subscribe({
            next: (data) => {
              this.CodigosEnvio = data;
              for (let datos of this.CodigosEnvio) {
                if (
                  datos.lugar_envio === this.entregaFisicaForm.value.lugarEnvio
                ) {
                  this.datosEnvio = datos;
                }
              }
              if (this.datosEnvio.lugar_envio === '') {
                this.TxtNtf8 = this.TxtNtf9;
                this.notiCodEnvio = true;
                setTimeout(() => {
                  this.notiCodEnvio = false;
                }, this.TimeNoti);
              }
              this.entregaFisicaForm.value.calle = this.datosEnvio.calle;
              this.entregaFisicaForm.value.numero = this.datosEnvio.numero;
              this.entregaFisicaForm.value.colonia = this.datosEnvio.colonia;
              this.entregaFisicaForm.value.delegacion =
                this.datosEnvio.delegacion;
              this.entregaFisicaForm.value.ciudad = this.datosEnvio.ciudad;
              this.entregaFisicaForm.value.codigoPostal =
                this.datosEnvio.codigo_postal;
              this.entregaFisicaForm.value.contacto = this.datosEnvio.contacto;
              this.entregaFisicaForm.value.telefono_d =
                this.datosEnvio.telefono_d;
            },
            error: (error) => {
              console.error('There was an error!', error);
            }
          });
      } else {
        this.datosEnvio = this.datosEnvio_aux;
        if (this.datosEnvio.lugar_envio === '') {
          this.TxtNtf8 = this.TxtNtf9;
          setTimeout(() => {
            this.notiCodEnvio = false;
          }, this.TimeNoti);
          this.notiCodEnvio = true;
        }
        this.entregaFisicaForm.value.calle = this.datosEnvio.calle;
        this.entregaFisicaForm.value.numero = this.datosEnvio.numero;
        this.entregaFisicaForm.value.colonia = this.datosEnvio.colonia;
        this.entregaFisicaForm.value.delegacion = this.datosEnvio.delegacion;
        this.entregaFisicaForm.value.ciudad = this.datosEnvio.ciudad;
        this.entregaFisicaForm.value.codigoPostal =
          this.datosEnvio.codigo_postal;
        this.entregaFisicaForm.value.contacto = this.datosEnvio.contacto;
        this.entregaFisicaForm.value.telefono_d = this.datosEnvio.telefono_d;
      }
    }
    //this.onSubmit();// ejecucion en caso de que no se de clic en el boton de continar de configuracion de armado
  }
  envioEspecialEnable: Boolean;
  habilitaForm() {
    if (
      this.envioEspecialEnable === false ||
      this.envioEspecialEnable === undefined
    ) {
      this.envioEspecialEnable = true;
      this.entregaFisicaForm.enable();
      // console.log('se habilita form');
    } else {
      this.envioEspecialEnable = false;
      this.entregaFisicaForm.controls.calle.disable();
      this.entregaFisicaForm.controls.numero.disable();
      this.entregaFisicaForm.controls.colonia.disable();
      this.entregaFisicaForm.controls.ciudad.disable();
      this.entregaFisicaForm.controls.delegacion.disable();
      this.entregaFisicaForm.controls.contacto.disable();
      this.entregaFisicaForm.controls.codigoPostal.disable();
      this.entregaFisicaForm.controls.telefono_d.disable();
      //  console.log('se deshabilita form');
    }
  }

  // funciones armado digital
  nueva: Boolean;
  nuevasub: Boolean;
  aux_nombre: string[];

  // carpetas:carpeta[];
  /*  nomen2: nombreCarp2[]=[
    {name:'PólizaPadre'},
    {name:'PólizaHija'},
    {name:'Contratante'},
    {name:'Asegurado'}
  ]; */
  origen: string;
  mostrarCrearCarpeta() {
    // console.log('crear carpeta');
    this.nueva = true;
    this.botoncancelar = true;
  }
  nivelCarp: string;
  mostrarSubcarpeta(nombre: string[], nivel?: string) {
    //this.tiposCarpetas=opcRamo;

    this.renombrar = false;
    this.nueva = true;
    this.aux_nombre = nombre;
    this.botoncancelar = true;
    this.nivelCarp = nivel;
  }

  notiCarpetas: Boolean;
  opcionCancelar: string;

  crearCarpeta() {
    // this.nueva = false;
    // this.notiCarpetas=false;
    //   console.log(this.nombreCarpeta.value);
    //   console.log(this.nuevaCarpeta.value.tipoCarpeta);
    //   console.log(this.nuevaCarpeta.value.nombreCarpeta);
    //   console.log(this.opcionCancelar)
    if (this.opcionCancelar === 'cancelar') {
      this.nueva = false;
      this.nombreCarpeta.reset('');
      this.nuevaCarpeta.controls.tipoCarpeta.reset('');
      this.nuevaCarpeta.controls.nombreCarpeta.reset('');
      this.aux_nombre = undefined;
      this.nueva = false;
      this.formGroupDirective.resetForm();
    } else {
      if (
        this.nuevaCarpeta.value.tipoCarpeta !== '' &&
        this.nombreCarpeta.value !== ''
      ) {
        //se valida el tipo y nombre que no sean vacios
        let lugar = 0;
        let lugar2 = 0;
        let lugar3 = 0;
        let cont = 0;
        aux_carpeta = null;
        aux_carpetas = [];
        let nivel_aux = '';
        let existeP = false;
        let existeH = false;
        if (this.aux_nombre.length === 1) {
          //    console.log('caso 2');
          for (let carpeta of carpetas) {
            if (
              carpeta.nombre === this.aux_nombre[0] &&
              this.nivelCarp === 'N/A'
            ) {
              // bloqueo de nuevas carpetas a anexo de nivel de contrato
              if (carpeta.nivel === '4') {
                //          console.log('no debe tener subcarpetas')
                this.mensajeCarpetaError(this.TxtNtf13);
              } else {
                //         console.log('1.1')
                if (carpeta.subcarpeta.length === 0) {
                  //se obtiene el nivel de la nueva carpeta
                  if (
                    this.nuevaCarpeta.value.tipoCarpeta ===
                    this.tiposCarpetas[0].name
                  ) {
                    nivel_aux = '2';
                  } else if (
                    this.nuevaCarpeta.value.tipoCarpeta ===
                    this.tiposCarpetas[1].name
                  ) {
                    nivel_aux = '3';
                  } else {
                    nivel_aux = '4';
                  }
                  aux_carpetas = carpeta.subcarpeta;
                  // tslint:disable-next-line: max-line-length
                  aux_carpeta = {
                    nombre: this.nombreCarpeta.value,
                    tipo: this.nuevaCarpeta.value.tipoCarpeta,
                    nivel: nivel_aux,
                    filelist: [],
                    subcarpeta: []
                  };
                  aux_carpetas[0] = aux_carpeta;
                  carpetas[lugar] = {
                    nombre: carpeta.nombre,
                    tipo: carpeta.tipo,
                    nivel: carpeta.nivel,
                    filelist: carpeta.filelist,
                    subcarpeta: aux_carpetas,
                    id: carpeta.id
                  };
                  this.nueva = false;
                } else {
                  for (let sub of carpeta.subcarpeta) {
                    if (sub.nivel === '2') {
                      existeP = true;
                    } else if (sub.nivel === '3') {
                      existeH = true;
                    }
                  }
                  if (
                    this.nuevaCarpeta.value.tipoCarpeta ===
                    this.tiposCarpetas[0].name
                  ) {
                    if (existeP === true || existeH === true) {
                      if (existeP === true) {
                        this.nueva = false;
                        this.mensajeCarpetaError(this.TxtNtf15);
                      } else if (existeH === true) {
                        this.mensajeCarpetaError(
                          'No se puede crear una carpeta Padre si ya existe una Hija'
                        );
                        this.nueva = false;
                      }
                    } else {
                      cont = carpeta.subcarpeta.length;
                      aux_carpetas = carpeta.subcarpeta;
                      aux_carpeta = {
                        nombre: this.nombreCarpeta.value,
                        tipo: this.tiposCarpetas[0].name,
                        nivel: '2',
                        filelist: [],
                        subcarpeta: []
                      };
                      aux_carpetas[cont] = aux_carpeta;
                      carpetas[lugar] = {
                        nombre: carpeta.nombre,
                        tipo: carpeta.tipo,
                        nivel: carpeta.nivel,
                        filelist: carpeta.filelist,
                        subcarpeta: aux_carpetas,
                        id: carpeta.id
                      };
                      this.nueva = false;
                    }
                  } else if (
                    this.nuevaCarpeta.value.tipoCarpeta ===
                    this.tiposCarpetas[1].name
                  ) {
                    if (existeP === true || existeH === true) {
                      if (existeP === true) {
                        this.mensajeCarpetaError(
                          'No se puede crear una carpeta hija fuera de una padre'
                        );
                        this.nueva = false;
                      } else if (existeH === true) {
                        this.mensajeCarpetaError(
                          'Ya existe una carpeta de tipo Hija/Certificado'
                        );
                        this.nueva = false;
                      }
                    } else {
                      cont = carpeta.subcarpeta.length;
                      aux_carpetas = carpeta.subcarpeta;
                      aux_carpeta = {
                        nombre: this.nombreCarpeta.value,
                        tipo: this.tiposCarpetas[1].name,
                        nivel: '3',
                        filelist: [],
                        subcarpeta: []
                      };
                      aux_carpetas[cont] = aux_carpeta;
                      carpetas[lugar] = {
                        nombre: carpeta.nombre,
                        tipo: carpeta.tipo,
                        nivel: carpeta.nivel,
                        filelist: carpeta.filelist,
                        subcarpeta: aux_carpetas,
                        id: carpeta.id
                      };
                      this.nueva = false;
                    }
                  } else if (
                    this.nuevaCarpeta.value.tipoCarpeta ===
                    this.tiposCarpetas[2].name
                  ) {
                    cont = carpeta.subcarpeta.length;
                    aux_carpetas = carpeta.subcarpeta;
                    aux_carpeta = {
                      nombre: this.nombreCarpeta.value,
                      tipo: this.tiposCarpetas[2].name,
                      nivel: '4',
                      filelist: [],
                      subcarpeta: []
                    };
                    aux_carpetas[cont] = aux_carpeta;
                    carpetas[lugar] = {
                      nombre: carpeta.nombre,
                      tipo: carpeta.tipo,
                      nivel: carpeta.nivel,
                      filelist: carpeta.filelist,
                      subcarpeta: aux_carpetas,
                      id: carpeta.id
                    };
                    this.nueva = false;
                  }
                }
              }
            }
            lugar++;
          }
        } else if (this.aux_nombre.length === 2) {
          //  console.log('caso 3')
          for (let carpeta of carpetas) {
            if (carpeta.nombre === this.aux_nombre[0]) {
              lugar2 = 0;
              for (let sub of carpeta.subcarpeta) {
                if (
                  sub.nombre === this.aux_nombre[1] &&
                  sub.nivel === this.nivelCarp
                ) {
                  if (sub.nivel === '4') {
                    this.mensajeCarpetaError(this.TxtNtf13);
                    this.nueva = false;
                  } else {
                    if (sub.subcarpeta.length === 0) {
                      if (
                        this.nuevaCarpeta.value.tipoCarpeta ===
                        this.tiposCarpetas[1].name
                      ) {
                        nivel_aux = '3';
                        aux_carpetas = sub.subcarpeta;
                        aux_carpeta = {
                          nombre: this.nombreCarpeta.value,
                          tipo: this.nuevaCarpeta.value.tipoCarpeta,
                          nivel: nivel_aux,
                          filelist: [],
                          subcarpeta: []
                        };
                        aux_carpetas[0] = aux_carpeta;
                        carpetas[lugar].subcarpeta[lugar2] = {
                          nombre: sub.nombre,
                          tipo: sub.tipo,
                          nivel: sub.nivel,
                          filelist: sub.filelist,
                          subcarpeta: aux_carpetas
                        };
                        this.nueva = false;
                      } else if (
                        this.nuevaCarpeta.value.tipoCarpeta ===
                        this.tiposCarpetas[2].name
                      ) {
                        nivel_aux = '4';
                        aux_carpetas = sub.subcarpeta;
                        aux_carpeta = {
                          nombre: this.nombreCarpeta.value,
                          tipo: this.nuevaCarpeta.value.tipoCarpeta,
                          nivel: nivel_aux,
                          filelist: [],
                          subcarpeta: []
                        };
                        aux_carpetas[0] = aux_carpeta;
                        carpetas[lugar].subcarpeta[lugar2] = {
                          nombre: sub.nombre,
                          tipo: sub.tipo,
                          nivel: sub.nivel,
                          filelist: sub.filelist,
                          subcarpeta: aux_carpetas
                        };
                        this.nueva = false;
                      } else {
                        this.mensajeCarpetaError(
                          'No se puede crear una carpeta Padre dentro de una carpeta Padre'
                        );
                        this.nueva = false;
                      }
                    } else {
                      for (let sub1 of sub.subcarpeta) {
                        if (sub1.nivel === '3') {
                          existeH = true;
                        }
                      }
                      if (
                        this.nuevaCarpeta.value.tipoCarpeta ===
                        this.tiposCarpetas[0].name
                      ) {
                        this.mensajeCarpetaError(this.TxtNtf16);
                        this.nueva = false;
                      } else if (
                        this.nuevaCarpeta.value.tipoCarpeta ===
                        this.tiposCarpetas[1].name
                      ) {
                        if (existeH === true) {
                          this.mensajeCarpetaError(this.TxtNtf17);
                          this.nueva = true;
                        } else {
                          cont = sub.subcarpeta.length;
                          aux_carpetas = sub.subcarpeta;
                          aux_carpeta = {
                            nombre: this.nombreCarpeta.value,
                            tipo: this.tiposCarpetas[1].name,
                            nivel: '3',
                            filelist: [],
                            subcarpeta: []
                          };
                          aux_carpetas[cont] = aux_carpeta;
                          carpetas[lugar].subcarpeta[lugar2] = {
                            nombre: sub.nombre,
                            tipo: sub.tipo,
                            nivel: sub.nivel,
                            filelist: sub.filelist,
                            subcarpeta: aux_carpetas
                          };
                          this.nueva = false;
                        }
                      } else if (
                        this.nuevaCarpeta.value.tipoCarpeta ===
                        this.tiposCarpetas[2].name
                      ) {
                        cont = sub.subcarpeta.length;
                        aux_carpetas = sub.subcarpeta;
                        aux_carpeta = {
                          nombre: this.nombreCarpeta.value,
                          tipo: this.tiposCarpetas[2].name,
                          nivel: '4',
                          filelist: [],
                          subcarpeta: []
                        };
                        aux_carpetas[cont] = aux_carpeta;
                        carpetas[lugar].subcarpeta[lugar2] = {
                          nombre: sub.nombre,
                          tipo: sub.tipo,
                          nivel: sub.nivel,
                          filelist: sub.filelist,
                          subcarpeta: aux_carpetas
                        };
                        this.nueva = false;
                      }
                    }
                  }
                }
                lugar2++;
              }
            }
            lugar++;
          }
        } else if (this.aux_nombre.length === 3) {
          for (let carpeta of carpetas) {
            if (carpeta.nombre === this.aux_nombre[0]) {
              lugar2 = 0;
              for (let sub of carpeta.subcarpeta) {
                if (sub.nombre === this.aux_nombre[1]) {
                  lugar3 = 0;
                  for (let sub1 of sub.subcarpeta) {
                    if (
                      sub1.nombre === this.aux_nombre[2] &&
                      sub1.nivel === this.nivelCarp
                    ) {
                      if (sub1.nivel === '4') {
                        //        console.log('no debe tener subcarpetas')
                        this.mensajeCarpetaError(this.TxtNtf13);
                        this.nueva = false;
                      } else {
                        if (
                          this.nuevaCarpeta.value.tipoCarpeta ===
                          this.tiposCarpetas[0].name
                        ) {
                          this.mensajeCarpetaError(this.TxtNtf18);
                          this.nueva = false;
                        } else if (
                          this.nuevaCarpeta.value.tipoCarpeta ===
                          this.tiposCarpetas[1].name
                        ) {
                          this.mensajeCarpetaError(this.TxtNtf17);
                          this.nueva = false;
                        } else if (
                          this.nuevaCarpeta.value.tipoCarpeta ===
                          this.tiposCarpetas[2].name
                        ) {
                          if (sub1.subcarpeta.length === 0) {
                            aux_carpetas = sub1.subcarpeta;
                            aux_carpeta = {
                              nombre: this.nombreCarpeta.value,
                              tipo: this.tiposCarpetas[2].name,
                              nivel: '4',
                              filelist: [],
                              subcarpeta: []
                            };
                            aux_carpetas[0] = aux_carpeta;
                            carpetas[lugar].subcarpeta[lugar2].subcarpeta[
                              lugar3
                            ] = {
                              nombre: sub1.nombre,
                              tipo: sub1.tipo,
                              nivel: sub1.nivel,
                              filelist: sub1.filelist,
                              subcarpeta: aux_carpetas
                            };
                            this.nueva = false;
                          } else {
                            cont = sub1.subcarpeta.length;
                            aux_carpetas = sub1.subcarpeta;
                            aux_carpeta = {
                              nombre: this.nombreCarpeta.value,
                              tipo: this.tiposCarpetas[2].name,
                              nivel: '4',
                              filelist: [],
                              subcarpeta: []
                            };
                            aux_carpetas[cont] = aux_carpeta;
                            carpetas[lugar].subcarpeta[lugar2].subcarpeta[
                              lugar3
                            ] = {
                              nombre: sub1.nombre,
                              tipo: sub1.tipo,
                              nivel: sub1.nivel,
                              filelist: sub1.filelist,
                              subcarpeta: aux_carpetas
                            };
                            this.nueva = false;
                          }
                        }
                      }
                    }
                    lugar3++;
                  }
                }
                lugar2++;
              }
            }
            lugar++;
          }
        }
      } else {
        //  console.log('datos vacios')
        this.mensajeCarpetaError(this.TxtNtf14);
        this.nueva = true;
      }
      //  console.log(carpetas);
      /* this.nombreCarpeta.reset();
      this.nuevaCarpeta.controls.tipoCarpeta.reset();
      this.nuevaCarpeta.controls.nombreCarpeta.reset(); */
      this.nombreCarpeta.reset('');
      this.nuevaCarpeta.controls.tipoCarpeta.reset('');
      this.nuevaCarpeta.controls.nombreCarpeta.reset('');
      this.aux_nombre = undefined;
    }
    console.log(carpetas);
    //console.log(this.carpetas2);
    //this.cargaCarpetas();
    this.formGroupDirective.resetForm();
  }
  mensajeCarpetaError(msn: string) {
    this.notiCarpetas = false;
    this.TxtNtf12 = msn;
    this.notiCarpetas = true;
    setTimeout(() => {
      this.notiCarpetas = false;
    }, this.TimeNoti);
  }
  botoncancelar: Boolean; // revisar
  cancelarOpc() {
    // revisar
    this.nombreCarpeta.reset('');
    this.nuevaCarpeta.controls.tipoCarpeta.reset('');
    this.nuevaCarpeta.controls.nombreCarpeta.reset('');
    this.nueva = false;
    this.notiCarpetas = false;
    this.botoncancelar = false;
  }
  eliminarCarpeta(nombres: string[]) {
    this.nueva = false;
    this.renombrar = false;
    let pos = 0;
    if (nombres.length === 1) {
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          if (carpeta.nivel === '1') {
            //         console.log('no se puede eliminar esta carpeta')
          } else {
            console.log(carpetas.indexOf(carpeta));
            pos = carpetas.indexOf(carpeta);
            if (carpeta.subcarpeta.length !== 0) {
              //         console.log('debe borrar primero las carptas hijas de esta carpeta')
            } else {
              for (let archi of carpeta.filelist) {
                this.resetArchivo(archi.renombre);
                if (archi.tipoArchivo === 'OTROS') {
                  this.ressetArchivoAdj(archi.ruta);
                }
              }
              carpetas.splice(pos, 1);
            }
          }
        }
      }
    }
    if (nombres.length === 2) {
      let pos2 = 0;
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          pos = carpetas.indexOf(carpeta);
          for (let sub of carpeta.subcarpeta) {
            if (sub.nombre === nombres[1]) {
              console.log(carpetas.indexOf(carpeta));
              pos2 = carpeta.subcarpeta.indexOf(sub);
              if (sub.subcarpeta.length !== 0) {
                //             console.log('debe borrar primero las carptas hijas de esta carpeta')
              } else {
                for (let archi of sub.filelist) {
                  this.resetArchivo(archi.renombre);
                  if (archi.tipoArchivo === 'OTROS') {
                    this.ressetArchivoAdj(archi.ruta);
                  }
                }
                carpetas[pos].subcarpeta.splice(pos2, 1);
              }
              // carpetas[pos].subcarpeta.splice(pos2,1);
            }
          }
        }
      }
    }
    if (nombres.length === 3) {
      let pos2 = 0;
      let pos3 = 0;
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          pos = carpetas.indexOf(carpeta);
          for (let sub of carpeta.subcarpeta) {
            if (sub.nombre === nombres[1]) {
              pos2 = carpeta.subcarpeta.indexOf(sub);
              for (let sub1 of sub.subcarpeta) {
                if (sub1.nombre === nombres[2]) {
                  pos3 = sub.subcarpeta.indexOf(sub1);
                  if (sub1.subcarpeta.length !== 0) {
                    //               console.log('debe borrar primero las carptas hijas de esta carpeta')
                  } else {
                    for (let archi of sub1.filelist) {
                      this.resetArchivo(archi.renombre);
                      if (archi.tipoArchivo === 'OTROS') {
                        this.ressetArchivoAdj(archi.ruta);
                      }
                    }
                    carpetas[pos].subcarpeta[pos2].subcarpeta.splice(pos3, 1);
                  }
                  // carpetas[pos].subcarpeta[pos2].subcarpeta.splice(pos3,1);
                }
              }
            }
          }
        }
      }
    }
    if (nombres.length === 4) {
      let pos2 = 0;
      let pos3 = 0;
      let pos4 = 0;
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          pos = carpetas.indexOf(carpeta);
          for (let sub of carpeta.subcarpeta) {
            if (sub.nombre === nombres[1]) {
              pos2 = carpeta.subcarpeta.indexOf(sub);
              for (let sub1 of sub.subcarpeta) {
                if (sub1.nombre === nombres[2]) {
                  pos3 = sub.subcarpeta.indexOf(sub1);
                  for (let sub2 of sub1.subcarpeta) {
                    if (sub2.nombre === nombres[3]) {
                      pos4 = sub1.subcarpeta.indexOf(sub2);
                      if (sub2.subcarpeta.length !== 0) {
                        //             console.log('debe borrar primero las carptas hijas de esta carpeta')
                      } else {
                        for (let archi of sub2.filelist) {
                          this.resetArchivo(archi.renombre);
                          if (archi.tipoArchivo === 'OTROS') {
                            this.ressetArchivoAdj(archi.ruta);
                          }
                        }
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].subcarpeta.splice(pos4, 1);
                      }
                      // carpetas[pos].subcarpeta[pos2].subcarpeta[pos3].subcarpeta.splice(pos4,1);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    //this.cargaCarpetas();
  }

  resetArchivo(nameArchivo: string) {
    let pos = 0;
    let pos2 = 0;
    for (let archivos of TREE_DATA_2) {
      pos = TREE_DATA_2.indexOf(archivos);
      for (let archivos_h of archivos.children) {
        pos2 = archivos.children.indexOf(archivos_h);
        if (archivos_h.name === nameArchivo) {
          TREE_DATA_2[pos].children[pos2].estatus = 'NA';
        }
      }
    }
  }
  resetArchivoTipo(tipoArchivo: string) {
    let pos = 0;
    let pos2 = 0;
    for (let archivos of TREE_DATA_2) {
      pos = TREE_DATA_2.indexOf(archivos);
      for (let archivos_h of archivos.children) {
        pos2 = archivos.children.indexOf(archivos_h);
        if (archivos_h.tipo2 === tipoArchivo) {
          TREE_DATA_2[pos].children[pos2].estatus = 'NA';
        }
      }
    }
  }
  ressetArchivoAdj(rutaArchivo: string) {
    for (let OTarchivo of OtrosArchivos) {
      if (rutaArchivo.includes(OTarchivo.nombreA)) {
        let num = OtrosArchivos.indexOf(OTarchivo);
        OtrosArchivos.splice(num, 1);
      }
    }
  }
  // funcion para renombrar carpeta
  renombrar: Boolean;
  aux_renombrar: string[];
  opcRenombrar(nombre: string[]) {
    this.nueva = false;
    this.renombrar = true;
    //  console.log(nombre);
    this.aux_renombrar = nombre;
    //  console.log(this.aux_renombrar);
  }

  renombrarCarp() {
    let pos = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    console.log(this.renombreCarpeta.value);
    if (this.renombreCarpeta.value !== '') {
      console.log(this.renombreCarpeta.value);
      console.log('renombrar carpeta');
      if (this.aux_renombrar.length === 1) {
        for (let carpeta of carpetas) {
          if (carpeta.nombre === this.aux_renombrar[0]) {
            pos = carpetas.indexOf(carpeta);
            carpetas[pos].nombre = this.renombreCarpeta.value;
          }
        }
      }
      if (this.aux_renombrar.length === 2) {
        for (let carpeta of carpetas) {
          if (carpeta.nombre === this.aux_renombrar[0]) {
            pos = carpetas.indexOf(carpeta);
            for (let sub of carpeta.subcarpeta) {
              if (sub.nombre === this.aux_renombrar[1]) {
                pos2 = carpeta.subcarpeta.indexOf(sub);
                carpetas[pos].subcarpeta[pos2].nombre =
                  this.renombreCarpeta.value;
              }
            }
          }
        }
      }
      if (this.aux_renombrar.length === 3) {
        for (let carpeta of carpetas) {
          if (carpeta.nombre === this.aux_renombrar[0]) {
            pos = carpetas.indexOf(carpeta);
            for (let sub of carpeta.subcarpeta) {
              if (sub.nombre === this.aux_renombrar[1]) {
                pos2 = carpeta.subcarpeta.indexOf(sub);
                for (let sub2 of sub.subcarpeta) {
                  if (sub2.nombre === this.aux_renombrar[2]) {
                    pos3 = sub.subcarpeta.indexOf(sub2);
                    carpetas[pos].subcarpeta[pos2].subcarpeta[pos3].nombre =
                      this.renombreCarpeta.value;
                  }
                }
              }
            }
          }
        }
      }
      if (this.aux_renombrar.length === 4) {
        for (let carpeta of carpetas) {
          if (carpeta.nombre === this.aux_renombrar[0]) {
            pos = carpetas.indexOf(carpeta);
            for (let sub of carpeta.subcarpeta) {
              if (sub.nombre === this.aux_renombrar[1]) {
                pos2 = carpeta.subcarpeta.indexOf(sub);
                for (let sub2 of sub.subcarpeta) {
                  if (sub2.nombre === this.aux_renombrar[2]) {
                    pos3 = sub.subcarpeta.indexOf(sub2);
                    for (let sub3 of sub2.subcarpeta) {
                      if (sub3.nombre === this.aux_renombrar[3]) {
                        pos4 = sub2.subcarpeta.indexOf(sub3);
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].subcarpeta[pos4].nombre = this.renombreCarpeta.value;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      this.renombreCarpeta.reset('');
      this.renombrar = false;
    } else {
      console.log('campo vacio');
      this.mensajeCarpetaError('Campo vacio');
      this.renombreCarpeta.reset('');
      this.renombrar = true;
    }

    /* this.renombreCarpeta.reset();
  this.renombrar=false; */
    //this.cargaCarpetas();
    this.formGroupDirective.resetForm();
  }
  cancelarCambio() {
    this.renombreCarpeta.reset('');
    this.renombrar = false;
    this.formGroupDirective.resetForm();
  }

  selectCarpeta(nombreArch: string, tipo?: string) {
    console.log(nombreArch, tipo);
    const nombre = nombreArch;
    const tipoA = tipo;
    this.cargarListaCarp();
    console.log(this.carpetasEstructura);
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '350px',
      data: { ramos: this.carpetasEstructura, carpeta: this.carpetaSelec }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result === undefined || result === '') {
        this.mensajeArchivoErr(this.TxtNtf34);
      } else {
        this.carpetaSelec = result[0];

        console.log('mostrar result', result);
        this.agregarArch(nombre, this.carpetaSelec, '', result[1], result[2]);
      }
    });
  }
  notiArchivos: Boolean;
  estiloNoti = '';
  agregarArch(
    var1: string,
    var2: string,
    origen?: string,
    nivel?: string,
    nivelP?: string
  ) {
    //var1: nombre arhivo
    //var2 : nombre carpeta
    console.log(var1, var2, origen);
    let pos = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    let numfile = 0;
    let validacion = '';
    let validacion2 = '';
    let tipoArchivo = '';
    let tipo2Archivo = '';
    this.notiArchivos = false;
    let ruta_A = '/Presolicitud/QA/';
    //validacion = this.validaArchivo(var1, origen);
    tipoArchivo = this.validaTipoArchivo(var1);
    tipo2Archivo = this.obtTipoArchivo(var1, origen);
    //tipo2Archivo = this.obtTipoArchivo_vBD(var1, origen);
    //tipoArchivo = this.validaTipoArchivo_vBD(var1); //cambio a BD
    validacion2 = this.validaArchivoV2(var1, tipo2Archivo, origen);
    console.log(validacion2);
    console.log('validacion archivo', validacion);
    console.log(origen, tipo2Archivo, tipoArchivo);
    //  if (this.deshaReglas === false || this.deshaReglas === undefined) {
    //    if (validacion === 'NA' || validacion === undefined) {
    if (validacion2 === 'NA' || validacion2 === undefined) {
      for (let c1 of carpetas) {
        //nivel 1
        if (c1.nombre === var2 && c1.nivel === nivel && nivelP === 'N/A') {
          pos = carpetas.indexOf(c1);
          if (c1.nivel === '1') {
            if (
              tipoArchivo === '2' ||
              tipoArchivo === '3' ||
              tipoArchivo === '1'
            ) {
              if (carpetas[pos].filelist.length === 0) {
                carpetas[pos].filelist[0] = {
                  renombre: var1,
                  tipoArchivo: tipo2Archivo
                };
                this.mensajeArchivoSuc(var1);
              } else {
                numfile = carpetas[pos].filelist.length;
                carpetas[pos].filelist[numfile] = {
                  renombre: var1,
                  tipoArchivo: tipo2Archivo
                };
                this.mensajeArchivoSuc(var1);
              }
            } else {
              this.mensajeArchivoErr(this.TxtNtf24);
            }
          } else if (c1.nivel === '4') {
            if (tipoArchivo === '4') {
              if (carpetas[pos].filelist.length === 0) {
                carpetas[pos].filelist[0] = {
                  renombre: var1,
                  tipoArchivo: tipo2Archivo
                };
                this.mensajeArchivoSuc(var1);
              } else {
                numfile = carpetas[pos].filelist.length;
                carpetas[pos].filelist[numfile] = {
                  renombre: var1,
                  tipoArchivo: tipo2Archivo
                };
                this.mensajeArchivoSuc(var1);
              }
            } else {
              this.mensajeArchivoErr(this.TxtNtf25);
            }
          }
        } else {
          for (let c2 of c1.subcarpeta) {
            //nivel 2
            pos2 = c1.subcarpeta.indexOf(c2);
            if (
              c2.nombre === var2 &&
              c2.nivel === nivel &&
              c1.nivel === nivelP
            ) {
              //pos2 = c1.subcarpeta.indexOf(c2);
              if (c2.nivel === '2') {
                if (tipoArchivo === '2' || tipoArchivo === '3') {
                  if (c2.filelist.length === 0) {
                    carpetas[pos].subcarpeta[pos2].filelist[0] = {
                      renombre: var1,
                      tipoArchivo: tipo2Archivo
                    };
                    this.mensajeArchivoSuc(var1);
                  } else {
                    numfile = c2.filelist.length;
                    carpetas[pos].subcarpeta[pos2].filelist[numfile] = {
                      renombre: var1,
                      tipoArchivo: tipo2Archivo
                    };
                    this.mensajeArchivoSuc(var1);
                  }
                } else {
                  this.mensajeArchivoErr(this.TxtNtf24);
                }
              } else if (c2.nivel === '3') {
                if (tipoArchivo === '3') {
                  if (c2.filelist.length === 0) {
                    carpetas[pos].subcarpeta[pos2].filelist[0] = {
                      renombre: var1,
                      tipoArchivo: tipo2Archivo
                    };
                    this.mensajeArchivoSuc(var1);
                  } else {
                    numfile = c2.filelist.length;
                    carpetas[pos].subcarpeta[pos2].filelist[numfile] = {
                      renombre: var1,
                      tipoArchivo: tipo2Archivo
                    };
                    this.mensajeArchivoSuc(var1);
                  }
                } else {
                  this.mensajeArchivoErr(this.TxtNtf26);
                }
              } else if (c2.nivel === '4') {
                if (tipoArchivo === '4') {
                  if (c2.filelist.length === 0) {
                    if (origen === 'dnd') {
                      carpetas[pos].subcarpeta[pos2].filelist[0] = {
                        renombre: var1,
                        tipoArchivo: tipo2Archivo,
                        ruta: ruta_A + var1
                      };
                      this.mensajeArchivoSuc(var1);
                    } else {
                      carpetas[pos].subcarpeta[pos2].filelist[0] = {
                        renombre: var1,
                        tipoArchivo: tipo2Archivo,
                        ruta: ruta_A + var1 + '.pdf'
                      };
                      this.mensajeArchivoSuc(var1);
                    }
                  } else {
                    numfile = c2.filelist.length;
                    if (origen === 'dnd') {
                      carpetas[pos].subcarpeta[pos2].filelist[numfile] = {
                        renombre: var1,
                        tipoArchivo: tipo2Archivo,
                        ruta: ruta_A + var1
                      };
                      this.mensajeArchivoSuc(var1);
                    } else {
                      carpetas[pos].subcarpeta[pos2].filelist[numfile] = {
                        renombre: var1,
                        tipoArchivo: tipo2Archivo,
                        ruta: ruta_A + var1 + '.pdf'
                      };
                      this.mensajeArchivoSuc(var1);
                    }
                  }
                } else {
                  this.mensajeArchivoErr(this.TxtNtf25);
                }
              }
            } else {
              for (let c3 of c2.subcarpeta) {
                //nivel 3
                pos3 = c2.subcarpeta.indexOf(c3);
                if (
                  c3.nombre === var2 &&
                  c3.nivel === nivel &&
                  c2.nivel === nivelP
                ) {
                  //pos3 = c2.subcarpeta.indexOf(c3);
                  if (c3.nivel === '3') {
                    if (tipoArchivo === '3') {
                      if (c3.filelist.length === 0) {
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[0] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      } else {
                        numfile = c3.filelist.length;
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[numfile] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      }
                    } else {
                      this.mensajeArchivoErr(this.TxtNtf26);
                    }
                  } else if (c3.nivel === '4') {
                    if (tipoArchivo === '2') {
                      if (c3.filelist.length === 0) {
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[0] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      } else {
                        numfile = c3.filelist.length;
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[numfile] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      }
                    }
                    if (tipoArchivo === '4') {
                      if (c3.filelist.length === 0) {
                        if (origen === 'dnd') {
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].filelist[0] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo,
                            ruta: ruta_A + var1
                          };
                          this.mensajeArchivoSuc(var1);
                        } else {
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].filelist[0] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo,
                            ruta: ruta_A + var1 + '.pdf'
                          };
                          this.mensajeArchivoSuc(var1);
                        }
                      } else {
                        numfile = c3.filelist.length;
                        if (origen === 'dnd') {
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].filelist[numfile] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo,
                            ruta: ruta_A + var1
                          };
                          this.mensajeArchivoSuc(var1);
                        } else {
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].filelist[numfile] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo,
                            ruta: ruta_A + var1 + '.pdf'
                          };
                          this.mensajeArchivoSuc(var1);
                        }
                      }
                    }
                    if (tipoArchivo === '3' && c2.nivel !== '3') {
                      //30062020
                      //if(tipoArchivo==='3' && c2.nivel!=='3') { //linea original antes de 30062020
                      this.mensajeArchivoErr(
                        'Este archivo no corresponde al tipo de la carpeta padre'
                      );
                    }
                    if (tipoArchivo === '3' && c2.nivel === '3') {
                      //deja agregar archivos de nivel ph a carpetas anexas 30062020
                      if (c3.filelist.length === 0) {
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[0] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      } else {
                        numfile = c3.filelist.length;
                        carpetas[pos].subcarpeta[pos2].subcarpeta[
                          pos3
                        ].filelist[numfile] = {
                          renombre: var1,
                          tipoArchivo: tipo2Archivo
                        };
                        this.mensajeArchivoSuc(var1);
                      }
                    }
                  } /* else {
                      this.mensajeArchivoErr(this.TxtNtf25);
                    } */
                } else {
                  for (let c4 of c3.subcarpeta) {
                    //nivel 4
                    pos4 = c3.subcarpeta.indexOf(c4);
                    if (
                      c4.nombre === var2 &&
                      c4.nivel === nivel &&
                      c3.nivel === nivelP
                    ) {
                      //pos4 = c3.subcarpeta.indexOf(c4);
                      if (tipoArchivo === '3') {
                        if (c4.filelist.length === 0) {
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].subcarpeta[pos4].filelist[0] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo
                          };
                          this.mensajeArchivoSuc(var1);
                        } else {
                          numfile = c4.filelist.length;
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].subcarpeta[pos4].filelist[numfile] = {
                            renombre: var1,
                            tipoArchivo: tipo2Archivo
                          };
                          this.mensajeArchivoSuc(var1);
                        }
                      }
                      if (tipoArchivo === '4') {
                        if (c4.filelist.length === 0) {
                          if (origen === 'dnd') {
                            carpetas[pos].subcarpeta[pos2].subcarpeta[
                              pos3
                            ].subcarpeta[pos4].filelist[0] = {
                              renombre: var1,
                              tipoArchivo: tipo2Archivo,
                              ruta: ruta_A + var1
                            };
                            this.mensajeArchivoSuc(var1);
                          } else {
                            carpetas[pos].subcarpeta[pos2].subcarpeta[
                              pos3
                            ].subcarpeta[pos4].filelist[0] = {
                              renombre: var1,
                              tipoArchivo: tipo2Archivo,
                              ruta: ruta_A + var1 + '.pdf'
                            };
                            this.mensajeArchivoSuc(var1);
                          }
                        } else {
                          numfile = c4.filelist.length;
                          if (origen === 'dnd') {
                            carpetas[pos].subcarpeta[pos2].subcarpeta[
                              pos3
                            ].subcarpeta[pos4].filelist[numfile] = {
                              renombre: var1,
                              tipoArchivo: tipo2Archivo,
                              ruta: ruta_A + var1
                            };
                            this.mensajeArchivoSuc(var1);
                          } else {
                            carpetas[pos].subcarpeta[pos2].subcarpeta[
                              pos3
                            ].subcarpeta[pos4].filelist[numfile] = {
                              renombre: var1,
                              tipoArchivo: tipo2Archivo,
                              ruta: ruta_A + var1 + '.pdf'
                            };
                            this.mensajeArchivoSuc(var1);
                          }
                        }
                      }
                      if (tipoArchivo === '2') {
                        this.mensajeArchivoErr(
                          'Este archivo no corresponde al tipo de la carpeta padre'
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else if (validacion2 === 'A') {
      this.mensajeArchivoErr(this.TxtNtf23 + var1 + this.TxtNtf30);
    }
    //  }
    console.log(carpetas);
  }
  mensajeArchivoSuc(nombrearchivo?: string) {
    //this.notiArchivos=false;
    this.TxtNtf20 = this.TxtNtf21 + nombrearchivo + this.TxtNtf31;
    this.notiArchivos = true;
    this.estiloNoti = 'notiSucces';
    setTimeout(() => {
      this.notiArchivos = false;
    }, this.TimeNoti);
  }
  mensajeArchivoErr(msn: string) {
    //this.notiArchivos=false;
    // this.TxtNtf20=this.TxtNtf23;
    this.TxtNtf20 = msn;
    this.notiArchivos = true;
    this.estiloNoti = 'notiError5';
    setTimeout(() => {
      this.notiArchivos = false;
    }, this.TimeNoti);
  }

  validaArchivo(nombreArch: string, origen?: string): string {
    let pos = 0;
    let pos2 = 0;
    if (origen === 'dnd') {
      return 'NA';
    } else {
      for (let archivos of TREE_DATA_2) {
        pos = TREE_DATA_2.indexOf(archivos);
        for (let subArchi of archivos.children) {
          if (subArchi.name === nombreArch) {
            pos2 = archivos.children.indexOf(subArchi);
            if (subArchi.estatus === 'A') {
              this.mensajeArchivoErr(this.TxtNtf23);
              console.log('archivo ya Asignado a una carpeta');
              return 'A';
            } else {
              // TREE_DATA_2[pos].children[pos2].estatus='A'
              return 'NA';
            }
          }
        }
      }
    }
  }
  validaArchivoV2(nombreArch: string, tipo?: string, origen?: string): string {
    console.log(tipo);
    let pos = 0;
    let pos2 = 0;
    if (origen === 'dnd') {
      for (let sub of carpetas[0].subcarpeta) {
        //subcarpetas del nivel 1
        if (sub.nivel === '4') {
          for (let dndArchivos of sub.filelist) {
            if (dndArchivos.tipoArchivo === 'OTROS') {
              const splitRuta = dndArchivos.ruta.split('/');
              const nombre = splitRuta.pop();
              if (nombre === nombreArch) {
                return 'A';
              }
            }
          }
        }
        for (let sub2 of sub.subcarpeta) {
          //subcarpetas del nivel 2
          if (sub2.nivel === '4') {
            for (let dndArchivos of sub2.filelist) {
              if (dndArchivos.tipoArchivo === 'OTROS') {
                const splitRuta = dndArchivos.ruta.split('/');
                const nombre = splitRuta.pop();
                if (nombre === nombreArch) {
                  return 'A';
                }
              }
            }
          }
          for (let sub3 of sub2.subcarpeta) {
            //subcarpetas del nivel 3
            if (sub3.nivel === '4') {
              for (let dndArchivos of sub3.filelist) {
                if (dndArchivos.tipoArchivo === 'OTROS') {
                  const splitRuta = dndArchivos.ruta.split('/');
                  const nombre = splitRuta.pop();
                  if (nombre === nombreArch) {
                    return 'A';
                  }
                }
              }
            }
          }
        }
      }
      return 'NA';
    } else {
      for (let sub of carpetas) {
        //contrato
        console.log(sub.filelist);
        for (let archivo of sub.filelist) {
          if (archivo.tipoArchivo === tipo) {
            console.log('contrato ya esta');
            return 'A';
          }
        }
        for (let sub2 of sub.subcarpeta) {
          //pp
          console.log(sub2.filelist);
          for (let archivo of sub2.filelist) {
            if (archivo.tipoArchivo === tipo) {
              console.log('pp ya esta');
              return 'A';
            }
          }
          for (let sub3 of sub2.subcarpeta) {
            //ph
            console.log(sub3.filelist);
            for (let archivo of sub3.filelist) {
              if (archivo.tipoArchivo === tipo) {
                console.log('ph ya esta');
                return 'A';
              }
            }
            for (let sub4 of sub3.subcarpeta) {
              console.log(sub4.filelist);
              for (let archivo of sub4.filelist) {
                if (archivo.tipoArchivo === tipo) {
                  console.log('anexos en ph ya esta');
                  return 'A';
                }
              }
            }
          }
        }
      }
      console.log('no esta');
      return 'NA';
    }
  }
  cambioEstatusArchivo(nombreArch: string) {
    let pos = 0;
    let pos2 = 0;
    for (let archivos of TREE_DATA_2) {
      pos = TREE_DATA_2.indexOf(archivos);
      for (let subArchi of archivos.children) {
        if (subArchi.name === nombreArch) {
          pos2 = archivos.children.indexOf(subArchi);
          /*  if(subArchi.estatus==='A'){
          console.log("archivo ya Asignado a una carpeta");
          return 'A';
        }else { */
          TREE_DATA_2[pos].children[pos2].estatus = 'A';
          /*   return 'NA';
        } */
        }
      }
    }
  }

  validaTipoArchivo(nombreArchivo: string): string {
    // console.log(nombreArchivo)
    let pos = 0;
    let pos2 = 0;
    let tipoP = '';
    let tipoH = '';
    console.log(carpetas[0]);
    if (carpetas[0].tipo === 'Contrato') {
      tipoP = 'PP';
      tipoH = 'PH';
    } else {
      tipoP = 'POL';
      tipoH = 'CERT';
    }
    for (let archivos of TREE_DATA_2) {
      pos = TREE_DATA_2.indexOf(archivos);
      for (let subArchi of archivos.children) {
        if (subArchi.name === nombreArchivo) {
          pos2 = archivos.children.indexOf(subArchi);
          if (subArchi.tipo === tipoP) {
            return '2';
          } else if (subArchi.tipo === tipoH) {
            return '3';
          } else {
            return '4';
          }
        }
      }
    }
    return '4';
  }
  validaTipoArchivo_vBD(nombreArchivo: string): string {
    //valida al cambio a BD
    // console.log(nombreArchivo)
    let pos = 0;
    let pos2 = 0;
    let tipoP = '';
    let tipoH = '';
    console.log(carpetas[0]);
    if (carpetas[0].tipo === 'Contrato') {
      tipoP = 'PP';
      tipoH = 'PH';
    } else {
      tipoP = 'POL';
      tipoH = 'CERT';
    }
    for (let archivos of TREE_DATA_2) {
      if (archivos.name === nombreArchivo) {
        if (archivos.tipo === tipoP) {
          return '2';
        } else if (archivos.tipo === tipoH) {
          return '3';
        } else {
          return '4';
        }
      }
    }
    return '4';
  }
  pdfunico: Boolean;
  deshaReglas: Boolean; // variable para saber si se dio clic en pdfunificado
  namePdfUnif: ''; // variable para obtener el nombre para pdf unificado
  carpetas2: any; // variable para las carpetas
  changeBoolean() {
    this.isPdfUnif = !this.isPdfUnif;
    if (this.deshaReglas === undefined || this.deshaReglas === false) {
      // se deshabilitan las reglas
      console.log('sin reglas inicio');
      this.deshaReglas = true;
      this.dialogoCont.prototype.deshaRenombre = true; // se dehabilita el nemonbrado
      // seccionPdfUni={uniquePDF:true,nombreuniquePDF:''}
      // console.log(this.namePdfUnif);
      this.resetArchivosPDF();
      if (opcVars.criterio === 'Pool') {
        carpetas = [
          {
            nombre: 'Pool',
            tipo: 'Pool',
            nivel: '1',
            filelist: [],
            subcarpeta: []
          }
        ];
      } else {
        carpetas = [
          {
            nombre: 'Contrato',
            tipo: 'Contrato',
            nivel: '1',
            filelist: [],
            subcarpeta: []
          }
        ];
      }
      console.log('SR ', carpetas);
      //this.carpetas2=carpetas;
    } else {
      // se habilitan las reglas
      console.log('con reglas inicio');
      this.deshaReglas = false;
      this.dialogoCont.prototype.deshaRenombre = false; // se habilita el renombrado
      // seccionPdfUni={uniquePDF:false,nombreuniquePDF:''}
      this.resetArchivosPDF();
      if (opcVars.criterio === 'Pool') {
        carpetas = [
          {
            nombre: 'Pool',
            tipo: 'Pool',
            nivel: '1',
            filelist: [],
            subcarpeta: []
          }
        ];
      } else {
        carpetas = [
          {
            nombre: 'Contrato',
            tipo: 'Contrato',
            nivel: '1',
            filelist: [],
            subcarpeta: []
          }
        ];
      }
      console.log('CR ', carpetas);
      //this.carpetas2=carpetas;
    }
  }
  variablesNivel: ListaVarNivel;
  mostrarContenido(nombre: string[], nivel?: string) {
    this.nueva = false;
    this.renombrar = false;
    let aux_nombres = nombre;
    let long = 0;
    let aux_archivos = [];
    long = aux_nombres.length;

    this.variablesNivel = this.listarArchivos(aux_nombres, nivel);
    console.log(this.variablesNivel);
    nivel_c = this.variablesNivel.nivel;
    //this.listaVariables(opcVars.ramo,opcVars.criterio,this.variablesNivel.nivel);
    if (nivel === '4') {
      this.listaVariables(
        RamoCarpeta_aux.ramo,
        RamoCarpeta_aux.tipoCarpeta,
        this.variablesNivel.nivelP
      );
    } else {
      this.listaVariables(
        RamoCarpeta_aux.ramo,
        RamoCarpeta_aux.tipoCarpeta,
        this.variablesNivel.nivel
      );
    }
    console.log(nomenV);
    console.log('mostrar contendio');
    console.log(nombre);
    //aux_archivos=this.listarArchivos(aux_nombres);
    console.log(aux_archivos);
    console.log(this.variablesNivel);
    const dialogRef = this.dialog.open(DialogContenidoCarpeta, {
      width: '50%',
      height: '50%',
      data: {
        nombre: aux_nombres[long - 1],
        archivos: this.variablesNivel.lista,
        nomenclatura: nomenV,
        nivelCarpeta: this.variablesNivel.nivel,
        nivelPadre: this.variablesNivel.nivelP
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      //  console.log('The dialog was closed');
      // nomenV=nomenbackup;
      // this.carpetaSelec = result;
      // console.log(result);
      // this.agregarArch(nombre,this.carpetaSelec)
    });
    //this.cargaCarpetas();
  }

  listarArchivos(nombres: string[], nivel?: string): ListaVarNivel {
    console.log(nombres, nivel);
    let resultado: ListaVarNivel;
    if (nombres.length === 1) {
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0] && nivel === 'N/A') {
          //return carpeta.filelist;
          return (resultado = { //NOSONAR
            lista: carpeta.filelist,
            nivel: carpeta.nivel,
            nivelP: 'N/A'
          });
        }
      }
    }
    if (nombres.length === 2) {
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          for (let sub of carpeta.subcarpeta) {
            console.log('nivel 2', sub.nombre, sub.nivel);
            if (sub.nombre === nombres[1] && sub.nivel === nivel) {
              if (sub.nivel === '4') {
                return (resultado = {
                  lista: sub.filelist,
                  nivel: sub.nivel,
                  nivelP: carpeta.nivel
                });
              } else {
                return (resultado = {
                  lista: sub.filelist,
                  nivel: sub.nivel,
                  nivelP: carpeta.nivel
                }); //NOSONAR
              }
            }
          }
        }
      }
    }
    if (nombres.length === 3) {
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          for (let sub of carpeta.subcarpeta) {
            if (sub.nombre === nombres[1]) {
              for (let sub2 of sub.subcarpeta) {
                console.log('nivel 3', sub2.nombre, sub2.nivel);
                if (sub2.nombre === nombres[2] && sub2.nivel === nivel) {
                  //return sub2.filelist;
                  if (sub2.nivel === '4') {
                    return (resultado = {
                      lista: sub2.filelist,
                      nivel: sub2.nivel,
                      nivelP: sub.nivel
                    });
                  } else {
                    return (resultado = {
                      lista: sub2.filelist,
                      nivel: sub2.nivel,
                      nivelP: sub.nivel
                    });
                  } //NOSONAR
                }
              }
            }
          }
        }
      }
    }
    if (nombres.length === 4) {
      for (let carpeta of carpetas) {
        if (carpeta.nombre === nombres[0]) {
          for (let sub of carpeta.subcarpeta) {
            if (sub.nombre === nombres[1]) {
              for (let sub2 of sub.subcarpeta) {
                if (sub2.nombre === nombres[2]) {
                  for (let sub3 of sub2.subcarpeta) {
                    if (sub3.nombre === nombres[3] && sub3.nivel === nivel) {
                      //return sub3.filelist
                      if (sub3.nivel === '4') {
                        return (resultado = {
                          lista: sub3.filelist,
                          nivel: sub3.nivel,
                          nivelP: sub2.nivel
                        });
                      } else {
                        return (resultado = {
                          lista: sub3.filelist,
                          nivel: sub3.nivel,
                          nivelP: sub2.nivel
                        });
                      } //NOSONAR
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  // actualiza el renombre del archivo
  cargarNomenclatura(): string {
    let pos = 0;
    let aux_nom = '';
    for (let vari of nomenV) {
      pos = nomenV.indexOf(vari);
      if (pos === 0) {
        aux_nom = nomenV[pos].name;
      } else {
        aux_nom = aux_nom + '_' + vari.name;
      }
    }
    return aux_nom;
  }
  obtenerDatosEntrega() {
    if (this.entregaFisicaForm.value.calle === '') {
      this.entregaFisicaForm.value.calle = this.datosEnvio.calle;
    }
    if (this.entregaFisicaForm.value.numero === '') {
      this.entregaFisicaForm.value.numero = this.datosEnvio.numero;
    }
    if (this.entregaFisicaForm.value.colonia === '') {
      this.entregaFisicaForm.value.colonia = this.datosEnvio.colonia;
    }
    if (this.entregaFisicaForm.value.delegacion === '') {
      this.entregaFisicaForm.value.delegacion = this.datosEnvio.delegacion;
    }
    if (this.entregaFisicaForm.value.ciudad === '') {
      this.entregaFisicaForm.value.ciudad = this.datosEnvio.ciudad;
    }
    if (this.entregaFisicaForm.value.codigoPostal === '') {
      this.entregaFisicaForm.value.codigoPostal = this.datosEnvio.codigo_postal;
    }
    if (this.entregaFisicaForm.value.contacto === '') {
      this.entregaFisicaForm.value.contacto = this.datosEnvio.contacto;
    }
    if (this.entregaFisicaForm.value.telefono_d === '') {
      this.entregaFisicaForm.value.telefono_d = this.datosEnvio.telefono_d;
    }
    // console.log(this.entregaFisicaForm.value)
    this.cargarDireccion();
  }

  cancelCA() {
    console.log('Hola Que hace');
    try {
      console.log('c1');
      this.formGroupDirective.resetForm();
      /*   expandPanelDGfromCA = true;
      disablePanelDGfromCA = false;
      this.resetArchivosPDF();
      const element: HTMLElement = document.getElementById('panelDatosGenerales') as HTMLElement;
      element.click();
      expandPanelDGfromCA = undefined; */
      // datos de digital
      this.files = [];
      this.filesFisico = [];
      this.nombreCarpeta.reset('');
      this.nuevaCarpeta.controls.tipoCarpeta.reset('');
      this.nuevaCarpeta.controls.nombreCarpeta.reset('');
      this.nueva = false;
      this.renombrar = false;
      this.namePdfUnif = ''; //reset al input de pdf unificado
      this.isPdfUnif = false;
      this.deshaReglas = false;
      this.dialogoCont.prototype.deshaRenombre = false;
      this.resetArchivosPDF();
      this.selectRamo = null;
      this.carpetas2 = [];
      TREE_DATA_2 = [];
      this.dataSource2.data = [];
      nomenV = [];
      estrucM = [];
      this.cambioSel = 'uno';
      this.formGroupDirective.resetForm();
      this.entregaFisicaForm.reset();
      this.ArregloCarpetas = [];
      carpetas = [];
      this.carpetasEstructura = [];
      console.log(estrucM);
    } catch {
      console.log('c2');
      // this.formGroupDirective.resetForm();
      /*  expandPanelDGfromCA = true;
      disablePanelDGfromCA = false;
      this.resetArchivosPDF();
      const element: HTMLElement = document.getElementById('panelDatosGenerales') as HTMLElement;
      element.click();
      expandPanelDGfromCA = undefined; */
      // datos de digital
      this.files = [];
      this.filesFisico = [];
      this.nombreCarpeta.reset('');
      this.nuevaCarpeta.controls.tipoCarpeta.reset('');
      this.nuevaCarpeta.controls.nombreCarpeta.reset('');
      this.nueva = false;
      this.renombrar = false;
      this.namePdfUnif = ''; //reset al input de pdf unificado
      this.isPdfUnif = false;
      this.deshaReglas = false;
      this.dialogoCont.prototype.deshaRenombre = false;
      this.resetArchivosPDF();
      this.selectRamo = null;
      this.carpetas2 = [];
      TREE_DATA_2 = [];
      this.dataSource2.data = [];
      nomenV = [];
      estrucM = [];
      this.cambioSel = 'uno';
      this.formGroupDirective.resetForm();
      this.ArregloCarpetas = [];
      carpetas = [];
      this.carpetasEstructura = [];
    }
  }

  continuarCA() {
    if (armadoJson.fisico === true) {
      // se valida si el tipo de impresion es fisica, para obtener sus datos
      this.onSubmit();
      console.log(datosfisico);
      console.log(armadoJson);
      if (this.passDatosArm === true && this.passDatosEnv === true) {
        this.expandPanelConfArmLoc = false;
        expandPanelDatosNotif = true;
        const elementDN: HTMLElement = document.getElementById(
          'panelDatosNotif'
        ) as HTMLElement;
        elementDN.click();
      }
    } else if (armadoJson.digital === true) {
      let num = 0;
      num = estrucM.length;
      console.log(num);
      console.log(estrucM);
      //this.cargaCarpetas();
      estrucM[num] = { estructuraCar, id: this.selectRamo };
      console.log(estrucM);
      this.expandPanelConfArmLoc = false;
      expandPanelDatosNotif = true;
      //const elementDN: HTMLElement = document.getElementById('panelDatosNotif') as HTMLElement;
      //elementDN.click();
    }
  }

  configIni() {
    if (expandPanelCAfromDN === undefined) {
      this.expandPanelConfArmLoc = true;
    } else {
      this.expandPanelConfArmLoc = false;
      const elementDN: HTMLElement = document.getElementById(
        'panelDatosNotif'
      ) as HTMLElement;
      elementDN.click();
    }
    this.disablePanelConfArmLoc = false;
    expandPanelDatosNotif = false;

    this.panelAF = Paneles.panelF;
    this.panelAD = Paneles.panelD;
    this.opcRamoSelec = opcRamoSelec;
    if (
      (this.panelAD === undefined || this.panelAD === false) &&
      (this.panelAF === undefined || this.panelAF === false)
    ) {
      this.notiArmado = true;
      this.TxtNtf27 = this.TxtNot1;
    } else {
      this.notiArmado = false;
      // if(opcVars.criterio===undefined||opcVars.criterio===''||opcVars.criterio===null){
      if (ListaJSON.length === 0 || ListaJSON === []) {
        //digM
        this.notiArmado = true;
        //this.TxtNtf27 = this.TxtNtf28
        this.TxtNtf27 = this.TxtNtf29;
        setTimeout(() => {
          this.notiArmado = false;
        }, this.TimeNoti);
      } else {
        if (this.edoConfArm === false) {
          // condicion para ejecutar la funcion de notiTipoArmado, en caso de que se de clic en el panel
          //this.notiTipoArmado();
          this.comboDigMulti = ListaJSON;
          this.edoConfArm = true;
        }
      }
      /* if(this.edoConfArm===false){// condicion para ejecutar la funcion de notiTipoArmado, en caso de que se de clic en el panel
        this.notiTipoArmado();
        this.edoConfArm=true;
      } */
    }
  }
  obtTipoArchivo(nombre: string, origen?: string): string {
    let pos = 0;
    if (origen !== 'dnd') {
      for (let carpArc of TREE_DATA_2) {
        pos = TREE_DATA_2.indexOf(carpArc);
        for (let archivo of carpArc.children) {
          if (archivo.name === nombre) {
            return archivo.tipo2;
          }
        }
      }
    } else {
      return 'OTROS';
    }
  }
  resetArchivosPDF() {
    // funcion que restablece los estatus de NA a los archivos cuando se clic en pdfunificado
    let pos = 0;
    let pos2 = 0;
    for (let archivos of TREE_DATA_2) {
      pos = TREE_DATA_2.indexOf(archivos);
      for (let archivos_h of archivos.children) {
        pos2 = archivos.children.indexOf(archivos_h);
        TREE_DATA_2[pos].children[pos2].estatus = 'NA';
      }
    }
    console.log(TREE_DATA_2);
  }
  obtTipoArchivo_vBD(nombre: string, origen?: string): string {
    let pos = 0;
    if (origen !== 'dnd') {
      for (let carpArc of TREE_DATA_2) {
        if (carpArc.name === nombre) {
          return carpArc.tipo2;
        }
      }
    } else {
      return 'OTROS';
    }
  }
  empateImpresion() {
    let empate = null;
    let listArchivo = [];
    let archivo: listaDocAne;
    let cont = 0;
    if (
      this.armadoFisicoForm.value.checkEmpate === undefined ||
      this.armadoFisicoForm.value.checkEmpate === false
    ) {
      empate = false;
      listArchivo = [];
    } else if (this.armadoFisicoForm.value.checkEmpate === true) {
      empate = true;
      if (this.filesFisico.length !== 0) {
        for (let file of this.filesFisico) {
          archivo = {
            tipoArchivo: 'OTROS',
            renombre: file.name,
            ruta: '/Presolicitud/QA/' + file.name
          };
          listArchivo[cont] = archivo;
          cont++;
        }
      }
    }
  }
  selectRamo: any;
  selectRamo_aux: any;
  tipoArmadoRamo() {
    console.log(this.selectRamo);
    /* if(this.cambioSel!=='uno'){
      console.log('1.1');
      let num=0;
      num=estrucM.length;
      let num2 = 0;
      num2=this.ArregloCarpetas.length;
      this.ArregloCarpetas[num2]=carpetas;
      this.cargaCarpetas();
      estrucM[num]={estructuraCar,id:this.selectRamo_aux};
      console.log(estrucM)
    }else if(this.cambioSel==='uno'){
      console.log('1.2');
      this.cambioSel='dos';
    } */
    if (this.ArregloCarpetas.length === 0) {
      console.log('1.3');
      this.notiTipoArmado(this.selectRamo);
      this.ArregloCarpetas[0] = carpetas;
      this.selectRamo_aux = this.selectRamo;
    } else {
      console.log('1.4');
      let ifCarpeta = false;
      for (let carpetas of this.ArregloCarpetas) {
        if (carpetas[0].id === this.selectRamo) {
          console.log('1.5');
          ifCarpeta = true;
          break;
        } else {
          console.log('1.6');
          ifCarpeta = false;
        }
      }
      if (ifCarpeta === true) {
        console.log('1.7');
        for (let armado of this.ArregloCarpetas) {
          let ramo = '';
          if (armado[0].id === this.selectRamo) {
            for (let listP of ListaJSON) {
              if (listP.id === this.selectRamo) {
                ramo = listP.ramo;
              }
            }

            console.log(ramo);

            this.mostrarMsg = false;
            this.files = [];
            console.log('1.8');
            console.log(armado[0].id);
            console.log(armado);
            let pos = this.ArregloCarpetas.indexOf(armado);
            console.log(pos);
            console.log(this.ArregloCarpetas[pos]);
            console.log(this.carpetas2);
            console.log(carpetas);
            //this.carpetas2=this.ArregloCarpetas[pos];
            //this.listaDocumentos(armado[0].ramo,armado[0].tipo);
            if (armado[0].tipo === 'Pool') {
              //this.tiposCarpetas = [{name: 'Póliza'}, {name: 'Certificado'}, {name: 'Anexos'}];
              this.tiposCarpetas = carpetasAzul;
            } else if (armado[0].tipo === 'Contrato') {
              //this.tiposCarpetas = [{name: 'Póliza Padre'}, {name: 'Póliza Hija'}, {name: 'Adjuntos'}];
              this.tiposCarpetas = carpetasInfo;
            }
            carpetas = this.ArregloCarpetas[pos];
            this.carpetas2 = carpetas;
            RamoCarpeta_aux = {
              ramo: ramo,
              tipoCarpeta: armado[0].tipo
            };
            this.listaDocumentos(ramo, armado[0].tipo);
            console.log(this.carpetas2);
          }
        }
      } else {
        console.log('1.9');
        this.notiTipoArmado(this.selectRamo);
        let num = this.ArregloCarpetas.length;
        this.ArregloCarpetas[num] = carpetas;
        this.selectRamo_aux = this.selectRamo;
        this.mostrarMsg = false;
        this.files = [];
      }
    }
    //this.notiTipoArmado(this.selectRamo);
    //this.selectRamo_aux=this.selectRamo;
    console.log(estrucM);
    console.log(this.ArregloCarpetas);
  }
  resetestrucM() {
    estrucM = [];
  }
  varsPorNivel(vars: any[], nivel: string) {
    //funcion que obtiene las variables por carpeta
    switch (nivel) {
      case '1':
        nomenV = vars.filter((vars) => vars.nivel == 'C' || vars.nivel == 'PL');
        break;
      case '2':
        nomenV = vars.filter(
          (vars) => vars.nivel == 'PP' || vars.nivel == 'PO'
        );
        break;
      case '3':
        nomenV = vars.filter((vars) => vars.nivel == 'PH');
        break;
    }
  }
  ordenaDocAutos() {
    // lista de comentos para autos-info
    AUTOSINFO = [
      { name: 'Carátulas', children: [] },
      { name: 'Solicitudes', children: [] },
      { name: 'Avisos', children: [] },
      { name: 'Anexos', children: [] },
      { name: 'Notas', children: [] },
      { name: 'Folletos de línea', children: [] }
    ];

    for (let docs of AUTOSINFOCarp) {
      let pos = AUTOSINFOCarp.indexOf(docs);
      let tam = 0;
      switch (docs.tipo2) {
        case 'CPP':
          tam = AUTOSINFO[0].children.length;
          AUTOSINFO[0].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'CPH':
          tam = AUTOSINFO[0].children.length;
          AUTOSINFO[0].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'SCP':
          tam = AUTOSINFO[1].children.length;
          AUTOSINFO[1].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'SCH':
          tam = AUTOSINFO[1].children.length;
          AUTOSINFO[1].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'AVGP':
          tam = AUTOSINFO[2].children.length;
          AUTOSINFO[2].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'ACH':
          tam = AUTOSINFO[2].children.length;
          AUTOSINFO[2].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'RCP':
          tam = AUTOSINFO[3].children.length;
          AUTOSINFO[3].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'RC':
          tam = AUTOSINFO[3].children.length;
          AUTOSINFO[3].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'OTRO':
          tam = AUTOSINFO[3].children.length;
          AUTOSINFO[3].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'NCH':
          tam = AUTOSINFO[4].children.length;
          AUTOSINFO[4].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
        case 'FAP':
          tam = AUTOSINFO[5].children.length;
          AUTOSINFO[5].children[tam] = {
            name: AUTOSINFOCarp[pos].name,
            tipo: AUTOSINFOCarp[pos].tipo,
            tipo2: AUTOSINFOCarp[pos].tipo2
          };
          break;
      }
    }
    console.log(AUTOSINFO);
  }
  ordenaDocVida() {
    VIDAINFO = [
      { name: 'Carátulas', children: [] },
      { name: 'Registros', children: [] },
      { name: 'Detalles de coberturas', children: [] },
      { name: 'Solicitudes', children: [] },
      { name: 'Avisos', children: [] },
      { name: 'Cartas', children: [] },
      { name: 'Requisitos', children: [] },
      { name: 'Certificados', children: [] },
      { name: 'Notas', children: [] }
    ];
    for (let docs of VIDAINFOCarp) {
      let pos = VIDAINFOCarp.indexOf(docs);
      let tam = 0;
      switch (docs.tipo2) {
        case 'CCGP':
          tam = VIDAINFO[0].children.length;
          VIDAINFO[0].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'RA':
          tam = VIDAINFO[1].children.length;
          VIDAINFO[1].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'DC':
          tam = VIDAINFO[2].children.length;
          VIDAINFO[2].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'SCP':
          tam = VIDAINFO[3].children.length;
          VIDAINFO[3].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'APP':
          tam = VIDAINFO[4].children.length;
          VIDAINFO[4].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'ACP':
          tam = VIDAINFO[4].children.length;
          VIDAINFO[4].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'AP':
          tam = VIDAINFO[4].children.length;
          VIDAINFO[4].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'CR':
          tam = VIDAINFO[5].children.length;
          VIDAINFO[5].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'RM':
          tam = VIDAINFO[6].children.length;
          VIDAINFO[6].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'CERTP':
          tam = VIDAINFO[7].children.length;
          VIDAINFO[7].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
        case 'NCP':
          tam = VIDAINFO[8].children.length;
          VIDAINFO[8].children[tam] = {
            name: VIDAINFOCarp[pos].name,
            tipo: VIDAINFOCarp[pos].tipo,
            tipo2: VIDAINFOCarp[pos].tipo2
          };
          break;
      }
    }
  }
  ordenaDocGMM() {
    // lista de comentos para GMM-info
    GMMINFO = [
      { name: 'Carátulas', children: [] },
      { name: 'Condiciones', children: [] },
      { name: 'Avisos', children: [] },
      { name: 'Registros', children: [] },
      { name: 'Folletos', children: [] },
      { name: 'Desgloses', children: [] },
      { name: 'Tarjetas', children: [] },
      { name: 'Certificados', children: [] }
    ];
    for (let docs of GMMINFOCarp) {
      let pos = GMMINFOCarp.indexOf(docs);
      let tam = 0;
      switch (docs.tipo2) {
        case 'CPP':
          if (docs.name === 'CONDICIONES PARTICULARES PADRE') {
            tam = GMMINFO[1].children.length;
            GMMINFO[1].children[tam] = {
              name: GMMINFOCarp[pos].name,
              tipo: GMMINFOCarp[pos].tipo,
              tipo2: GMMINFOCarp[pos].tipo2
            };
          } else {
            tam = GMMINFO[0].children.length;
            GMMINFO[0].children[tam] = {
              name: GMMINFOCarp[pos].name,
              tipo: GMMINFOCarp[pos].tipo,
              tipo2: GMMINFOCarp[pos].tipo2
            };
          }
          break;
        case 'CGP':
          tam = GMMINFO[1].children.length;
          GMMINFO[1].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'CPH':
          tam = GMMINFO[1].children.length;
          GMMINFO[1].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'ACP':
          tam = GMMINFO[2].children.length;
          GMMINFO[2].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'RA':
          tam = GMMINFO[3].children.length;
          GMMINFO[3].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'RAP':
          tam = GMMINFO[3].children.length;
          GMMINFO[3].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'REBP':
          tam = GMMINFO[3].children.length;
          GMMINFO[3].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'FGMM':
          tam = GMMINFO[4].children.length;
          GMMINFO[4].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'DP':
          tam = GMMINFO[5].children.length;
          GMMINFO[5].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'TD':
          tam = GMMINFO[6].children.length;
          GMMINFO[6].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
        case 'CERT':
          tam = GMMINFO[7].children.length;
          GMMINFO[7].children[tam] = {
            name: GMMINFOCarp[pos].name,
            tipo: GMMINFOCarp[pos].tipo,
            tipo2: GMMINFOCarp[pos].tipo2
          };
          break;
      }
    }
  }
  ordenaDocGMMAzul() {
    // lista de comentos para autos-info
    GMMAZUL = [
      { name: 'Carátulas', children: [] },
      { name: 'Registros', children: [] },
      { name: 'Endosos', children: [] },
      { name: 'Solicitudes', children: [] },
      { name: 'Avisos', children: [] },
      { name: 'Preimpresos', children: [] },
      { name: 'Folletos', children: [] },
      { name: 'Certificados', children: [] },
      { name: 'Tarjetas', children: [] }
    ];
    for (let docs of GMMAZULCarp) {
      let pos = GMMAZULCarp.indexOf(docs);
      let tam = 0;
      switch (docs.tipo2) {
        case 'CAR':
          tam = GMMAZUL[0].children.length;
          GMMAZUL[0].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'RA':
          tam = GMMAZUL[1].children.length;
          GMMAZUL[1].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'EN':
          tam = GMMAZUL[2].children.length;
          GMMAZUL[2].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'SC':
          tam = GMMAZUL[3].children.length;
          GMMAZUL[3].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'AC':
          tam = GMMAZUL[4].children.length;
          GMMAZUL[4].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'AP':
          tam = GMMAZUL[4].children.length;
          GMMAZUL[4].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'CG':
          tam = GMMAZUL[5].children.length;
          GMMAZUL[5].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'FO':
          tam = GMMAZUL[6].children.length;
          GMMAZUL[6].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'CERTA':
          tam = GMMAZUL[7].children.length;
          GMMAZUL[7].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
        case 'TD':
          tam = GMMAZUL[8].children.length;
          GMMAZUL[8].children[tam] = {
            name: GMMAZULCarp[pos].name,
            tipo: GMMAZULCarp[pos].tipo,
            tipo2: GMMAZULCarp[pos].tipo2
          };
          break;
      }
    }
  }
  isCarpetaAdjAne: Boolean;
  ocualtarCrearCarpAdjuntos(tipocarpeta: string) {
    console.log('soy ocualtarCrearCarpAdjuntos ');
    console.log(tipocarpeta);
    if (tipocarpeta === 'Adjuntos' || tipocarpeta === 'Anexos') {
      this.isCarpetaAdjAne = true;
    } else {
      this.isCarpetaAdjAne = false;
    }
  }
  notiCodigo500() {
    this.openSnackBar('Error de Servidor', '');
  }
  notiCodigo400() {
    this.openSnackBar('Error de Request', '');
  }
  notiCodigo0() {
    this.openSnackBar('Error de Conexión', '');
  }
  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 9000
    });
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.html',
  styleUrls: ['./confarmado.component.scss']
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'dialog-contenido-carpeta',
  templateUrl: './dialog-contenido-carpeta.html',
  styleUrls: ['./confarmado.component.scss']
})
export class DialogContenidoCarpeta {
  @ViewChild('dialogForm') formDialog;

  Txtlbl1 = 'Contenido Carpeta';
  Txtlbl2 = 'Nombre: ';
  Txtlbl3 = 'Nomenclatura de archivo';
  Txtlbl4 = 'Ordenamiento de nombre';
  Txtlbl5 = 'Número de caracteres disponibles: ';
  Txtlbl6 = 'Renombrar';
  Txtlbl7 = 'Nomenclatura';
  Txtlbl8 = 'Cancelar';
  Txtlbl9 = 'Aceptar';
  Txtlbl10 = 'Cerrar';
  TimeNoti = 7000;
  TxtNot1 = 'Campo obligatorio';
  numCaracteres = 0;
  public confArm = ConfarmadoComponent;
  constructor(
    public dialogRef: MatDialogRef<DialogContenidoCarpeta>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: DialogData2
  ) {}
  NomenForm: FormGroup;
  controlNomen = new FormControl('', [Validators.required]);
  newNomen = new FormControl('', [Validators.required]);
  editarNombre: Boolean;
  archivoNomen: string = '';
  deshaRenombre: Boolean;
  aux_carp: carpeta[] = [];

  deleteArchivob64(nombre?: string) {}

  quitarArchivo(
    carpeta: string,
    nombreArch: string,
    tipo?: string,
    nivel?: string,
    nivelP?: string
  ) {
    let pos = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    let posArchivo = 0;
    console.log('quitar Archivo');
    console.log('parametros', carpeta, nombreArch, tipo, nivel);
    console.log('carpetas', carpetas);
    if (this.deshaRenombre === false || this.deshaRenombre === undefined) {
      console.log(carpeta);
      for (let carp of carpetas) {
        pos = carpetas.indexOf(carp);
        if (carp.nombre === carpeta && nivelP === 'N/A') {
          for (let archivo of carp.filelist) {
            if (archivo.renombre === nombreArch) {
              posArchivo = carp.filelist.indexOf(archivo);
              carpetas[pos].filelist.splice(posArchivo, 1);
              if (archivo.tipoArchivo === 'OTROS') {
                for (let OTarchivo of OtrosArchivos) {
                  if (archivo.ruta.includes(OTarchivo.nombreA)) {
                    console.log(OTarchivo);
                    let num = OtrosArchivos.indexOf(OTarchivo);
                    OtrosArchivos.splice(num, 1);
                  }
                }
              }
            }
          }
        } else {
          for (let carp1 of carp.subcarpeta) {
            pos2 = carp.subcarpeta.indexOf(carp1);
            if (
              carp1.nombre === carpeta &&
              carp1.nivel === nivel &&
              carp.nivel === nivelP
            ) {
              for (let archivo of carp1.filelist) {
                if (archivo.renombre === nombreArch) {
                  posArchivo = carp1.filelist.indexOf(archivo);
                  carpetas[pos].subcarpeta[pos2].filelist.splice(posArchivo, 1);
                  if (archivo.tipoArchivo === 'OTROS') {
                    for (let OTarchivo of OtrosArchivos) {
                      if (archivo.ruta.includes(OTarchivo.nombreA)) {
                        console.log(OTarchivo);
                        let num = OtrosArchivos.indexOf(OTarchivo);
                        OtrosArchivos.splice(num, 1);
                      }
                    }
                  }
                }
              }
            } else {
              for (let carp2 of carp1.subcarpeta) {
                pos3 = carp1.subcarpeta.indexOf(carp2);
                if (
                  carp2.nombre === carpeta &&
                  carp2.nivel === nivel &&
                  carp1.nivel === nivelP
                ) {
                  for (let archivo of carp2.filelist) {
                    if (archivo.renombre === nombreArch) {
                      posArchivo = carp2.filelist.indexOf(archivo);
                      carpetas[pos].subcarpeta[pos2].subcarpeta[
                        pos3
                      ].filelist.splice(posArchivo, 1);
                      if (archivo.tipoArchivo === 'OTROS') {
                        for (let OTarchivo of OtrosArchivos) {
                          if (archivo.ruta.includes(OTarchivo.nombreA)) {
                            console.log(OTarchivo);
                            let num = OtrosArchivos.indexOf(OTarchivo);
                            OtrosArchivos.splice(num, 1);
                          }
                        }
                      }
                    }
                  }
                } else {
                  for (let carp3 of carp2.subcarpeta) {
                    pos4 = carp2.subcarpeta.indexOf(carp3);
                    if (
                      carp3.nombre === carpeta &&
                      carp3.nivel === nivel &&
                      carp2.nivel === nivelP
                    ) {
                      for (let archivo of carp3.filelist) {
                        if (archivo.renombre === nombreArch) {
                          posArchivo = carp3.filelist.indexOf(archivo);
                          carpetas[pos].subcarpeta[pos2].subcarpeta[
                            pos3
                          ].subcarpeta[pos4].filelist.splice(posArchivo, 1);
                          if (archivo.tipoArchivo === 'OTROS') {
                            for (let OTarchivo of OtrosArchivos) {
                              if (archivo.ruta.includes(OTarchivo.nombreA)) {
                                console.log(OTarchivo);
                                let num = OtrosArchivos.indexOf(OTarchivo);
                                OtrosArchivos.splice(num, 1);
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      //this.confArm.prototype.cargaCarpetas();
    } else {
      for (let carp of carpetas) {
        pos = carpetas.indexOf(carp);
        if (carp.nombre === carpeta) {
          for (let archivo of carp.filelist) {
            if (archivo.renombre === nombreArch) {
              posArchivo = carp.filelist.indexOf(archivo);
              carpetas[pos].filelist.splice(posArchivo, 1);
              this.aux_carp = carpetas;
            }
          }
        }
      }
      console.log(carpetas);
      //carpetas=this.aux_carp;
      //this.confArm.prototype.carpetas2=carpetas;
    }
    //this.confArm.prototype.carpetas2=carpetas;
    // this.confArm.prototype.cargaCarpetas();
    console.log(OtrosArchivos);
  }

  aux_carpeta: string;
  aux_archivo: string;
  renombrarArchivo(carpeta: string, archivo: string) {
    this.aux_carpeta = carpeta;
    this.aux_archivo = archivo;
    // nomenV=nomenbackup;
    console.log('renombrar');
    /* if(nomenV!==nomenbackup){
      console.log(nomenV,nomenbackup)
      nomenV=nomenbackup;
    } */
    /*  if(JSON.stringify(nomenV)!=JSON.stringify(nomenbackup)){
      console.log("false")
      nomenV=nomenbackup;
      this.archivoNomen=this.confArm.prototype.cargarNomenclatura();
    } */

    this.editarNombre = true;
    this.archivoNomen = this.confArm.prototype.cargarNomenclatura();
    this.NomenForm.value.newNomen = this.archivoNomen;
    console.log('d', this.archivoNomen);
    this.cambioNombre();
  }
  addNomenOpc: Boolean;
  notiNomen: Boolean;
  addNomen() {
    let pos = 0;
    console.log('agregar Nomenclatura');
    if (this.controlNomen.value === '' || this.controlNomen.value === null) {
      console.log('campo vacio');
      this.notiNomen = true;
      setTimeout(() => {
        this.notiNomen = false;
      }, this.TimeNoti);
    } else {
      console.log(this.controlNomen.value);
      if (nomenV.length < 7) {
        pos = nomenV.length;
        nomenV[pos] = { name: this.controlNomen.value };
      } else {
        console.log('limite de 3 varibables personalizadas');
      }
      this.addNomenOpc = false;
      this.controlNomen.reset(null);
      this.archivoNomen = this.confArm.prototype.cargarNomenclatura();
      console.log('e', this.archivoNomen);
      this.cambioNombre();
    }
  }

  deleteNomen(nombre: string) {
    let pos = 0;
    console.log('borrar nomenclatura');
    console.log(nombre);
    for (let variable of nomenV) {
      if (variable.name === nombre) {
        pos = nomenV.indexOf(variable);
        nomenV.splice(pos, 1);
      }
    }
    this.archivoNomen = this.confArm.prototype.cargarNomenclatura();
    this.cambioNombre();
  }
  cambiarNombre() {
    console.log('validar y cambiar');

    // console.log(" detect", this.NomenForm.value.newNomen)
  }
  PP: number = 0;
  PH: number = 0;
  NC: number = 0;
  NA: number = 0;
  cambioNombre() {
    /*  let PP=0;
    let PH=0;
     let NC=0;
    let NA=0; */
    let nombre: string;
    let variables: string[];
    nombre = this.NomenForm.value.newNomen;
    console.log(nombre.split('_'));
    variables = nombre.split('_');
    console.log('1', variables);
    //console.log('3',nomen);
    console.log(' detect', this.NomenForm.value.newNomen);

    this.numCaracteres = 35;
    if (this.NomenForm.value.newNomen === 0) {
      this.numCaracteres = 35;
    } else {
      for (let nombre of variables) {
        for (let nomen_aux of nomenV) {
          if (nombre === nomen_aux.name && nombre.includes('Poliza')) {
            this.numCaracteres = this.numCaracteres - 14;
          } else if (nombre === nomen_aux.name) {
            this.numCaracteres = this.numCaracteres - 20;
          } else {
            // this.numCaracteres=this.numCaracteres+10;
          }
        }
      }
    }
    console.log(this.numCaracteres);
  }
  notiRenombre: Boolean;
  cambiarNombreArchivo() {
    let pos = 0;
    let pos2 = 0;
    let pos3 = 0;
    let pos4 = 0;
    let posArc = 0;
    let nombre = '';
    // console.log(this.NomenForm.value.newNomen)
    // nombre=this.NomenForm.value.newNomen;
    nombre = this.confArm.prototype.cargarNomenclatura();
    // if(this.NomenForm.value.newNomen===''){
    if (nombre === '') {
      console.log('cadena no valida'); // agregar notificacion
      this.notiRenombre = true;
      setTimeout(() => {
        this.notiRenombre = false;
      }, this.TimeNoti);
    } else {
      for (let sub of carpetas) {
        pos = carpetas.indexOf(sub);
        if (sub.nombre === this.aux_carpeta) {
          for (let archivo of sub.filelist) {
            if (archivo.renombre === this.aux_archivo) {
              posArc = sub.filelist.indexOf(archivo);
              carpetas[pos].filelist[posArc].renombre = nombre;
              console.log('Se realizo renombre');
            }
          }
        }
        for (let sub1 of sub.subcarpeta) {
          pos2 = sub.subcarpeta.indexOf(sub1);
          if (sub1.nombre === this.aux_carpeta) {
            for (let archivo of sub1.filelist) {
              if (archivo.renombre === this.aux_archivo) {
                posArc = sub1.filelist.indexOf(archivo);
                carpetas[pos].subcarpeta[pos2].filelist[posArc].renombre =
                  nombre;
                console.log('Se realizo renombre');
              }
            }
          }
          for (let sub2 of sub1.subcarpeta) {
            pos3 = sub1.subcarpeta.indexOf(sub2);
            if (sub2.nombre === this.aux_carpeta) {
              for (let archivo of sub2.filelist) {
                if (archivo.renombre === this.aux_archivo) {
                  posArc = sub2.filelist.indexOf(archivo);
                  carpetas[pos].subcarpeta[pos2].subcarpeta[pos3].filelist[
                    posArc
                  ].renombre = nombre;
                  console.log('Se realizo renombre');
                }
              }
            }
            for (let sub3 of sub2.subcarpeta) {
              pos4 = sub2.subcarpeta.indexOf(sub3);
              if (sub3.nombre === this.aux_carpeta) {
                for (let archivo of sub3.filelist) {
                  if (archivo.renombre === this.aux_archivo) {
                    posArc = sub3.filelist.indexOf(archivo);
                    carpetas[pos].subcarpeta[pos2].subcarpeta[pos3].subcarpeta[
                      pos4
                    ].filelist[posArc].renombre = nombre;
                    console.log('Se realizo renombre');
                  }
                }
              }
            }
          }
        }
      }
    }
    // this.confArm.prototype.cargaCarpetas();
    this.editarNombre = false;
    this.addNomenOpc = false;
    this.controlNomen.reset(null);
    this.confArm.prototype.listaVariables(
      RamoCarpeta_aux.ramo,
      RamoCarpeta_aux.tipoCarpeta,
      nivel_c
    );
    console.log(nomenV);
    this.data.nomenclatura = nomenV;
  }
  cancelAddNome() {
    this.addNomenOpc = false;
    this.controlNomen.reset(null);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnInit(): void {
    this.formDialog.resetForm();
    this.NomenForm = this.formBuilder.group({
      newNomen: ['', Validators.required]
    });
  }
}
