import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FoodNode } from '../app/confarmado/confarmado.component';
import { appSettings } from './appSettings';
import { environment } from 'src/environments/environment';
interface requestPolInfo {
  noPoliza: string;
  ramo: string;
}
interface requestConInfo {
  noContrato: string;
  ramo: string;
}
@Injectable({
  providedIn: 'root'
})
export class ServicioAzulService {
  constructor(protected http: HttpClient) {}

  consultaAzul(pool: string): Observable<any> {
    const url =
      environment.apiUrlArmado + '/crm-polizas/poliza-gmm-pool?cvePool=' + pool;
    console.log(url);
    return this.http.get(url, { observe: 'response' });
  }
  consultaAzulP(numeroPoliza: string): Observable<any> {
    const url =
      environment.apiUrlArmado +
      '/ConsultaPolizaAzul?numeroPoliza=' +
      numeroPoliza;
    console.log(url);
    return this.http.get(url, { observe: 'response' });
  }

  consultaConPolP(parametro: string, ramo: string): Observable<any> {
    const url = environment.apiUrlArmado + '/con_pol_info/consulta';
    let reqInfoPol: requestPolInfo;
    reqInfoPol = {
      noPoliza: parametro,
      ramo: ramo
    };
    console.log(url);
    return this.http.post(url, reqInfoPol, { observe: 'response' });
  }
  consultaConPolC(parametro: string, ramo: string): Observable<any> {
    const url = environment.apiUrlArmado + '/con_pol_info/consulta';
    let reqInfoCon: requestConInfo;
    reqInfoCon = {
      noContrato: parametro,
      ramo: ramo
    };
    console.log(url);
    return this.http.post(url, reqInfoCon, { observe: 'response' });
  }

  /* getOTValue(insert: OTModel): any{
    //const url = 'https://us-central1-gnp-armadodigital-qa.cloudfunctions.net/ConsultaPolizaInfo?noContrato=' + parametro + '&ramo=' + ramo;
    const url = 'http://localhost:8080/';
    console.log(url)
    //return this.http.get(url, {observe: 'response'});
    this.http.post<resultOT>(url,OTModel).subscribe(data => {
       return 'OK';
    }); 
  }*/
  parametros: HttpParams;
  getDocRamo(ramo: string, plataforma: string): Observable<any> {
    this.parametros = new HttpParams();
    this.parametros = this.parametros.append('ramo', ramo);
    this.parametros = this.parametros.append('plataforma', plataforma);
    return this.http.get<FoodNode[]>(appSettings.URL_OT18, {
      observe: 'response',
      params: this.parametros
    });
  }
}
