<!--mat-expansion-panel id="panelDatosNotif" (click)="iniDatosNotificacion()"-->
<!--mat-expansion-panel-header>
        <mat-panel-title>
            {{TxtStep3}}     
        </mat-panel-title>     
    </mat-expansion-panel-header>
    <mat-divider></mat-divider-->
<form [formGroup]="profileForm">
  <div class="form-group row">
    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl3 }}</mat-label>
      <input
        type="text"
        formControlName="nombreDestinatario"
        matInput
        placeholder="Nombre de Destinatario"
        required
      />
      <mat-error *ngIf="nombreDestinatario.hasError('required')">
        {{ TxtNtf1 }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl4 }}</mat-label>
      <input
        type="email"
        formControlName="correo"
        matInput
        placeholder="Correo electrónico"
        pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
        required
        (change)="actualizaValor()"
      />
      <!--[a-zA-Z0-9\-\.]+\.([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/-->
      <mat-error *ngIf="!correoActual"> ¡Correo no válido! </mat-error>
    </mat-form-field>
    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl5 }}</mat-label>
      <input
        type="number"
        min="0"
        oninput="validity.valid||(value='');"
        formControlName="celular"
        matInput
        placeholder="Celular"
        pattern="^[1-9]\d{9}$"
        maxlength="10"
      />
      <mat-error *ngIf="!celular.hasError('pattern')">
        Debe ingresar un celular a 10 digitos.
      </mat-error>
    </mat-form-field>
    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl6 }}</mat-label>
      <input
        type="email"
        formControlName="correoOpcional"
        matInput
        placeholder="Correo Opcional"
        pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/"
        (change)="actualizaValorOp()"
      />
      <mat-error *ngIf="!correoActualOp"> ¡Correo no válido! </mat-error>
    </mat-form-field>
  </div>
</form>
<div class="containerNoti" *ngIf="notificacion">
  <div class="containerNotiTitle center-v">
    <div class="containerIcon">
      <mat-icon class="iconCheck"> </mat-icon>
    </div>
    <div class="msgTitle">
      <p>{{ TxtNot1 }} {{ TxtNotTil }}</p>
    </div>
  </div>
  <div class="containerMsg">
    <div class="msgBody">{{ TxtNotMsg }}</div>
  </div>
</div>
<div [hidden]="!notiDatosNoti" class="notiError4">
  {{ mensajeError }}
</div>
<!--/mat-expansion-panel-->
