import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { appSettings } from '../../appSettings';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EmpateDocumentacionService {
  constructor(private http: HttpClient) {}

  uploadDocument(formData: any): Observable<any> {
    const headers = new HttpHeaders({
      apikey: environment.apiKeyEmpate
    });
    const options = { headers };
    return this.http
      .post(appSettings.URL_CARGA_DOCUMENTO, formData, options)
      .pipe(catchError((error) => throwError(error)));
  }
}
