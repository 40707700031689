import { Injectable } from '@angular/core';

export interface DatosEnvio {
  lugar_envio: string;
  calle: string;
  numero: string;
  colonia: string;
  delegacion: string;
  ciudad: string;
  codigo_postal: string;
  contacto: string;
  telefono_d: string;
}
let DatosEnvios_aux: DatosEnvio = {
  lugar_envio: '',
  calle: '',
  numero: '',
  colonia: '',
  delegacion: '',
  ciudad: '',
  codigo_postal: '',
  contacto: '',
  telefono_d: ''
};
let DatosEnvios: DatosEnvio[] = [
  {
    lugar_envio: 'A339',
    calle: 'BLVD EVERARDO MARQUEZ TORRE JOYA',
    numero: ' 100 / 5P',
    colonia: 'CUESCO',
    delegacion: 'PACHUCA',
    ciudad: 'PACHUCA',
    codigo_postal: '42090',
    contacto: 'PROGROS Y ASOCIADOS MA DE LOURDES MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A602',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 2',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'DA 602 REFORMA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A606',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'H P DESAROLLADORES SA DE CV',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A611',
    calle: 'AV PASEO DE LA REFORMA ',
    numero: '144 / PISO 3',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'EDIFICA ASESORES SA SRA CATALINA QUIJAS TREJO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A620',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'DA 620 REFORMA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A644',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 6',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'XIMICH Y ASOCIADOS CESIAH ARELI TORRES NOVELO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A645',
    calle: 'AV LEONA VICARIO',
    numero: '729A / INT 108',
    colonia: 'REAL ARCOS LA PURISIMA ',
    delegacion: 'ARBOLEDAS I METEPEC',
    ciudad: 'ARBOLEDAS I METEPEC',
    codigo_postal: '52154',
    contacto: 'ARTURO CAMPOS DELGADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A661',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'FERNANDO CASTILLO VILLANUEVA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A670',
    calle: 'MIGUEL CERVANTES SAAVEDRA',
    numero: '169  INT 202, 203',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'MARCO ANTONIO COOLEY HUGO RAMIREZ',
    telefono_d: '52030377'
  },
  {
    lugar_envio: 'A702',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 5',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ERNESTO TAGLE MARTHA ELENA MOCTEZUMA HDZ',
    telefono_d: '51406703 EXT 110 Y 198'
  },
  {
    lugar_envio: 'A704',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 5',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ADELANTA ASOCIADOS SC CLAUDIA ADRIANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A706',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'JULIO CESAR ROCHELIN SANCHEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A707',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'EDUARDO SEDANO GASCA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A709',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ROBERTO CARLOS RUBIO HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A723',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 3',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'VIDA Y PATRIMONIO CECILIA LOPEZ BATIZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A726',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'AMALINALLI  MARTINEZ GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A737',
    calle: 'MIGUEL CERVANTES SAAVEDRA',
    numero: '169 INT 202, 203',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'MARCO ANTONIO COOLEY HUGO RAMIREZ',
    telefono_d: '52030377'
  },
  {
    lugar_envio: 'A758',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'CARLOS EDUARDO HERNANDEZ BURBARTIAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A773',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 3',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'PETRIZ CONSULSTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A774',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'ESPECIALISTAS EN S PARA LA VIDA ROBERTA ROMAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A775',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 1',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ASESORIA PROFESIONAL EN SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A777',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 6',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'MOLINA CORTES Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A779',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 5',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'MARBIN BENITEZ ORTEGA MARBIN BENITEZ ORTEGA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A780',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'GRUPO ADMINISTRATIVO MEXICANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A783',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'JOSE MIGUEL MORAN COALLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A785',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 1',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ENLACE Y DESARROLLO INTEGRAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A793',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 3',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'AGENCIA DESARROLLO DE NOVELES Y PRODUCTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'A798',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / PISO 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'GERARDO GUTIERREZ SUAREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AO17',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'CTA NORTE COMERCIALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AO17 LP',
    calle: 'AV PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'CTA NORTE PERSONALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AO51',
    calle: 'MARIO PANI',
    numero: '750 / PISO 11 OF D',
    colonia: 'SANTA FE',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5109',
    contacto: 'GPO RAMOS AGTE DE SEGUROS Y DE FIANZAS SAPI',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AS31',
    calle: 'BOSQUES DE DURAZNOS',
    numero: '75 / PISO 4',
    colonia: 'BOSQUES DE LA LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'ENLACE Y DESARROLLO INT ROSA ISELA MENDOZA',
    telefono_d: '5245 2800 Y 5525 0613'
  },
  {
    lugar_envio: 'AS33',
    calle: 'AV PROVIDENCIA ESQ DIVISION DEL NTE',
    numero: '502',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'RAMIREZ IBARROLA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW01',
    calle: 'BOSQUES DE CIRUELOS',
    numero: '278 / PISO 1',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'ADRISA MARISA FIGUEROA O EDGAR TELLEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW03',
    calle: 'MONTE HERMON ESQ SIERRA GAMON ',
    numero: '125',
    colonia: 'LOMAS DE CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11000',
    contacto: 'ASEGURATE AGENTE DE SEGUROS ROJAS ARLETH',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW05',
    calle: 'MIGUEL CERVANTES SAAVEDRA',
    numero: '169 INT 202, 203',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'MA COOLEY Y ASOCIADOS GUTIERREZ RODOLFO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW06',
    calle: 'AGRICULTURA',
    numero: '21 / PISO 5',
    colonia: 'ESCANDON',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11800',
    contacto: 'FRANCISCO DOMINGUEZ GLORIA LOPEZ ',
    telefono_d: '52033918 y 52034777'
  },
  {
    lugar_envio: 'AW07',
    calle: 'AV LOMAR DEL RIO OTE, EDIF PUERTA GDE',
    numero: '113',
    colonia: 'LOMAS DEL RIO',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53800',
    contacto: 'INVESTAINVESTIGACIONES Y ESTUDIOS ACTUARIALES',
    telefono_d: '55962065'
  },
  {
    lugar_envio: 'AW08',
    calle: 'MIGUEL CERVANTES SAAVEDRA, TORRE',
    numero: '301 / PISO 17',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'HJK LAURA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW09',
    calle: 'MIGUEL CERVANTES SAAVEDRA, TORRE',
    numero: '301 / PISO 17',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'HJK LAURA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW10',
    calle: 'DESCARTES',
    numero: '54 / PISO 6',
    colonia: 'ANZURES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11590',
    contacto: 'LAFON Y ASOCIADOS AGTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW11',
    calle: 'PLANO REGULADOR',
    numero: '16 / NAVE 4',
    colonia: 'XOCOYAHUALCO',
    delegacion: 'TLALNEPANTLA DE BAZ',
    ciudad: 'TLALNEPANTLA DE BAZ',
    codigo_postal: '54080',
    contacto: 'LOZANO ADMINISTRADORES EN RIESGOS',
    telefono_d: '55729431'
  },
  {
    lugar_envio: 'AW12',
    calle: 'PARQUE BOSENCHEVE',
    numero: '22',
    colonia: 'EL PARQUE',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53390',
    contacto: 'MIGUEL LOZANO GALINDO NANCY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW13',
    calle: 'DIVISION DEL NORTE',
    numero: '338',
    colonia: 'LOMAS DE MEMETLA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5330',
    contacto: 'MENDOZA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW14',
    calle: 'VOLCAN',
    numero: '214',
    colonia: 'LOMAS DE CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11000',
    contacto: 'MERLO AGENTE DE SEGUROS',
    telefono_d: '55407260'
  },
  {
    lugar_envio: 'AW15',
    calle: 'MIGUEL ANGEL DE QUEVEDO',
    numero: '621 / PISO 2',
    colonia: 'ROMERO DE TERREROS',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4320',
    contacto: 'CARLOS MOLINA AGACIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW16',
    calle: 'SACALUM',
    numero: '311',
    colonia: 'LOMAS DE PADIERNA SUR',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14740',
    contacto: 'SOCRATES MONSALVO MARTIN HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW17',
    calle: 'BLVD A CAMACHO',
    numero: '2324 / PISO 2',
    colonia: 'SAN LUCAS TEPETLATALCO',
    delegacion: 'TLALNEPANTLA DE BAZ',
    ciudad: 'TLALNEPANTLA DE BAZ',
    codigo_postal: '54055',
    contacto: 'NORIEGA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW18',
    calle: 'CTO CIRCUNVALACION PONIENTE',
    numero: '10A  INT 7D',
    colonia: 'CIUDAD SATELITE',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53100',
    contacto: 'CARLOS PARAMO CONSULTORES',
    telefono_d: '53439898'
  },
  {
    lugar_envio: 'AW19',
    calle: 'HAVRE',
    numero: '28 INT 104',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'PATTON Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW20',
    calle: 'INSURGENTES SUR',
    numero: '1602 / PISO 4',
    colonia: 'CREDITO CONSTRUCTOR',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3940',
    contacto: 'ROSALES Y ASOCIADOS',
    telefono_d: '10009105'
  },
  {
    lugar_envio: 'AW22',
    calle: 'JOSE MARIA CASTORENA',
    numero: '324 / LOCAL 116',
    colonia: 'CUAJIMALPA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5000',
    contacto: 'ARIADNA NURIA TOURNE VELASCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW23',
    calle: 'PINO',
    numero: '94',
    colonia: 'BARRIO DE SANTA CATARINA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4010',
    contacto: 'RELAX EDUARDO HEREDIA',
    telefono_d: '56600609'
  },
  {
    lugar_envio: 'AW24',
    calle: 'AV JUAREZ NORTE',
    numero: '101 / DESP 304',
    colonia: 'CENTRO',
    delegacion: 'TOLUCA EDO DE MEXICO',
    ciudad: 'TOLUCA EDO DE MEXICO',
    codigo_postal: '50000',
    contacto: 'FCO JAVIER ZEA AVILES',
    telefono_d: '2150493'
  },
  {
    lugar_envio: 'AW25',
    calle: 'BLVD A CAMACHO',
    numero: '2324 / PISO 2',
    colonia: 'SAN LUCAS TEPETLATALCO',
    delegacion: 'TLALNEPANTLA DE BAZ',
    ciudad: 'TLALNEPANTLA DE BAZ',
    codigo_postal: '54055',
    contacto: 'NORIEGA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW30',
    calle: 'PERIFERICO SUR ARTZ PEDREGAL, TORRE 2',
    numero: '3720 / PISO 7',
    colonia: 'JARDINES DEL PEDREGAL',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1900',
    contacto: 'SEKURA GARZA Y CHAVEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW31',
    calle: 'AV LOMAS VERDES',
    numero: '464 / PISO 4',
    colonia: 'LOMAS VERDES',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53120',
    contacto: 'ESVIDA AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW32',
    calle: 'BOSQUES DE DURAZNOS',
    numero: '75 / PISO 4',
    colonia: 'BOSQUE DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'ENLACE Y DESARROLLO INT',
    telefono_d: '5245 2800 5533 3789 Y 5525 0613'
  },
  {
    lugar_envio: 'AW33',
    calle: 'AV PROVIDENCIA ESQ DIVISION DEL NTE',
    numero: '502',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'RAMIREZ IBARROLA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW34',
    calle: 'MIGUEL CERVANTES SAAVEDRA, TORRE SUR',
    numero: '301 / PISO 17',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'MK AGTE DE SEGUROS',
    telefono_d: '52559200 ext 9388'
  },
  {
    lugar_envio: 'AW35',
    calle: 'AV INSTITUTO LITERARIO',
    numero: '502 / PISO 1',
    colonia: 'CENTRO',
    delegacion: 'TOLUCA EDO DE MEXICO',
    ciudad: 'TOLUCA EDO DE MEXICO',
    codigo_postal: '50000',
    contacto: 'EDIFICA ALMA ZEA',
    telefono_d: '7222139424'
  },
  {
    lugar_envio: 'AW36',
    calle: 'BERNARDO COUTO ZONAL AZUL SATELITE',
    numero: '16',
    colonia: 'CIRCUITO DIPLOMATICOS ',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53100',
    contacto: 'ROBERTO LOZANO RUBIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW37',
    calle: 'CIRCUITO DRAMATURGOS',
    numero: '22A',
    colonia: 'SATELITE',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53100',
    contacto: 'LOZANO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW38',
    calle: 'HOMERO',
    numero: '1428 INT 1201',
    colonia: 'POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11510',
    contacto: 'BUFETE MATEMATICO ACTURIAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW39',
    calle: 'BOSQUES DE CIDROS',
    numero: '54 / DESP 603',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '5120',
    contacto: 'AAR AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW40',
    calle: 'COSCOMATE',
    numero: '195',
    colonia: 'BOSQUES DE TETLAMEYA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4730',
    contacto: 'GRUPO APRO AGTE DE SEGS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW41',
    calle: 'GUTEMBERG',
    numero: '117',
    colonia: 'ANZURES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11300',
    contacto: 'JOSE ALBERTO MORA CASTELLANOS�',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW42',
    calle: 'AV DIVISION DEL NORTE',
    numero: '2462 INT 704 A / PISO 7',
    colonia: 'PORTALES SUR',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3300',
    contacto: 'SER SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW43',
    calle: 'HOMERO',
    numero: '534 / DESP 204',
    colonia: 'POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11560',
    contacto: 'VALUACIONES ACTUARIALES DEL SUR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW44',
    calle: 'RIO LERMA',
    numero: '198/ PISO 3',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'HACHMEISTER DELGADO CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW45',
    calle: 'OCOTEPEC',
    numero: '81',
    colonia: 'SAN JERONIMO LIDICE ',
    delegacion: 'MAGDALENA CONTRERAS',
    ciudad: 'MAGDALENA CONTRERAS',
    codigo_postal: '10200',
    contacto: 'INTERTEC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW46',
    calle: 'AV SANTA FE',
    numero: '440 INT 101',
    colonia: 'SANTA FE',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5348',
    contacto: 'EIKOS AGENTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW47',
    calle: 'PASEO SAN PEDRO',
    numero: '102',
    colonia: 'FRACC SAN CARLOS',
    delegacion: 'METEPEC',
    ciudad: 'METEPEC',
    codigo_postal: '52159',
    contacto: 'FAUSTO ARMANDO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW48',
    calle: 'BLVD AVILA CAMACHO',
    numero: '184 / PIOS 16',
    colonia: 'REFORMA SOCIAL',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11650',
    contacto: 'MARQUARD Y ASOCIADOS AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW49',
    calle: 'CIRCUITO CIRCUNVALACION PTE',
    numero: '8',
    colonia: 'SATELITE',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'PROTECCION FAMILIAR AGTE DE SEGUROS',
    telefono_d: '53742882'
  },
  {
    lugar_envio: 'AW50',
    calle: 'VALLE BANDERAS',
    numero: '347',
    colonia: 'FRACC VALLE SAN JAVIER',
    delegacion: 'PACHUCA HIDALGO',
    ciudad: 'PACHUCA HIDALGO',
    codigo_postal: '42086',
    contacto: 'LUIS ROBERTO ROMAN ALFONZO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW51',
    calle: 'HORACIO ESQ ARISTOTELES',
    numero: '723',
    colonia: 'POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11550',
    contacto: 'ACMES AGTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW52',
    calle: 'FLORENCIA',
    numero: '31 / PISO 5',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ALTER EN RIESGOS AGTE DE SEG Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW53',
    calle: 'SENECA',
    numero: '346',
    colonia: 'POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11550',
    contacto: 'CASTILLO Y ASOCIADOS AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW54',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5325',
    colonia: 'VISTA HERMOSA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5900',
    contacto: 'COMPARA AGENTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW55',
    calle: 'BOSQUES DE DURAZNOS',
    numero: '61 / PISO 4 DESP 4B',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'GPO ACTUARIOS ASOCIADOS AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW56',
    calle: 'PASEO DE LA REFORMA',
    numero: '295/ PISO 9',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'SUREXS AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW57',
    calle: 'LATACUNGA',
    numero: '920',
    colonia: 'LINDAVISTA',
    delegacion: 'GUSTAVO A MADERO',
    ciudad: 'GUSTAVO A MADERO',
    codigo_postal: '7300',
    contacto: 'JL AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW58',
    calle: 'HEROES DEL 47',
    numero: '46 INT C',
    colonia: 'CHURUBUSCO',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4120',
    contacto: 'MC COY RETAIL BROKERS AGENTE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW59',
    calle: 'TIHUATLAN',
    numero: '41 INT 101',
    colonia: 'SAN JERONIMO ACULCO',
    delegacion: 'MAGDALENA CONTRERAS',
    ciudad: 'MAGDALENA CONTRERAS',
    codigo_postal: '10400',
    contacto: 'MAP AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW60',
    calle: 'HALLEY',
    numero: '5',
    colonia: 'ANZURES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11590',
    contacto: 'PRM AGENTE DE SEGUROS Y FIANZAS ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW61',
    calle: 'PERIFERICO SUR, ARTZ PEDREGAL TORRE 2',
    numero: '3720 / PISO 7',
    colonia: 'JARDINES DEL PEDREGAL',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1900',
    contacto: 'IRL AGENTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AW62',
    calle: 'MIGUEL CERVANTES SAAVEDRA',
    numero: '169 / PISO 2',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'MACOOLEY BROKERS AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AY59',
    calle: 'AV LEONA VICARIO',
    numero: '729A INT 108',
    colonia: 'REAL ARCOS LA PURISIMA',
    delegacion: 'ARBOLEDAS I METEPEC',
    ciudad: 'ARBOLEDAS I METEPEC',
    codigo_postal: '52154',
    contacto: 'ARTURO CAMPOS DELGADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ11',
    calle: 'BOSQUES DE CIDROS',
    numero: '54 / DES 603',
    colonia: 'BOSQUES DE LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'A A R AGENTE DE SEGUROS Y DE FIANZA',
    telefono_d: '52589480'
  },
  {
    lugar_envio: 'AZ17',
    calle: 'PROVIDENCIA',
    numero: '502',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'RAMIREZ IBARROLA Y ASOCIADOS',
    telefono_d: '55144048'
  },
  {
    lugar_envio: 'AZ21',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ROBERTO LUIS GARCIA ALCOCER',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ24',
    calle: 'CDA SANTISIMA',
    numero: '30',
    colonia: 'STA CRUZ ATOYAC',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3310',
    contacto: 'ESTRATEGAS EN PROTECCION',
    telefono_d: '50202240'
  },
  {
    lugar_envio: 'AZ26',
    calle: 'SUR 75',
    numero: '4251',
    colonia: 'ASTURIAS',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6850',
    contacto: 'ALBERTO A CARRENO BRACAMONTES',
    telefono_d: '57409000'
  },
  {
    lugar_envio: 'AZ29',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ENRIQUEZ ARJONA RICARDO AURELIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ31',
    calle: 'COSCOMATE',
    numero: '195',
    colonia: 'BOSQUES DE TETLAMAYA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4330',
    contacto: 'GPO APRO AGTE DE SEGS Y DE FIANZAS',
    telefono_d: '56666633'
  },
  {
    lugar_envio: 'AZ33',
    calle: 'INSURGENTES SUR',
    numero: '1602 / PISO 4',
    colonia: 'CREDITO CONSTRUCTOR',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3940',
    contacto: 'JESUS ROSALES MATEOS',
    telefono_d: '10009105'
  },
  {
    lugar_envio: 'AZ35',
    calle: 'GUTEMBERG',
    numero: '117',
    colonia: 'ANZURES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11300',
    contacto: 'JOSE ALBERTO MORA CASTELLANOS',
    telefono_d: '51406600'
  },
  {
    lugar_envio: 'AZ39',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'MATEO FLORES SARENANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ41',
    calle: 'HAVRE',
    numero: '28 INT 104',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'PATTON Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ43',
    calle: 'BERNARDO COUTO ZONAL AZUL SATELITE',
    numero: '16',
    colonia: 'CIRCUITO DIPLOMATICOS',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'ROBERTO LOZANO PRIETO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ44',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'SLOANE Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ46',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'SERVIPLAN GNP',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ48',
    calle: 'BLVD AVILA CAMACHO',
    numero: '2324 / PISO 2',
    colonia: 'SAN LUCAS TEPETLATALCO',
    delegacion: 'TLALNEPANTLA DE BAZ',
    ciudad: 'TLALNEPANTLA DE BAZ',
    codigo_postal: '54055',
    contacto: 'NORIEGA Y ASOCIADOS',
    telefono_d: '53442443'
  },
  {
    lugar_envio: 'AZ49',
    calle: 'DIVISION DEL NORTE',
    numero: '338',
    colonia: 'LOMAS DE MEMETLA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5330',
    contacto: 'MENDOZA Y ASOCIADOS SA DE CV',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ50',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'SALVADOR MILANES GARCIA MORENO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ52',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'LUIS FRANCISCO ORTIZ AMEZQUITA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ54',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / SOTANO',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'ST AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ55',
    calle: 'PASEO DE LA REFORMA',
    numero: '144 / PISO 2',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'RIGOBERTO PEREZ CHAVEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ56',
    calle: 'PASEO DE LA REFORMA',
    numero: '295 / PISO 9',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'SUREXS AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ57',
    calle: 'PASEO DE LA REFORMA',
    numero: '342 / PISO 26 OF 2667',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'JL AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'AZ58',
    calle: 'HEROES DEL 47',
    numero: '46 INT C',
    colonia: 'CHURUBUSCO',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4120',
    contacto: 'MC COY RETAIL BROKERS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B602',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JESUS DAVID CAMINOS TERAN',
    telefono_d: '54885700'
  },
  {
    lugar_envio: 'B604',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 19',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ADOLFO DIAZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B607',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'CARLOS FEIJOO SABORIT',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B608',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ADAN AGUSTIN FIGUEROA MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B610',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'FERNANDO CASILLAS MEZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B612',
    calle: 'DOMINGO DIEZ',
    numero: '1589 INT 103',
    colonia: 'EL EMPLEADO',
    delegacion: 'CUERNAVACA MORELOS',
    ciudad: 'CUERNAVACA MORELOS',
    codigo_postal: '62250',
    contacto: 'SANTOYO LARA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B616',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'LARITZA EUGENIA RIVERA SANCHEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B617',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JOSE CARLO LABASTIDA JIMENEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B619',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'MARCO ANTONIO RODRIGUEZ ALCALA',
    telefono_d: '54885700'
  },
  {
    lugar_envio: 'B620',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JORGE NAVARRETE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B621',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 16',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'GREGORIO OLVERA GRACIDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B622',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 19',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ADMON Y APOYOS SC LEONARDO AGUAS CASTRO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B623',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'SALVADO VILLASENOR YANNINI',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B626',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ANDONI AGUIRRE o RAMIRO CLAVEL CRESPO ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B635',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 16',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'EMMA ANGULO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B636',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ARTURO BUSTO HERREJON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B637',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JAVIER GARCIA DIAZ GASPE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B639',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 16',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'AMEPRIS SC EMMA ANGULO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B643',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JAVIER CISNEROS ALCERRECA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B651',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ARTURO SANCHEZ HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B658',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'CARLOS PULIDO MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B662',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'DA 662',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B698',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'FERNANDO CABRERA MENDOZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B701',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'CESAR ARTURO LOVERA TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B708',
    calle: 'INSURGENTES SUR',
    numero: '1486 / PISO 2',
    colonia: 'SANTA CRUZ ATOYAC',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3310',
    contacto: 'ERNESTO ARVIZU VARELA CORPORATIVO ARVAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B711',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JAVIER MENDEZ MENDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B713',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'MARIPAZ COVARRUBIAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B715',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'NUNEZ MORA ADMON DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B716',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'OSCAR HORACIO OSTOS ALVAREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B719',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ADMINISTRACION Y CONSULTORIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B722',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'LAURA P LEIVA CLAVEL Y RUIZ PROMOTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B727',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 16',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'J GABINO NAVARRO o IGNACIO LANDEROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B742',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'HECTOR VALDEZ FRANCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B750',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'MANUEL GARCIA BONILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B752',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'GRUPO ASERTI',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B765',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ASESORIA ESPECIALIZADA JMG',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B768',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'GERARDO RIVERA CASTA�EDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B677',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JOSE EDGAR GALEAZZI CAMACHO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'B797',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'RODRIGO VAZQUEZ Y SANCHEZ DE CIMA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BO23',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'CTA METRO SUR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BS11',
    calle: 'HACIENDA DE JIQUILPAN',
    numero: '3',
    colonia: 'PRADO COAPA 3ER SECC',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14350',
    contacto: 'JUAN AGUIRRE MARISCAL o REFUGIO SOTO FRAGOSO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BS38',
    calle: 'AV COLONIA DEL VALLE',
    numero: '620 INT 3',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'KOL CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW01',
    calle: 'CRACOVIA',
    numero: '72 B 113 / PISO 1',
    colonia: 'SAN ANGEL',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1000',
    contacto: 'ANCORA AGENTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW02',
    calle: 'MINERIA',
    numero: '13',
    colonia: 'ESCANDON',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11800',
    contacto: 'APIASA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW03',
    calle: 'AMATLAN',
    numero: '20',
    colonia: 'CONDESA',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6140',
    contacto: 'ALMA ROSA CASTRO GARCIA SUAREZ GAMBOA',
    telefono_d: '52111191'
  },
  {
    lugar_envio: 'BW04',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'COPSE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW06',
    calle: 'GUILLERMO GONZALEZ CAMARENA',
    numero: '1600 / PB',
    colonia: 'SANTA FE',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1210',
    contacto: 'CHAS CHAVEZ Y ASOCIADOS AGTE DE SEGS',
    telefono_d: '52583900'
  },
  {
    lugar_envio: 'BW07',
    calle: 'MIGUEL ANGEL DE QUEVEDO',
    numero: '621 INT 3A',
    colonia: 'ROMERO DE TERREROS',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4320',
    contacto: 'GRUPO DIEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW09',
    calle: 'INSURGENTES SUR',
    numero: '1605 / PISO 9',
    colonia: 'SAN JOSE INSURGENTES',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3900',
    contacto: 'CINTHYA REUNE CARRASCO GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW10',
    calle: 'CORDILLERAS',
    numero: '38',
    colonia: 'LAS AGUILAS',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1710',
    contacto: 'AGUERREBERE Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW12',
    calle: 'LAGO ZURICH',
    numero: '219 / PISO 3',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'INTERCAM',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW13',
    calle: 'VARSOVIA',
    numero: '57 / Piso 4',
    colonia: 'JUAREZ',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6600',
    contacto: 'LAURA BARCENAS RUIZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW15',
    calle: 'MIGUEL ANGEL DE QUEVEDO',
    numero: '995 / PISO 1',
    colonia: 'EL ROSEDAL',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4330',
    contacto: 'GRUPO FOSADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW16',
    calle: 'PROL CARRASCO',
    numero: '43',
    colonia: 'CANTERA PTE DE PIEDRA',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14040',
    contacto: 'CONSUELO HERIBERTO GARZA MACIAS NUNEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW17',
    calle: 'HUIZACHES',
    numero: '283',
    colonia: 'ARBOLEDAS DEL SUR',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14380',
    contacto: 'GIL AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW18',
    calle: 'JOSE MARIA RICO',
    numero: '212 / DESP 705',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'HURTADO Y ASOCIADOS',
    telefono_d: '47511865 Y 66'
  },
  {
    lugar_envio: 'BW19',
    calle: 'GONZALEZ DE COSSIO',
    numero: '306',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'KATAOKA Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW20',
    calle: 'CERRADA DE VALLE ESCONDIDO',
    numero: '12',
    colonia: 'CALACOAYA',
    delegacion: 'ATIZAPAN DE ZARAGOZA',
    ciudad: 'ATIZAPAN DE ZARAGOZA',
    codigo_postal: '52996',
    contacto: 'LOZANO Y ASOCIADOS ASESORES',
    telefono_d: '53932411'
  },
  {
    lugar_envio: 'BW23',
    calle: 'MIGUEL OCARANZA',
    numero: '154 A',
    colonia: 'MERCED GOMEZ',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1600',
    contacto: 'MALDONADO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW24',
    calle: 'MANUEL CASTRO PADILLA',
    numero: '5',
    colonia: 'GUADALUPE INN',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1020',
    contacto: 'MACIAS ADMINISTRACION GUERRERO MA EUGENIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW25',
    calle: 'INSURGENTES SUR',
    numero: '1877 / PISO 10',
    colonia: 'GUADALUPE INN',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1020',
    contacto: 'PANIAGUA ASESORES EN BENEFICIOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW26',
    calle: 'RIO TIGRIS',
    numero: '91',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'DAR OPERADORES DE SERVICIOS Y CONSULTORIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW27',
    calle: 'CANAL DE MIRAMONTES',
    numero: '2142',
    colonia: 'EDUCACION',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4400',
    contacto: 'BENITEZ AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '5552174349'
  },
  {
    lugar_envio: 'BW28',
    calle: 'AV COLONIA DEL VALLE',
    numero: '620 INT 3',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'KOL CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW29',
    calle: 'CANOA',
    numero: '521 / PISO 7',
    colonia: 'TIZAPAN SAN ANGEL',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1090',
    contacto: 'MURGUIA CONSULTORES',
    telefono_d: '56160356'
  },
  {
    lugar_envio: 'BW32',
    calle: 'ROMULO O FARRIL',
    numero: '591',
    colonia: 'LAS AGUILAS',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1710',
    contacto: 'JUAN CARLOS PLIEGO ASESORIA INTEGRAL',
    telefono_d: '36925000'
  },
  {
    lugar_envio: 'BW33',
    calle: 'CALLE PINO',
    numero: '18',
    colonia: 'FLORIDA',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1030',
    contacto: 'META TRANSFERENCIA DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW35',
    calle: 'AVENIDA SAN JERONIMO',
    numero: '913',
    colonia: 'SAN JERONIMO LIDICE',
    delegacion: 'MAGDALENA CONTRERAS',
    ciudad: 'MAGDALENA CONTRERAS',
    codigo_postal: '10200',
    contacto: 'PREVEX AGENTE DE SEGUROS YO BEATRIZ GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW36',
    calle: 'VIENA',
    numero: '71 / PISO 2',
    colonia: 'EL CARMEN',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4100',
    contacto: 'CARASSA JOSE LUIS DOMIGUEZ o FRANCISCO BAEZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW37',
    calle: 'LAGO ZURICH',
    numero: '219 / PISO 3',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'INTERCAM AGTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW38',
    calle: 'PASEO ALEXANDER VON HUMBOLDT',
    numero: '43 / PISO 1',
    colonia: 'LOMAS VERDES 3RA SECC',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53120',
    contacto: 'INNOVAZIONE DULCE RIVAS OSNAYA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW39',
    calle: 'TLACOQUEMECATL',
    numero: '21  INT 501',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'ISMAEL ABAD ALEGRIA AHUMADA',
    telefono_d: '5556711438'
  },
  {
    lugar_envio: 'BW40',
    calle: 'MIGUEL CERVANTES DE SAAVEDRA',
    numero: '169 / PISO 8',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'TOWERS WATSON MEXICO',
    telefono_d: '53506000 ext 6065'
  },
  {
    lugar_envio: 'BW41',
    calle: 'AV JESUS DEL MONTE',
    numero: '398 / PISO 13',
    colonia: 'HACIENDA DE LAS PALMAS',
    delegacion: 'HUIXQUILUCAN EDO DE MEX',
    ciudad: 'HUIXQUILUCAN EDO DE MEX',
    codigo_postal: '11000',
    contacto: 'GIN FLEX BERENICE GARCIA AYALA',
    telefono_d: '3620 0183 5247 5545 EXT 159'
  },
  {
    lugar_envio: 'BW42',
    calle: 'PASEO MISION DE CONCA',
    numero: '29 INT 4',
    colonia: 'COLINAS DEL BOSQUE 1ERA SECC',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76904',
    contacto: 'HURTADO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW43',
    calle: 'CALLE MARGARITAS',
    numero: '317',
    colonia: 'FLORIDA',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1030',
    contacto: 'WASPERT',
    telefono_d: '40356068'
  },
  {
    lugar_envio: 'BW44',
    calle: 'COLINA DE LOS ACONITOS',
    numero: '43 / DESP 203',
    colonia: 'FRACC BOULEVARES',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53140',
    contacto: 'QUIET GROUP',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW45',
    calle: 'PERIFERICO SUR, ARTZ PEDREGAL TORRE 2',
    numero: '3720 / PISO 7',
    colonia: 'JARDINES DEL PEDREGAL',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1900',
    contacto: 'INTEGRA AGENTE DE SEGS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW46',
    calle: 'AVENIDA DE LA PALMA',
    numero: '8 / 8 INT 303',
    colonia: 'SAN FERDANDO LA HERRADURA',
    delegacion: 'HUIXQUILUCAN EDO DE MEX',
    ciudad: 'HUIXQUILUCAN EDO DE MEX',
    codigo_postal: '52760',
    contacto: 'FERDINANDO ABASOLO BONILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW47',
    calle: 'RIO TIBER',
    numero: '93 / PISO 2',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'FERNANDO MARIN BONILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW48',
    calle: 'BOSQUES DE DURAZNOS',
    numero: '75 / PISO 4',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'ILAN ESQUNAZI LEVY o MARTHA MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW49',
    calle: 'JOSE MARIA IBARRARAN',
    numero: '6',
    colonia: 'SAN JOSE INSURGENTES',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3900',
    contacto: 'JORGE BARBA PINGARRON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW50',
    calle: 'CAMPOS ELISEOS',
    numero: '188 INT 302',
    colonia: 'POLANCO CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11560',
    contacto: 'MAURICIO CAMINO MATTA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW51',
    calle: 'CINCINNATI',
    numero: '81 / DESP 306',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'MARIA CONCEPCION SILVA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW52',
    calle: 'THIERS',
    numero: '20',
    colonia: 'NUEVA ANZURES POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11590',
    contacto: 'IES CONSULTING DE MEXICO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW53',
    calle: 'AV NUEVO LEON',
    numero: '73 / PISO 4',
    colonia: 'CONDESA',
    delegacion: 'CUAUTHEMOC',
    ciudad: 'CUAUTHEMOC',
    codigo_postal: '6140',
    contacto: 'ISIIX INTELIGENCIA EN SEGUROS INSTITUCIONALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW54',
    calle: 'HIDALGO',
    numero: '61 INT 1 / PB',
    colonia: 'SAN JERONIMO',
    delegacion: 'MAGDALENA CONTRERAS',
    ciudad: 'MAGDALENA CONTRERAS',
    codigo_postal: '10200',
    contacto: 'SANTIAGO SUAREZ CORTES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW55',
    calle: 'AV DOMINGO DIEZ',
    numero: '1598 / LOCAL 16',
    colonia: 'DEL EMPLEADO',
    delegacion: 'CUERNAVACA MORELOS',
    ciudad: 'CUERNAVACA MORELOS',
    codigo_postal: '62250',
    contacto: 'GILBERTO FAHARA ALGABA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW56',
    calle: 'AV DOMINGO DIEZ',
    numero: '1598 / LOCAL 16',
    colonia: 'DEL EMPLEADO',
    delegacion: 'CUERNAVACA MORELOS',
    ciudad: 'CUERNAVACA MORELOS',
    codigo_postal: '62250',
    contacto: 'ALEJANDRA BAUTISTA FUENTES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW57',
    calle: 'REFORMA',
    numero: '627 / PISO 2  OFICINA 206',
    colonia: 'PASEO DE LAS LOMAS',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1330',
    contacto: 'GAPPER AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW58',
    calle: 'SIERRA NEVADA',
    numero: '255',
    colonia: 'LOMAS DE CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11000',
    contacto: 'RAYMUNDO LANDO GUTIERREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW59',
    calle: 'PATRICIO SANZ',
    numero: '442',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'DULCE MARIA VELAZQUEZ JAIME',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW60',
    calle: 'CARRETERA MEX TOL',
    numero: '2430 / PISO 2 OF BROVEC',
    colonia: 'LOMAS DE BEZARES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11910',
    contacto: 'BROVE AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW61',
    calle: 'MONTECITO, EDIF WTC',
    numero: '38 / PISO 8 OF 17',
    colonia: 'NAPOLES',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3810',
    contacto: 'FRANCISCO HERNANDEZ o OMAR CASTILLO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW62',
    calle: 'ADOLFO PRIETO',
    numero: '421 / DEPTO 1',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'MARTHA AGUIRRE Y/O JUAN CARLOS CORNEJO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW63',
    calle: 'HERIBERTO FRIAS',
    numero: '1157 / PISO 4',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'ARACELI TORRES o SOFIA JUAREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW64',
    calle: 'BARTOLOME R SALIDOS',
    numero: '126 INT 1',
    colonia: 'INDEPENDENCIA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3630',
    contacto: 'BERNARDO MIGUEL CARMONA GATICA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW65',
    calle: 'INSURGENTES SUR',
    numero: '1431 / PISO 10',
    colonia: 'INSURGENTES MIXCOAC',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3920',
    contacto: 'BASANEZ CONSULTING',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BW66',
    calle: 'DOMINGO DIEZ',
    numero: '1589 INT 103',
    colonia: 'EL EMPLEADO',
    delegacion: 'CUERNAVACA MORELOS',
    ciudad: 'CUERNAVACA MORELOS',
    codigo_postal: '62250',
    contacto: 'ASERTI CUERNAVACA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY01',
    calle: 'AV DOMINGO DIEZ',
    numero: '1589 / LOC 16',
    colonia: 'TEZONTEPEC',
    delegacion: 'CUERNAVACA MORELOS',
    ciudad: 'CUERNAVACA MORELOS',
    codigo_postal: '62250',
    contacto: 'GUADALUPE DOMINGUEZ NAVARRO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY09',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'MARCO A LOZANO TROCHEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY23',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'BLANCA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY25',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'BLANCA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY26',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'NUNEZ MORA ADMINISTRACION DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY40',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ERNESTO MORENO ROBLES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY60',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ADMINISTRACION Y CONSULTORIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BY65',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'NUNEZ MORA ADMINISTRACION DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ11',
    calle: 'INSURGENTES SUR',
    numero: '1079 /  PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: '3A MEXICO AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ14',
    calle: 'CORDILLERAS',
    numero: '38',
    colonia: 'LAS AGUILAS',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1710',
    contacto: 'AGUERREBERE Y ASOCIADOS',
    telefono_d: '56885831'
  },
  {
    lugar_envio: 'BZ18',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ASEFI CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ19',
    calle: 'CERRADA VALLE ESCONDIDO',
    numero: '12',
    colonia: 'CALACOAYA',
    delegacion: 'ATIZAPAN DE ZARAGOZA',
    ciudad: 'ATIZAPAN DE ZARAGOZA',
    codigo_postal: '52990',
    contacto: 'LOZANO Y ASOCIADOS ASESORES',
    telefono_d: '53932411'
  },
  {
    lugar_envio: 'BZ20',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ASESORES GENERALES ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ22',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ASESORIA INTERNACIONAL DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ30',
    calle: 'BOSQUES DE DURAZNOS',
    numero: '75 / PISO 4',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11700',
    contacto: 'DAR OPERADORA',
    telefono_d: '5245 2800 5533 3789 Y 5525 0613'
  },
  {
    lugar_envio: 'BZ33',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ENRIQUES Y ASOCIADOS AGTE DE SEG',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ39',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'GRUPO CORPORATIVO DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ41',
    calle: 'MIGUEL ANGEL DE QUEVEDO',
    numero: '995 / PISO 1',
    colonia: 'EL ROSEDAL',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4330',
    contacto: 'GRUPO FOSADO ASESORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ48',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'LOPEZ CABRAL Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ55',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'PI AND PI AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ58',
    calle: 'ROUSSEAU',
    numero: '14/ PISO 14',
    colonia: 'ANZURES',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11590',
    contacto: 'PROMOBROKER AGENTE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ69',
    calle: 'CORDILLERAS',
    numero: '38',
    colonia: 'LAS AGUILAS',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1710',
    contacto: 'ADRIANA BARRIOS SANCHEZ',
    telefono_d: '56885831'
  },
  {
    lugar_envio: 'BZ70',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 17',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ASEGURA RIESGOS INMOBILIARIOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ76',
    calle: 'FUENTE DE LAS PIRAMIDES',
    numero: '1 / DESP 502',
    colonia: 'LOMAS DE TECAMACHALCO',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53950',
    contacto: 'GRUPO IMPULSOR OPERATIVO CEJUDO',
    telefono_d: '56166030'
  },
  {
    lugar_envio: 'BZ78',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 18',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'HECTOR PADILLA GARIBAY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ79',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'AARCO AGENTE DE SEGUROS ',
    telefono_d: ''
  },
  {
    lugar_envio: 'BZ80',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 15',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JUAN GUTIERREZ ZAMORA FERREIRA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ83',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO13',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ARCA AGENTE DE SEGUROS Y DE FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ85',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ANGEL JUAREZ SANCHEZ DE TAGLE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ89',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 16',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'JANEM',
    telefono_d: '0'
  },
  {
    lugar_envio: 'BZ98',
    calle: 'INSURGENTES SUR',
    numero: '1079 / PISO 14',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'RAFAEL GONZALEZ DURAZO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX11',
    calle: 'PASEO ALEXANDER VON HUMBOLDT',
    numero: '43 A / PISO 2',
    colonia: 'LOMAS VERDES 3a SECC',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53125',
    contacto: 'AON ISRAEL RIOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX12',
    calle: 'SANTA FE',
    numero: '481 / PISO 19',
    colonia: 'CRUZ MANCA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5349',
    contacto: 'LOCKTON DIEGO QUIROZ',
    telefono_d: '59804300'
  },
  {
    lugar_envio: 'CX13',
    calle: 'LAGO ZURICH,  TORRE FRISCO ZURICH',
    numero: '245 / PISO 16',
    colonia: 'AMPLIACION GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11529',
    contacto: 'BUFETE ORDAS KARINA ROCHA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX14',
    calle: 'MIGUEL CERVANTES SAAVEDRA, TORRE SUR',
    numero: '301 /PISO 17',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'INTERPROTECCION',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX16',
    calle: 'AV PARQUE CHAPULTEPEC',
    numero: '56',
    colonia: 'EL PARQUE',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53398',
    contacto: 'LORANT MARTINEZ ELIZABETH CAMPOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX17',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505  / PISO, 13',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH RICARDO IVAN GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX20',
    calle: 'CASCADA',
    numero: '914 BIS',
    colonia: 'BANJIDAL',
    delegacion: 'IZTAPALAPA',
    ciudad: 'IZTAPALAPA',
    codigo_postal: '9450',
    contacto: 'SESPEC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX22',
    calle: 'VASCO DE QUIROGA, PENA BLANCA',
    numero: '2121 / PISO 1',
    colonia: 'SANTA FE',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1210',
    contacto: 'GLOBAL AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CX24',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505 / PISO 15',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH TORRE MAYOR ULISES GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ11',
    calle: 'PASEO ALEXANDER VON HUMBOLDT',
    numero: '43 A / PISO 2',
    colonia: 'LOMAS VERDES 3a SECC',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53125',
    contacto: 'AON EFREN CARRILLO MONROY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ12',
    calle: 'AV SANTA FE',
    numero: '481 / PISO 19',
    colonia: 'CRUZ MANCA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5349',
    contacto: 'LOCKTON OLIVIA CRUZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ13',
    calle: 'LAGO ZURICH',
    numero: '245 / PISO 16',
    colonia: 'AMPLIACION GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11529',
    contacto: 'BUFETE ORDAS YOLANDA DELGADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ14',
    calle: 'MIGUEL CERVANTES SAAVEDRA TORRE SUR',
    numero: '301 / Piso 17',
    colonia: 'GRANADA',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11520',
    contacto: 'INTERPROTECCION',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ16',
    calle: 'AV PARQUE CHAPULTEPEC',
    numero: '56',
    colonia: 'EL PARQUE',
    delegacion: 'NAUCALPAN',
    ciudad: 'NAUCALPAN',
    codigo_postal: '53398',
    contacto: 'LORANT BLANCA GUTIERREZ o JAZMIN MACEDO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ18 AUTOS',
    calle: 'PERIFERICO SUR',
    numero: '5452',
    colonia: 'OLIMPICA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4710',
    contacto: 'PRODISA 2 ESTELA ENCISO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ18 DANOS',
    calle: 'PERIFERICO SUR',
    numero: '5452',
    colonia: 'OLIMPICA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4710',
    contacto: 'PRODISA 4 ANA MARIA ESCORZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ18 GMI',
    calle: 'PERIFERICO SUR',
    numero: '5452',
    colonia: 'OLIMPICA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4710',
    contacto: 'PRODISA 1 KARIME PINEDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ18 LC',
    calle: 'PERIFERICO SUR',
    numero: '5452',
    colonia: 'OLIMPICA',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4710',
    contacto: 'PROTECCION DINAMICA AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ20',
    calle: 'CASCADA',
    numero: '909',
    colonia: 'BANJIDAL',
    delegacion: 'IZTAPALAPA',
    ciudad: 'IZTAPALAPA',
    codigo_postal: '9450',
    contacto: 'SESPEC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ21 AUTOS',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505 / PISO 15',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH TORRE MAYOR MARIO ALFONSO ROBLES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ21 GMI',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505 / PISO 13',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH TORRE MAYOR EVANGELINA VARGAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ21 DANOS',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505 / PISO 13',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH TORRE MAYOR SINAI TORIBIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ22',
    calle: 'VASCO DE QUIROGA, PENA BLANCA',
    numero: '2121 / PISO 1',
    colonia: 'SANTA FE',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1210',
    contacto: 'GLOBAL AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'CZ24',
    calle: 'REFORMA, TORRE MAYOR',
    numero: '505 / PISO 13',
    colonia: 'CUAUHTEMOC',
    delegacion: 'CUAUHTEMOC',
    ciudad: 'CUAUHTEMOC',
    codigo_postal: '6500',
    contacto: 'MARSH TORRE MAYOR DIANA GALLARDO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D206',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'TRAZENTA SC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D207',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'MARIA ELIA SOTO VIVE DESARROLLO PATRIMONIAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D208',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'JAIME ROBERTO KENNEDY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D209',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'JAIME ROBERTO KENNEDY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D210',
    calle: 'BLVD MUSALA, PLAZA MUSALA',
    numero: '1430 / LOCAL 5 y 6',
    colonia: 'RAQUET CLUB',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '80060',
    contacto: 'VICTOR ESTAVILLO VENEGAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D211',
    calle: 'CALLE ALDAMA',
    numero: '552 / LOCAL 3',
    colonia: 'CENTRO',
    delegacion: 'COLIMA COLIMA ',
    ciudad: 'COLIMA COLIMA ',
    codigo_postal: '28000',
    contacto: 'LAURA MARTINEZ ORTIZ ASESORIA PATRIMONIAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D212',
    calle: 'AV LAZARO CARDENAS',
    numero: '347',
    colonia: 'CENTRO',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '80129',
    contacto: 'ARELY HIZAR ZAZUETA Y ASOCIADOS CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D222',
    calle: 'AV 3ERA ANILLO PERIFERICO',
    numero: '164 / LOC 15 o 16',
    colonia: 'RDCIAL ESMERALDA NTE',
    delegacion: 'COLIMA COLIMA ',
    ciudad: 'COLIMA COLIMA ',
    codigo_postal: '28017',
    contacto: 'JOSE DE JESUS LOPEZ MENDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D225',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 11',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'DANIEL LEO RODRIGUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D226',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'LIZBETH MORGADO ROSAS AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D229',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 11',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'JOSE ALBERTO GONZALEZ RAZO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D241',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 11',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'OCTAVIO MENESES BELTRAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D242',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 11',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'GRACIELA CASILLAS CORDOBA   ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D245',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'TRAZENTA SC ABIGAIL GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D246',
    calle: 'INSURGENTES TORRE NAFIN',
    numero: '357 / PISO 2',
    colonia: 'SAN JUAN',
    delegacion: 'TEPIC NAYARIT',
    ciudad: 'TEPIC NAYARIT',
    codigo_postal: '63130',
    contacto: 'DA 246 GDL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D248',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 9',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'DESARROLLADORA AGENTES CHRISTOPHER OCHOA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D249',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'JMG ASESORES ALBERTO ARROYO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D253',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 11',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'LUIS FELIPE ROMAN VILLEGAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D276',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'PREVE ASESORIA PATRIMONIAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D292',
    calle: 'INSURGENTES PTE, EDIF NAFINSA',
    numero: '357 / PISO 2',
    colonia: 'SAN JUAN',
    delegacion: 'TEPIC NAYARIT',
    ciudad: 'TEPIC NAYARIT',
    codigo_postal: '63130',
    contacto: 'HECTOR GUTIERREZ VILLARREAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D296',
    calle: 'HERIBERTO VALDEZ PONIENTE ALTOS',
    numero: '165',
    colonia: 'CENTRO',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '81200',
    contacto: 'RESGUARDA CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D302',
    calle: 'AV TECNOLOGICO',
    numero: '100 / DESP 409',
    colonia: 'SAN ANGEL',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'GRUPO ASESORES ROBLES RAMIRO CHAVEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D303',
    calle: 'CALLE PASCUAL ORTIZ RUBIO',
    numero: '94',
    colonia: 'CHAPULTEPEC',
    delegacion: 'MORELIA MICHOACAN',
    ciudad: 'MORELIA MICHOACAN',
    codigo_postal: '58280',
    contacto: 'JOSE ALFREDO BARRON o CLAUDIA BARRON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D305',
    calle: 'PASEO DE LOS INSURGENTES',
    numero: '3356',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37150',
    contacto: 'DA 305 LEON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D308',
    calle: 'SIERRA MORENA',
    numero: '512 INT 101',
    colonia: 'FRAC BOSQUES DEL PRADO NORTE',
    delegacion: 'AGUASCALIENTES AGS',
    ciudad: 'AGUASCALIENTES AGS',
    codigo_postal: '20130',
    contacto: 'AGENCIA PREVISION PATRIMONIAL LORENA REYES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D311',
    calle: 'AV UNIVERSIDAD',
    numero: '101 / DESP 203',
    colonia: 'BOSQUES DEL PRADO',
    delegacion: 'AGUASCALIENTES AGS',
    ciudad: 'AGUASCALIENTES AGS',
    codigo_postal: '20127',
    contacto: 'DA 311 LEON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D314',
    calle: 'PROLONGACION ZARAGOZA',
    numero: '279 / DEP 305',
    colonia: 'EL PRADO',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'DA 314 JOSE LUIS CARLES LUEVANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D317',
    calle: 'AV ZARAGOZA',
    numero: '279 / PISO 3 INT 308',
    colonia: 'EL PRADO',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76039',
    contacto: 'GRUPO ASESORES RAM LIZETH RAMIREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D319',
    calle: 'PASEO DE LOS INSURGENTES',
    numero: '3356 / PISO 1',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'SOLALINDE PROMOTORES GRACIELA SOLALINDE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D321',
    calle: 'PASEO DE LOS INSURGENTES. TORRE 2',
    numero: '3356 / PISO 1',
    colonia: 'CUMBRES DEL CAMPESTRE',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37128',
    contacto: 'HECTOR MARTINEZ o CONCEPCION CELIS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D325',
    calle: 'AV CAMELINAS',
    numero: '3455 / P ALTA',
    colonia: 'COL LAS AMERICA',
    delegacion: 'MORELIA MICHOACAN',
    ciudad: 'MORELIA MICHOACAN',
    codigo_postal: '58270',
    contacto: 'OSCAR CORONA LOPEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D326',
    calle: 'AV REAL DE LOMAS',
    numero: '1015 / PISO 2 SEC C',
    colonia: 'LOMAS CUARTA SECCION',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78216',
    contacto: 'PROMOTORES ADMON RIESGOS MARIA ELENA LINAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D328',
    calle: 'AV UNIVERSIDAD',
    numero: '815 INT 203',
    colonia: 'BOSQUES DEL PRADO',
    delegacion: 'AGUASCALIENTES',
    ciudad: 'AGUASCALIENTES',
    codigo_postal: '20127',
    contacto: 'HUGO HERNANDEZ SANDOVAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D335',
    calle: 'PASEO DE LOS INSURGENTES, TORRE 2',
    numero: '3356 / PISO 5',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37128',
    contacto: 'ALFREDO HERRERA CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D336',
    calle: 'PASEO DE LOS INSURGENTES',
    numero: '3356',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37358',
    contacto: 'MARIA ESTHER VILLALOBOS GOMEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D337',
    calle: 'REPUBLICA DEL SALVADOR',
    numero: '1619',
    colonia: 'EL DORDO ',
    delegacion: 'AGUASCALIENTES',
    ciudad: 'AGUASCALIENTES',
    codigo_postal: '20235',
    contacto: 'MARTHA ELENA BARRAGAN NEGRETE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D340',
    calle: 'TECNOLOGICO',
    numero: '100 / 3er PISO DESP 302',
    colonia: 'SAN ANGEL',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'GABRIEL OCHOA o LETICIA AGUILAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D341',
    calle: 'PASEO DE LOS INSURGENTES, TORRE 2',
    numero: '3356 INT C / PISO 1',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'MULTISERVICIOS ZAVALA ANDRADE SC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D342',
    calle: 'AV ZARAGOZA EDIF 5 ESTRELLAS',
    numero: '279 INT 305',
    colonia: 'EL PRADO',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76090',
    contacto: 'CID DE LEON ASESORES ERENDIRA DORADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D343',
    calle: 'AV REVOLUCION',
    numero: '300',
    colonia: 'REVOLUCION',
    delegacion: 'PACHUCA HIDALGO',
    ciudad: 'PACHUCA HIDALGO',
    codigo_postal: '42060',
    contacto: 'MARIA YUNUEN FERNANDEZ LOZANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D345',
    calle: 'AV TECNOLOGICO 100 DESPACHO',
    numero: '100 / DESPACHO 312',
    colonia: 'SAN ANGEL',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'MIGUEL A MARTINEZ MENDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D344',
    calle: 'REAL DE LOMAS',
    numero: 'SN ',
    colonia: 'LOMAS IV',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78216',
    contacto: 'JOSE LUIS BARCENA MELGAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D347',
    calle: 'AV CAMELIAS',
    numero: '3455 / P ALTA INT 1',
    colonia: 'FRACC LAS AMERICAS',
    delegacion: 'MORELIA MICHOACAN',
    ciudad: 'MORELIA MICHOACAN',
    codigo_postal: '58270',
    contacto: 'ELSA HERNANDEZ KNAPP',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D348',
    calle: 'PASEO DE LA REPUBLICA',
    numero: '13020 / PISO 3 DESP 306',
    colonia: 'JURIQUILLA',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76230�',
    contacto: 'MARCO ANTONIO URIBE OSORIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D387',
    calle: 'PASEO DE LOS INSURGENTES TORRE 2',
    numero: '3356 INT B / PISO 1',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'AGAPE PATRIMONIAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D388',
    calle: 'PASEO DE LOS INSURGENTES TORRE 2',
    numero: '3356 INT A / PISO 3',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'VIVE DESARROLLO PATRIMONIAL PERLA DE LOS COBOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'D389',
    calle: 'AV REAL DE LOMAS',
    numero: '1015 / PISO 2',
    colonia: 'LOMAS 4TA SECC',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78216',
    contacto: 'JORGE OMAR CAPIN COTA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO06 LC',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'MARIA DEL SOCORRO GARCIA ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO06 LP',
    calle: 'BLVD PUERTA DE HIERRO',
    numero: '5153 / PISO 12',
    colonia: 'PLAZA ANDARES',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45116',
    contacto: 'MONICA LOZANO RAMIREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO09',
    calle: 'AV LAZARO CARDENAS',
    numero: '347',
    colonia: 'PALACIO DE GOB',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '80129',
    contacto: 'CTA OF CULIACAN ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO10 AUTOS',
    calle: 'PASEO DE LOS INSURGENTES, TORRE 1',
    numero: '3356 / PISO Y',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'ADRIANA SALAZAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO10 LC',
    calle: 'PASEO DE LOS INSURGENTES, TORRE 1',
    numero: '3356 / PISO Y',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'JOSE LUIS CAMARGO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO10 LP',
    calle: 'PASEO DE LOS INSURGENTES, TORRE 1',
    numero: '3356 / PISO Y',
    colonia: 'SAN JOSE DE LAS PILETAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '37300',
    contacto: 'MAYRA SALAZAR ONATE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO54 LC',
    calle: 'AV TECNOLOGICO',
    numero: '100 / PISO 3  DESP 306',
    colonia: 'SAN ANGEL',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'CTA OF QUERETARO SELENE MUNIZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DO54 LP',
    calle: 'AV TECNOLOGICO',
    numero: '100 / PB  DESP 18',
    colonia: 'SAN ANGEL',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76030',
    contacto: 'CTA OF QUERETARO VERONICA ARCOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DX04',
    calle: 'LUIGI PIRANDELO ENTRE DANTE ALIGIERI Y JUAN PALOMAR',
    numero: '5593',
    colonia: 'VALLARTA UNIVERSIDAD',
    delegacion: 'ZAPOPAN JALISCO',
    ciudad: 'ZAPOPAN JALISCO',
    codigo_postal: '45110',
    contacto: 'AXCALE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DX50',
    calle: 'AVENIDA REAL DE LOMAS, TORRE 1',
    numero: '1005 / PB',
    colonia: 'LOMAS 4TA SECC',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78216',
    contacto: 'AON ANTONIO TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DX51',
    calle: 'FRAY DIEGO DE LA MAGDALENA',
    numero: '675',
    colonia: 'LAS AGUILAS',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78260',
    contacto: 'ANDRES Y CIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DZ04',
    calle: 'LUIGI PIRANDELO ENTRE DANTE ALIGIERI Y JUAN PALOMAR',
    numero: '5593',
    colonia: 'VALLARTA UNIVERSIDAD',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45110',
    contacto: 'AXCALE AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DZ31',
    calle: 'ALBERTA',
    numero: '2288 / PISO 3 A',
    colonia: 'COLOMOS PROVIDENCIA',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '44660',
    contacto: 'VERONICA HERRERA QUINTERO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DZ50',
    calle: 'AVENIDA REAL DE LOMAS, TORRE 1',
    numero: '1005 / PB',
    colonia: 'LOMAS 4TA SECC',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78216',
    contacto: 'AON ANTONIO TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'DZ51',
    calle: 'FRAY DIEGO DE LA MAGDALENA',
    numero: '675',
    colonia: 'LAS AGUILAS',
    delegacion: 'SAN LUIS POTOSI SLP',
    ciudad: 'SAN LUIS POTOSI SLP',
    codigo_postal: '78260',
    contacto: 'ANDRES Y CIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E101',
    calle: 'RICARDO MARGAIN FRACC STA ENGRACIA ',
    numero: '555 A / PISO 5',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'RAFAEL MANZO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E104',
    calle: 'CALLE ROBLE',
    numero: '2015',
    colonia: 'VALLE ALTO',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64989',
    contacto: 'ENRIQUE LIVAS DE LOS SANTOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E106',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851 / PISO 1 OF 1',
    colonia: 'GRANJAS SAN ISIDRO',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'AGCIA CONECTA AGENTE GUSTAVO SALUM CASTILLO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E108',
    calle: 'AV RIO FUERTE',
    numero: '747',
    colonia: 'LA ESTRELLA',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27010',
    contacto: 'DA 108 ALFONSO AGUIRRE GOMEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E111',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851 / PISO 1 OF 2',
    colonia: 'GRANJAS SAN ISIDRO ',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'ASESORES PROFESIONALES ROBERTO CHAVEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E112',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851 / PISO 1 OF 3',
    colonia: 'GRANJAS SAN ISIDRO ',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'PLANEA TU RETIRO SCHRAIDT FERNANDO MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E114',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'AIDA GARZA QUINTANILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E121',
    calle: 'PASEO COLON',
    numero: '3554',
    colonia: 'JARDIN SEGUNDA SECCION',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '88260',
    contacto: 'LEOPOLDO GARZA GARZA',
    telefono_d: '8677145225'
  },
  {
    lugar_envio: 'E123',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'EDGAR OSVALDO ECHARTEA TREVINO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E126',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'DA 126 GARZA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E129',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'RAFAEL MANTECON Y ASOCIADOS MARTHA L ANGULO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E130',
    calle: 'JOSE MARIA MORELO',
    numero: '926',
    colonia: 'RODRIGUEZ',
    delegacion: 'REYNOSA TAMAULIPAS ',
    ciudad: 'REYNOSA TAMAULIPAS ',
    codigo_postal: '88630',
    contacto: 'HECTOR JAVIER MARTINEZ ESCOBAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E131',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'CONS PAT Y DE RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E132',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'GARZA ORTIZ Y ASOCIADOS RAUL GARZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E133',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'FRANCISCO QUIROZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E135',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'LUIS ALBERTO PENA TREVINO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E136',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'FRANCISCO QUIROZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E138',
    calle: 'AV ROBLE, EDIF QUIMMCO TORRE AZUL',
    numero: '701 / PISO 3 OF 190',
    colonia: 'VALLE DEL CAMPESTRE',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66265',
    contacto: 'SERVICIOS AP ASESORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E139',
    calle: 'MIER Y TERAN NORTE',
    numero: '121',
    colonia: 'ZONA CENTRO',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '87000',
    contacto: 'SALDANA ASESORES JORGE L SALDANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E144',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'DA 144 GARZA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E150',
    calle: 'BOULEVAR GALERIAS',
    numero: '20',
    colonia: 'PARQUE CENTRO',
    delegacion: 'SALTILLO COAHUILA',
    ciudad: 'SALTILLO COAHUILA',
    codigo_postal: '25279',
    contacto: 'DA 150 GARZA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E154',
    calle: 'HIDALGO  NUM ENTRE 5 Y 6',
    numero: 'SN / LOC 1',
    colonia: 'CENTRO',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '87300',
    contacto: 'PROMOTORES EN PROTECCION PEDRO GOMEZ MATAR',
    telefono_d: '0186 88 13 35 50'
  },
  {
    lugar_envio: 'E155',
    calle: 'RICARDO MARGAIN, EDIF A',
    numero: '555 / PISO 2',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'DA 155 OF GARZA GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E158',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'AMEXOR CONSULTORES SILVIA SIERRA TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E163',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'COBERTURA PATRIMONIAL JESUS CARDONA GOMEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E168',
    calle: 'BLVD JOSE MARIA MORELOS Y PAVON',
    numero: '926 / PISO 1',
    colonia: 'RODRIGUEZ',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '88630',
    contacto: 'GUADALUPE GARZA Y ASOCIADOS HECTOR MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E171',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'VALDEMAR GRAUZAS LEAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E179',
    calle: 'RICARDO MARGAIN, EDIF A',
    numero: '555 / PISO 4',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'CONSOLIDA CAPITAL MARTHA ANGULO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E189',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'RAFAEL RAMIREZ WIELLA PERALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E190',
    calle: 'RICARDO MARGAIN EDIF A',
    numero: '555 / PISO 3',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'ASESORES UNIDOS DEL NORTE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E195',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'SN PEDRO GRZA GARCIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'IDEAS PATRIMONIALES KAORI SALDANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E200',
    calle: 'AV 20 DE NOVIEMBRE',
    numero: '1911 A / PISO 2',
    colonia: 'NUEVA VIZCAYA',
    delegacion: 'DURANGO DURANGO',
    ciudad: 'DURANGO DURANGO',
    codigo_postal: '34080',
    contacto: 'JUAN MANUEL QUINTANILLA DEL CAMPO ',
    telefono_d: '6184555341 O 6184556413'
  },
  {
    lugar_envio: 'E225',
    calle: 'RAFAEL VALENZUELA',
    numero: '309',
    colonia: 'DEL MAESTRO',
    delegacion: 'DURANGO DURANGO',
    ciudad: 'DURANGO DURANGO',
    codigo_postal: '34240',
    contacto: 'PEDRO BALLESTEROS GUTIERREZ',
    telefono_d: '188361721'
  },
  {
    lugar_envio: 'E332',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 B',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'CANDIDO GOMEZ BONILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E381',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'TAMPICO TAMAULIPAS',
    ciudad: 'TAMPICO TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'OVIEDO TOLENTINO MARCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E861',
    calle: 'PRIV LOS REMATES',
    numero: '140',
    colonia: 'CONTRY LA SILLA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64860',
    contacto: 'DA 861 MONTERREY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'E907',
    calle: 'AV LA CLINICA, EDIF DELTA',
    numero: '2520 INT 221',
    colonia: 'SERTOMA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64040',
    contacto: 'DA 907  MONTERREY',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EO07',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851',
    colonia: 'GRANJAS SAN ISIDRO',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'KAREN ENRIQUEZ QUEZADA ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EO43 LC',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'FRACC SANTA ENGRACIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'LUISA FERNANDA RIOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MIROSLAVA',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'FRACC SANTA ENGRACIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'MIROSLAVA TRISTAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EO12 LP',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'FRACC SANTA ENGRACIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'FRANCISCO QUIROZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EO14',
    calle: 'PASEO COLON',
    numero: '3554',
    colonia: 'JARDIN',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '88260',
    contacto: 'LEOPOLDO GARZA GARZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EX02',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'EVA CHIVACUAN o JUDITH ARREGOITIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EX05',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'HAM CERECEDO JORGE ANTONIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EX10',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'SILVIA DE LA LLATA o JUDITH ARREGOITIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EY03',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851',
    colonia: 'GRANJAS SAN ISIDRO',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'FRANCISCO CAZAREZ VILLALOBOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EY07',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851',
    colonia: 'GRANJAS SAN ISIDRO',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'JUAN ANGEL HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EY43',
    calle: 'RICARDO MARGAIN, FRACC STA ENGRACIA',
    numero: '555 C / MEZANINE',
    colonia: 'FRACC SANTA ENGRACIA',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66267',
    contacto: 'AGENTES HUERFANOS DE MTY KARLA LOZANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EY44',
    calle: 'BLVD MORELOS 926',
    numero: '926',
    colonia: 'RODRIGUEZ',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '88630',
    contacto: 'ROSALINDA FLORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EZ02',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'EVA CHIVACUAN o JUDITH ARREGOITIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EZ03',
    calle: 'BLVD INDEPENDENCIA OTE',
    numero: '851',
    colonia: 'GRANJAS SAN ISIDRO',
    delegacion: 'TORREON COAHUILA ',
    ciudad: 'TORREON COAHUILA ',
    codigo_postal: '27100',
    contacto: 'KAREN ENRIQUEZ QUEZADA ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EZ05',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'HAM CERECEDO JORGE ANTONIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EZ10',
    calle: 'AV HIDALGO ESQ RUBI',
    numero: '4702 A',
    colonia: 'CHAIREL SECC 33',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89219',
    contacto: 'SILVIA DE LA LLATA o JUDITH ARREGOITIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F110',
    calle: 'BLVD TOMAS FERNANDEZ, EDIF B',
    numero: '7930',
    colonia: 'CAMPESTRE',
    delegacion: 'CD JUAREZ CHIHUAHUA',
    ciudad: 'CD JUAREZ CHIHUAHUA',
    codigo_postal: '32460',
    contacto: 'PROMOASPA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F115',
    calle: 'BLVD TOMAS FERNANDEZ',
    numero: '7930 /OFICINAS',
    colonia: 'CAMPESTRE',
    delegacion: 'CD JUAREZ CHIHUAHUA',
    ciudad: 'CD JUAREZ CHIHUAHUA',
    codigo_postal: '32460',
    contacto: 'JFLA CONSULTORESZ 115 MARIANA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F119',
    calle: 'BLVD ORTIZ MENA',
    numero: '2800',
    colonia: 'CAMPESTRE',
    delegacion: 'CHIHUAHUA CHIHUAHUA',
    ciudad: 'CHIHUAHUA CHIHUAHUA',
    codigo_postal: '31214',
    contacto: 'DANIEL ADRIAN AVALOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F121',
    calle: 'BLVD ORTIZ MENA',
    numero: '2800 / PISO 1',
    colonia: 'LOMAS DEL SANTUARIO',
    delegacion: 'CHIHUAHUA CHIHUAHUA',
    ciudad: 'CHIHUAHUA CHIHUAHUA',
    codigo_postal: '31280',
    contacto: 'PROMOTORA DIBA ANA SILLAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F124',
    calle: 'BLVD ORTIZ MENA',
    numero: '2800 / PISO 1',
    colonia: 'LOMAS DEL SANTUARIO',
    delegacion: 'CHIHUAHUA CHIHUAHUA',
    ciudad: 'CHIHUAHUA CHIHUAHUA',
    codigo_postal: '31280',
    contacto: 'SAMUEL ABRHAM CUELLAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F162',
    calle: 'BLVD ORTIZ MENA',
    numero: '2029 / PISO 2',
    colonia: 'LAS AGUILAS ARGEO',
    delegacion: 'CHIHUAHUA CHIHUAHUA',
    ciudad: 'CHIHUAHUA CHIHUAHUA',
    codigo_postal: '31217',
    contacto: 'GRUPO AMPARAN CONSULTORES VALYA SANCHEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F201',
    calle: 'BLVD BENITO JUAREZ',
    numero: '1295 INT 2 / PISO 1',
    colonia: 'JARDINES DEL VALLE',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '21280',
    contacto: 'BUFETE PROTECCION PATRIMONIAL CARLOS RAMOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F203',
    calle: 'BLVD BENITO JUAREZ',
    numero: '1327 INT 2 / PISO 1',
    colonia: 'JARDINES DEL VALLE',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '21280',
    contacto: 'WONG ROMERO ASESORES Y CONSULT',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F214',
    calle: 'AV MIGUEL ALEMAN SUR',
    numero: '222 / PLANTA ALTA',
    colonia: 'CENTRO',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '85000',
    contacto: 'KARINA MERCADO ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F220',
    calle: 'BLVD LUIS D COLOSIO ESQ PERI PTE NEGOPLAZA',
    numero: 'SN / PISO 1',
    colonia: 'VILLA SATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'DANIA JUDITH FIGUEROA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F232',
    calle: 'CTO INTERIOR PTE Y LUIS D COLOSIO EDIF B NEGOPLAZA 2P PB',
    numero: 'SN',
    colonia: 'VILLA SATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'PROASPA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F233',
    calle: 'BLVD BENITO JUAREZ, PLAZA FIMBRES',
    numero: '1295 / LOCAL B 2',
    colonia: 'JARDINES DEL VALLE',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '21280',
    contacto: 'PROTECCION EN VIDA JORGE ALDRETE HAAS',
    telefono_d: '01686564 7346'
  },
  {
    lugar_envio: 'F235',
    calle: 'ABASOLO ENTRE NAYARIT PLAZA FLAMINGOS',
    numero: 'LOCALES 3,4 y 5',
    colonia: 'PUEBLO NUEVO',
    delegacion: 'LA PAZ BAJA CALIFORNIA SUR',
    ciudad: 'LA PAZ BAJA CALIFORNIA SUR',
    codigo_postal: '23060',
    contacto: 'ARIAS ASOCIADOS ASESORES EN PROTECCION FAM',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F238',
    calle: 'AV DIEGO RIVERA',
    numero: '1511 C',
    colonia: 'ZONA DEL RIO ',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22010',
    contacto: 'PROASE EDGAR SERRATOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F250',
    calle: 'AV RAFAEL BUELNA, EDIF POLIMEDICA',
    numero: '198 / PISO 1',
    colonia: 'HACIENDA DE LAS CRUCES',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '82126',
    contacto: 'JOSE ANTONIO MORENO QUIJADA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F254',
    calle: 'AV DIEGO RIVERA',
    numero: '792',
    colonia: 'ZONA DEL RIO',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22200',
    contacto: 'DIANA TORRES ESCOBEDO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F258',
    calle: 'REVOLUCION ESQ ANTONIO ROSALES',
    numero: 'SN',
    colonia: 'ZONA CENTRO',
    delegacion: 'LA PAZ BAJA CALIFORNIA SUR',
    ciudad: 'LA PAZ BAJA CALIFORNIA SUR',
    codigo_postal: '23000',
    contacto: 'ROBERTO JAVIER VALDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F268',
    calle: 'AV DIEGO RIVERA',
    numero: '1511 B',
    colonia: 'ZONA DEL RIO',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22698',
    contacto: 'ASESORIA GLOBAL MARLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F271',
    calle: 'BLVD BENITO JUAREZ, PLAZA FIMBRES',
    numero: '1295 INT 2 / PISO 2',
    colonia: 'JARDINES DEL VALLE',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '21280',
    contacto: 'CONSULTORES FINANCIEROS CARLOS CARBALLIDO',
    telefono_d: '686 564 66 54'
  },
  {
    lugar_envio: 'F280',
    calle: 'BLVD LUIS D COLOSIO ESQ PERI PTE NEGOPLAZA',
    numero: 'SN',
    colonia: 'VILLA SATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'LUIS ALBERTO SANDOVAL SOTO',
    telefono_d: '6624676031'
  },
  {
    lugar_envio: 'F282',
    calle: 'AV DIEGO RIVERA',
    numero: '1511 / PISO 2 A',
    colonia: 'ZONA DEL RIO',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22310',
    contacto: 'ASOCIACION DESARROLLO ESTRATEGICO PROFESIONAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F290',
    calle: 'BLVD COSTERO',
    numero: '678 INT 7',
    colonia: 'ZONA CENTRO',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22800',
    contacto: 'MIGUEL ANGEL MENDOZA GAMBOA',
    telefono_d: '664118 8079'
  },
  {
    lugar_envio: 'F296',
    calle: 'HERIBERTO VALDEZ PTE',
    numero: '165 INT 6 Y 7',
    colonia: 'CENTRO PRIMER CUADRO',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '81200',
    contacto: 'RESGUARDA CONSULTORES CONCHITA BARRAZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F298',
    calle: 'HERIBERTO VALDEZ PTE ALTOS',
    numero: '165',
    colonia: 'CENTRO PRIMER CUADRO',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '81200',
    contacto: 'EA SERVICIOS LAURA VAZQUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'F785',
    calle: 'PERIFERICO PTE Y LUIS D COLOSIO, EDIF B',
    numero: 'SN / PB',
    colonia: 'VILLA SATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'OF HERMOSILLO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FO01',
    calle: 'BLVD BENITO JUAREZ PLAZA FIMBRES',
    numero: '1295',
    colonia: 'JARDINES DEL VALLE',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '21280',
    contacto: 'CTA OF MEXICALI JANNETE RUIZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FO02',
    calle: 'PERIFERICO PTE Y LUIS D COLOSIO, EDIF B',
    numero: 'SN / PB',
    colonia: 'VILLA SATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'CTA OF HERMOSILLO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FO03',
    calle: 'MIGUEL ALEMAN SUR',
    numero: '222 / PB',
    colonia: 'CENTRO',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '85000',
    contacto: 'CTA OF OBREGON DALETMA MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FO11',
    calle: 'ANGELA PERALTA',
    numero: '4118',
    colonia: 'FRACC LOS NOGALES',
    delegacion: 'CD JUAREZ CHIHUAHUA',
    ciudad: 'CD JUAREZ CHIHUAHUA',
    codigo_postal: '32350',
    contacto: 'CTA OF JUAREZ IMELDA MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FO21',
    calle: 'AV DIEGO RIVERA',
    numero: '1511 C',
    colonia: 'ZONA DEL RIO',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22010',
    contacto: 'CTA OF TIJUANA URIEL DIAZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FY14',
    calle: 'BLVD LUIS D COLOSIO, ESQ PERI PTE NEGOPLAZA',
    numero: 'SN / PISO 1',
    colonia: 'VILLASATELITE',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83200',
    contacto: 'LUISA MARIA CELAYA BELTRAN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FY15',
    calle: 'CUAUHTEMOC Y JOSEFA ORTIZ DE DOMINGUEZ',
    numero: '10',
    colonia: 'CONSTITUCION',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '85820',
    contacto: 'DA 214 NAVOJOA GUADALUPE MENDIVIL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FY16',
    calle: 'AV CERDAN Y CALLE 9, PLAZA GUAYMAS',
    numero: '659 INT 10',
    colonia: 'GUAYMAS CENTRO',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '85400',
    contacto: 'DA 214 GUAYMAS CARMEN MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FY21',
    calle: 'HERIBERTO VALDEZ PONIENTE INT 6 Y 7',
    numero: '165 INT 6 Y 7',
    colonia: 'CENTRO PRIMER CUADRO',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '81200',
    contacto: 'RESGUARDA CONSULTORES CONCHITA BARRAZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'FY23',
    calle: 'CARR TRANSPENINSULAR KM PUNTO 5 PISO 2',
    numero: '5 / 5 PISO 2',
    colonia: 'PLAZA SAN LUCAS SUIT 88',
    delegacion: 'LA PAZ BAJA CALIFORNIA SUR',
    ciudad: 'LA PAZ BAJA CALIFORNIA SUR',
    codigo_postal: '23454',
    contacto: 'LOQ AGTE MONSERRAT MUNOZ',
    telefono_d: '6241431212'
  },
  {
    lugar_envio: 'FZ76',
    calle: 'AV VALLE ESCONDIDO',
    numero: '5500 / INT 601',
    colonia: 'COMPLEJO IND EL SAUCILLO',
    delegacion: 'CHIHUAHUA CHIHUAHUA',
    ciudad: 'CHIHUAHUA CHIHUAHUA',
    codigo_postal: '31123',
    contacto: 'ROLANDO RODRIGUEZ VILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G402',
    calle: 'CALZADA PORFIRIO DIAZ',
    numero: '197',
    colonia: 'REFORMA AMERICA NTE',
    delegacion: 'OAXACA OAXACA',
    ciudad: 'OAXACA OAXACA',
    codigo_postal: '68050',
    contacto: 'PIO V ENRIQUE ALPIZAR ESTRADA GABRIELA LARA C',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G404',
    calle: 'CALLE 20',
    numero: '96 / LOCAL 7',
    colonia: 'MEXICO NTE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'IMPULSA Y APS EMPRESARIOS�SA DE�CV',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G406',
    calle: 'PRIVADA PATRICIO TRUEBA',
    numero: '2',
    colonia: 'SAN RAFAEL',
    delegacion: 'CAMPECHE CAMPECHE',
    ciudad: 'CAMPECHE CAMPECHE',
    codigo_postal: '24090',
    contacto: 'CONSULTORES GUERRERO SELEM EDUARDO GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G408',
    calle: 'CALLE 20',
    numero: '105 B 25',
    colonia: 'MEXICO NTE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'GONGORA TRACONIS ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G409',
    calle: '6TA SUR PONIENTE',
    numero: '1248',
    colonia: 'LA LOMITA',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29060',
    contacto: 'CANCINO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G411',
    calle: 'PASEO TABASCO',
    numero: '1120',
    colonia: 'MEXICO',
    delegacion: 'VILLAHERMOSA TABASCO',
    ciudad: 'VILLAHERMOSA TABASCO',
    codigo_postal: '86040',
    contacto: 'DA 411 VILLAHERMOSA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G412',
    calle: 'PLAZA CALABRIA BLVD EUROPA',
    numero: '2213 / LOCAL 22 B',
    colonia: 'BALCONES DE XALAPA',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91194',
    contacto: 'ALAN GUSTAVO GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G414',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'IIAS SF GEORGINA GUERRERO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G415',
    calle: 'MURILLO VIDA, ALTOS 2 ',
    numero: '47',
    colonia: 'CUAUHTEMOC XALAPA',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91069',
    contacto: 'HOBART AGTES CONSULTORES ENRIQUE BACA',
    telefono_d: '2282036502'
  },
  {
    lugar_envio: 'G417',
    calle: 'CALLE 20',
    numero: '105 B',
    colonia: 'COLONIA MEXICO',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'MIGUEL JOSE GARCIA METRI',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G418',
    calle: 'PROLONGACION PASEO JARDIN',
    numero: '9',
    colonia: 'ELECTRICISTAS',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91920',
    contacto: 'LUIS ERNESTO ZUNIGA FOUCHE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G419',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS PISO 4',
    numero: '2 / PISO 4',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'BERNARDO HACES FERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G422',
    calle: 'CALLE 15 PTE NORTE  ENTRE 4a y 5a NTE',
    numero: '561 / PISO 1',
    colonia: 'BONAMPAK NORTE',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29030',
    contacto: 'RITERDAN DA 422',
    telefono_d: '19616131215'
  },
  {
    lugar_envio: 'G423',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'VAZQUEZETINA Y ASOCIADOS DULCE ROJAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G424',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS PISO 4',
    numero: '2 / PISO 4',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'JOSE EDUARDO INTERIAN ESCAMILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G427',
    calle: 'PASEO JARDIN, PTA A',
    numero: '9 / PISO 1 OF 427',
    colonia: 'ELECTRICISTAS',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91919',
    contacto: 'JDC CONSULTORES EN PROTECCION Y PREVISION',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G429',
    calle: 'AV TULUM',
    numero: 'MZ 1 LT 3 ',
    colonia: 'SUPERMANZANA 9',
    delegacion: 'CHETUMAL QUINTANA ROO',
    ciudad: 'CHETUMAL QUINTANA ROO',
    codigo_postal: '77500',
    contacto: 'ARMANDO ROMERO CONSORCIO SERV PATRIMONIALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G430',
    calle: 'CALLE 20',
    numero: '105 B',
    colonia: 'MEXICO',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'ANA LILIA MAGA�A PONCE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G432',
    calle: 'CALLE 20',
    numero: '105 B 25',
    colonia: 'MEXICO NTE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'OF MERIDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G433',
    calle: 'PASEO TABASCO',
    numero: '1120',
    colonia: 'ADOLFO LOPEZ MATEOS ',
    delegacion: 'CENTRO TABASCO',
    ciudad: 'CENTRO TABASCO',
    codigo_postal: '86040',
    contacto: 'LOZANO Y ASOCIADOS ASESORES PREOFESIONALES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G434',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'ASESORES FINANCIEROS GABRIELA OCANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G435',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS PISO 4',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'JOAQUIN JAVIER FUERTES JARQUE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G437',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'ASESORIA PATRIMONIAL PLANIFICADA VERONICA ROJAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G439',
    calle: '15 PONIENTE NTE',
    numero: '561 / PISO 1',
    colonia: 'BONAMPAK',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29080',
    contacto: 'HEV FIANCIEROS JAIME LOPEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G441',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'GZ 441 GRISELDA GONZALEZ COATL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G442',
    calle: 'CALLE 20',
    numero: '407',
    colonia: 'CAZONES',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '93230',
    contacto: 'SEDEPERSA MIGUEL PEREZ SEDANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G445',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'DA 445',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G449',
    calle: 'CALLE 20',
    numero: '105 B',
    colonia: 'MEXICO',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'ERICK LEONARDO DE LA CRUZ GUZMAN ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G450',
    calle: 'PASEO JARDIN',
    numero: '9',
    colonia: 'VIRGINIA',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '94294',
    contacto: 'DA 450 VERACRUZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G452',
    calle: 'AV TULUM, EDIF TULUM',
    numero: 'MZ 1 LT 5 / LOCAL 5',
    colonia: 'SUPER MANZANA 9',
    delegacion: 'CHETUMAL QUINTANA ROO',
    ciudad: 'CHETUMAL QUINTANA ROO',
    codigo_postal: '77500',
    contacto: 'DA 452 ANGELICA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G454',
    calle: 'CALLE 20',
    numero: '105 B',
    colonia: 'MEXICO',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'MIGUEL ANGEL MORENO CAMPOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G488',
    calle: 'AV TULUM EDIF TULUM TRADE CENTER',
    numero: 'MZ 1 LT 3 / DESP 003',
    colonia: 'SUPERMANZANA 9',
    delegacion: 'CHETUMAL QUINTANA ROO',
    ciudad: 'CHETUMAL QUINTANA ROO',
    codigo_postal: '77500',
    contacto: 'DA 488 429 CANCUN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'G905',
    calle: 'CALLE 21 38',
    numero: '151 A / PISO 2',
    colonia: 'CAMPESTRE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97120',
    contacto: 'DA 905 MERIDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO19',
    calle: 'PASEO TABASCO',
    numero: '1120',
    colonia: 'JESUS GARCIA',
    delegacion: 'VILLAHERMOSA TABASCO',
    ciudad: 'VILLAHERMOSA TABASCO',
    codigo_postal: '86040',
    contacto: 'CTA OF 19 VILLAHERMOSA PALMA RODRIGUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO26 LC',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS',
    numero: '2 / PISO 4',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'CTA OF PUEBLA MA D LOS ANGELES RAMOS ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO39 LC',
    calle: 'CALLE 20',
    numero: '105 B 25',
    colonia: 'MEXICO NTE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97128',
    contacto: 'CTA OF 39 MERIDA MARIO CABRAL PERERA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO39 LP',
    calle: 'CALLE 20',
    numero: '105 B 25',
    colonia: 'EMILIANO ZAPATA NORTE',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97129',
    contacto: 'CTA OF 39 MERIDA VICTOR MANUEL CAMARA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO41',
    calle: 'PASEO JARDIN',
    numero: '9',
    colonia: 'FRACC VIRGINIA',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91920',
    contacto: 'CTA OF 41 VERACRUZ CECILIA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GO50',
    calle: 'AV TULUM EDIF TULUM TRADE CENTER',
    numero: 'MZ 1 LT 3 / LOCAL 5',
    colonia: 'SUPERMANZANA 9',
    delegacion: 'CHETUMAL QUINTANA ROO',
    ciudad: 'CHETUMAL QUINTANA ROO',
    codigo_postal: '77500',
    contacto: 'OF 50 CANCUN ZUELMY DEL ROSARIO HERRERA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GX25',
    calle: 'CALLE NUBE',
    numero: '13',
    colonia: 'SUPERMANZANA 4',
    delegacion: 'CHETUMAL QUINTANA ROO',
    ciudad: 'CHETUMAL QUINTANA ROO',
    codigo_postal: '77500',
    contacto: '?CAROLINA GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GX50',
    calle: 'GILBERTO ACEVES, PRIVADA PARAISO',
    numero: '102',
    colonia: 'FRACC PARAISO',
    delegacion: 'XALAPA VERACRUZ',
    ciudad: 'XALAPA VERACRUZ',
    codigo_postal: '96523',
    contacto: 'CRG AGENTE DE SEGUROS LUZ CRISTINA VAZQUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GX51',
    calle: 'CALLE 55  ENTRE CALLE 50 y AV FLAMBOYANES',
    numero: '129',
    colonia: 'MIAMI',
    delegacion: 'CAMPECHE CAMPECHE',
    ciudad: 'CAMPECHE CAMPECHE',
    codigo_postal: '24115',
    contacto: 'CONSULTORES INTEGRALES ALEX HERNANDEZ',
    telefono_d: '1.9384E+14'
  },
  {
    lugar_envio: 'GX53',
    calle: 'CALLE TEPEACA',
    numero: '33 / OF 1',
    colonia: 'LA PAZ',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72160',
    contacto: 'PABLO PONCE MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY02',
    calle: 'PERIFERICO SUR PONIENTE',
    numero: '1251 INT 3',
    colonia: 'LA LOMITA',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29060',
    contacto: 'GZ 409 ARACELY HIDALGO HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY03',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA CORP ANGELOPOLIS',
    numero: '2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'DA 414 VERACRUZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY05',
    calle: 'PASEO JARDIN',
    numero: '9',
    colonia: 'FRACC VIRGINIA',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91920',
    contacto: 'BEATRIZ POOL GZ 195',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY06',
    calle: 'PERIFERICO SUR PONIENTE',
    numero: '1251',
    colonia: 'LA LOMITA',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29060',
    contacto: 'GZ 411 VILLAHERMOSA KARLA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY19',
    calle: 'PERIFERICO SUR PONIENTE',
    numero: '1251',
    colonia: 'LA LOMITA',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29060',
    contacto: 'GZ 411 VILLAHERMOSA KARLA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY20',
    calle: '15 PONIENTE NORTE',
    numero: '561 / Piso 1',
    colonia: 'BONAMPAK',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '29080',
    contacto: 'DA 439 TAPACHULA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY21',
    calle: 'CALZADA PORFIRIO DIAZ',
    numero: '197 / PLANTA BAJA',
    colonia: 'REFORMA',
    delegacion: 'TUXTLA GUTIERREZ CHIAPAS',
    ciudad: 'TUXTLA GUTIERREZ CHIAPAS',
    codigo_postal: '68050',
    contacto: 'DA 402 VICTORIA RIVERA RUIZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY22',
    calle: 'REVOLUCION NUM',
    numero: '1015',
    colonia: 'CENTRO',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '96400',
    contacto: 'JOSE DE LA PAZ HERNANDEZ VAZQUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY52',
    calle: 'AMANECER',
    numero: '115',
    colonia: 'FRACC VISTABELLA ALVARADO',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '95264',
    contacto: 'ANA CRISTINA PARDO PASTRANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GY56',
    calle: 'BLVD MUNICIPIO LIBRE',
    numero: '1983 / PISO 4',
    colonia: 'RESIDENCIAL LA CIMA',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72197',
    contacto: 'ACEVEDO COUTTOLENC Y ASOC',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GZ50',
    calle: 'GILBERTO ACEVES, PRIVADA PARAISO',
    numero: '102',
    colonia: 'FRACC PARAISO',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '96523',
    contacto: 'CRG AGTE DE SEG Y FIANZAS',
    telefono_d: '9212122545'
  },
  {
    lugar_envio: 'GZ52',
    calle: 'AMANECER',
    numero: '115',
    colonia: 'FRACC VISTABELLA ALVARADO',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '95264',
    contacto: 'ANA CRISTINA PARDO PASTRANA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GZ53',
    calle: 'CALLE TEPEACA',
    numero: '33 / OF 1',
    colonia: 'LA PAZ',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72160',
    contacto: 'PABLO PONCE MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GZ54',
    calle: 'CALZADA PORFIRIO DIAZ',
    numero: '197 /PB',
    colonia: 'REFORMA',
    delegacion: 'OAXACA OAXACA',
    ciudad: 'OAXACA OAXACA',
    codigo_postal: '68050',
    contacto: 'VICTORIA RIVERA y AARON CHAVEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GZ55',
    calle: 'AVENIDA 27 DE FEBRERO',
    numero: '1524 INT 6',
    colonia: 'EL AGUILA',
    delegacion: 'TABASCO VILLAHERMOSA',
    ciudad: 'TABASCO VILLAHERMOSA',
    codigo_postal: '86080',
    contacto: 'LUIS ENRIQUE JIMENEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'GZ56',
    calle: 'BLVD MUNICIPIO LIBRE',
    numero: '1983 / PISO 4',
    colonia: 'RESIDENCIAL LA CIMA',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72197',
    contacto: 'ACEVEDO COUTTOLENC Y ASOC AGTES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX33',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'SERGIO MARTINEZ o LUIS SANTIAGO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX35',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'SEC PUBLICO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX50',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'AVM GNP MODULO UNAM BERNARDO AGUILAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX51',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'P HIERRO GPE CONTRERAS o MIGUEL ANGEL CRUZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX52',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'PENOLES GPE CONTRERAS o MIGUEL ANGEL CRUZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'HX54',
    calle: 'CALE NEGRA MODELO',
    numero: '4 / BODEGA A',
    colonia: 'CERVECERIA MODELO',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53330',
    contacto: 'ZURICH SANTANDER LETICIA RAMIREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'H823',
    calle: 'BOSQUES DE ALISOS, EDIF ALISOS EMPRESARIALES',
    numero: '47  / PLANTA ALTA',
    colonia: 'BOSQUES DE LAS LOMAS',
    delegacion: 'CUAJIMALPA DE MORELOS ',
    ciudad: 'CUAJIMALPA DE MORELOS ',
    codigo_postal: '5120',
    contacto: 'GRUPO FIDERE CAROLINA HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M501',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 501 FLAVIO ABIGAIL RODRIGUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M502',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJIMALPA DE MORELOS',
    ciudad: 'CUAJIMALPA DE MORELOS',
    codigo_postal: '5320',
    contacto: 'GZ 502 JUAN CARLOS SOSA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M503',
    calle: 'PROLONGACION CARLOS ECHANOVE',
    numero: '5420',
    colonia: 'LA ROSITA ',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5340',
    contacto: 'GZ 503 LAURA AGUILERA FIERRO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M504',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 504 ABEL ARIZA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M505',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 505 FERNANDO ALQUICIRA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M507',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 507 SELENE CANTU',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M508',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 508 XAVIER CORTINA MANGINO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M509',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 509 EDUARDO ENRIQUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M510',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 510 PATRICIA BARCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M512',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 512 GERARDO SALADO MUNOZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M513',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 513 JOSE JUAN RAMIREZ ROBLEDO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M515',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 515 JAIME NAGANO MALAGON ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M516',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 516 XAVIER CORTINA MANGINO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M518',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 518 ADRIANA VARA LOPEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M519',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 519 ALAN AUSTRIA ANAYA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M520',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 520�LUIS EDUARDO HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M521',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 521 MANUEL CERVANTES OROZCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M522',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 522 ISRAEL TELLEZ GARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M523',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 523 MARIA POMPA ESTRADA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M524',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 524 GUSTAVO LUIS JOLYT ESPONDA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M527',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 527 ARIA Y CONS PAT TRASLOHEROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M528',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 528 LETICIA MARQUEZ MURRIETA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M529',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 529 JANETTE ALVARADO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M531',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 531 CLAUDIO ALI MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M532',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 532 MONICA SANCHEZ ALVAREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M534',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 534 4P CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M535',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 535 JOSE JOAQUIN DEL MORAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M537',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 537 ROSALINDA GONZALEZ MARIN',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M538',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 538 FRANCISCO BUCH TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M539',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 539 JOSE FRANCISCO HERNANDEZ CARDONA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M546',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 546 ROMO DE VIVAR VALLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M548',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 548 ALCANTARA CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M550',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 550 BEHFER Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M551',
    calle: 'CARRETERA MEXICO TOLUCA',
    numero: '5420 / PISO 6',
    colonia: 'EL YAQUI',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5320',
    contacto: 'GZ 551 LUIS CARLOS GONZALEZ VAZQUEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M552',
    calle: 'PROLONGACION CARLOS ECHANOVE',
    numero: '5420 / PISO 6',
    colonia: 'LA ROSITA ',
    delegacion: 'CUAJAMILPA DE MORELOS ',
    ciudad: 'CUAJAMILPA DE MORELOS ',
    codigo_postal: '5340',
    contacto: 'GZ 552 VICTOR MANUEL SABAG',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M557',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 557 SANCHEZ BALDI Y DEL RIO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M562',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 562 MACRIN ING PATRI SA DE CV',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M587',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 587 FT ASESORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'M596',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'GZ 596 EDGAR DIAZ MARTINEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MS01',
    calle: 'MONTE CAUCASO',
    numero: '915 / PISO 5 DEP 505',
    colonia: 'LOMAS DE CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11000',
    contacto: 'GZ 506 LITZIE RIVEIRO ECHEVARRIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MW01',
    calle: 'MONTE CAUCASO',
    numero: '915 / PISO 5 DEP 505',
    colonia: 'LOMAS DE CHAPULTEPEC',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11000',
    contacto: 'GZ 506 LITZIE RIVEIRO ECHEVARRIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MS02',
    calle: 'EMILIO G BAZ RESIDENCIAL LOMAS DEL RIO',
    numero: '59',
    colonia: 'INDEPENDENCIA',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53830',
    contacto: 'JACK SNAIDERMAN AGUILAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MW02',
    calle: 'EMILIO G BAZ RESIDENCIAL LOMAS DEL RIO',
    numero: '59',
    colonia: 'INDEPENDENCIA',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53830',
    contacto: 'JACK SNAIDERMAN AGUILAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MS03',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'LOZANO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MW03',
    calle: 'AV CIRCUNVALACION ORIENTE',
    numero: '130',
    colonia: 'SATELITE ',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53100',
    contacto: 'LOZANO Y ASOCIADOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MS04',
    calle: 'MARIO PANI',
    numero: '750 / PISO 6',
    colonia: 'SANTA FE',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5348',
    contacto: 'CAE AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'MW04',
    calle: 'MARIO PANI',
    numero: '750 / PISO 6',
    colonia: 'SANTA FE',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5348',
    contacto: 'CAE AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX02',
    calle: 'AV CUAUHTEMOC',
    numero: '1486 / PISO 2',
    colonia: 'SANTA CRUZ ATOYAC',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3310',
    contacto: 'ARVAL FILIALES RUIZ GUADALUPE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX37',
    calle: 'ASTRONOMOS',
    numero: '19',
    colonia: 'ESCANDON',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11800',
    contacto: 'AVE CG RICARDO GONZALAEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX38',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS',
    numero: '2 / PISO 2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'AVE ROMO FEDERICO ROMO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX39',
    calle: 'VIENA',
    numero: '71 / PISO 2',
    colonia: 'DEL CARMEN',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4100',
    contacto: 'MAG ANGELICA IBANEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX40',
    calle: 'CANTU',
    numero: '9 /PISO4',
    colonia: 'ANZURES',
    delegacion: 'ANZURES MIGUEL HIDALGO',
    ciudad: 'ANZURES MIGUEL HIDALGO',
    codigo_postal: '11590',
    contacto: 'AVE SICARI MARY CARMEN GOMEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX41',
    calle: 'COHOLANDA',
    numero: '21',
    colonia: 'CAMPESTRE CHURUBUSCO',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4120',
    contacto: 'AVE GPO PME RAUL AREVALO TORRES',
    telefono_d: '59158684'
  },
  {
    lugar_envio: 'VX42',
    calle: 'ADOLFO PRIETO',
    numero: '1649 / Piso 5',
    colonia: 'DEL VALLE CENTRO',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'AVE PROTECCION TECNICA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX43',
    calle: 'TORRE PERISUR ALBORADA',
    numero: '124 / PH 4',
    colonia: 'PARQUES DEL PEDREGAL',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14900',
    contacto: 'AVE RESPALDO Y PROTECCION AGENTE',
    telefono_d: '47809737'
  },
  {
    lugar_envio: 'VX44',
    calle: 'CALLE 22',
    numero: '127, 9  y 11',
    colonia: 'SAN ANTONIO CINTA',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97317',
    contacto: 'AVE CONS INTEG AGENTE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX45',
    calle: 'JUAN ESCUTIA',
    numero: '107 / PISO 1',
    colonia: 'LINDAVISTA',
    delegacion: 'GUSTAVO A MADERO',
    ciudad: 'GUSTAVO A MADERO',
    codigo_postal: '86050',
    contacto: 'AVE CRG AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX46',
    calle: 'AV 1 PLAZA FUNDADORES',
    numero: '2620 / LOCAL 12',
    colonia: 'SAN JOSE',
    delegacion: 'NUEVO SAN JOSE CORDOBA',
    ciudad: 'NUEVO SAN JOSE CORDOBA',
    codigo_postal: '94560',
    contacto: 'AVE CRG AGENTE DE SEGUROS Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX47',
    calle: 'AV INSURGENTES PTE',
    numero: '4017',
    colonia: 'COLINAS DE SAN JERONIMO',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64630',
    contacto: 'AVE YTURRIA AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX50',
    calle: 'PERIFERICO CARLOS PELLICER CAMARA',
    numero: '2820',
    colonia: 'MIGUEL HIDALGO',
    delegacion: 'TABASCO VILLAHERMOSA',
    ciudad: 'TABASCO VILLAHERMOSA',
    codigo_postal: '86126',
    contacto: 'AVE EMX AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX51',
    calle: 'AV 5 DE FEBRERO OTE ',
    numero: '711 INT 8',
    colonia: 'CENTRO',
    delegacion: 'DURANGO DURANGO',
    ciudad: 'DURANGO DURANGO',
    codigo_postal: '34000',
    contacto: 'AVE PORRAS VALDIVIA CONSULTORES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX52',
    calle: 'AV ADOLFO LOPEZ MATEOS',
    numero: '101',
    colonia: 'METROPOLITANA 2A SECC',
    delegacion: 'NEZAHUALCOYOTL',
    ciudad: 'NEZAHUALCOYOTL',
    codigo_postal: '57740',
    contacto: 'AVE LUIS EDUARDO HERNANDEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX53',
    calle: 'AV 5 DE FEBRERO',
    numero: ' 271 B',
    colonia: 'SAN PABLO',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76125',
    contacto: 'AVE MI AGENTE DE SEGUROS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX54',
    calle: 'LERDO DE TEJADA',
    numero: '2280/ PLANTA ALTA',
    colonia: 'LAFAYETTE',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '44150',
    contacto: 'AVE MI AGENTE DE SEGUROS Y FIANZAS JALISCO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX55',
    calle: 'LAGO ALBERTO',
    numero: '442 / PISO 1 DESP 7',
    colonia: 'ANAHUAC SECCION I',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11320',
    contacto: 'AVE LUIS CARDENAS CANTU',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX56',
    calle: 'PASEO OPERA 2 TOWN CENTER SONATA ANGELOPOLIS',
    numero: '2  / PISO 2',
    colonia: 'LOMAS DE ANGELOPOLIS',
    delegacion: 'PUEBLA PUEBLA',
    ciudad: 'PUEBLA PUEBLA',
    codigo_postal: '72830',
    contacto: 'AVE LUIS CARDENAS CANTU TLAXCALA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX57',
    calle: 'CALLE VILLA IMPERIAL INTERIORES',
    numero: '203',
    colonia: 'QUINTA LAS VILLAS',
    delegacion: 'LEON GUANAJUATO',
    ciudad: 'LEON GUANAJUATO',
    codigo_postal: '36643',
    contacto: 'AVE ARTURO AURELIO MACIAS CASANOVA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX58',
    calle: 'AV INSURGENTES',
    numero: '145',
    colonia: 'LOMAS DE HIDALGO',
    delegacion: 'MORELIA MICHOACAN',
    ciudad: 'MORELIA MICHOACAN',
    codigo_postal: '58240',
    contacto: 'AVE ADAN BLANCAS CAMACHO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX59',
    calle: 'BLVD ADOLFO LOPEZ MATEOS',
    numero: '601 INT 8',
    colonia: 'CENTRO',
    delegacion: 'ZACATECAS ZACATECAS',
    ciudad: 'ZACATECAS ZACATECAS',
    codigo_postal: '98000',
    contacto: 'AVE GRUPO AB TLAXCALA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX60',
    calle: 'AV NETZAHUALCOYOTL',
    numero: '1660  INT 101',
    colonia: 'ZONA URBANA RIO TIJUANA',
    delegacion: 'MEXICALI BAJA CALIFORNIA',
    ciudad: 'MEXICALI BAJA CALIFORNIA',
    codigo_postal: '22010',
    contacto: 'AVE PRAXIS AGENTE DE SEG Y FIANZAS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX61',
    calle: 'CL ADOBES',
    numero: '15',
    colonia: 'HERMOSILLO',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '83249',
    contacto: 'AVE LUIS MARIO PUJOL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX62',
    calle: 'CALLE 19 NUM 111 ALTOS INT 10',
    numero: '111 Altos INT 10',
    colonia: 'MONTECRISTO',
    delegacion: 'MERIDA YUCATAN',
    ciudad: 'MERIDA YUCATAN',
    codigo_postal: '97133',
    contacto: 'VICENTE ARTURO GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX68',
    calle: 'AV 20 DE NOVIEMBRE',
    numero: '499 int 5',
    colonia: 'MODELO',
    delegacion: 'XALAPA VERACRUZ ',
    ciudad: 'XALAPA VERACRUZ ',
    codigo_postal: '91040',
    contacto: 'AVE GPO PME RAUL AREVALO TORRES',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX69',
    calle: 'ALFONSO REYES',
    numero: '318 INT 209,210',
    colonia: 'CONTRY LOS ESTANQUES',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64860',
    contacto: 'AVE ESTRATEGAS NORMA VILLARREAL',
    telefono_d: '8113130010'
  },
  {
    lugar_envio: 'VX70',
    calle: 'CALZADA GALVAN',
    numero: '147',
    colonia: 'CENTRO',
    delegacion: 'COLIMA COLIMA ',
    ciudad: 'COLIMA COLIMA ',
    codigo_postal: '28000',
    contacto: 'AVE LUIS EDUARDO GARZA SOTO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX71',
    calle: 'ADOLFO PRIETO',
    numero: '1649 / Piso 5',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'AVE PROTECCION TECNICA DIEGO LOPEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX72',
    calle: 'PLAZA COMERCIAL VENUSTIANO C ESQ ESPINOSA',
    numero: 'V1818 LOC 46',
    colonia: 'CENTRO',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '64000',
    contacto: 'AVE ERNESTO SANTOS CHANDE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX73',
    calle: 'ALLENDE',
    numero: '37 / PISO 1',
    colonia: 'CENTRO',
    delegacion: 'TLAXCALA TLAXCALA',
    ciudad: 'TLAXCALA TLAXCALA',
    codigo_postal: '90000',
    contacto: 'AVE FEDERICO ROMO BERNAL',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX74',
    calle: 'BLVD NORMA CORONA SAPIEN NTE',
    numero: '3029 / LOCAL 5',
    colonia: 'RINCON DEL HUMAYA',
    delegacion: 'CULIACAN SINALOA',
    ciudad: 'CULIACAN SINALOA',
    codigo_postal: '80058',
    contacto: 'AVE JOSE DE JESUS DIAZ SUAREZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX75',
    calle: 'AGUADALUCE EDIF ERGON',
    numero: '523 / P BAJA 101',
    colonia: 'LA FLORIDA',
    delegacion: 'CIUDAD VICTORIA TAMAULIPAS',
    ciudad: 'CIUDAD VICTORIA TAMAULIPAS',
    codigo_postal: '89110',
    contacto: 'AVE PARREY JORGE LUIS PADILLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX76',
    calle: 'DR NICOLAS DE LEON',
    numero: '44 INT 1',
    colonia: 'JARDIN BALBUENA',
    delegacion: 'VENUSTIANO CARRANZA',
    ciudad: 'VENUSTIANO CARRANZA',
    codigo_postal: '15900',
    contacto: 'AVE RAYMUNDO PINZON ANGUIANO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX77',
    calle: 'AV PROVIDENCIA ESQ AV DIVISION DEL NORTE',
    numero: '502',
    colonia: 'DEL VALLE',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3100',
    contacto: 'AVE RAMIREZ IBARROLA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'VX78',
    calle: 'BOSQUE DE LAVANDA, EDIF 11',
    numero: 'SUITE 11 01',
    colonia: 'BOSQUE ESMERALDA',
    delegacion: 'ATIZAPAN DE ZARAGOZA',
    ciudad: 'ATIZAPAN DE ZARAGOZA',
    codigo_postal: '52930',
    contacto: 'AVE PULIDO FERNANDEZ Y ASOCIADOS',
    telefono_d: '5517401021 Y 5517401020'
  },
  {
    lugar_envio: 'VX79',
    calle: 'SERIS',
    numero: '48 INT 1 ',
    colonia: 'LA RAZA',
    delegacion: 'AZCAPOTZALCO',
    ciudad: 'AZCAPOTZALCO',
    codigo_postal: '2990',
    contacto: 'AVE JUAN CARLOS ESCOBAR LOPEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'UAEM',
    calle: 'MIGUEL A DE QUEVEDO',
    numero: '621 /GUION 301',
    colonia: 'ROMERO DE TERREROS',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4320',
    contacto: 'MARCELA DURAN NARCIA',
    telefono_d: '0'
  },
  {
    lugar_envio: 'POLICIA FEDERAL',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ROSARIO COLIN SALAZAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'UAM CRS',
    calle: 'AV INSURGENTES SUR',
    numero: '1079 / PISO 12',
    colonia: 'NOCHE BUENA',
    delegacion: 'BENITO JUAREZ',
    ciudad: 'BENITO JUAREZ',
    codigo_postal: '3720',
    contacto: 'ROSARIO COLIN SALAZAR',
    telefono_d: '0'
  },
  {
    lugar_envio: 'ITT CANON',
    calle: 'AV LIBRE COMERCIO SN PARQ INDUSTRIAL NUEVO NOGALES',
    numero: 'SN',
    colonia: 'NOGALES',
    delegacion: 'HERMOSILLO SONORA',
    ciudad: 'HERMOSILLO SONORA',
    codigo_postal: '84093',
    contacto: 'CARMEN ARREOLA DOMINGUEZ',
    telefono_d: '6313110628'
  },
  {
    lugar_envio: 'CRYOINFRA',
    calle: 'VASCO DE QUIROGA, PENA BLANCA',
    numero: '2121 / PISO 1',
    colonia: 'SANTA FE',
    delegacion: 'ALVARO OBREGON',
    ciudad: 'ALVARO OBREGON',
    codigo_postal: '1210',
    contacto: 'FERNANDO ARAMBULA ARGOTE',
    telefono_d: '0'
  },
  {
    lugar_envio: 'EL OSO',
    calle: 'JUAN VAZQUEZ DE MELLO, EDIF CAD CREA',
    numero: '481/ PISO  2',
    colonia: 'POLANCO',
    delegacion: 'MIGUEL HIDALGO',
    ciudad: 'MIGUEL HIDALGO',
    codigo_postal: '11510',
    contacto: 'MARTIN CORDOVA ALTERNATIVAS EN RIESGOS',
    telefono_d: '0'
  },
  {
    lugar_envio: 'UAM MODULO 1',
    calle: 'AV SAN PABLO, EDIF C',
    numero: '180 / PISO 1 DPTO RH',
    colonia: 'REYNOSA TAMAULIPAS',
    delegacion: 'AZCAPOTZALCO',
    ciudad: 'AZCAPOTZALCO',
    codigo_postal: '2200',
    contacto: 'ERIKA MORALES PINON',
    telefono_d: '53189000 Ext 2004'
  },
  {
    lugar_envio: 'UAM MODULO 2',
    calle: 'AV VASCO DE QUIROGA',
    numero: '4871 /PISO 4 DPTO RH',
    colonia: 'SANTA FE CUAJIMALPA',
    delegacion: 'CUAJIMALPA',
    ciudad: 'CUAJIMALPA',
    codigo_postal: '5348',
    contacto: 'VICTOR MATIAS MONTOYA',
    telefono_d: '58146500 Ext 3407'
  },
  {
    lugar_envio: 'UAM MODULO 3',
    calle: 'AV SAN RAFAEL ATLIXCO, EDIF A',
    numero: '186/ PB DPTO RH',
    colonia: 'VICENTINA',
    delegacion: 'IZTAPALAPA',
    ciudad: 'IZTAPALAPA',
    codigo_postal: '9340',
    contacto: 'JOSE DE JESUS CARBAJAL CORNEJO',
    telefono_d: '58044812'
  },
  {
    lugar_envio: 'UAM MODULO 4',
    calle: 'AV DE LAS GARZAS',
    numero: '10 MODULO G NUM 7',
    colonia: 'EL PANTEON',
    delegacion: 'LERMA ESTADO DE MEXICO',
    ciudad: 'LERMA ESTADO DE MEXICO',
    codigo_postal: '52005',
    contacto: 'MAURICIO VIDAL CAMACHO',
    telefono_d: '7282827002 Ext 1319'
  },
  {
    lugar_envio: 'UAM MODULO 5',
    calle: 'CALZADA DEL HUESO, EDIF A',
    numero: '1100 / PISO 2 DPTO RH',
    colonia: 'VILLA QUIETUD',
    delegacion: 'COYOACAN',
    ciudad: 'COYOACAN',
    codigo_postal: '4960',
    contacto: 'SANDRA NALLELYSALDANA ORTIZ',
    telefono_d: '54837000 Ext 3068'
  },
  {
    lugar_envio: 'UAM MODULO 6',
    calle: 'PROLONGACION CANAL DE MIRAMONTES, EDIF C',
    numero: '3855 / PB',
    colonia: 'EXHACIENDA SAN JUAN D DIOS',
    delegacion: 'TLALPAN',
    ciudad: 'TLALPAN',
    codigo_postal: '14387',
    contacto: 'ARACELI GRIMALDO DORANTES',
    telefono_d: '54834000 Ext 1451'
  },
  {
    lugar_envio: 'IGLESIA',
    calle: 'CALLE FUENTE PIRAMIDES',
    numero: '1 / PISO 9 10 y 11',
    colonia: 'LOMAS DE TECAMACHALCO',
    delegacion: 'NAUCALPAN EDO DE MEXICO',
    ciudad: 'NAUCALPAN EDO DE MEXICO',
    codigo_postal: '53950',
    contacto: 'IGLESIA DE JESUCRISTO',
    telefono_d: '0'
  },
  {
    lugar_envio: 'LOCKTON GDL',
    calle: 'AV EUGENIO GARZA SADA',
    numero: '120 INT 15 /Piso 2',
    colonia: 'POCITOS',
    delegacion: 'AGUASCALIENTES AGS',
    ciudad: 'AGUASCALIENTES AGS',
    codigo_postal: '20328',
    contacto: 'LOCKTON GDL NANCY DIAZ ',
    telefono_d: '4499031126'
  },
  {
    lugar_envio: 'AON QRO',
    calle: 'EDIF LA VISTA BLVD BERNARDO QUINTANA',
    numero: '9750 / DESP 311 y 312',
    colonia: 'CENTRO SUR ',
    delegacion: 'QUERETARO QRO',
    ciudad: 'QUERETARO QRO',
    codigo_postal: '76090',
    contacto: 'YADIRA RIVERA',
    telefono_d: '4422291293'
  },
  {
    lugar_envio: 'IBS CDMX',
    calle: 'MORELOS',
    numero: '24',
    colonia: 'SAN JERONIMO TEPETLACALCO',
    delegacion: 'TLALNEPANTLA DE BAZ',
    ciudad: 'TLALNEPANTLA DE BAZ',
    codigo_postal: '54090',
    contacto: 'LUIS ALBERTO GONZALEZ',
    telefono_d: '0'
  },
  {
    lugar_envio: 'IBS GDL',
    calle: 'PLAZA UNIVERSIDAD AV PABLO NERUDA',
    numero: '4341 / LOCAL i 19',
    colonia: 'LOMAS DEL VALLE',
    delegacion: 'GUADALAJARA JALISCO',
    ciudad: 'GUADALAJARA JALISCO',
    codigo_postal: '45129',
    contacto: 'RAFAEL CASTANON',
    telefono_d: '0'
  },
  {
    lugar_envio: 'IBS MTY',
    calle: 'REGIO PLAZA AV ISIDRO SEPULVEDA',
    numero: '690 / LOCAL 24',
    colonia: 'PARQUE INDUSTRIAL JM',
    delegacion: 'MONTERREY NUEVO LEON',
    ciudad: 'MONTERREY NUEVO LEON',
    codigo_postal: '66633',
    contacto: 'FERNANDO FIGUEROA',
    telefono_d: '0'
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  },
  {
    lugar_envio: '',
    calle: '',
    numero: '',
    colonia: '',
    delegacion: '',
    ciudad: '',
    codigo_postal: '',
    contacto: '',
    telefono_d: ''
  }
];

@Injectable({
  providedIn: 'root'
})
export class CdEnvioService {
  constructor() {}

  getDatosCod(codigoEnvio: string): DatosEnvio {
    for (let datos of DatosEnvios) {
      //console.log(datos.lugar_envio)
      if (codigoEnvio === datos.lugar_envio) {
        return datos;
      }
    }
    return DatosEnvios_aux;
  }
}
