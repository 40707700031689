<h1 mat-dialog-title class="title">{{Txtlbl1}}</h1>
<div mat-dialog-content style="height: 80%" #dialogForm>
  <h3>{{Txtlbl2}} {{data.nombre}}</h3>
  <div class="container mt-3">
    <h3
      style="
        font-family: 'Open Sans', sans-serif !important;
        font-weight: bold !important;
      "
    >
      Documentos añadidos
    </h3>
    <mat-card class="example-card containerFilesEdit">
      <cdk-virtual-scroll-viewport
        itemSize="50"
        class="example-viewportCarpeta"
      >
        <div class="single-fileEdit" *ngFor="let archivos of data.archivos">
          <div class="containerInfoEdit center-h center-v">
            <div>
              <h4
                style="
                  margin-top: 5px;
                  margin-left: 10px;
                  font-family: 'Open Sans', sans-serif !important;
                  font-weight: bold !important;
                "
              >
                {{archivos.renombre}}
              </h4>
            </div>
            <div></div>
            <div class="containerInfoEdit center-h center-v">
              <div>
                <mat-icon
                  style="font-size: medium; cursor: pointer; margin-top: 7px"
                  (click)="renombrarArchivo(data.nombre,archivos.renombre);"
                  [hidden]="deshaRenombre"
                  >create</mat-icon
                >
              </div>
              <div>
                <mat-icon
                  style="font-size: medium; cursor: pointer; margin-top: 7px"
                  (click)="quitarArchivo(data.nombre,archivos.renombre,archivos.tipoArchivo,data.nivelCarpeta,data.nivelPadre);"
                  >close</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </mat-card>
    <div class="container mt-3"></div>
    <div [hidden]="!notiRenombre" class="notiError3">
      El renombrado no puede ser vacio
    </div>
    <div [hidden]="!editarNombre">
      <mat-divider></mat-divider>
      <h1 mat-dialog-title>{{Txtlbl3}}</h1>
      <h3>{{Txtlbl4}}</h3>
      <button
        mat-button
        (click)="addNomenOpc=true"
        id="btn-buscar"
        style="margin-top: -70px"
      >
        Agregar nomenclatura
      </button>
      <div [hidden]="!addNomenOpc">
        Nueva Nomenclatura
        <mat-form-field class="col-sm-5" appearance="outline">
          <mat-label>{{Txtlbl7}}</mat-label>
          <input
            type="text"
            matInput
            placeholder="Nomenclatura"
            [formControl]="controlNomen"
            required
          />
          <mat-error *ngIf="controlNomen.hasError('required')">
            {{TxtNot1}}
          </mat-error>
        </mat-form-field>
        <div class="form-group row">
          <div class="col-sm-3"></div>
          <!-- <div class="col-sm-3"></div> -->
          <!-- <button mat-button id="btn-buscar" (click)="addNomenOpc=false">{{Txtlbl8}}</button> -->
          <button mat-button id="btn-buscar" (click)="cancelAddNome()">
            {{Txtlbl8}}
          </button>
          <div class="col-sm-0" style="width: 30px"></div>
          <button mat-button id="btn-buscar" (click)="addNomen()">
            {{Txtlbl9}}
          </button>
        </div>
      </div>
      <div [hidden]="!notiNomen" class="notiError3">
        El campo no puede ser vacio
      </div>
      <mat-card class="example-card containerNomen">
        <cdk-virtual-scroll-viewport
          itemSize="50"
          class="example-viewport"
          style="margin-top: 10px"
        >
          <div class="single-fileEdit" *ngFor="let nomen of data.nomenclatura">
            <div class="containerInfoEdit center-h center-v">
              <div>
                <h4
                  style="
                    margin-top: 5px;
                    margin-left: 20px;
                    font-family: 'Open Sans', sans-serif !important;
                    font-weight: bold !important;
                  "
                >
                  {{nomen.name}}
                </h4>
              </div>
              <div></div>
              <div class="containerInfoEdit center-h center-v">
                <div>
                  <mat-icon
                    style="font-size: medium; cursor: pointer; margin-top: 7px"
                    (click)="deleteNomen(nomen.name)"
                    >close</mat-icon
                  >
                </div>
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
      </mat-card>
      <form [formGroup]="NomenForm">
        <label style="margin-top: 20px">Vista previa renombrado</label>
        <input
          type="text"
          style="width: 100%; margin-top: 20px"
          formControlName="newNomen"
          (change)="cambioNombre()"
          [value]="archivoNomen"
          disabled="true"
        />
        <label style="margin-top: 20px">{{Txtlbl5}} {{numCaracteres}}</label>
        <button
          mat-button
          id="btn-buscar"
          style="margin-left: 80%"
          (click)="cambiarNombreArchivo()"
        >
          {{Txtlbl6}}
        </button>
      </form>
    </div>
    <!--  <div [hidden]="!addNomenOpc">
      <mat-form-field class="col-sm-4" appearance="outline">
        <mat-label>{{Txtlbl7}}</mat-label>
        <input type="text" matInput placeholder="Nomenclatura" [formControl]="controlNomen" required>
        <mat-error *ngIf="controlNomen.hasError('required')">
          {{TxtNot1}}
        </mat-error>
      </mat-form-field>
      <div class="form-group row">
        <button mat-button id="btn-buscar" (click)="addNomenOpc=false">{{Txtlbl8}}</button>
        <div class="col-sm-1"></div>
        <button mat-button id="btn-buscar" (click)="addNomen()">{{Txtlbl9}}</button>
      </div>
    </div> -->
    <div mat-dialog-actions>
      <button
        style="border: 0px; border-radius: 5px; outline: none; margin-left: 80%"
        mat-raised-button
        id="btn-buscar"
        mat-button
        [mat-dialog-close]
      >
        {{Txtlbl10}}
      </button>
      <button [hidden]="true" mat-button cdkFocusInitial>Ok</button>
    </div>
  </div>
</div>
