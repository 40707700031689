<div class="container-fluid px-5">
  <!--mat-accordion>
        <app-datosgral></app-datosgral>
        <app-confarmado></app-confarmado>
        <app-notificacion></app-notificacion>
    </mat-accordion-->

  <mat-accordion class="example-headers-align">
    <mat-expansion-panel
      [expanded]="step === 0"
      (opened)="setStep(0)"
      hideToggle
      [disabled]="disableGral"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <!--{{TxtStep1}}-->
          Datos Generales
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider></mat-divider>
      <app-datosgral></app-datosgral>

      <!--mat-form-field>
            <mat-label>First name</mat-label>
            <input matInput>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Age</mat-label>
            <input matInput type="number" min="1">
          </mat-form-field-->

      <mat-action-row class="justify-content-end">
        <!-- <button mat-button color="primary" (click)="nextStep()">Continuar</button>-->
        <div class="col-sm-3"></div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelDatos"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelDatos"
            (click)="cancel()"
          >
            Cancelar
          </button>
        </div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelDatos"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelDatos"
            (click)="nextStep()"
          >
            Continuar
          </button>
        </div>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="step === 1"
      (opened)="setStep(1)"
      hideToggle
      [disabled]="disableArmado"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Configuración de Armado
          <div [hidden]="!notiArmado" class="notiError2">
            {{ TxtNotiArmado }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider></mat-divider>
      <app-confarmado></app-confarmado>

      <!--<mat-form-field>
                <mat-label>Country</mat-label>
                <input matInput>
            </mat-form-field-->

      <mat-action-row>
        <div [hidden]="!errorCA2" class="col-sm-5" class="notiError4">
          <div class="msn">
            {{ TxtNotiCA2 }}
          </div>
        </div>
        <div [hidden]="!errorCA" class="col-sm-4" class="notiError3">
          <div class="msn">
            {{ TxtNotiCA }}
          </div>
        </div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelDatos"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelDatos"
            (click)="prevStep()"
          >
            Cancelar
          </button>
        </div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelDatos"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelDatos"
            (click)="nextStep()"
          >
            Continuar
          </button>
        </div>
      </mat-action-row>
    </mat-expansion-panel>

    <mat-expansion-panel
      [expanded]="step === 2"
      (opened)="setStep(2)"
      hideToggle
      [disabled]="disableNoti"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Datos de notificación
          <!--{{TxtStep3}}
                    <div [hidden]="!notiContinuar" class="notiError2">
                        hola
                        {{mensajeError2}}
                    </div-->
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-divider></mat-divider>
      <app-notificacion></app-notificacion>
      <mat-action-row class="justify-content-end">
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelNotif"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelNotif"
            (click)="finalCancel()"
          >
            Cancelar
          </button>
        </div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelNotif"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelNotif"
            (click)="prevStep()"
          >
            Atras
          </button>
        </div>
        <div class="col-sm-3">
          <button
            class="col-sm-12"
            type="button"
            mat-raised-button
            data-toggle="collapse"
            data-target="#headerPanelNotif"
            id="btn-buscar"
            aria-expanded="false"
            aria-controls="headerPanelNotif"
            (click)="onSubmit()"
          >
            Enviar
          </button>
        </div>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>
