import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators
} from '@angular/forms';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener
} from '@angular/material/tree';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
// import { ConfarmadoComponent } from '../confarmado/confarmado.component';
import { ServicioAzulService } from '../src_app_servicio-azul.service';
import { EventEmitterService } from '../event-emitter.service';
import { HttpClient } from '@angular/common/http';
import { appSettings } from '../appSettings';
import { MatSnackBar } from '@angular/material/snack-bar';

interface FoodNode {
  name: string;
  children?: FoodNode[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

export let regresaPanel: Boolean;
// tslint:disable-next-line: ban-types
export let armadoFenableExt: Boolean;
// tslint:disable-next-line: ban-types
export let armadoDenableExt: Boolean;
export let opcRamo: any[] = [];
export let opcRamoSelec: string;
export let carpetaBase: {};

// Variables que viajan para expandir o contraer los paneles
export let expandPanelConfArm: boolean;
export let disablePanelConfArm: boolean;
export let expandPanelDatGrales: boolean;

let respuestaCRM: any[];
let respuestaInfo: any[];
export let Paneles: any = {
  panelD: false,
  panelF: false
};

export interface PolizasElement {
  position: number;
  poliza: string;
  ramo: string;
  fchVigenciaInicial?: string;
  fchVigenciaFinal: string;
  codIntermediario?: string;
  razonSocial: string;
  idParticipanteA?: string;
  nombresA?: string;
  apellidoPaternoA?: string;
  apellidoMaternoA?: string;
  // tslint:disable-next-line: ban-types
  ifHijas?: Boolean;
  noContrato: string;
}

// modificado para el tree polizas
export interface HijasElement {
  position: number;
  poliza: string;
  movimiento: string;
  emision: string;
  poliza_padre: string;
}

export interface Phija {
  position: number;
  poliza: string;
  poliza_padre: string;
}

export let ELEMENT_POLIZAS: PolizasElement[] = [];
const histoPolizaH: Phija[] = [];
// tree polizas
export let TREE_DATA3: FoodNode[] = [];
// tslint:disable-next-line: variable-name
export let TREE_DATA3_aux: FoodNode[] = [null];
// tslint:disable-next-line: variable-name
export let TREE_DATA3_vacio: FoodNode[] = [];
let PoPH: FoodNode = {
  name: null,
  children: []
};
// tslint:disable-next-line: variable-name
let PoPH_ch: FoodNode = {
  name: null
};

// tslint:disable-next-line: class-name
export interface contacto {
  nombre: string;
  correo: string[];
  telefono: string[];
}

// tslint:disable-next-line: class-name
export interface listaArmados {
  tipo?: string;
  ramo?: string;
  numConPol?: string;
  listaPolizas?: any[];
  armadoEstructura?: [];
  id?: number;
}

// tslint:disable-next-line: class-name
export interface armados {
  // tslint:disable-next-line: ban-types
  digital: Boolean;
  // tslint:disable-next-line: ban-types
  fisico: Boolean;
}

export let contactoJson: contacto = {
  nombre: null,
  correo: [''],
  telefono: ['']
};
export let listaJson: listaArmados = {
  tipo: '',
  ramo: '',
  numConPol: '',
  listaPolizas: [],
  armadoEstructura: []
};
export let ListaJSON: listaArmados[] = []; //digM
export let armadoJson: armados = {
  digital: null,
  fisico: null
};

export interface OpcVars {
  ramo: string;
  criterio: string;
}

export let opcVars: OpcVars = {
  ramo: '',
  criterio: ''
};

@Component({
  selector: 'app-datosgral',
  templateUrl: './datosgral.component.html',
  styleUrls: ['./datosgral.component.scss'],
  providers: [MatSnackBar],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatosgralComponent implements OnInit {
  CaseRamos: any;
  @ViewChild('fechaPublicacionFilter') fechaPublicacionFilter: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    protected servicioAzul: ServicioAzulService,
    private eventEmitterService: EventEmitterService,
    protected http: HttpClient,
    private _snackBar: MatSnackBar
  ) {
    this.dataSource3.data = TREE_DATA3_vacio;
    this.getramos();
    this.caseRamos();
    /* this.http.get(appSettings.URL_OT3)// ramos
.subscribe({
  next: data => {
    this.ramos=data;
    console.log(data)
    for(let ramo of this.ramos){
      let pos=this.ramos.indexOf(ramo);
      if(ramo.name==='AUTOS'){
        this.ramos[pos].name="Autos"
      }else if(ramo.name==='VIDA'){
        this.ramos[pos].name="Vida"
      }
    }
  },
  error: error =>{
    if (error.status === 500) {
      console.error('error de servidor', error)
    }else if(error.status === 400){
      console.error('request no valido', error)
    }else if(error.status === 0){
      console.error('error de conexion', error)
    }
  }
});
this.http.get(appSettings.URL_OT16)//case de ramos
.subscribe({
  next: data => {
    this.CaseRamos = data;
  },
  error: error => {
    if (error.status === 500) {
      console.error('error de servidor', error)
    }else if(error.status === 400){
      console.error('request no valido', error)
    }else if(error.status === 0){
      console.error('error de conexion', error)
    }
  }
}); */
  }

  // Variables locales para expandir el panel
  expandPanelDatGralesLoc: boolean;
  disablePanelDatGralesLoc: boolean;

  // tslint:disable-next-line: variable-name
  // public aux_ConfgArm = ConfarmadoComponent;
  datosGralForm: FormGroup;
  tipoImpresion: FormGroup;
  test: FormGroup;

  criterio = new FormControl('', [Validators.required]);
  // tslint:disable-next-line: variable-name
  txt_criterio = new FormControl('', [Validators.required]);
  // tslint:disable-next-line: variable-name
  txt_criterio2 = new FormControl('', [Validators.required]);
  ramoDG = new FormControl('', [Validators.required]);

  // Tree Documentos
  private transformer = (node: FoodNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level
    };
  };
  // tslint:disable-next-line: member-ordering
  data = Object.assign(ELEMENT_POLIZAS);
  // tslint:disable-next-line: member-ordering
  displayedColumns: string[] = [
    'select',
    'position',
    'poliza',
    'ramo',
    'contratante',
    'intermediario',
    'vigencia',
    'fechaPublicacion'
  ];
  // tslint:disable-next-line: member-ordering
  dataSource = new MatTableDataSource<PolizasElement>(ELEMENT_POLIZAS);
  // dataSource:any;
  // tslint:disable-next-line: member-ordering
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable
  );
  // tslint:disable-next-line: member-ordering
  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children
  );
  // tslint:disable-next-line: member-ordering
  selection = new SelectionModel<PolizasElement>(true, []);

  // tree polizas
  // tslint:disable-next-line: member-ordering
  dataSource3 = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  // tslint:disable-next-line: member-ordering
  ramoSelect = 'Ramo ';
  // tslint:disable-next-line: member-ordering
  ramos: any;

  // tslint:disable-next-line: member-ordering
  criteriosOtros: any[] = [{ name: 'Contrato' }, { name: 'Póliza' }];
  // tslint:disable-next-line: member-ordering
  criteriosGMM: any[] = [
    { name: 'Contrato' },
    { name: 'Pool' },
    { name: 'Póliza' }
  ];

  // tslint:disable-next-line: member-ordering
  criterios: any[] = [
    { name: 'Contrato' },
    { name: 'Pool' },
    { name: 'Póliza' }
  ];
  // tslint:disable-next-line: member-ordering
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  // tslint:disable-next-line: member-ordering
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // tslint:disable-next-line: member-ordering
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  // tslint:disable-next-line: member-ordering
  TxtStep1 = 'Datos Generales';
  // tslint:disable-next-line: member-ordering
  Txtlbl1 = 'Ramo';
  // tslint:disable-next-line: member-ordering
  Txtlbl2 = 'Criterio de búsqueda';
  // tslint:disable-next-line: member-ordering
  Txtlbl3 = 'Tipo de impresión';
  // tslint:disable-next-line: member-ordering
  Txtlbl4 = 'Tabla de Resumen';
  // tslint:disable-next-line: member-ordering
  Txtlbl5 = 'Pólizas H/ Certificado';
  // tslint:disable-next-line: member-ordering
  Txtlbl6 = 'Certificado';
  // tslint:disable-next-line: member-ordering
  TxtNot1 = 'Campo obligatorio';
  // tslint:disable-next-line: member-ordering
  TxtNot50 = 'No se seleccionó un tipo de impresión';
  // tslint:disable-next-line: member-ordering
  TxtNot2 = '';
  // tslint:disable-next-line: member-ordering
  TxtNot3 = 'Debe de seleccionar al menos una póliza padre';
  // tslint:disable-next-line: member-ordering
  TxtNot4 = 'El campo texto criterio no fue llenado';
  // tslint:disable-next-line: member-ordering
  TxtNot5 = 'El campo de criterio no fue llenado';
  // tslint:disable-next-line: member-ordering
  TxtNot6 = 'El campo de Ramo no fue llenado';
  // tslint:disable-next-line: member-ordering
  TxtNot7 = 'Ningun campo fue llenado';
  // tslint:disable-next-line: member-ordering
  TxtNot8 = 'El campo de certificado no fue llenado';
  // tslint:disable-next-line: member-ordering
  TxtNot9 = 'Servicio no disponible';
  // tslint:disable-next-line: member-ordering
  TxtNot10 = 'Ya se realizó esta consulta';
  // tslint:disable-next-line: member-ordering
  TxtNot11 = '';
  TxtNot12 = '';
  // tslint:disable-next-line: member-ordering
  placeholder = 'Seleccione un criterio';
  // tslint:disable-next-line: member-ordering
  Txtbtn1 = 'Buscar';
  // tslint:disable-next-line: member-ordering
  Txtbtn2 = 'Seleccionar';
  // tslint:disable-next-line: member-ordering
  TxtlblBx1 = 'Física';
  // tslint:disable-next-line: member-ordering
  TxtlblBx2 = 'Digital';
  // tslint:disable-next-line: member-ordering
  espacio = ' ';
  // tslint:disable-next-line: member-ordering
  GastosMedicos = 'GMM';
  // tslint:disable-next-line: member-ordering
  Pool = 'Pool';
  // tslint:disable-next-line: member-ordering
  TxlBtCnt = 'Continuar';
  // tslint:disable-next-line: member-ordering
  TxlBtCnl = 'Cancelar';
  // tslint:disable-next-line: member-ordering
  tiposImpr: string[] = ['Física', 'Digital'];

  /*NgModels*/
  // tslint:disable-next-line: member-ordering
  armadoF = false;
  // tslint:disable-next-line: member-ordering
  armadoD = false;
  // tslint:disable-next-line: member-ordering
  ngCriterio = '';
  // varibale spara habilitar y desabilitar el tipo impresion
  armadoFenable: Boolean;
  // tslint:disable-next-line: member-ordering
  armadoDenable: Boolean;
  // tslint:disable-next-line: member-ordering
  mostrarCert = false;
  // tslint:disable-next-line: member-ordering
  datosContratante: any[] = [];
  // tslint:disable-next-line: member-ordering
  polizapadre_aux: PolizasElement;
  // tslint:disable-next-line: member-ordering
  numpoliza = '';
  // tslint:disable-next-line: member-ordering
  ramoV = '';
  // tslint:disable-next-line: member-ordering
  fecha = '';
  // tslint:disable-next-line: member-ordering
  intermediario = '';
  // tslint:disable-next-line: member-ordering
  ifHijas = false;
  // tslint:disable-next-line: member-ordering
  tipo = '';
  // varible para la notificacion de busqueda
  notiBuscarP: Boolean;
  // tslint:disable-next-line: member-ordering
  // data: any = null;
  // tslint:disable-next-line: member-ordering
  result: any = null;
  // tslint:disable-next-line: member-ordering
  proBuscar: Boolean;
  aux_txt_criterio: string;
  auxTxtCriterioCeros: string;
  aux_criterio: string;
  aux_ramo: string;
  noContrato: string;
  // tslint:disable-next-line: member-ordering
  aux_Phija: Phija = {
    poliza: '',
    position: 0,
    poliza_padre: ''
  };
  // tslint:disable-next-line: ban-types
  notiPolizasH: Boolean; // variable par mostrar la notificacion de Polizas polizas e hijas

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnInit(): void {
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar =
        this.eventEmitterService.invokeFirstComponentFunction.subscribe(
          (name: string) => {
            this.continuarDG();
          }
        );
    }

    this.datosGralForm = this.formBuilder.group({
      ramoDG: [''],
      criterio: [''],
      txt_criterio: [''],
      txt_criterio2: ['']
    });

    this.tipoImpresion = this.formBuilder.group({
      opcTipo: ['']
      /* armadoD: [''],
armadoF: [''] */
    });
    this.test = this.formBuilder.group({
      opcTipoTest: ['']
    });
    // Paginador
    this.paginator._intl.itemsPerPageLabel = 'Resultados por página';
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.filterPredicate = (
      data: PolizasElement,
      filter: string
    ) => {
      return data.fchVigenciaFinal == filter;
    };
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 9000
    });
  }

  getramos() {
    this.http
      .get(appSettings.URL_OT3) // ramos
      .subscribe({
        next: (data) => {
          this.ramos = data;
          //console.log(data)
          for (let ramo of this.ramos) {
            let pos = this.ramos.indexOf(ramo);
            if (ramo.name === 'AUTOS') {
              this.ramos[pos].name = 'Autos';
            } else if (ramo.name === 'VIDA') {
              this.ramos[pos].name = 'Vida';
            }
          }
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            console.error('request no valido', error);
            this.notiCodigo400();
            this.getramos();
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }

  caseRamos() {
    this.http
      .get(appSettings.URL_OT16) //case de ramos
      .subscribe({
        next: (data) => {
          this.CaseRamos = data;
        },
        error: (error) => {
          if (error.status === 500) {
            this.notiCodigo500();
            console.error('error de servidor', error);
          } else if (error.status === 400) {
            this.notiCodigo400();
            this.caseRamos();
            console.error('requesemt no valido', error);
          } else if (error.status === 0) {
            this.notiCodigo0();
            console.error('error de conexion', error);
          }
        }
      });
  }

  notiCodigo500() {
    this.openSnackBar('Error de Servidor', '');
  }

  notiCodigo400() {
    this.openSnackBar('Error de Request', '');
  }

  notiCodigo0() {
    this.openSnackBar('Error de Conexión', '');
  }

  iniDatosGenerales() {
    /*if (expandPanelDGfromCA !== undefined){
  this.expandPanelDatGralesLoc = true;
  this.disablePanelDatGralesLoc = false;
}
expandPanelConfArm = false;
disablePanelConfArm = true;*/
  }

  checkboxLabel(row?: PolizasElement): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  someMethod(value: any) {
    this.placeholder = value;
  }

  applyFilter(fechaPublicaicon: any): void {
    this.dataSource.filter = fechaPublicaicon.format('DD/MM/YYYY');
  }

  clearFilter(): void {
    this.dataSource.filter = null;
    this.fechaPublicacionFilter.nativeElement.value = null;
  }

  guardarDatos(
    pos: number,
    poliza: string,
    ramo: string,
    razon: string,
    intermediario: string,
    fecha: string,
    hijas?: Boolean,
    contrato?: string
  ) {
    //cambio para guardar el contrato
    // tslint:disable-next-line: max-line-length
    let fecha_aux = '';
    fecha_aux = this.formatofecha(fecha);
    //this.polizapadre_aux = {position: pos + 1, poliza , ramo, razonSocial: razon, codIntermediario: intermediario, fchVigenciaFinal: fecha_aux, ifHijas: hijas, noContrato:contrato};
    this.polizapadre_aux = {
      position: pos + 1,
      poliza,
      ramo,
      razonSocial: razon,
      codIntermediario: intermediario,
      fchVigenciaFinal: fecha,
      ifHijas: hijas,
      noContrato: contrato
    };
    ELEMENT_POLIZAS[pos] = this.polizapadre_aux;
    this.dataSource = new MatTableDataSource<PolizasElement>(ELEMENT_POLIZAS);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(ELEMENT_POLIZAS);
  }

  limpiarTabla() {
    this.dataSource = new MatTableDataSource<PolizasElement>(ELEMENT_POLIZAS);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  resetForm() {
    this.proBuscar = false;
    this.datosGralForm.controls.ramoDG.reset();
    this.datosGralForm.controls.criterio.reset();
    this.datosGralForm.controls.txt_criterio.reset();
    this.datosGralForm.controls.txt_criterio2.reset();
  }

  resetForm2() {
    this.proBuscar = false;
    this.datosGralForm.controls.ramoDG.reset();
    this.datosGralForm.controls.criterio.reset();
    this.datosGralForm.controls.txt_criterio.reset();
  }

  cargaDatos() {
    ELEMENT_POLIZAS = [];
    console.log(respuestaCRM);
    if (
      respuestaCRM['numPoliza'] === null ||
      respuestaCRM.length === 0 ||
      respuestaCRM['cvePool'] === null ||
      respuestaCRM['codigo'] === '00009'
    ) {
      //    console.log("No resultados")
      if (respuestaCRM['cvePool'] === null) {
        this.TxtNot2 = 'El pool es erróneo';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.resetForm();
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      } else {
        this.TxtNot2 = 'Sin resultados';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.resetForm();
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      }
      //}else if(respuestaCRM['numPoliza'] !== null&&respuestaCRM['numPoliza'] !== undefined){
    } else if (respuestaCRM.length !== 0 && respuestaCRM.length > 1) {
      console.log('servicio 1 azul');
      this.proBuscar = false;
      TREE_DATA3 = TREE_DATA3_vacio;
      let cantPolizas = 0;
      let hijas: any;
      this.ramoV = this.datosGralForm.value.ramoDG;
      let contrato = '';
      contrato = this.aux_criterio2;
      this.noContrato = contrato;
      /* if(respuestaCRM['polizas'].length!==0){
  this.proBuscar=false; */
      //  let ifcontratante : Boolean = false;
      /*  for(let poliza of respuestaCRM){
    if(poliza.length>=5){
      ifcontratante=true;
      break;
    }
  }*/
      // if(ifcontratante===true){
      for (let poliza of respuestaCRM) {
        let numpoliza = '';
        numpoliza = poliza['numPoliza'];
        let contratante = '';
        contratante = poliza['nombreContratante'];
        this.intermediario = poliza['nombreIntermediario'];
        let fechaIV = '';
        fechaIV = poliza['fchVigenciaInicial'];
        let fecha_aux = '';
        fecha_aux = this.getFechaPoolAzul(fechaIV);
        if (ELEMENT_POLIZAS.length !== 0) {
          hijas = false;
          cantPolizas = ELEMENT_POLIZAS.length;
          // tslint:disable-next-line: max-line-length
          this.guardarDatos(
            cantPolizas,
            numpoliza,
            this.GastosMedicos,
            contratante,
            this.intermediario,
            fecha_aux,
            hijas,
            contrato
          ); //se añade contrato
        } else {
          hijas = false;
          cantPolizas = ELEMENT_POLIZAS.length;
          // tslint:disable-next-line: max-line-length
          //let fecha_aux='';
          //fecha_aux=this.getFechaPoolAzul(fechaIV);
          this.guardarDatos(
            cantPolizas,
            numpoliza,
            this.GastosMedicos,
            contratante,
            this.intermediario,
            fecha_aux,
            hijas,
            contrato
          ); //se añade contrato
        }
      }
      /* }else{
  this.TxtNot2 = 'Las pólizas no tienen contratante';
  this.notiBuscarP = true;
  this.proBuscar=false
  this.resetForm();
  this.limpiarTabla();
  setTimeout( () => {  this.notiBuscarP = false; }, 5000 );
}*/
      //}
      this.resetForm();
      this.proBuscar = false;
    } else if (respuestaCRM['codigo'] === '00000') {
      console.log('servicio 2 azul');
      this.proBuscar = false;
      this.proBuscar = false;
      TREE_DATA3 = TREE_DATA3_vacio;
      let cantPolizas = 0;
      let hijas: any;
      this.ramoV = this.datosGralForm.value.ramoDG;
      this.intermediario = '';
      let contrato = '';
      let contratante = '';
      let Npoliza = '';
      let fecha_aux = '';
      contratante = '';
      contrato = respuestaCRM['cvePool'];
      this.noContrato = contrato;
      for (let poliza of respuestaCRM['polizas']) {
        Npoliza = poliza['poliza'];
        contratante = poliza['razonSocial'];
        fecha_aux = this.formatofecha(poliza['fecIniVig']);
        if (ELEMENT_POLIZAS.length !== 0) {
          console.log(poliza);
          hijas = false;
          cantPolizas = ELEMENT_POLIZAS.length;
          this.guardarDatos(
            cantPolizas,
            Npoliza,
            this.GastosMedicos,
            contratante,
            this.intermediario,
            fecha_aux,
            hijas,
            contrato
          ); //se añade contrato
        } else {
          hijas = false;
          cantPolizas = ELEMENT_POLIZAS.length;
          // tslint:disable-next-line: max-line-length
          this.guardarDatos(
            cantPolizas,
            Npoliza,
            this.GastosMedicos,
            contratante,
            this.intermediario,
            fecha_aux,
            hijas,
            contrato
          ); //se añade contrato
        }
      }
    }
    //   console.log(contactoJson);
    //listaJson = {tipo: this.tipo, ramo: this.aux_ramo, numConPol: this.noContrato, listaPolizas: this.listaPP};//cambio, guardando el #contrato y polizas padre y el ramo
  }

  backRamo(ramoRest: string): string {
    switch (ramoRest) {
      case 'SA':
        return 'GMM';
      case 'VI':
        return 'Vida';
      case 'AU':
        return 'Autos';
      default:
        return 'N/A';
    }
  }

  aux_ramo2: string = '';

  cargaDatosInfo() {
    TREE_DATA3 = TREE_DATA3_vacio;
    ELEMENT_POLIZAS = [];
    let cantPolizas = 0;
    let ramo_aux = '';
    //console.log(respuestaInfo)
    if (
      respuestaInfo === null ||
      respuestaInfo === [] ||
      respuestaInfo['lstPolPadres'][0]['ramoP'] === ''
    ) {
      //if (respuestaInfo === null || respuestaInfo === []|| respuestaInfo['lstPolPadres'][0]['ramoP']===''||respuestaInfo['noContrato']===''){//posible solucion del falta de contrato
      //  console.log("sin datos la respuesta")
      /* if(respuestaInfo['noContrato']===''){//posible solucion del falta de contrato
   this.TxtNot2 = 'La póliza no tiene contrato';
   this.notiBuscarP = true;
   this.proBuscar=false;
   this.resetForm2();
   this.limpiarTabla();
   setTimeout( () => {  this.notiBuscarP = false; }, 5000 );
 }
 else */
      if (this.aux_ramo === 'GMM' && this.aux_criterio === 'Póliza') {
        console.log('consulta azul GMM pool');
        this.callazulP(this.aux_txt_criterio);
        opcVars = {
          ramo: 'GMM',
          criterio: 'Pool'
        };
      } else {
        // console.log("sin datos la respuesta");
        this.TxtNot2 = 'Sin resultados';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.resetForm2();
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      }
    } else {
      this.proBuscar = false;
      // this.ramoV = this.datosGralForm.value.ramoDG;
      ramo_aux = respuestaInfo['lstPolPadres'][0]['ramoP'];
      if (ramo_aux !== '') {
        this.ramoV = this.backRamo(ramo_aux);
        //this.fecha = respuestaInfo['feInContrato'];
        //this.noContrato = respuestaInfo['noContrato'];
        //this.intermediario =  respuestaInfo['lstPolPadres'][0]['nomIntermediarioP'];
        let hijas: any;
        let ifPolizaVacia = false;
        for (const poliza of respuestaInfo['lstPolPadres']) {
          this.numpoliza = poliza['poPadre'];
          const nombreC = poliza['nomContratanteP'];
          //console.log(poliza['lstPoHija'])
          this.fecha = poliza['feeftonaP'];
          this.intermediario = poliza['nomIntermediarioP'];
          console.log(this.intermediario);
          if (
            poliza['lstPoHija'] === null ||
            poliza['lstPoHija'].length === 0
          ) {
            hijas = false;
          } else {
            hijas = true;
          }
          if (respuestaInfo['noContrato'] === '') {
            //se valida que un no. poliza padre no sea vacio
            this.noContrato = this.aux_criterio3;
          } else {
            this.noContrato = respuestaInfo['noContrato'];
          }

          let fecha_aux = '';
          fecha_aux = this.formatofecha(this.fecha);
          if (this.numpoliza !== null && this.numpoliza !== '') {
            //validacion de poliza no nula
            if (ELEMENT_POLIZAS.length !== 0) {
              cantPolizas = ELEMENT_POLIZAS.length;
              // console.log(cantPolizas,this.numpoliza,this.ramoV,nombreC,this.intermediario,this.fecha)
              this.guardarDatos(
                cantPolizas,
                this.numpoliza,
                this.ramoV,
                nombreC,
                this.intermediario,
                fecha_aux,
                hijas,
                this.noContrato
              ); //se añade contrato
            } else {
              cantPolizas = ELEMENT_POLIZAS.length;
              // console.log(cantPolizas,this.numpoliza,this.ramoV,nombreC,this.intermediario,this.fecha)
              this.guardarDatos(
                cantPolizas,
                this.numpoliza,
                this.ramoV,
                nombreC,
                this.intermediario,
                fecha_aux,
                hijas,
                this.noContrato
              ); //se añade contrato
            }
          } else {
            ifPolizaVacia = true;
          }
        }
        // console.log(ELEMENT_POLIZAS)
        //listaJson = {tipo: this.datosGralForm.value.criterio, ramo: this.ramoV, numConPol: this.numpoliza};
        // listaJson = {tipo: this.datosGralForm.value.criterio, ramo: this.ramoV, numConPol:this.noContrato}; //cambio, pasando el # de contrato
        //  listaJson = {tipo: this.tipo, ramo: this.aux_ramo, numConPol: this.noContrato, listaPolizas: this.listaPP};//cambio, guardando el #contrato y polizas padre y el ramo
        // console.log(ELEMENT_POLIZAS);
        // console.log(listaJson)

        // }
        this.resetForm2();
        this.cargarPolizasHijas();
      } else {
        this.TxtNot2 = 'Sin resultados';
        this.notiBuscarP = true;
        this.resetForm2();
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      }
    }
  }

  cargaDatosInfoC() {
    TREE_DATA3 = TREE_DATA3_vacio;
    this.numpoliza = respuestaInfo['lstPolPadres'][0]['poPadre'];
    this.ramoV = this.datosGralForm.value.criterio;
    const nombre = respuestaInfo['lstPolPadres'][0]['nomContratanteP'];
    this.fecha = respuestaInfo['feInContrato'];
    let fecha_aux = '';
    fecha_aux = this.formatofecha(this.fecha);
    this.intermediario = '';
    let cantPolizas = 0;
    this.proBuscar = false;
    if (ELEMENT_POLIZAS.length !== 0) {
      for (const polizas of ELEMENT_POLIZAS) {
        if (polizas.poliza === this.numpoliza) {
          this.TxtNot2 = this.TxtNot10;
          this.notiBuscarP = true;
          setTimeout(() => {
            this.notiBuscarP = false;
            this.notiInfo2();
          }, 5000);
        } else {
          cantPolizas = ELEMENT_POLIZAS.length;
          this.guardarDatos(
            cantPolizas,
            this.numpoliza,
            this.ramoV,
            nombre,
            this.intermediario,
            fecha_aux
          );
        }
      }
    } else {
      cantPolizas = ELEMENT_POLIZAS.length;
      this.guardarDatos(
        cantPolizas,
        this.numpoliza,
        this.ramoV,
        nombre,
        this.intermediario,
        fecha_aux
      );
    }
  }

  // funcion para validar los datos de busqueda de polizas
  tipoRamo() {
    // Para refrescar los checks de la sección de configuracion de armado
    opcRamoSelec = this.datosGralForm.value.ramoDG;
    if (this.datosGralForm.value.ramoDG === this.GastosMedicos) {
      this.criterios = this.criteriosGMM;
    } else {
      this.criterios = this.criteriosOtros;
    }
  }

  callazul(numPo: string) {
    this.servicioAzul.consultaAzul(numPo).subscribe(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          //console.log("codigo 200", response.body);
          respuestaCRM = response.body;
          this.cargaDatos();
        } else if (response.status === 500) {
          //  console.log("servicio no disponible");
          respuestaCRM = [];
        } else if (response.status === 202) {
          //   console.log("solicitud sin resultados");
          respuestaCRM = [];
        } else if (response.status === 204) {
          //21072020
          console.log('busqueda sin resultados 204');
          this.mensaje204('Sin resultados');
        }
      },
      (error) => {
        if (error.status === 500) {
          this.mensaje500();
        }
      }
    );
  }

  callazulP(numPo: string) {
    console.log(numPo);
    this.servicioAzul.consultaAzulP(numPo).subscribe(
      (response) => {
        console.log(response);
        if (response.status === 200) {
          //console.log("codigo 200", response.body);
          respuestaCRM = response.body;
          this.cargarDAtosPoliAzul();
        } else if (response.status === 500) {
          // console.log("servicio no disponible");
          respuestaCRM = [];
        } else if (response.status === 202) {
          // console.log("solicitud sin resultados");
          respuestaCRM = [];
        } else if (response.status === 204) {
          //21072020
          console.log('busqueda sin resultados 204');
          this.mensaje204('Sin resultados');
        }
      },
      (error) => {
        if (error.status === 500) {
          this.mensaje500();
        }
        if (error.status === 504) {
          this.mensaje500();
        }
      }
    );
  }

  callInfoPol(numPo: string, ramo: string) {
    this.servicioAzul.consultaConPolP(numPo, ramo).subscribe(
      (response) => {
        if (response.status === 200) {
          console.log('codigo 200', response.body);
          respuestaInfo = response.body;
          localStorage.setItem(
            'polizaPadre',
            response.body.lstPolPadres[0].poPadre
          );
          this.cargaDatosInfo();
        } else if (response.status === 202) {
          //21072020
          console.log('busqueda sin resultados 202');
          localStorage.setItem('polizaPadre', null);
          this.mensaje202('Póliza errónea');
        } else if (response.status === 204) {
          //21072020
          localStorage.setItem('polizaPadre', null);
          console.log('busqueda sin resultados 204');
          this.mensaje500();
        }
      },
      (error) => {
        localStorage.setItem('polizaPadre', null);
        if (error.status === 500) {
          respuestaInfo = null;
          this.cargaDatosInfo();
        }
        if (error.status === 504) {
          this.mensaje500();
        }
      }
    );
  }

  callInfoCon(contra: string, ramo: string) {
    this.servicioAzul.consultaConPolC(contra, ramo).subscribe(
      (response) => {
        if (response.status === 200) {
          console.log('codigo 200', response.body);
          respuestaInfo = response.body;
          this.cargaDatosInfo();
        } else if (response.status === 202) {
          //21072020
          console.log('busqueda sin resultados 202');
          this.mensaje202('Contrato erróneo');
        } else if (response.status === 204) {
          //21072020
          console.log('busqueda sin resultados 204');
          this.mensaje500();
        }
      },
      (error) => {
        if (error.status === 500) {
          this.mensaje500();
        }
        if (error.status === 504) {
          this.mensaje500();
        }
      }
    );
  }

  // tslint:disable-next-line: member-ordering
  // tslint:disable-next-line: variable-name
  aux_criterio2 = ''; // variable para guardar el valor del criterio de busqueda
  aux_criterio3 = ''; // variable para guardar el valor del criterio de busqueda
  onSubmitDG() {
    this.aux_criterio = ''; ///reset de la variable, para que haga una busqueda
    this.aux_criterio2 = '';
    this.aux_ramo = '';
    this.aux_ramo2 = '';
    this.aux_txt_criterio = '';
    this.aux_criterio3 = '';
    this.selection.clear();
    let numP = '';
    opcVars = {
      ramo: this.datosGralForm.value.ramoDG,
      criterio: this.datosGralForm.value.criterio
    };
    this.tipo = this.datosGralForm.value.criterio;
    console.log();
    /*    if (this.datosGralForm.value.criterio === 'Pool'){
 // this.aux_ConfgArm.prototype.carpetas = [{nombre: 'Pool', tipo: 'Pool', nivel: '1', filelist: [], subcarpeta: []}];
  carpetaBase = {nombre: 'Pool', tipo: 'Pool', nivel: '1', filelist: [], subcarpeta: []};
  //opcRamo = [{name: 'Póliza'}, {name: 'Certificado'}, {name: 'Anexos'}];
}else if (this.datosGralForm.value.criterio === 'Póliza' || this.datosGralForm.value.criterio === 'Contrato'){
  // this.aux_ConfgArm.prototype.carpetas = [{nombre: 'Contrato', tipo: 'Contrato', nivel: '1', filelist: [], subcarpeta: []}];
  carpetaBase = {nombre: 'Contrato', tipo: 'Contrato', nivel: '1', filelist: [], subcarpeta: []};
  //opcRamo = [{name: 'Póliza Padre'}, {name: 'Póliza Hija'}, {name: 'Adjuntos'}];
}*/
    this.notiBuscarP = false;
    // tslint:disable-next-line: max-line-length
    if (
      (this.datosGralForm.value.ramoDG === '' ||
        this.datosGralForm.value.ramoDG === null) &&
      (this.datosGralForm.value.criterio === '' ||
        this.datosGralForm.value.criterio === null) &&
      (this.datosGralForm.value.txt_criterio === '' ||
        this.datosGralForm.value.txt_criterio === null)
    ) {
      this.TxtNot2 = this.TxtNot7;
      this.notiBuscarP = true;
      setTimeout(() => {
        this.notiBuscarP = false;
        this.notiInfo2();
      }, 5000);
    } else {
      this.notiBuscarP = false;
      if (
        this.datosGralForm.value.ramoDG !== '' &&
        this.datosGralForm.value.ramoDG !== null
      ) {
        // console.log("ramo correcto");
        this.aux_ramo2 = this.datosGralForm.value.ramoDG;
        this.aux_ramo = this.datosGralForm.value.ramoDG;
        if (
          this.datosGralForm.value.criterio !== '' &&
          this.datosGralForm.value.criterio !== null
        ) {
          //  console.log("criterio correcto");
          if (this.datosGralForm.value.txt_criterio !== '') {
            this.aux_txt_criterio = this.datosGralForm.value.txt_criterio;
            numP = this.datosGralForm.value.txt_criterio;
            this.aux_criterio2 = this.datosGralForm.value.txt_criterio; // obtengo el valor de la busqueda para azulPoli
            this.auxTxtCriterioCeros =
              this.datosGralForm.value.txt_criterio.padStart(14, '0');
            this.aux_criterio3 = this.datosGralForm.value.txt_criterio;
            if (this.datosGralForm.value.ramoDG === this.GastosMedicos) {
              if (this.datosGralForm.value.criterio === this.Pool) {
                this.proBuscar = true;
                this.callazul(numP); // colsulta de azul por pool
                this.formGroupDirective.resetForm();
              } else if (this.datosGralForm.value.criterio === 'Póliza') {
                this.aux_criterio = this.datosGralForm.value.criterio;
                // this.aux_ramo=this.datosGralForm.value.ramoDG;
                let auxRam: string;

                switch (this.datosGralForm.value.ramoDG) {
                  case 'Autos':
                    //auxRam = 'AU';
                    auxRam = this.CaseRamos[0].ramo;
                    break;
                  case 'GMM':
                    //auxRam = 'SA';
                    auxRam = this.CaseRamos[1].ramo;
                    break;
                  case 'Vida':
                    //auxRam = 'VI';
                    auxRam = this.CaseRamos[2].ramo;
                    break;
                }
                this.proBuscar = true;
                this.callInfoPol(this.auxTxtCriterioCeros, auxRam);
                this.formGroupDirective.resetForm();
              } else if (this.datosGralForm.value.criterio === 'Contrato') {
                console.log('GMM por contrato');
                let auxRam: string;
                //this.aux_criterio3=this.datosGralForm.value.txt_criterio;

                switch (this.datosGralForm.value.ramoDG) {
                  case 'Autos':
                    //auxRam = 'AU';
                    auxRam = this.CaseRamos[0].ramo;
                    break;
                  case 'GMM':
                    auxRam = this.CaseRamos[1].ramo;
                    //auxRam = 'SA';
                    break;
                  case 'Vida':
                    //auxRam = 'VI';
                    auxRam = this.CaseRamos[2].ramo;
                    break;
                }

                console.log('GMM contrato' + this.datosGralForm.value.ramoDG);
                this.proBuscar = true;
                this.callInfoCon(numP, auxRam);
                this.formGroupDirective.resetForm();
              }
            } else {
              let auxRam: string;

              switch (this.datosGralForm.value.ramoDG) {
                case 'Autos':
                  //auxRam = 'AU';
                  auxRam = this.CaseRamos[0].ramo;
                  break;
                case 'GMM':
                  //auxRam = 'SA';
                  auxRam = this.CaseRamos[1].ramo;
                  break;
                case 'Vida':
                  //auxRam = 'VI';
                  auxRam = this.CaseRamos[2].ramo;
                  break;
              }

              if (this.datosGralForm.value.criterio === 'Contrato') {
                // console.log('otro contrato' + this.datosGralForm.value.ramoDG);
                this.proBuscar = true;
                this.callInfoCon(numP, auxRam);
                this.formGroupDirective.resetForm();
              } else if (this.datosGralForm.value.criterio) {
                console.log('otro poliza' + this.datosGralForm.value.ramoDG);
                this.proBuscar = true;
                this.callInfoPol(this.auxTxtCriterioCeros, auxRam);
                this.formGroupDirective.resetForm();
              }
            }
          } else {
            //   console.log("El campo texto criterio no fue llenado")
            this.TxtNot2 = this.TxtNot4;
            this.notiBuscarP = true;
            setTimeout(() => {
              this.notiBuscarP = false;
              this.notiInfo2();
            }, 5000);
          }
        } else {
          // console.log("El campo de criterio no fue llenado")
          this.TxtNot2 = this.TxtNot5;
          this.notiBuscarP = true;
          setTimeout(() => {
            this.notiBuscarP = false;
            this.notiInfo2();
          }, 5000);
        }
      } else {
        //  console.log("El campo de Ramo no fue llenado")
        this.TxtNot2 = this.TxtNot6;
        this.notiBuscarP = true;
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      }
    }

    // setTimeout(() => this.formGroupDirective.resetForm(), 200);
  }

  // funcion que guarda las polizas hijas de las busquedas
  cargarPolizasHijas() {
    // console.log(respuestaInfo);
    // console.log(histoPolizaH.length);
    let pos = 0;
    pos = histoPolizaH.length;
    for (const ph of respuestaInfo['lstPolPadres']) {
      if (ph['lstPoHija'] === null) {
        console.log('no tiene poliza hija');
      } else {
        for (const hija of ph['lstPoHija']) {
          pos = histoPolizaH.length;
          this.aux_Phija = {
            position: pos + 1,
            poliza: hija['poHija'],
            poliza_padre: ph['poPadre']
          };
          histoPolizaH[pos] = this.aux_Phija;
        }
      }
    }
    // console.log(histoPolizaH)
  }

  /***Carga las polizas padre e hijas en la tabla de resumen   */
  mostrarPolizasH(): void {
    this.isSelectPolizaP();
    this.dataSource3.data = TREE_DATA3;
    // TREE_DATA3 = TREE_DATA3_vacio;
    this.obtenerPadre(); // se obtinen las polizas padre seleccionadas
    console.log(ListaJSON);
  }

  isSelectPolizaP() {
    // this.dataSource3.data = [];
    // TREE_DATA3 = [];
    let longLista = 0;
    let numSelected = 0; //posible solucion a carga polizas cuando no hay se seleccionoalguna pero si carga //////////////////**** indicnedia 1 */
    numSelected = this.selection.selected.length;
    if (numSelected === 0) {
      console.log('Lista vacia');
      this.notiError(this.TxtNot3);
      //TREE_DATA3 = TREE_DATA3_vacio;
      //TREE_DATA3 = [];*****opcion 1 para incidencia 1
      const newFormulalist = TREE_DATA3.filter(
        (v, i) => TREE_DATA3.findIndex((item) => item.name === v.name) === i
      ); ///*****opcion 1 para incidencia 1
      TREE_DATA3 = newFormulalist;
      this.notiPolizasH = true;
    } else {
      this.notiPolizasH = false;
      let cont = 0;
      let ifSelecPoliza = false;
      console.log(TREE_DATA3);
      for (const poliza of ELEMENT_POLIZAS) {
        cont = TREE_DATA3.length;
        longLista = ListaJSON.length;
        // if (this.selection.isSelected(poliza) === true &&poliza.ifHijas!==false) {
        for (let polizaPH of this.dataSource3.data) {
          if (this.selection.isSelected(poliza)) {
            console.log('poliza ya seleccionada');
            if (poliza.poliza === polizaPH.name) {
              console.log(poliza.poliza, polizaPH.name);
              ifSelecPoliza = true;
              this.notiError('La póliza ya fue seleccionada');
              //break;
            } else {
              ifSelecPoliza = false;
            }
          }
        }
        console.log(ifSelecPoliza);
        if (ifSelecPoliza === false) {
          if (this.selection.isSelected(poliza)) {
            if (poliza.ifHijas === false) {
              console.log('la poliza no tiene poliza hijas');
              this.notiInfo(poliza.poliza);
              PoPH = { name: poliza.poliza, children: [] };
              TREE_DATA3[cont] = PoPH;
              //this.obtenerPadre();
              // listaJson={tipo:this.tipo,ramo:poliza.ramo,numConPol:poliza.noContrato,listaPolizas:TREE_DATA3}
              // ListaJSON[longLista]=listaJson;
            } else {
              let cont2 = 0;
              TREE_DATA3_aux = [];
              // for (let polizaH of ELEMENT_POLIZASH) {
              for (const polizaH of histoPolizaH) {
                if (polizaH.poliza_padre === poliza.poliza) {
                  TREE_DATA3_aux[cont2] = PoPH_ch = {
                    name: polizaH.poliza
                  };
                  PoPH = {
                    name: poliza.poliza,
                    children: TREE_DATA3_aux
                  };
                  cont2 = cont2 + 1;
                }
              }
              //listaJson={tipo:this.tipo,ramo:poliza.ramo,numConPol:poliza.poliza,listaPolizas:TREE_DATA3_aux}
              //this.obtenerPadre();
              //listaJson={tipo:this.tipo,ramo:poliza.ramo,numConPol:poliza.noContrato,listaPolizas:TREE_DATA3}
              //ListaJSON[longLista]=listaJson;
              TREE_DATA3[cont] = PoPH;
              console.log('Arreglo final ', TREE_DATA3);
              // cont = cont + 1;
            }
          }
        } else if (ifSelecPoliza === true) {
          console.log('ya fue 2');
          this.notiError('La póliza ya fue seleccionada');
        }
      }

      const newFormulalist = TREE_DATA3.filter(
        (v, i) => TREE_DATA3.findIndex((item) => item.name === v.name) === i
      );
      TREE_DATA3 = newFormulalist;
    }
    console.log(TREE_DATA3);
    console.log(ListaJSON);
  }

  notiInfo(poliza: string) {
    this.notiPol = false;
    this.notiPolizasH = false;
    this.TxtNot12 = 'La póliza ' + poliza + ' no tiene pólizas hijas';
    this.notiPol = true;
    //setTimeout( () => {  this.notiPolizasH2 = false; }, 5000 );
    setTimeout(() => {
      this.notiPol = false;
      this.notiInfo2();
    }, 5000);
  }

  notiInfo2() {
    const element: HTMLElement = document.getElementById(
      'botonOculto'
    ) as HTMLElement;
    element.click();
    this.notiPol = false;
  }

  notiError(msn: string) {
    this.notiPol = false;
    console.log('soy notierror');
    this.TxtNot11 = msn;
    this.notiPolizasH = true;
    //setTimeout( () => {  this.notiPolizasH = false; this.notiInfo2()}, 5000 );
  }

  cancel() {
    console.log('Cancelar Seccion : ' + this.treeControl.dataNodes.length);
    // TREE_DATA3_vacio=[];
    // TREE_DATA3 = TREE_DATA3_vacio;
    // this.dataSource3.data = TREE_DATA3;
    window.location.assign('/');
    this.selection.clear();
    // this.treeControl.dataNodes.splice(1, this.treeControl.dataNodes.length);
  }

  removeSelectedRows() {
    this.dataSource.data.forEach((row) => this.selection.select(row));
    this.selection.selected.forEach((item) => {
      let index: number = this.data.findIndex((d) => d === item);
      this.data.splice(index, 1);
      this.dataSource = new MatTableDataSource<PolizasElement>(this.data);
    });
    this.selection = new SelectionModel<PolizasElement>(true, []);
  }

  notiPol: Boolean;

  continuarDG() {
    // const numSelected = this.selection.selected.length;
    //if (numSelected === 0 ) {
    console.log('Lista vacia');
    this.notiError(this.TxtNot3);
    TREE_DATA3 = TREE_DATA3_vacio;
    this.notiPolizasH = true;
    /*} /*else if ( this.tipoImpresion.value.opcTipo === undefined  ){
  console.log('No selecciono tipo impresion');
  this.notiError(this.TxtNot50);
  // TREE_DATA3 = TREE_DATA3_vacio;
  // this.notiPolizasH = true;
}else {
  this.expandPanelDatGralesLoc = false;
  this.disablePanelDatGralesLoc = true;
  expandPanelConfArm = true;
  disablePanelConfArm = true;
  console.log(ListaJSON);
  const element: HTMLElement = document.getElementById('panelConfArmado') as HTMLElement;
  element.click();
  const elementH: HTMLElement = document.getElementById('panelConfArmadoH') as HTMLElement;
  elementH.click();

}*/
  }

  selectImpresion() {
    console.log(this.tipoImpresion.value.opcTipo);
    if (this.tipoImpresion.value.opcTipo === this.tiposImpr[0]) {
      // fisica
      if (this.armadoFenable === undefined || this.armadoFenable === false) {
        this.armadoFenable = true;
        armadoJson = { digital: false, fisico: true };
        //  this.tipoImpresion.controls.armadoD.disable();

        if (
          Paneles.panelF === '' ||
          Paneles.panelF === undefined ||
          Paneles.panelF === false
        ) {
          Paneles = { panelD: false, panelF: true };
        } else {
          Paneles = { panelD: false, panelF: false };
        }
        // console.log(Paneles)
      } else {
        if (
          Paneles.panelF === '' ||
          Paneles.panelF === undefined ||
          Paneles.panelF === false
        ) {
          Paneles = { panelD: false, panelF: true };
        } else {
          Paneles = { panelD: false, panelF: false };
        }
        this.armadoFenable = false;
        // this.tipoImpresion.controls.armadoD.enable();
      }
    } else {
      // digital
      if (this.armadoDenable === undefined || this.armadoDenable === false) {
        armadoJson = { digital: true, fisico: false };
        this.armadoDenable = true;
        // this.tipoImpresion.controls.armadoF.disable();
        if (
          Paneles.panelD === '' ||
          Paneles.panelD === undefined ||
          Paneles.panelD === false
        ) {
          Paneles = { panelD: true, panelF: false };
        } else {
          Paneles = { panelD: false, panelF: false };
        }
      } else {
        if (
          Paneles.panelD === '' ||
          Paneles.panelD === undefined ||
          Paneles.panelD === false
        ) {
          Paneles = { panelD: true, panelF: undefined };
        } else {
          Paneles = { panelD: false, panelF: undefined };
        }
        this.armadoDenable = false;
        // this.tipoImpresion.controls.armadoF.enable();
      }
    }
  }

  obtenerHijas(pPadre: string): string[] {
    let cant = 0;
    let pos = 0;
    let listaPHijas: string[] = [];
    for (let poliza of TREE_DATA3) {
      if (poliza.name === pPadre) {
        for (let hijas of poliza.children) {
          cant = listaPHijas.length;
          listaPHijas[cant] = hijas.name;
        }
      }
    }
    return listaPHijas;
  }

  listaPP: string[] = [];
  ramo_auxM: string;
  ConPool: string;

  obtenerPadre(contrato?: string) {
    //funcion para obtener las polizas padre selecionadas
    const numSelected = this.selection.selected.length;
    let respuesta: string[] = [];
    this.listaPP = [];
    let longLista = 0;
    if (numSelected === 0) {
      console.log('Lista vacia');
      this.notiError(this.TxtNot3);
      this.listaPP = [];
      //return [];
    } else {
      this.notiPolizasH = false;
      let cont = 0;
      for (const poliza of ELEMENT_POLIZAS) {
        cont = this.listaPP.length;
        this.ConPool = poliza.noContrato;
        this.ramo_auxM = poliza.ramo;
        if (this.selection.isSelected(poliza)) {
          let numero = 0;
          numero = Number(poliza.poliza);
          console.log(numero);
          let cadena = '';
          cadena = numero.toString();
          //this.listaPP[cont]=poliza.poliza;
          this.listaPP[cont] = cadena;
        }
      }
      const newFormulalist = this.listaPP.filter(
        (v, i) => this.listaPP.findIndex((item) => item === v) === i
      );
      this.listaPP = newFormulalist;
      //return respuesta;
      longLista = ListaJSON.length;
      listaJson = {
        tipo: this.tipo,
        ramo: this.ramo_auxM,
        numConPol: this.ConPool,
        listaPolizas: this.listaPP,
        id: longLista + 1
      };

      ListaJSON[longLista] = listaJson;
      //ListaJSON=ListaJSON.reverse();//DEFECTO DE QUE NO ACTILIZA LA LOQ EU SELECIONA DE POLIZAAS PADRE
      const newFormulalist2 = ListaJSON.filter(
        (v, i) =>
          ListaJSON.findIndex(
            (item) => item.ramo === v.ramo && item.numConPol === v.numConPol
          ) === i
      );
      ListaJSON = newFormulalist2;
    }
  }

  cargarDAtosPoliAzul() {
    ELEMENT_POLIZAS = [];
    console.log(respuestaCRM);
    if (
      (respuestaCRM['descripcion'] !== 'EXITO' &&
        respuestaCRM['cveRamo'] === '') ||
      respuestaCRM === [] ||
      respuestaCRM.length === 0 ||
      respuestaCRM['cvePool'] === '' ||
      respuestaCRM['contratante'] === null
    ) {
      if (respuestaCRM.length === 0) {
        this.TxtNot2 = 'Uno de los criterios fue erróneo';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      } else if (respuestaCRM['cvePool'] === '') {
        this.TxtNot2 = 'La póliza no tiene pool';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      } else if (respuestaCRM['contratante'] === null) {
        this.TxtNot2 = 'La póliza no tiene contratante';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      } else {
        this.TxtNot2 = 'Sin resultados';
        this.notiBuscarP = true;
        this.proBuscar = false;
        this.limpiarTabla();
        setTimeout(() => {
          this.notiBuscarP = false;
          this.notiInfo2();
        }, 5000);
      }
    } else if (respuestaCRM['descripcion'] === 'EXITO') {
      console.log('exito');
      this.proBuscar = false;
      TREE_DATA3 = TREE_DATA3_vacio;
      let cantPolizas = 0;
      let hijas: any;
      this.ramoV = this.datosGralForm.value.ramoDG;
      this.intermediario = '';
      let contrato: '';
      let razon = '';
      let fechaIni: '';
      this.tipo = 'Pool'; //modificacion 21072020
      razon = respuestaCRM['razonSocial'];
      razon = razon.trim();
      contrato = respuestaCRM['pool'];
      this.noContrato = contrato;
      fechaIni = respuestaCRM['iniVig'];
      let fecha_aux = '';
      fecha_aux = this.formatofecha(fechaIni);
      if (ELEMENT_POLIZAS.length !== 0) {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          this.aux_criterio2,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fecha_aux,
          hijas,
          contrato
        ); //se añade contrato
      } else {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          this.aux_criterio2,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fecha_aux,
          hijas,
          contrato
        ); //se añade contrato
      }
      this.resetForm();
    } else if (
      /* if(respuestaCRM['cveRamo']===''){
console.log("primera busqueda 3")
this.TxtNot2 = 'Sin resultados';
this.notiBuscarP = true;
this.proBuscar=false;
this.limpiarTabla();
} */
      respuestaCRM['cveRamo'] !== '' &&
      respuestaCRM['cvePool'] !== '' &&
      respuestaCRM['cveSistema'] === 'AZUL'
    ) {
      console.log('primera busqueda 4');
      this.proBuscar = false;
      let cantPolizas = 0;
      let hijas: any;
      this.ramoV = this.datosGralForm.value.ramoDG;
      this.intermediario = respuestaCRM['nombreIntermediario'];
      let contrato: '';
      let razon = '';
      let fechaIni2: '';
      let poliza: '';
      this.tipo = 'Pool';
      razon = respuestaCRM['contratante']['razonSocial'];
      //razon=razon.trim();
      if (razon === '') {
        razon =
          respuestaCRM['contratante']['nombres'] +
          ' ' +
          respuestaCRM['contratante']['apellidoPaterno'] +
          ' ' +
          respuestaCRM['contratante']['apellidoMaterno'];
      }
      contrato = respuestaCRM['cvePool'];
      this.noContrato = contrato;
      fechaIni2 = respuestaCRM['fchVigenciaInicial'];
      poliza = respuestaCRM['numPoliza'];

      if (ELEMENT_POLIZAS.length !== 0) {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          poliza,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fechaIni2,
          hijas,
          contrato
        ); //se añade contrato
      } else {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          poliza,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fechaIni2,
          hijas,
          contrato
        ); //se añade contrato
      }
      this.resetForm();
    } else if (
      respuestaCRM['cveRamo'] !== '' &&
      respuestaCRM['cvePool'] !== '' &&
      respuestaCRM['cveSistema'] === 'INFO'
    ) {
      console.log('primera busqueda 5');
      this.proBuscar = false;
      let cantPolizas = 0;
      let hijas: any;
      this.ramoV = this.datosGralForm.value.ramoDG;
      this.intermediario = respuestaCRM['nombreIntermediario'];
      let contrato: '';
      let razon = '';
      let fechaIni2: '';
      let poliza: '';
      razon = respuestaCRM['contratante']['razonSocial'];
      razon = razon.trim();
      if (razon === '') {
        razon =
          respuestaCRM['contratante']['nombres'] +
          ' ' +
          respuestaCRM['contratante']['apellidoPaterno'] +
          ' ' +
          respuestaCRM['contratante']['apellidoMaterno'];
      }
      contrato = respuestaCRM['cvepool'];
      this.noContrato = contrato;
      fechaIni2 = respuestaCRM['fchVigenciaInicial'];
      poliza = respuestaCRM['numPoliza'];

      if (ELEMENT_POLIZAS.length !== 0) {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          poliza,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fechaIni2,
          hijas,
          contrato
        ); //se añade contrato
      } else {
        hijas = false;
        cantPolizas = ELEMENT_POLIZAS.length;
        this.guardarDatos(
          cantPolizas,
          poliza,
          this.GastosMedicos,
          razon,
          this.intermediario,
          fechaIni2,
          hijas,
          contrato
        ); //se añade contrato
      }
      this.resetForm();
    }

    //listaJson = {tipo: this.ti  po, ramo: this.aux_ramo, numConPol: this.noContrato, listaPolizas: this.listaPP};//cambio, guardando el #contrato y polizas padre y el ramo
  }

  formatofecha(fecha: string): string {
    let dia: string;
    let mes: string;
    let anio: string;
    dia = fecha.slice(6, 8);
    mes = fecha.slice(4, 6);
    anio = fecha.slice(0, 4);
    return dia + '/' + mes + '/' + anio;
  }

  getFechaPoolAzul(fecha: string): string {
    let fecha_aux: number;
    fecha_aux = Number(fecha);
    let fecha_fort;
    fecha_fort = new Date(fecha_aux);
    let dia = '';
    let mes = '';
    let anio = '';
    dia = fecha_fort.getDate();
    mes = fecha_fort.getMonth();
    anio = fecha_fort.getFullYear();
    return dia + '/' + mes + '/' + anio;
  }

  mensaje500() {
    this.TxtNot2 = 'Sin resultados';
    this.notiBuscarP = true;
    this.proBuscar = false;
    this.limpiarTabla();
    setTimeout(() => {
      this.notiBuscarP = false;
      this.notiInfo2();
    }, 5000);
  }

  mensaje202(msn: string) {
    this.TxtNot2 = msn;
    this.notiBuscarP = true;
    this.proBuscar = false;
    this.limpiarTabla();
    setTimeout(() => {
      this.notiBuscarP = false;
      this.notiInfo2();
    }, 5000);
  }

  mensajePolizasNull(msn: string) {
    this.TxtNot2 = msn;
    this.notiBuscarP = true;
    //this.proBuscar=false;
    //this.limpiarTabla();
    setTimeout(() => {
      this.notiBuscarP = false;
      this.notiInfo2();
    }, 5000);
  }

  limpiarTabla2() {
    this.dataSource = new MatTableDataSource<PolizasElement>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    console.log(this.dataSource.data);
  }

  mensaje204(msn: string) {
    this.TxtNot2 = msn;
    this.notiBuscarP = true;
    this.proBuscar = false;
    this.limpiarTabla2();
    setTimeout(() => {
      this.notiBuscarP = false;
      this.notiInfo2();
    }, 5000);
  }
}
