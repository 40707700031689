import { Component } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { appSettings } from '../app/appSettings';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  datoscrm: any[] = [];
  datosAny: any;
  title = 'ARMADO FASE 4';
  constructor(protected http: HttpClient) {
    //let ltpa = '';
    //ltpa=this.getCookieByName();
    //ltpa="LtpaToken2=H8c9AbUeSMfoj4VVI2rIyVwi/tRaV6m9l/vWvNu/poK461t1tGeQg2zPrLKoONKrFcQ7FRpi8xgb90fzZNIMxDW5TkmKDAkkQClnF7zo34xvBZrzNFzgj4IosJBLcBmrHM4+az2wGyK9kgq/qOvp588J0RJlj42+nu9SVKERWxvkiwvdx4B7Egr3jG1nrudw+ZEUGiJyKpr819cUVx5aF+vyvo+gEfIvWkBr6g2NntJFCQ9UweNDQP4ftQ7WsLGh5HAH+BJRS9kWGODcHuMfmzAr9PODdqjFA89uM49I5wCfahHmB18dswlyiSrXBIKpDzJllRDCQvR+wW9qkejQPElDd0lKFMFbRfAshMTImcXcGzd2TPeiqhulpLn0jFQK9oFR/4tIkqunbDMfFanMag==";
    //this.callServiceToken(ltpa);
    //--linea extra
  }
  ifToken: Boolean;
  ifResp: Boolean = false;
  nameCookie = 'LtpaToken2';
  valorLtpaToken = '';
  setCookie(name: string, value: string, days: number) {
    let expires = '';
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  }
  getCookieByName(): string {
    //funcion para obtener de las cookies el ltpatoken
    let lasCookies = '';
    lasCookies = document.cookie;
    let ArrayCookies: Array<string>;
    ArrayCookies = lasCookies.split(';');
    for (let ck of ArrayCookies) {
      if (ck.includes(this.nameCookie)) {
        this.valorLtpaToken = ck;
        return ck;
      }
    }
  }
  callServiceToken(ltpaToken: string) {
    ///metodo para enviar el lpatoken
    this.GetToken(ltpaToken).subscribe(
      (response) => {
        //console.log('respuesta');
        //console.log(response);
        if (response.status === 200) {
          console.log('codigo 200', response.body);
          this.ifToken = true;
          this.ifResp = true;
        } else if (response.status === 202) {
          console.log('sin token - 202');
          this.ifToken = false;
          this.ifResp = true;
        } else if (response.status === 204) {
          console.log('sin token - 204');
          this.ifToken = false;
          this.ifResp = true;
        }
      },
      (error) => {
        //console.log(error)
        if (error.status === 200) {
          console.log('Parametros incorrectos - 200');
          this.ifToken = false;
          this.ifResp = true;
        }
        if (error.status === 400) {
          console.log('Faltan parametros - 400');
          this.ifToken = false;
          this.ifResp = true;
        }
        if (error.status === 500) {
          console.log('sin token - 500');
          this.ifToken = false;
          this.ifResp = true;
        }
      }
    );
  }
  GetToken(LtpaToken: string): Observable<any> {
    //funcion para pasar el ltpa token en la cabecera
    let headers = new HttpHeaders();
    let parametros = new HttpParams();
    parametros = parametros.append('cadena', LtpaToken);
    return this.http.get(appSettings.URL_OT21, {
      headers: headers,
      params: parametros,
      observe: 'response'
    });
    //return this.http.get('http://localhost:8080/Solicitud', {headers:headers,params:parametros,observe: 'response'});
  }
}
