import { environment } from 'src/environments/environment';

export class appSettings {
  public static URL_OT = environment.apiUrlArmado + '/GuardaArchIBS';

  public static URL_OT2 = environment.apiUrlArmado + '/GuardaArchIBS';
  public static URL_OT3 = environment.apiUrlArmado + '/getRamos';
  public static URL_OT4 = environment.apiUrlArmado + '/getDocAutos';
  public static URL_OT5 = environment.apiUrlArmado + '/getDocVida';
  public static URL_OT6 = environment.apiUrlArmado + '/getDocGMM';
  public static URL_OT7 = environment.apiUrlArmado + '/getDocGMMAzul';
  public static URL_OT8 = environment.apiUrlArmado + '/getCarpAzul';
  public static URL_OT9 = environment.apiUrlArmado + '/getCarpInfo';
  public static URL_OT10 = environment.apiUrlArmado + '/getVarAuto';
  public static URL_OT11 = environment.apiUrlArmado + '/getVarGMM';
  public static URL_OT12 = environment.apiUrlArmado + '/getVarVida';
  public static URL_OT13 = environment.apiUrlArmado + '/getVarGMMAzul';
  public static URL_OT14 = environment.apiUrlArmado + '/getCodigo';
  public static URL_OT15 = environment.apiUrlArmado + '/getAllcodigo';
  public static URL_OT16 = environment.apiUrlArmado + '/getCaseRamos';
  public static URL_OT18 = environment.apiUrlArmado + '/getDocRamo';
  public static URL_OT19 = environment.apiUrlArmado + '/getCarpRamo';
  public static URL_OT20 = environment.apiUrlArmado + '/getVarRamo';
  public static URL_OT21 = environment.apiUrlArmado + '/Solicitud';
  public static URL_CARGA_DOCUMENTO =
    environment.apiUrlEmpate;
}
