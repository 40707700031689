import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  armadoJson,
  DatosgralComponent,
  ListaJSON,
  opcRamoSelec,
  Paneles
} from '../datosgral/datosgral.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EventEmitterService } from '../event-emitter.service';
import {
  ConfarmadoComponent,
  datosfisico,
  estrucM,
  OtrosArchivos
} from '../confarmado/confarmado.component';
import { NotificacionComponent } from '../notificacion/notificacion.component';
import { ValidatorService } from '../validator.service';
import { resultOT } from '../models/resultOT';
import { OTModel } from '../models/OTModel';
import { HttpClient } from '@angular/common/http';
import { resultFile } from '../models/resultFile';
import { appSettings } from '../appSettings';
import { EmpateDocumentacionService } from '../services/empate/empate-documentacion.service';

interface DatosNoti {
  nombre: string;
  email: string[];
  celular: string[];
}
let datosNoti: DatosNoti = {
  nombre: '',
  email: null,
  celular: null
};

interface listadoA {
  tipo: string;
  ramo: string;
  numConPol: string;
  listaPolizas: any[];
  armadoEstructura: any[];
}
let listado: listadoA = {
  tipo: '',
  ramo: '',
  numConPol: '',
  listaPolizas: [],
  armadoEstructura: []
};
let listadoM: listadoA[] = [];

interface jsonFisico {
  idOrden: string;
  armados: any;
  fisico: any;
}

interface jsonDigital {
  idOrden: string;
  armados: any;
  digital: any;
}
let jsonF: jsonFisico = {
  idOrden: '',
  armados: {},
  fisico: {}
};
let jsonD: jsonDigital = {
  idOrden: '',
  armados: {},
  digital: {}
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [MatSnackBar]
})
export class HomeComponent implements OnInit, AfterViewChecked {
  @ViewChild(DatosgralComponent) panelGral: DatosgralComponent;
  @ViewChild(ConfarmadoComponent) panelCA: ConfarmadoComponent;
  @ViewChild(NotificacionComponent) panelNoti: NotificacionComponent;

  step = 0;
  disableGral = false;
  disableArmado = true;
  disableNoti = true;
  notiArmado: Boolean;
  TxtNotiArmado = '';
  ramoBusqueda = '';
  prefijoOT = 'OT_';
  TxtNotiCA = '';
  TxtNotiCA2 = '';
  TxtErr = 'Faltan realizar Armados';
  RamoAutos = 'Autos';
  setStep(index: number) {
    this.step = index;
    if (this.step === 0) {
      this.disableGral = false;
      this.disableArmado = true;
      this.disableNoti = true;
    } else if (this.step === 1) {
      this.disableGral = true;
      this.disableArmado = false;
      this.disableNoti = true;
    } else if (this.step === 2) {
      this.disableGral = true;
      this.disableArmado = true;
      this.disableNoti = false;
    }
  }

  nextStep() {
    console.log('----->>>', this.step);
    switch (this.step) {
      case 1:
        if (
          this.panelGral.tipoImpresion.value.opcTipo ===
          this.panelGral.tiposImpr[0]
        ) {
          armadoJson.fisico = true;
          armadoJson.digital = false;
        } else if (
          this.panelGral.tipoImpresion.value.opcTipo ===
          this.panelGral.tiposImpr[1]
        ) {
          armadoJson.fisico = false;
          armadoJson.digital = true;
        }

        if (armadoJson.fisico === true) {
          // se valida si el tipo de impresion es fisica, para obtener sus datos
          this.panelCA.onSubmit();
          if (
            this.panelCA.passDatosArm === true &&
            this.panelCA.passDatosEnv === true
          ) {
            this.step++;
          }
        } else if (armadoJson.digital === true) {
          console.log('disparar listener', ListaJSON);
          this.panelCA.cargaCarpetas();
          console.log('=========================');
          console.log('isValid?', this.panelCA.validateEmpate());
          if (this.panelCA.validateEmpate()) {
            console.log('payloadEmpate', this.panelCA.payloadEmpate());
            estrucM[0].estructuraCar[0].listaDocumentosAnexos = [];
            for (const pair of this.panelCA.payloadEmpate().entries()) {
              if (pair[1].name) {
                estrucM[0].estructuraCar[0].listaDocumentosAnexos.push(
                  pair[1].name
                );
              } else {
                estrucM[0].estructuraCar[0].empate = JSON.parse(pair[1]);
              }
            }
            this.step++;
          }
          console.log(JSON.stringify(estrucM));
          console.log('=========================');
          //this.panelCA.cargaCarpetas();
          let numArmL = 0;
          let numArmC = 0;

          const control = 0;
          numArmL = ListaJSON.length;
          numArmC = estrucM.length;
          console.log(numArmL, numArmC);
          if (numArmL === numArmC) {
          } else {
            this.MsnError(this.TxtErr);
          }
          if (control === ListaJSON.length) {
            this.step++;
          }
        }
        break;
      default:
        const numSelected = this.panelGral.selection.selected.length;
        if (numSelected === 0 && (ListaJSON.length === 0 || ListaJSON === [])) {
          console.log('Aui Estoy');
          this.firstComponentFunction();
          this.openSnackBar(this.panelGral.TxtNot3, '');
        } else if (
          numSelected !== 0 &&
          (ListaJSON.length === 0 || ListaJSON === [])
        ) {
          console.log('caso 2');
          this.notiArmado = true;
          this.TxtNotiArmado = 'No selecciono una poliza';
          setTimeout(() => {
            this.notiArmado = false;
          }, this.panelCA.TimeNoti);
        } else if (
          numSelected !== 0 &&
          ListaJSON.length !== 0 &&
          ListaJSON !== []
        ) {
          console.log(ListaJSON);
          console.log('3');
          // this.panelGral.selectImpresion();
          this.panelCA.panelAF = Paneles.panelF;
          this.panelCA.panelAD = Paneles.panelD;

          // tslint:disable-next-line: max-line-length
          if (
            (this.panelCA.panelAD === undefined ||
              this.panelCA.panelAD === false) &&
            (this.panelCA.panelAF === undefined ||
              this.panelCA.panelAF === false)
          ) {
            this.notiArmado = true;
            this.TxtNotiArmado = 'No se seleccionó un tipo de impresión';
          } else {
            this.notiArmado = false;
            /* if (ListaJSON.length === 0 || ListaJSON === []){ // digM
              this.notiArmado = true;
              // this.TxtNtf27 = this.TxtNtf28
              this.TxtNotiArmado = 'No selecciono una poliza';
              setTimeout( () => {  this.notiArmado = false; }, this.panelCA.TimeNoti );
            }else{*/
            this.panelCA.comboDigMulti = ListaJSON;
            this.panelCA.edoConfArm = true;
            this.panelCA.notiTipoArmado();
            this.ramoBusqueda = opcRamoSelec;
            this.panelCA.opcRamoSelec = opcRamoSelec;
            this.panelCA.armadoFisicoForm.controls.ramo.setValue(
              this.ramoBusqueda
            );
            this.panelCA.armadoFisicoForm.controls.ramo.disable();
            this.panelCA.mostrarMsg = false; //muestra el mensaje del DnD de ArmDig
            this.panelCA.ordenaDocAutos();
            this.panelCA.ordenaDocVida();
            this.panelCA.ordenaDocGMM();
            this.panelCA.ordenaDocGMMAzul();
            this.step++;
            //}
          }
        } else if (
          numSelected === 0 &&
          ListaJSON.length !== 0 &&
          ListaJSON !== []
        ) {
          console.log(ListaJSON);
          console.log('4');
          // this.panelGral.selectImpresion();
          this.panelCA.panelAF = Paneles.panelF;
          this.panelCA.panelAD = Paneles.panelD;
          if (
            (this.panelCA.panelAD === undefined ||
              this.panelCA.panelAD === false) &&
            (this.panelCA.panelAF === undefined ||
              this.panelCA.panelAF === false)
          ) {
            this.notiArmado = true;
            this.TxtNotiArmado = 'No se seleccionó un tipo de impresión';
          } else {
            this.notiArmado = false;
            /* if (ListaJSON.length === 0 || ListaJSON === []){ // digM
              this.notiArmado = true;
              // this.TxtNtf27 = this.TxtNtf28
              this.TxtNotiArmado = 'No selecciono una poliza';
              setTimeout( () => {  this.notiArmado = false; }, this.panelCA.TimeNoti );
            }else{*/
            this.panelCA.comboDigMulti = ListaJSON;
            this.panelCA.edoConfArm = true;
            this.panelCA.notiTipoArmado();
            this.ramoBusqueda = opcRamoSelec;
            this.panelCA.opcRamoSelec = opcRamoSelec;
            this.panelCA.armadoFisicoForm.controls.ramo.setValue(
              this.ramoBusqueda
            );
            this.panelCA.armadoFisicoForm.controls.ramo.disable();
            this.panelCA.mostrarMsg = false; //muestra el mensaje del DnD de ArmDig
            this.panelCA.ordenaDocAutos();
            this.panelCA.ordenaDocVida();
            this.panelCA.ordenaDocGMM();
            this.panelCA.ordenaDocGMMAzul();
            this.step++;
            //}
          }
        }
        break;
    }
  }

  prevStep() {
    this.step--;
    switch (this.step) {
      case 0:
        armadoJson.digital = undefined;
        armadoJson.fisico = undefined;
        this.panelCA.cancelCA();
        break;
      case 1:
        this.panelCA.resetestrucM(); //reset de la variable estrucM, al dar clic en el boton "Atras"
        break;
      default:
        break;
    }
  }
  numOrden: number;
  banderaAutos: Boolean; //variable para indicar si se tiene un armado de autos
  onSubmit() {
    console.log('home');
    this.banderaAutos = false;
    //    this.panelGral.notiPolizasH2 = false;
    this.panelGral.notiPol = false;
    if (
      this.panelNoti.profileForm.value.nombreDestinatario !== '' &&
      this.panelNoti.profileForm.value.correo !== ''
    ) {
      console.log('1.1');
      this.panelNoti.notiDatosNoti = false;
      let validaCorreoOp = true;
      if (
        this.panelNoti.profileForm.value.correoOpcional !== '' &&
        this.panelNoti.profileForm.value.correoOpcional !== null
      ) {
        console.log('1.2');
        validaCorreoOp = this.panelNoti.correoActualOp;
      }
      let validaCel = true;
      // tslint:disable-next-line: max-line-length
      const celStr =
        this.panelNoti.profileForm.value.celular !== null
          ? this.panelNoti.profileForm.value.celular.toString()
          : this.panelNoti.profileForm.value.celular;
      if (
        this.panelNoti.profileForm.value.celular !== '' &&
        this.panelNoti.profileForm.value.celular !== null &&
        celStr.length !== 10
      ) {
        validaCel = false;
        console.log('1.3');
      }
      if (
        this.serviceValidator.esEmailValido(
          this.panelNoti.profileForm.value.correo
        ) &&
        validaCorreoOp &&
        validaCel
      ) {
        console.log('1.4');
        this.panelNoti.notiDatosNoti = false;
        datosNoti = {
          nombre: this.panelNoti.profileForm.value.nombreDestinatario,
          email: [
            this.panelNoti.profileForm.value.correo,
            this.panelNoti.profileForm.value.correoOpcional
          ],
          celular: [this.panelNoti.profileForm.value.celular]
        };
        console.log('1.5');
        var auxOT: resultOT;
        //var otNumber = Math.floor(Math.random() * (4957 - 1000)) + 1000;
        var jsonSave: OTModel;
        var otNumber = '';
        var archivoSave: OTModel;
        if (armadoJson.digital === true) {
          console.log('1.6');
          this.http
            .post<resultOT>(appSettings.URL_OT, {
              guardado: 'json',
              tipoArmado: 'ARMDI'
            })
            .subscribe({
              next: (data) => {
                auxOT = data; //se obtiene le sigueinte id de orden
                console.log('auxOT' + auxOT.otNext);
                otNumber = auxOT.otNext.substring(2);
                console.log('OTNumber' + otNumber);
                let numArm = 0;
                let num = 0;
                this.numOrden = Number(otNumber);
                for (let conPool of ListaJSON) {
                  //se obtiene los contratos/pool
                  for (let armCarp of estrucM) {
                    //se obtienen los armados
                    if (armCarp.id === conPool.id) {
                      if (conPool.ramo === this.RamoAutos) {
                        //condicion para saber si hay un armado de autos
                        this.banderaAutos = true;
                      }
                      listado = {
                        tipo: conPool.tipo,
                        ramo: conPool.ramo,
                        numConPol: conPool.numConPol,
                        listaPolizas: conPool.listaPolizas,
                        armadoEstructura: armCarp.estructuraCar
                      };
                    }
                  }
                  numArm = numArm + 1;
                  num = listadoM.length;
                  listadoM[num] = listado;
                }
                jsonD = {
                  //se realizan la estructura del json
                  idOrden: auxOT.otNext,
                  armados: armadoJson,
                  digital: {
                    contacto: datosNoti,
                    listaArmados: listadoM
                  }
                };

                console.log('============================================');
                const formData = new FormData();
                let index = 0;
                for (const pair of this.panelCA.payloadEmpate().entries()) {
                  if (pair[1].name) {
                    formData.append(`file${index}`, pair[1]);
                    index = index + 1;
                  }
                }

                formData.append('json', JSON.stringify(jsonD));
                this.empateService.uploadDocument(formData).subscribe(
                  (res) => {
                    console.log(res);
                  },
                  (err) => {
                    console.log(err);
                  }
                );
                console.log('=============================================');

                var auxb64 = window.btoa(
                  unescape(encodeURIComponent(JSON.stringify(jsonD)))
                );
                console.log('Base64: ' + auxb64);

                jsonSave = {
                  tipoArchivo: 'json',
                  tipoArmado: '',
                  guardado: '',
                  nombreArchivo: auxOT.otNext + '.json',
                  carpetaArchivo: otNumber,
                  otArchivo: otNumber,
                  ambiente: 'PROD',
                  bytesArchivo: auxb64
                };

                console.log('jsonToSave: ' + JSON.stringify(jsonSave));

                this.http
                  .post<resultFile>(appSettings.URL_OT, jsonSave)
                  .subscribe({
                    //se enviar el armado al Jboss
                    next: (data) => {
                      console.log('jsonToSave: ' + JSON.stringify(data));
                    },
                    error: (error) => {
                      console.error('There was an error!', error);
                    }
                  });
                if (this.banderaAutos === true) {
                  console.log('llamado al Jboss del Zip');
                  //solo agrega la url para el jboss del zip
                  /*  this.http.post<resultZip>(, jsonSave).subscribe({//se enviar el json para generar el zip
                  next: data => {
                    console.log('respuesta Jboss Zip ' + JSON.stringify(data));
                  },
                  error: error => {
                    console.error('There was an error!', error)
                  }
                });*/
                }
                console.log(OtrosArchivos);
                for (let archivo of OtrosArchivos) {
                  //se obtiene los archivos que se adjuntaron
                  archivoSave = {
                    tipoArchivo: 'otro',
                    tipoArmado: '',
                    guardado: '',
                    nombreArchivo:
                      this.prefijoOT + auxOT.otNext + '_' + archivo.nombreA,
                    carpetaArchivo: otNumber,
                    otArchivo: otNumber,
                    ambiente: 'PROD',
                    bytesArchivo: archivo.base64
                  };
                  console.log(archivoSave);
                  this.http
                    .post<resultFile>(appSettings.URL_OT, archivoSave)
                    .subscribe({
                      next: (data) => {
                        console.log('ArchivoToSave: ' + JSON.stringify(data));
                      },
                      error: (error) => {
                        console.error('There was an error!', error);
                      }
                    });
                }
                this.panelNoti.dyanmicDownloadByHtmlTag({
                  //se descagar el archivoJson
                  fileName: auxOT.otNext + '.json',
                  text: JSON.stringify(jsonD)
                });

                this.panelNoti.mostrarNotificacion('Enviar', auxOT.otNext);
              },
              error: (error) => {
                console.error('There was an error!', error);
              }
            });
          /*
          let numArm = 0;
          this.panelNoti.numOrden = Number(otNumber);
          let num = 0;
          for (let conPool of ListaJSON){
                for (let armCarp of estrucM){
                  if (armCarp.id === conPool.id){
                    listado = {
                      tipo: conPool.tipo,
                      ramo: conPool.ramo,
                      numConPol: conPool.numConPol,
                      listaPolizas: conPool.listaPolizas,
                      armadoEstructura: armCarp.estructuraCar,
                    };
                  }

                }
                numArm = numArm + 1;
                num = listadoM.length;
                listadoM[num] = listado;
              }

          jsonD = {
                idOrden: this.panelNoti.numOrden.toString(),
                armados: armadoJson,
                digital: {
                  contacto: datosNoti,
                  listaArmados: listadoM,
                }
              };

          var auxb64 = window.btoa(unescape(encodeURIComponent( JSON.stringify(jsonD) )));
          console.log('Base64: ' + auxb64);

          jsonSave = {
                tipoArchivo: 'json',
                tipoArmado: '',
                guardado: '',
                nombreArchivo: this.panelNoti.numOrden + '.json',
                carpetaArchivo: this.panelNoti.numOrden.toString(),
                otArchivo: this.panelNoti.numOrden.toString(),
                ambiente: 'QA',
                bytesArchivo: auxb64
              };

          console.log('jsonToSave: ' + JSON.stringify(jsonSave));

          this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
                next: data => {
                  console.log('jsonToSave: ' + JSON.stringify(data));
                },
                error: error => {
                  console.error('There was an error!', error);
                }
              });
              console.log(OtrosArchivos);
              for(let archivo of OtrosArchivos){
                archivoSave={
                  tipoArchivo:'otro',
                  tipoArmado:'',
                  guardado:'',
                  nombreArchivo:this.prefijoOT+this.panelNoti.numOrden.toString()+'_'+archivo.nombreA,
                  carpetaArchivo:this.panelNoti.numOrden.toString(),
                  otArchivo:this.panelNoti.numOrden.toString(),
                  ambiente:'QA',
                  bytesArchivo:archivo.base64
                };
                console.log(archivoSave)
                   this.http.post<resultFile>(appSettings.URL_OT, archivoSave).subscribe({
                    next: data => {
                      console.log('ArchivoToSave: '+ JSON.stringify(data));
                    },
                    error: error =>{
                      console.error('There was an error!', error)
                    }
                  });
              }

          this.panelNoti.dyanmicDownloadByHtmlTag({
                fileName: this.panelNoti.numOrden + '.json',
                text: JSON.stringify(jsonD)
              });

          this.panelNoti.mostrarNotificacion('Enviar');
        */
        } else if (armadoJson.fisico === true) {
          this.http
            .post<resultOT>(appSettings.URL_OT, {
              guardado: 'json',
              tipoArmado: 'ARMFI'
            })
            .subscribe({
              next: (data) => {
                auxOT = data; // se obtiene el id de la orden
                console.log('auxOT' + auxOT.otNext);
                otNumber = auxOT.otNext.substring(2);
                console.log('OTNumber' + otNumber);
                this.numOrden = Number(otNumber);
                jsonF = {
                  //se realiza la estructura del json
                  idOrden: auxOT.otNext,
                  armados: armadoJson,
                  fisico: datosfisico
                };

                var auxb64 = window.btoa(
                  unescape(encodeURIComponent(JSON.stringify(jsonF)))
                );
                console.log('Base64: ' + auxb64);

                jsonSave = {
                  //se arma el request de para guardar el Json
                  tipoArchivo: 'json',
                  tipoArmado: '',
                  guardado: '',
                  nombreArchivo: auxOT.otNext + '.json',
                  carpetaArchivo: otNumber,
                  otArchivo: otNumber,
                  ambiente: 'PROD',
                  bytesArchivo: auxb64
                };

                console.log('jsonToSave: ' + JSON.stringify(jsonSave));

                this.http
                  .post<resultFile>(appSettings.URL_OT, jsonSave)
                  .subscribe({
                    next: (data) => {
                      console.log('jsonToSave: ' + JSON.stringify(data));
                    },
                    error: (error) => {
                      console.error('There was an error!', error);
                    }
                  });

                this.panelNoti.dyanmicDownloadByHtmlTag({
                  fileName: auxOT.otNext + '.json',
                  text: JSON.stringify(jsonF)
                });

                this.panelNoti.mostrarNotificacion('Enviar', auxOT.otNext);
              },
              error: (error) => {
                console.error('There was an error!', error);
              }
            });
          /* this.panelNoti.numOrden = otNumber;

          jsonF = {
            idOrden: this.panelNoti.numOrden.toString(),
            armados: armadoJson,
            fisico: datosfisico
          };

          var auxb64 = window.btoa(unescape(encodeURIComponent( JSON.stringify(jsonF) )));
          console.log('Base64: ' + auxb64);

          jsonSave = {
            tipoArchivo: 'json',
            tipoArmado: '',
            guardado: '',
            nombreArchivo: this.panelNoti.numOrden.toString() + '.json',
            carpetaArchivo: this.panelNoti.numOrden.toString(),
            otArchivo: this.panelNoti.numOrden.toString(),
            ambiente: 'QA',
            bytesArchivo: auxb64
          };

          console.log('jsonToSave: ' + JSON.stringify(jsonSave));

          this.http.post<resultFile>(appSettings.URL_OT, jsonSave).subscribe({
              next: data => {
                console.log('jsonToSave: ' + JSON.stringify(data));
              },
              error: error => {
                console.error('There was an error!', error);
              }
            });

          this.panelNoti.dyanmicDownloadByHtmlTag({
              fileName: this.panelNoti.numOrden + '.json',
              text: JSON.stringify(jsonF)
            });

          this.panelNoti.mostrarNotificacion('Enviar');

        */
        }
      }
    } else {
      this.panelNoti.profileForm.reset();
      this.panelNoti.mensajeError = 'No se llenaron los campos obligatorios';
      this.panelNoti.notiDatosNoti = true;
    }
  }

  constructor(
    private eventEmitterService: EventEmitterService,
    private _snackBar: MatSnackBar,
    private serviceValidator: ValidatorService,
    protected http: HttpClient,
    private cdRef: ChangeDetectorRef,
    private empateService: EmpateDocumentacionService
  ) {}

  ngOnInit(): void {}

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  firstComponentFunction() {
    this.eventEmitterService.onFirstComponentButtonClick();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  cancel() {
    window.location.assign('/');
    this.panelGral.selection.clear();
  }

  finalCancel() {
    this.panelNoti.openDialog();
  }
  errorCA: Boolean;
  errorCA2: Boolean;
  MsnError(msn: string) {
    this.errorCA = true;
    this.TxtNotiCA = msn;
    setTimeout(() => {
      this.errorCA = false;
    }, 5000);
  }
  MsnError2(msn: string) {
    this.errorCA2 = true;
    this.errorCA = false;
    this.TxtNotiCA2 = msn;
    setTimeout(() => {
      this.errorCA2 = false;
    }, 5000);
  }
}
