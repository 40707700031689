import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTreeFlatDataSource } from '@angular/material/tree';
import { EmpateDocumentacionService } from '../../services/empate/empate-documentacion.service';
import { FileInput, FileValidator } from 'ngx-material-file-input';

@Component({
  selector: 'app-empate-upload',
  templateUrl: './empate-upload.component.html',
  styleUrls: ['./empate-upload.component.scss']
})
export class EmpateUploadComponent implements OnInit, OnChanges {
  @Input() carpetasInput: MatTreeFlatDataSource<any, any>;
  form: FormGroup;
  carpetas: Array<any> = [];
  files: Array<File>;

  validateErrors(): any {
    this.renderErrors();
    return this.form.valid;
  }

  onSubmit(): any {
    return this.payload();
  }

  constructor(private service: EmpateDocumentacionService) {
    this.form = new FormGroup({
      tipoArmado: new FormControl(),
      file: new FormControl(null, [
        FileValidator.maxContentSize(536870912),
        Validators.required
      ]), // 536870912 = 512MB
      carpetas: new FormControl()
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    changes.carpetasInput.currentValue._data.subscribe(
      (data: Array<any>) => (this.carpetas = data)
    );
  }

  onFileSelected() {
    console.log(this.form.get('file').errors);
    if (this.form.get('file').errors) {
      setTimeout(() => {
        this.deleteAllFiles();
        this.form.get('file').setErrors(null);
        this.form.get('file').updateValueAndValidity();
      }, 3000);
    } else {
      const fileForm: FileInput = this.form.get('file').value;
      this.files = fileForm.files;
    }
  }

  deleteAllFiles() {
    this.form.get('file').reset();
    this.files = [];
  }

  deleteFileSelected(file: File) {
    const indexOfFile = this.files.indexOf(file);
    this.files.splice(indexOfFile, 1);
    if (!this.files.length) this.form.get('file').reset();
  }

  renderFileIcon(type: string): string {
    if (type === 'application/zip') {
      return 'folder_zip';
    } else if (type === 'application/pdf') {
      return 'picture_as_pdf';
    }
    return 'difference';
  }

  payload() {
    const extraData = this.form.value;
    extraData.poliza = localStorage.getItem('polizaPadre');
    delete extraData.file;
    const fileForm: FileInput = this.form.get('file').value;
    const formData = new FormData();
    fileForm.files.forEach((item, index) =>
      formData.append(`file${index}`, item)
    );
    formData.append('json', JSON.stringify(extraData));
    return formData;
  }

  renderErrors() {
    console.log('rendering errors');
  }
}
