<style>
  #btn-buscar {
    color: #ffffff !important;
    background-color: #fc6c25 !important;
    margin-top: 20px !important;
    border-radius: 4px;
    border-color: #fc6c25 !important;
  }

  #btn-buscar:hover {
    color: #ffffff !important;
    background-color: #ffb022 !important;
    border-radius: 4px;
  }

  #btn-buscar:active {
    color: #ffffff !important;
    background-color: #ff8d21 !important;
    border-radius: 4px;
    border-color: #fc6c25 !important;
  }

  #btn-cancel {
    color: #fc6c25 !important;
    background-color: #ffffff !important;
    margin-top: 20px !important;
    border-radius: 4px;
    border-color: #fc6c25 !important;
  }

  #btn-cancel:hover {
    color: #ffffff !important;
    background-color: #ffb022 !important;
    border-radius: 4px;
    border-color: #fc6c25 !important;
  }

  #btn-cancel:active {
    color: #ffffff !important;
    background-color: #ff8d21 !important;
    border-radius: 4px;
    border-color: #fc6c25 !important;
  }
</style>
<div mat-dialog-content>
  <h1
    style="
      color: #002e71 !important;
      font-family: 'Open Sans', sans-serif !important;
      font-weight: bold !important;
    "
  >
    Cancelar armado
  </h1>
  <p
    style="
      color: #002e71 !important;
      font-family: 'Open Sans', sans-serif !important;
    "
  >
    ¿Está seguro que desea cancelar su armado?
  </p>
</div>
<div mat-dialog-actions>
  <div class="row">
    <div class="col-sm-2"></div>
    <button id="btn-cancel" class="col-sm-3" mat-button (click)="onNoClick()">
      No
    </button>
    <div class="col-sm-2"></div>
    <button id="btn-buscar" class="col-sm-3" mat-button (click)="onClick()">
      Sí
    </button>
    <div class="col-sm-2"></div>
  </div>
</div>
