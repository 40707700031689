import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidatorService {
  constructor() {}
  /*esEmailValido(email: string): boolean {
    let mailValido = false;
    const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(EMAIL_REGEX)) {
      mailValido = true;
    }
    return mailValido;
  }*/

  esEmailValido(email: string): boolean {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  esCelularValido(numCelular: string): boolean {
    /*let celularValido = false;
    const CELULAR_REGEX = /^[0-9-]{8,12}$/;
    if (numCelular.match(CELULAR_REGEX)) {
      celularValido = true;
    }
    return celularValido;*/
    const CELULAR_REGEX = /^[0-9-]{8,12}$/;
    return CELULAR_REGEX.test(numCelular);
  }
  esNumeroValido(numero: string): boolean {
    const NUMERO_REGEX = /^[0-9]+$/;
    return NUMERO_REGEX.test(numero);
  }
}
