<!--mat-expansion-panel id="panelDatosGenerales" (click)="iniDatosGenerales()"-->
<form [formGroup]="datosGralForm" (ngSubmit)="onSubmitDG()">
  <div class="form-group row">
    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl1 }}</mat-label>
      <mat-select
        formControlName="ramoDG"
        (selectionChange)="tipoRamo()"
        required
      >
        <mat-option *ngFor="let ramo of ramos" form [value]="ramo.name">
          {{ ramo.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="ramoDG.hasError('required') && ramoDG.untouched">
        {{ TxtNot1 }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ Txtlbl2 }}</mat-label>
      <mat-select
        (selectionChange)="someMethod($event.value)"
        formControlName="criterio"
        required
      >
        <mat-option *ngFor="let criterio of criterios" [value]="criterio.name">
          {{ criterio.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="criterio.hasError('required')">
        {{ TxtNot1 }}
      </mat-error>
    </mat-form-field>

    <mat-form-field class="col-sm-3" appearance="outline">
      <mat-label>{{ placeholder }}</mat-label>
      <input
        type="text"
        formControlName="txt_criterio"
        [(ngModel)]="ngCriterio"
        matInput
        [placeholder]="placeholder"
        required
      />
      <mat-error *ngIf="txt_criterio.hasError('required')">
        {{ TxtNot1 }}
      </mat-error>
    </mat-form-field>

    <div class="col-sm-3">
      <button type="submit" class="col-sm-12" mat-raised-button id="btn-buscar">
        {{ Txtbtn1 }}
      </button>
    </div>
  </div>
  <div class="form-group row" *ngIf="proBuscar">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <div class="form-group row">
    <div class="col-sm-3"></div>
    <div [hidden]="!notiBuscarP" class="notiError">
      {{ TxtNot2 }}
    </div>
  </div>

  <div class="form-group row">
    <p class="subtitle">{{ Txtlbl3 }}</p>
  </div>

  <div class="form-group row">
    <form [formGroup]="tipoImpresion">
      <mat-radio-group
        class="example-margin subtitle"
        formControlName="opcTipo"
        (change)="selectImpresion()"
      >
        <mat-radio-button
          style="margin-left: 25px"
          *ngFor="let tipo of tiposImpr"
          [value]="tipo"
        >
          {{ tipo }}
        </mat-radio-button>
      </mat-radio-group>
    </form>
  </div>
  <div class="form-group row">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
            [aria-label]="checkboxLabel()"
          >
          </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            class="check"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
            [aria-label]="checkboxLabel(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>

      <!-- Poliza Column -->
      <ng-container matColumnDef="poliza">
        <th mat-header-cell *matHeaderCellDef>Pólizas</th>
        <td mat-cell *matCellDef="let element">{{ element.poliza }}</td>
      </ng-container>

      <!-- Ramo Column -->
      <ng-container matColumnDef="ramo">
        <th mat-header-cell *matHeaderCellDef>Ramo</th>
        <td mat-cell *matCellDef="let element">{{ element.ramo }}</td>
      </ng-container>

      <!-- Contratante Column -->
      <ng-container matColumnDef="contratante">
        <th mat-header-cell *matHeaderCellDef>Contratante</th>
        <td mat-cell *matCellDef="let element">{{ element.razonSocial }}</td>
      </ng-container>

      <!-- Intermediario Column -->
      <ng-container matColumnDef="intermediario">
        <th mat-header-cell *matHeaderCellDef>Intermediario</th>
        <td mat-cell *matCellDef="let element">
          {{ element.codIntermediario }}
        </td>
      </ng-container>

      <!-- Vigencia Column -->
      <ng-container matColumnDef="vigencia">
        <th mat-header-cell *matHeaderCellDef>Inicio de vigencia</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fchVigenciaFinal }}
        </td>
      </ng-container>

      <!-- Vigencia Column -->
      <ng-container matColumnDef="fechaPublicacion">
        <th mat-header-cell *matHeaderCellDef>
          <mat-form-field class="input-full-width">
            <mat-label>Fecha de publicación</mat-label>
            <input
              matInput
              readonly
              [matDatepicker]="picker"
              (dateChange)="applyFilter($event.value)"
              #fechaPublicacionFilter
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <button mat-icon-button type="button" (click)="clearFilter()">
            <mat-icon>cached</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.fchVigenciaFinal }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="element-row"
        (click)="selection.toggle(row)"
      ></tr>
    </table>
  </div>
</form>

<mat-toolbar>
  <mat-toolbar-row>
    <button
      class="col-sm-3"
      style="margin-top: 0px !important"
      mat-raised-button
      (click)="mostrarPolizasH()"
      id="btn-buscar"
    >
      {{ Txtbtn2 }}
    </button>
    <span class="example-spacer"></span>
    <mat-paginator
      class="paginator"
      [pageSizeOptions]="[5, 10, 20]"
    ></mat-paginator>
  </mat-toolbar-row>
</mat-toolbar>

<div class="form-group row">
  <p class="subtitle">{{ Txtlbl4 }}</p>
  <div class="col-sm-3"></div>
  <div *ngIf="notiPolizasH" class="notiError">
    <!-- Notificacion de polizasH -->
    {{ TxtNot11 }}
  </div>
  <div [hidden]="!notiPol" class="notiError">
    <!-- Notificacion de polizasH -->
    {{ TxtNot12 }}
  </div>
  <div [hidden]="true">
    <!-- Notificacion de polizasH -->
    <form [formGroup]="test">
      <input formControlName="opcTipoTest" />
      <button id="botonOculto"></button>
    </form>
  </div>
</div>

<mat-card class="example-card" style="height: 300px; width: 100%">
  <mat-card-header> {{ Txtlbl5 }} </mat-card-header>
  <mat-card-content>
    <cdk-virtual-scroll-viewport itemSize="50" class="example-viewportPolizas">
      <mat-tree [dataSource]="dataSource3" [treeControl]="treeControl">
        <mat-tree-node
          class="tree element-row2"
          *matTreeNodeDef="let node"
          matTreeNodePadding
        >
          <button mat-icon-button disabled></button>
          {{ node.name }}
        </mat-tree-node>
        <mat-tree-node
          class="tree element-row2"
          *matTreeNodeDef="let node; when: hasChild"
          matTreeNodePadding
        >
          <button
            mat-icon-button
            matTreeNodeToggle
            [attr.aria-label]="'toggle ' + node.name"
          >
            <mat-icon class="boton2">
              {{
                treeControl.isExpanded(node)
                  ? 'remove_circle_outline'
                  : 'add_circle_outline'
              }}
            </mat-icon>
          </button>
          {{ node.name }}
        </mat-tree-node>
      </mat-tree>
    </cdk-virtual-scroll-viewport>
  </mat-card-content>
</mat-card>
<!-- <div class="form-group row justify-content-end">
     <div class="col-sm-3">
          <button class="col-sm-12"type="button" mat-raised-button
           data-toggle="collapse" data-target="#headerPanelDatos" id="btn-buscar"
          aria-expanded="false" aria-controls="headerPanelDatos" (click)="cancel()">
          {{TxlBtCnl}}
        </button>
     </div>
     <div class="col-sm-3">
         <button class="col-sm-12"type="button" mat-raised-button
          data-toggle="collapse" data-target="#headerPanelDatos" id="btn-buscar"
         aria-expanded="false" aria-controls="headerPanelDatos" (click)="continuarDG()" [disabled]="notiPolizasH">
         {{TxlBtCnt}}
       </button>
    </div>
 </div>-->
<!-- </form> -->
<!--/mat-expansion-panel-->
