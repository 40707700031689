<div (click)="configIni()">
  <!--mat-expansion-panel id="panelConfArmado" (click)="configIni()"-->
  <!--mat-expansion-panel-header>
        <mat-panel-title id="panelConfArmadoH" (click)="notiTipoArmado()">
            {{TxtStep2}}
            <div [hidden]="!notiArmado" class="notiError2">
                {{TxtNtf27}}
             </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-divider></mat-divider  (change)="validaArmado()"-->
  <mat-tab-group>
    <mat-tab label="Armado Digital" *ngIf="panelAD">
      <div class="form-group row">
        <mat-form-field class="col-sm-3" appearance="outline">
          <mat-label>{{ Txtlbl1 }}</mat-label>
          <mat-select
            [(ngModel)]="selectRamo"
            (selectionChange)="tipoArmadoRamo()"
            required
          >
            <mat-option
              *ngFor="let arlCarp of comboDigMulti"
              form
              [value]="arlCarp.id"
            >
              {{ arlCarp.ramo }}/{{ arlCarp.numConPol }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group row">
        <div class="col-sm-6">
          <mat-card class="example-card carpeta">
            <mat-card-content>
              <div>
                <div *ngFor="let item of carpetas2">
                  <mat-icon>folder_open</mat-icon>{{ item.tipo }}/{{
                    item.nombre
                  }}
                  <!-- nmuestro el nombre del nuevo elemento -->
                  <button mat-icon-button [matMenuTriggerFor]="appMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #appMenu="matMenu">
                    <!-- menu del nuevo elemento -->
                    <ng-template matMenuContent let-name="name">
                      <button
                        mat-menu-item
                        class="element-row"
                        (click)="mostrarSubcarpeta([item.nombre], 'N/A')"
                        [hidden]="isPdfUnif"
                      >
                        Crear carpeta
                      </button>
                      <button
                        mat-menu-item
                        class="element-row"
                        (click)="opcRenombrar([item.nombre])"
                      >
                        Cambiar Nombre
                      </button>
                      <button
                        mat-menu-item
                        class="element-row"
                        (click)="mostrarContenido([item.nombre], 'N/A')"
                      >
                        Mostrar Contenido
                      </button>
                      <!--    <button mat-menu-item class="element-row" (click)="eliminarCarpeta([item.nombre])">{{Txtlbl5}}</button> -->
                    </ng-template>
                  </mat-menu>

                  <div
                    style="margin-left: 25px"
                    *ngFor="let item2 of item.subcarpeta"
                  >
                    <mat-icon>folder_open</mat-icon>{{ item2.tipo }}/{{
                      item2.nombre
                    }}
                    <button
                      mat-icon-button
                      [matMenuTriggerFor]="appMenu"
                      (click)="ocualtarCrearCarpAdjuntos(item2.tipo)"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                      <!-- menu del nuevo elemento -->
                      <ng-template matMenuContent let-name="name">
                        <button
                          mat-menu-item
                          class="element-row"
                          (click)="
                            mostrarSubcarpeta(
                              [item.nombre, item2.nombre],
                              item2.nivel
                            )
                          "
                          [hidden]="isCarpetaAdjAne"
                        >
                          Crear carpeta
                        </button>
                        <button
                          mat-menu-item
                          class="element-row"
                          (click)="opcRenombrar([item.nombre, item2.nombre])"
                        >
                          Cambiar Nombre
                        </button>
                        <button
                          mat-menu-item
                          class="element-row"
                          (click)="
                            mostrarContenido(
                              [item.nombre, item2.nombre],
                              item2.nivel
                            )
                          "
                        >
                          Mostrar Contenido
                        </button>
                        <button
                          mat-menu-item
                          class="element-row"
                          (click)="eliminarCarpeta([item.nombre, item2.nombre])"
                        >
                          {{ Txtlbl5 }}
                        </button>
                      </ng-template>
                    </mat-menu>
                    <div
                      style="margin-left: 25px"
                      *ngFor="let item3 of item2.subcarpeta"
                    >
                      <mat-icon>folder_open</mat-icon>{{ item3.tipo }}/{{
                        item3.nombre
                      }}
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="appMenu"
                        (click)="ocualtarCrearCarpAdjuntos(item3.tipo)"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #appMenu="matMenu">
                        <!-- menu del nuevo elemento -->
                        <ng-template matMenuContent let-name="name">
                          <button
                            mat-menu-item
                            class="element-row"
                            (click)="
                              mostrarSubcarpeta(
                                [item.nombre, item2.nombre, item3.nombre],
                                item3.nivel
                              )
                            "
                            [hidden]="isCarpetaAdjAne"
                          >
                            Crear carpeta
                          </button>
                          <button
                            mat-menu-item
                            class="element-row"
                            (click)="
                              opcRenombrar([
                                item.nombre,
                                item2.nombre,
                                item3.nombre
                              ])
                            "
                          >
                            Cambiar Nombre
                          </button>
                          <button
                            mat-menu-item
                            class="element-row"
                            (click)="
                              mostrarContenido(
                                [item.nombre, item2.nombre, item3.nombre],
                                item3.nivel
                              )
                            "
                          >
                            Mostrar Contenido
                          </button>
                          <button
                            mat-menu-item
                            class="element-row"
                            (click)="
                              eliminarCarpeta([
                                item.nombre,
                                item2.nombre,
                                item3.nombre
                              ])
                            "
                          >
                            {{ Txtlbl5 }}
                          </button>
                        </ng-template>
                      </mat-menu>
                      <div
                        style="margin-left: 25px"
                        *ngFor="let item4 of item3.subcarpeta"
                      >
                        <mat-icon>folder_open</mat-icon>{{ item4.tipo }}/{{
                          item4.nombre
                        }}
                        <button mat-icon-button [matMenuTriggerFor]="appMenu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #appMenu="matMenu">
                          <!-- menu del nuevo elemento -->
                          <ng-template matMenuContent let-name="name">
                            <button
                              mat-menu-item
                              class="element-row"
                              (click)="
                                opcRenombrar([
                                  item.nombre,
                                  item2.nombre,
                                  item3.nombre,
                                  item4.nombre
                                ])
                              "
                            >
                              Cambiar Nombre
                            </button>
                            <button
                              mat-menu-item
                              class="element-row"
                              (click)="
                                mostrarContenido(
                                  [
                                    item.nombre,
                                    item2.nombre,
                                    item3.nombre,
                                    item4.nombre
                                  ],
                                  item4.nivel
                                )
                              "
                            >
                              Mostrar Contenido
                            </button>
                            <button
                              mat-menu-item
                              class="element-row"
                              (click)="
                                eliminarCarpeta([
                                  item.nombre,
                                  item2.nombre,
                                  item3.nombre,
                                  item4.nombre
                                ])
                              "
                            >
                              {{ Txtlbl5 }}
                            </button>
                          </ng-template>
                        </mat-menu>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
          <div class="form-group row" [hidden]="!nueva">
            <form [formGroup]="nuevaCarpeta" (ngSubmit)="crearCarpeta()">
              <mat-form-field class="col-sm-4" appearance="outline">
                <mat-label>{{ Txtlbl33 }}</mat-label>
                <mat-select formControlName="tipoCarpeta">
                  <mat-option
                    *ngFor="let tipos of tiposCarpetas"
                    [value]="tipos.name"
                    required
                  >
                    {{ tipos.name }}
                  </mat-option>
                  <mat-error *ngIf="tipoCarpeta.hasError('required')">
                    {{ TxtNtf1 }}
                  </mat-error>
                </mat-select>
              </mat-form-field>
              <mat-form-field class="col-sm-4" appearance="outline">
                <mat-label>Nombre Carpeta</mat-label>
                <input
                  type="text"
                  matInput
                  [formControl]="nombreCarpeta"
                  placeholder="Nombre de la carpeta"
                  required
                  onkeypress="return event.keyCode != 13;"
                />
                <mat-error *ngIf="nombreCarpeta.hasError('required')">
                  {{ TxtNtf1 }}
                </mat-error>
              </mat-form-field>
              <div class="form-group row">
                <div class="col-sm-1"></div>
                <button
                  class="col-sm-3"
                  mat-raised-button
                  (click)="opcionCancelar = 'cancelar'"
                  id="btn-buscar"
                >
                  Cancelar
                </button>
                <div class="col-sm-1"></div>
                <button
                  class="col-sm-3"
                  type="submit"
                  (click)="opcionCancelar = 'No cancelar'"
                  mat-raised-button
                  id="btn-buscar"
                >
                  Aceptar
                </button>
              </div>
            </form>
          </div>
          <div class="form-group row" [hidden]="!renombrar">
            <mat-form-field
              class="col-sm-5"
              style="margin-top: 5px"
              appearance="outline"
            >
              <mat-label>Renombar Carpeta</mat-label>
              <input
                type="text"
                matInput
                [formControl]="renombreCarpeta"
                placeholder="Nombre de la carpeta"
                required
              />
              <mat-error *ngIf="renombreCarpeta.hasError('required')">
                {{ TxtNtf1 }}
              </mat-error>
            </mat-form-field>
            <div class="col-sm-3">
              <button
                class="col-sm-12"
                mat-raised-button
                (click)="cancelarCambio()"
                id="btn-buscar"
              >
                Cancelar
              </button>
            </div>
            <div class="col-sm-3">
              <button
                class="col-sm-12"
                mat-raised-button
                (click)="renombrarCarp()"
                id="btn-buscar"
              >
                Aceptar
              </button>
            </div>
          </div>

          <div [hidden]="!notiCarpetas" class="notiError5">
            {{ TxtNtf12 }}
          </div>
          <div [hidden]="!notiArchivos" class="{{ estiloNoti }}">
            {{ TxtNtf20 }}
          </div>
          <!--  <div class="form-group row" [hidden]="!renombrar">

                        <mat-form-field class="col-sm-5" style="margin-top: 5px;" appearance="outline">
                            <mat-label>Renombar Carpeta</mat-label>
                            <input type="text"  matInput [formControl]="renombreCarpeta" placeholder="Nombre de la carpeta" required>
                            <mat-error *ngIf="renombreCarpeta.hasError('required')">
                            {{TxtNtf1}}
                            </mat-error>
                        </mat-form-field>
                        <div class="col-sm-3">
                            <button  class="col-sm-12" mat-raised-button (click)="cancelarCambio()" id="btn-buscar">Cancelar</button>
                        </div>
                        <div class="col-sm-3">
                            <button class="col-sm-12" mat-raised-button (click)="renombrarCarp()" id="btn-buscar">Aceptar</button>
                        </div>
                   </div> -->
        </div>

        <div class="col-sm-6">
          <h3 class="title" style="margin-top: 25px">{{ Txtlbl27 }}</h3>
          <mat-card class="example-card treeDoc">
            <mat-card-header> </mat-card-header>
            <mat-card-content>
              <cdk-virtual-scroll-viewport
                itemSize="50"
                class="example-viewportDoc"
              >
                <mat-tree
                  [dataSource]="dataSource2"
                  [treeControl]="treeControl"
                >
                  <mat-tree-node
                    class="tree element-row"
                    *matTreeNodeDef="let node"
                    matTreeNodePadding
                  >
                    <!-- use a disabled button to provide padding for tree leaf -->
                    <button mat-icon-button disabled></button>
                    {{ node.name }}
                    <button mat-icon-button [matMenuTriggerFor]="appMenu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #appMenu="matMenu">
                      <!-- menu del nuevo elemento -->
                      <ng-template matMenuContent let-name="name">
                        <button
                          mat-menu-item
                          class="element-row"
                          (click)="selectCarpeta(node.name)"
                        >
                          Agregar a carpeta
                        </button>
                      </ng-template>
                    </mat-menu>
                  </mat-tree-node>
                  <mat-tree-node
                    class="tree element-row"
                    *matTreeNodeDef="let node; when: hasChild"
                    matTreeNodePadding
                  >
                    <button
                      mat-icon-button
                      matTreeNodeToggle
                      [attr.aria-label]="'toggle ' + node.name"
                      style="color: #ff6b0b !important"
                    >
                      <mat-icon class="mat-icon-rtl-mirror maticon">
                        {{
                          treeControl.isExpanded(node)
                            ? 'expand_more'
                            : 'chevron_right'
                        }}
                      </mat-icon>
                    </button>
                    {{ node.name }}
                  </mat-tree-node>
                </mat-tree>
              </cdk-virtual-scroll-viewport>
            </mat-card-content>
          </mat-card>
          <div [hidden]="!dropFilesD" class="notiError7">
            {{ TxtNtf32 }}
          </div>
        </div>
      </div>
      <app-empate-upload [carpetasInput]="dataSource2"></app-empate-upload>
    </mat-tab>
    <mat-tab label="Armado Fisico" *ngIf="panelAF">
      <!--mat-expansion-panel-header-->
      <mat-panel-title>
        {{ TxtStep4 }}
      </mat-panel-title>
      <!--/mat-expansion-panel-header-->
      <mat-divider></mat-divider>

      <form [formGroup]="armadoFisicoForm" (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl7 }}</mat-label>
            <input
              type="text"
              formControlName="fechaSolEntrega"
              matInput
              [placeholder]="placeholder"
              [matDatepicker]="picker"
              [min]="today"
              required
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="fechaSolEntrega.hasError('required')">
              {{ TxtNtf1 }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl1 }}</mat-label>
            <input
              type="text"
              formControlName="ramo"
              [placeholder]="placeholder"
              matInput
            />
          </mat-form-field>
        </div>
        <div class="form-group row">
          <mat-checkbox
            *ngIf="opcRamoSelec == 'GMM' || opcRamoSelec == 'Vida'"
            formControlName="bloquesGmmVida"
            class="col-sm-2"
          >
            {{ Txtlbl8 }}
          </mat-checkbox>
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl10 }}</mat-label>
            <input
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              formControlName="contidadPolizas"
              matInput
              [placeholder]="placeholder"
              (keydown.space)="$event.preventDefault()"
              required
            />
            <mat-error *ngIf="contidadPolizas.hasError('required')">
              {{ TxtNtf1 }}
            </mat-error>
            <mat-error *ngIf="!contidadPolizas.hasError('pattern')">
              {{ TxtNtf2 }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl11 }}</mat-label>
            <input
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              formControlName="totalDependientes"
              matInput
              [placeholder]="placeholder"
              (keydown.space)="$event.preventDefault()"
              required
            />
            <mat-error *ngIf="totalDependientes.hasError('required')">
              {{ TxtNtf1 }}
            </mat-error>
            <mat-error *ngIf="!contidadPolizas.hasError('pattern')">
              {{ TxtNtf2 }}
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl12 }}</mat-label>
            <input
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              formControlName="totalTitulares"
              matInput
              [placeholder]="placeholder"
              (keydown.space)="$event.preventDefault()"
              required
            />
            <mat-error *ngIf="totalTitulares.hasError('required')">
              {{ TxtNtf1 }}
            </mat-error>
            <mat-error *ngIf="!contidadPolizas.hasError('pattern')">
              {{ TxtNtf2 }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group row">
          <mat-checkbox
            *ngIf="opcRamoSelec == 'Autos'"
            formControlName="bloquesAutos"
            class="col-sm-2"
          >
            {{ Txtlbl8 }}
          </mat-checkbox>
          <mat-checkbox
            *ngIf="opcRamoSelec == 'Autos'"
            formControlName="folder"
            class="check col-sm-1"
          >
            {{ Txtlbl13 }}
          </mat-checkbox>
          <mat-checkbox
            *ngIf="opcRamoSelec == 'GMM' || opcRamoSelec == 'Vida'"
            formControlName="carteras"
            class="col-sm-2"
          >
            Condiciones<br />Generales
          </mat-checkbox>
          <mat-form-field class="col-sm-3" appearance="outline">
            <mat-label>{{ Txtlbl14 }}</mat-label>
            <input
              type="number"
              min="0"
              oninput="validity.valid||(value='');"
              formControlName="cantidadUnidades"
              matInput
              [placeholder]="placeholder"
              (keydown.space)="$event.preventDefault()"
              required
            />
            <mat-error *ngIf="cantidadUnidades.hasError('required')">
              {{ TxtNtf1 }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="form-group row">
          <mat-form-field class="col-sm-12" appearance="outline">
            <mat-label>{{ Txtlbl15 }}</mat-label>
            <textarea
              rows="15"
              formControlName="caracteristicasAF"
              matInput
              placeholder="Agregar características"
              style="overflow: scroll; resize: none"
            ></textarea>
          </mat-form-field>
        </div>
        <div class="form-group row">
          <div class="col-sm-3">
            <mat-checkbox
              class="example-margin"
              formControlName="checkEmpate"
              style="margin-left: 20px"
              (click)="agregarArchivos()"
              (change)="empateImpresion()"
              >{{ Txtlbl30 }}</mat-checkbox
            >
          </div>
          <div class="col-sm-3"></div>
          <div class="col-sm-3">
            <div [hidden]="!notiDatosArmado" class="notiError3">
              {{ TxtNtf5 }}
            </div>
          </div>
        </div>
        <!--Revisar div-->
        <div class="form-group row" *ngIf="mostrar">
          <mat-card
            class="example-card containerFilesFisico"
            appDnd
            (fileDropped)="onFileDroppedFisico($event)"
            (change)="empateImpresion()"
          >
            <cdk-virtual-scroll-viewport
              itemSize="50"
              class="example-viewportArmado"
            >
              <p *ngIf="!mostrarMsgFisico" class="msgDropFisico">
                {{ Txtlbl2 }}<br />{{ Txtlbl3 }}
              </p>
              <div
                class="single-file"
                *ngFor="let file of filesFisico; let i = index"
              >
                <div class="containerInfoArch center-h center-v">
                  <div>
                    <h4 class="name size3">
                      {{ file?.name }}
                    </h4>
                  </div>
                  <div></div>
                  <div class="containerInfoArch center-h center-v">
                    <div class="name size1">{{ formatBytes(file?.size) }}</div>
                    <div>
                      <mat-icon class="delete" (click)="deleteFileFisico(i)"
                        >close</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </mat-card>
        </div>
        <div [hidden]="!dropFiles" class="notiError6">Sólo archivos PDF</div>

        <!--mat-expansion-panel-header-->
        <mat-panel-title>
          {{ TxtStep5 }}
        </mat-panel-title>
        <!--/mat-expansion-panel-header-->
        <mat-divider></mat-divider>
        <form [formGroup]="entregaFisicaForm">
          <div class="form-group row">
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl17 }}</mat-label>
              <input
                type="text"
                formControlName="lugarEnvio"
                matInput
                (change)="cambio()"
                [placeholder]="placeholder"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl18 }}</mat-label>
              <input
                type="text"
                matInput
                formControlName="calle"
                [placeholder]="placeholder"
                [value]="datosEnvio.calle"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl19 }}</mat-label>
              <input
                type="text"
                id="numero"
                matInput
                [placeholder]="placeholder"
                formControlName="numero"
                [value]="datosEnvio.numero"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl20 }}</mat-label>
              <input
                type="text"
                id="colonia"
                matInput
                [placeholder]="placeholder"
                formControlName="colonia"
                [value]="datosEnvio.colonia"
                (change)="onSubmit()"
              />
            </mat-form-field>
          </div>
          <div class="form-group row">
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl21 }}</mat-label>
              <input
                type="text"
                id="delegacion"
                matInput
                [placeholder]="placeholder"
                formControlName="delegacion"
                [value]="datosEnvio.delegacion"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl22 }}</mat-label>
              <input
                type="text"
                id="ciudad"
                matInput
                [placeholder]="placeholder"
                formControlName="ciudad"
                [value]="datosEnvio.ciudad"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl23 }}</mat-label>
              <input
                type="text"
                id="codigoPostal"
                matInput
                [placeholder]="placeholder"
                formControlName="codigoPostal"
                [value]="datosEnvio.codigo_postal"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl34 }}</mat-label>
              <input
                type="text"
                id="nombreDest"
                matInput
                [placeholder]="placeholder"
                formControlName="contacto"
                [value]="datosEnvio.contacto"
                (change)="onSubmit()"
              />
            </mat-form-field>
          </div>
          <div class="form-group row">
            <mat-form-field class="col-sm-3" appearance="outline">
              <mat-label>{{ Txtlbl24 }}</mat-label>
              <input
                type="text"
                id="telefono"
                matInput
                [placeholder]="placeholder"
                formControlName="telefono_d"
                [value]="datosEnvio.telefono_d"
                (change)="onSubmit()"
              />
            </mat-form-field>
            <mat-checkbox class="col-sm-3" (change)="habilitaForm()">
              {{ Txtlbl25 }}
            </mat-checkbox>
            <div [hidden]="!notiCodEnvio" class="notiError4">
              {{ TxtNtf8 }}
            </div>
          </div>
          <div class="form-group row">
            <mat-form-field class="col-sm-12" appearance="outline">
              <mat-label>{{ Txtlbl26 }}</mat-label>
              <textarea
                matInput
                rows="10"
                formControlName="referencias"
                placeholder="Agregar referencias de entrega"
                style="overflow: scroll; resize: none"
              ></textarea>
            </mat-form-field>
          </div>
        </form>
      </form>
    </mat-tab>
  </mat-tab-group>
  <!--  <div class="form-group row justify-content-end">
        <div class="col-sm-3">
             <button class="col-sm-12"type="button" mat-raised-button
              data-toggle="collapse" data-target="#headerPanelDatos" id="btn-buscar"
             aria-expanded="false" aria-controls="headerPanelDatos" (click)="cancelCA()">
             {{TxlBtCnl}}
           </button>
        </div>
        <div class="col-sm-3">
            <button class="col-sm-12"type="button" mat-raised-button
             data-toggle="collapse" data-target="#headerPanelDatos" id="btn-buscar"
            aria-expanded="false" aria-controls="headerPanelDatos" (click)="continuarCA()">
            {{TxlBtCnt}}
          </button>
       </div>
    </div> -->
  <!--/mat-expansion-panel-->
</div>
