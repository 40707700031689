<!--h1 mat-dialog-title>Hi {{this.data.ramos}}</h1>
<h2 mat-dialog-title>Hola {{this.data.fecha}}</h2-->
<h1 mat-dialog-title>
  Seleccione la carpeta en la que desea colocar el archivo
</h1>
<div mat-dialog-content>
  <div class="container mt-3">
    <div class="form-group row">
      <mat-form-field class="col-sm-6" appearance="outline">
        <mat-label>Carpetas</mat-label>
        <mat-select id="ramo" [(ngModel)]="data.carpeta">
          <mat-option
            *ngFor="let ramo of data.ramos"
            [value]="[ramo.name,ramo.nivel,ramo.nivelP]"
            required
          >
            {{ramo.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button
    mat-button
    [mat-dialog-close]="data.carpeta"
    id="btn-buscar"
    style="margin-left: 60%"
  >
    Aceptar
  </button>
  <!--  <button mat-button (click)="mensaje()">Ok</button> -->
</div>
